import { Component, OnInit, ViewChild, } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';
import { OutstandingSummary } from '@/models/outstanding-payment';
import { OutstandingService } from '@services/outstanding-services';


@Component({
  selector: 'app-outstanding',
  templateUrl: './outstanding.component.html',
  styleUrls: ['./outstanding.component.scss']
})
export class OutstandingComponent implements OnInit {

  forzaadmin: string;
  userid: string;
  id: string;
  month: string;
  year: any;

  dataSource = new MatTableDataSource<OutstandingSummary>();
  dataSourceLength: any;
  toppingList: any;
  isLoading = true;
  lockdate : any;
  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;
  outstandingSummaryList: OutstandingSummary[];
  displayedColumns: string[] = ['customer_name', 'location', 'account_admin', 'total_invoice_amt', 'total_receipt', 'total_outstanding', 'Actions'];

  constructor(
    private outstandingService: OutstandingService,
    private router: Router,
  ) {
    // const month = ["January", "February", "March", "April", "May", "June", "July",
    //   "August", "September", "October", "November", "December"];
    // this.month = month[new Date(this.lockdate).getMonth()];
  }

  ngOnInit(): void {
    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.id = localStorage.getItem('Id');
    this.toppingList = this.displayedColumns;
    this.getOutstandingList();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getOutstandingList = () => {
    const data = {
      user_id: this.id
    }
    this.outstandingService.getOutStandingPaymentSummary(data).subscribe(
      res => {
        const response: any = res;
        setTimeout(() => {
          
          if (response.success == true) {
            let lockdate = response.lock_date
            this.outstandingSummaryList = response.OutstandingPayment;
            this.dataSource = new MatTableDataSource(this.outstandingSummaryList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSourceLength = this.outstandingSummaryList.length;
            this.isLoading = false;
            
            
            console.log(lockdate);
            
            if(lockdate && lockdate === undefined){
              this.month = "sdsd";
              this.year = "sds"
            }
            else{
            const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            
              var date = new Date(lockdate);
              this.month = month[date.getMonth()] 
              this.year = date.getFullYear()             
            }
            
            // console.log(month1,year1);
            
            
          }
        }, 100);
      }
    )
  }

  view = (summary: OutstandingSummary) => {
    this.router.navigate(['/Outstanding/details', summary.customers_id, summary.location, summary.customer_name]);
  }

  export = (type) => {
    this.isLoading = true
    let exportType = ExportType.CSV;
    if (type == 'xlsx') {
      exportType = ExportType.XLSX;
    }
    if (type == 'csv') {
      exportType = ExportType.CSV;
    }
    this.exporter.exportTable(exportType, {
      fileName: "Outstanding_summary",
      sheet: "Outstanding_summary",
      Props: {
        Author: "Outstanding_summary"
      }
    })
    setTimeout(() => {
      this.isLoading = false
    }, Number(this.outstandingSummaryList.length) * 2);
  }

  printPage = () => {
    var divToPrint = document.getElementById("tablerecords");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
    newWin.print();
    newWin.close();
  }

  removeUnderScore = (val) => {
    return val.toString().replace(/_/gi, " ");
  }

  applyFilter = (filterValue: string) => {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.dataSourceLength = this.dataSource.filteredData.length
  }


}
