<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/reports/history-confirmation">History of confirmation and Rejection Report</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">View</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-hisofconfirmation.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">View Details</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/reports/history-confirmation"><span
              class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <h2 class="form-heading" style="margin-right: -20px !important; margin-left: -20px !important">Reports Details</h2>
            </div>
            <div class="col-md-3 history-labels view-label">
              <p>Booking ID
              </p>
              <span class="view-data"><b>{{historyConfirmationRejection.booking_id}}</b></span>
            </div>
            <div class="col-md-3 history-labels view-label">
              <p>Pickup Date
              </p>
              <span class="view-data"><b>{{historyConfirmationRejection.pickup_date | date:'dd-MM-yyyy'}}</b></span>
            </div>
            <div class="col-md-3 history-labels view-label">
              <p>Pickup Time
              </p>
              <span class="view-data"><b>{{historyConfirmationRejection.pickup_time}}</b></span>
            </div>
            <div class="col-md-3 history-labels view-label">
              <p>Guest Name
              </p>
              <span class="view-data"><b>{{historyConfirmationRejection.guest_name}}</b></span>
            </div>
            <div class="col-md-12 history-labels view-label">

            <mat-divider></mat-divider>
          </div>

            <div class="col-md-3 history-labels view-label">
              <p>Billing Location
              </p>
              <span class="view-data"><b>{{historyConfirmationRejection.billing_location}}</b></span>
            </div>
            <div class="col-md-3 history-labels view-label">
              <p>Rental City
              </p>
              <span class="view-data"><b>{{historyConfirmationRejection.rental_city_name}}</b></span>
            </div>
            <div class="col-md-3 history-labels view-label">
              <p>Rental Type
              </p>
              <span class="view-data"><b>{{historyConfirmationRejection.rental_type_name}}</b></span>
            </div>
            <div class="col-md-3 history-labels view-label">
              <p>Vehicel Type
              </p>
              <span class="view-data"><b>{{historyConfirmationRejection.vehicle_model_name}}</b></span>
            </div>
          </div>
          <hr>

          <div class="row">
            <div class="col-md-12">
              <mat-expansion-panel hideToggle #example>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5>History</h5>
                    <span *ngIf="!example.expanded"> <i class="fa fa-angle-down" aria-hidden="true"></i></span>
                    <span *ngIf="example.expanded"><i class="fa fa-angle-up" aria-hidden="true"></i></span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-stepper orientation="vertical" labelPosition="top" linear="true" #stepper>
                  <ng-container *ngFor="let log of logList">
                    <mat-step label="Booking {{ getStatus(log.action) }} by {{log.user_name}}">
                      <span class="view-label">{{log.date_time | date: 'MMM-dd-yyyy'}}</span> - <span
                        class="view-data">{{log.date_time | date : 'shortTime'}}</span>
                    </mat-step>
                  </ng-container>
                </mat-stepper>
              </mat-expansion-panel>


            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</div>