<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-12">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" routerLink="/">Dashboard</li>
          <li class="breadcrumb-item" routerLink="/managecustomerguest">Manage Customer Guest</li>
          <li class="breadcrumb-item active">Import</li>

        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content mb-3">
  
  <div class="container-fluid">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <h5>Import Customer Guest</h5>
        </div>
        <div class="col-sm-6 text-right">
          <h6 class="pointer hover-color float-right" routerLink="/managecustomerguest">Back</h6>
        </div>
      </div>
    </div>
    <mat-card class="mat-card">
        <div class="loader" *ngIf="isLoader"></div>

      <div class="form-group" *ngIf="!isLoader">
        <div>
          <div *ngIf="!isShowFile">
            <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
              <div class="text-wrapper">
                <div class="centered">
                  <label for="upload_excel"><span class="textLink"><i class='fas fa-arrow-down'></i></span>Select your
                    file or
                    Drop it here!</label>
                  <input type="file" class="file_upload"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="upload_excel"
                    (change)="onFileChange($event.target.files)" />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isShowFile">

            <h3 class="file">Files</h3>
            <div *ngFor="let file of files">
              <div class="">
                <mat-icon mat-list-icon style="position: absolute;">folder</mat-icon>
                <h4 style="font-size: 15px;margin-left: 30px;padding-top: 5px;">
                  {{file.name}}</h4>
              </div>
              <i class="fas fa-trash" (click)="deleteFile(file)"
                style="color: #fb8542;  float: right;  margin-top: -23px;"></i>
            </div>

          </div>

        </div>
      </div>


      <div class="form-group" *ngIf="excelJSON.length == 0 && !isLoader">
        <mat-divider></mat-divider>
        <button class="btn btn-primary import-btnbtn mt-2" (click)="export()"><span class="download"></span>Sample Excel
          File</button>
      </div>
    </mat-card>

    <mat-card class="mat-card mt-2" *ngIf="excelJSON.length > 0">
      <div class="col-md-12 mt-2 table">
        <h5 class="card-header">Available To Import</h5>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 " matSort>

          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> # </th>
            <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
          </ng-container>

          <ng-container matColumnDef="Customer_Group">
            <th mat-header-cell *matHeaderCellDef> Customer Group </th>
            <td mat-cell *matCellDef="let element"> {{element.Customer_Group}} </td>
          </ng-container>

          <ng-container matColumnDef="Customer_Name">
            <th mat-header-cell *matHeaderCellDef> Customer</th>
            <td mat-cell *matCellDef="let element"> {{element.Customer_Name}} </td>
          </ng-container>

          <ng-container matColumnDef="employee_location">
            <th mat-header-cell *matHeaderCellDef> Employee Location</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_location}} </td>
          </ng-container>

          <ng-container matColumnDef="employee_name">
            <th mat-header-cell *matHeaderCellDef> Employee Name </th>
            <td mat-cell *matCellDef="let element"> {{element.employee_name}} </td>
          </ng-container>

          <ng-container matColumnDef="employee_mobile_number">
            <th mat-header-cell *matHeaderCellDef> Employee Mobile Number</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_mobile_number}} </td>
          </ng-container>
          <ng-container matColumnDef="employee_email_id">
            <th mat-header-cell *matHeaderCellDef>Employee Email ID</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_email_id}} </td>
          </ng-container>
          <ng-container matColumnDef="employee_id">
            <th mat-header-cell *matHeaderCellDef>Employee Id</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_id}} </td>
          </ng-container>
          <ng-container matColumnDef="customer_admin_name">
            <th mat-header-cell *matHeaderCellDef> Customer Admin</th>
            <td mat-cell *matCellDef="let element"> {{element.customer_admin_name}} </td>
          </ng-container>
          <ng-container matColumnDef="credit_limit">
            <th mat-header-cell *matHeaderCellDef>Credit Limit</th>
            <td mat-cell *matCellDef="let element"> {{element.credit_limit }} </td>
          </ng-container>
          <ng-container matColumnDef="select_vehicle">
            <th mat-header-cell *matHeaderCellDef>Select Vehicle</th>
            <td mat-cell *matCellDef="let element"> {{element.select_vehicle}} </td>
          </ng-container>
          <ng-container matColumnDef="rental_type">
            <th mat-header-cell *matHeaderCellDef>Rental Type</th>
            <td mat-cell *matCellDef="let element"> {{element.rental_type}} </td>
          </ng-container>
          <ng-container matColumnDef="rental_location">
            <th mat-header-cell *matHeaderCellDef>rental_location</th>
            <td mat-cell *matCellDef="let element"> {{element.rental_location}} </td>
          </ng-container>
          <ng-container matColumnDef="direct_booking">
            <th mat-header-cell *matHeaderCellDef>Direct Booking</th>
            <td mat-cell *matCellDef="let element"> {{element.direct_booking}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>status</th>
            <td mat-cell *matCellDef="let element">{{element.status}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>


        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 30, 50]" [pageSize]="10" ></mat-paginator> -->


      </div>

    </mat-card>

    <mat-card class="mat-card mt-2" *ngIf="failexcel.length > 0">
      <div class="col-md-12 mt-2 table">
        <h5 class="card-header">Fail To Import</h5>
        <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8 " matSort>

          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> # </th>
            <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
          </ng-container>


          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>Reason</th>
            <td mat-cell *matCellDef="let element" style="min-width: 250px !important;"><span
                class="text-danger">{{element.message}}</span> </td>
          </ng-container>

          
          <ng-container matColumnDef="Customer_Group">
            <th mat-header-cell *matHeaderCellDef> Customer Group </th>
            <td mat-cell *matCellDef="let element"> {{element.Customer_Group}} </td>
          </ng-container>

          <ng-container matColumnDef="Customer_Name">
            <th mat-header-cell *matHeaderCellDef> Customer</th>
            <td mat-cell *matCellDef="let element"> {{element.Customer_Name}} </td>
          </ng-container>

          <ng-container matColumnDef="employee_location">
            <th mat-header-cell *matHeaderCellDef> Employee Location</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_location}} </td>
          </ng-container>

          <ng-container matColumnDef="employee_name">
            <th mat-header-cell *matHeaderCellDef> Employee Name </th>
            <td mat-cell *matCellDef="let element"> {{element.employee_name}} </td>
          </ng-container>

          <ng-container matColumnDef="employee_mobile_number">
            <th mat-header-cell *matHeaderCellDef> Employee Mobile Number</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_mobile_number}} </td>
          </ng-container>
          <ng-container matColumnDef="employee_email_id">
            <th mat-header-cell *matHeaderCellDef>Employee Email ID</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_email_id}} </td>
          </ng-container>
          <ng-container matColumnDef="employee_id">
            <th mat-header-cell *matHeaderCellDef>Employee Id</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_id}} </td>
          </ng-container>
          <ng-container matColumnDef="customer_admin_name">
            <th mat-header-cell *matHeaderCellDef> Customer Admin</th>
            <td mat-cell *matCellDef="let element"> {{element.customer_admin_name}} </td>
          </ng-container>
          <ng-container matColumnDef="credit_limit">
            <th mat-header-cell *matHeaderCellDef>Credit Limit</th>
            <td mat-cell *matCellDef="let element"> {{element.credit_limit }} </td>
          </ng-container>
          <ng-container matColumnDef="select_vehicle">
            <th mat-header-cell *matHeaderCellDef>Select Vehicle</th>
            <td mat-cell *matCellDef="let element"> {{element.select_vehicle}} </td>
          </ng-container>
          <ng-container matColumnDef="rental_type">
            <th mat-header-cell *matHeaderCellDef>Rental Type</th>
            <td mat-cell *matCellDef="let element"> {{element.rental_type}} </td>
          </ng-container>
          <ng-container matColumnDef="rental_location">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let element"> {{element.rental_location}} </td>
          </ng-container>
          <ng-container matColumnDef="direct_booking">
            <th mat-header-cell *matHeaderCellDef>Direct Booking</th>
            <td mat-cell *matCellDef="let element"> {{element.direct_booking}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>status</th>
            <td mat-cell *matCellDef="let element">{{element.status}} </td>
          </ng-container>

          


          <tr mat-header-row *matHeaderRowDef="displayedColumnsf"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsf;"></tr>

        </table>


        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 30, 50]" [pageSize]="10" ></mat-paginator> -->


      </div>
    </mat-card>

    <div class="row mt-2" *ngIf="excelJSON.length > 0">
      <div class="col-md-12">
        <button class="buttonload btn btn-primary import-btn" style="float:right;" disabled *ngIf="isLoading"><i
            class="fa fa-spinner fa-spin"></i>Loading </button>

        <button class="btn btn-primary import-btn" style="float:right;" (click)="submitimport()"
          *ngIf="!isLoading">Import</button>
      </div>
    </div>
  </div>
</section>