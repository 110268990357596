<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" routerLink="/dashboard">Dashboard</li>
            <li class="breadcrumb-item active">Booking Details
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
        <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6 ">
                <h5>Booking Details
                </h5>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <p>Booking ID
                        </p>
                        <span><b>51636544</b></span>
                    </div>
                    <div class="col-md-4">
                        <p>Pickup Date
                        </p>
                        <span><b>03-12-2022</b></span>
                    </div>
                    <div class="col-md-4">
                        <p>Pickup Time
                        </p>
                        <span><b>13:45</b></span>
                    </div>
                    <div class="col-md-4">
                        <p>Guest Name
                        </p>
                        <span><b>saran</b></span>
                    </div>

                    <div class="col-md-4">
                        <p>Billing Location
                        </p>
                        <span><b>chennai</b></span>
                    </div>
                    <div class="col-md-4">
                        <p>Rental City
                        </p>
                        <span><b>chennai</b></span>
                    </div>
                    <div class="col-md-4">
                        <p>Rental Type
                        </p>
                        <span><b>Direct</b></span>
                    </div>
                    <div class="col-md-4">
                        <p>Vehicel Type
                        </p>
                        <span><b>51636544</b></span>
                    </div>
                </div>
                <hr>
                <div class="history">
                  <p>History</p>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <ol class="activity-feed">
                          <li class="feed-item">
                            <span class="span-date">Booking Posted by Suresh<br>Dec 01 2022 - 5 PM
                            </span>
                            <!-- <time class="date"> </time>
                            <span class="text"></span> -->
                          </li> 
                          <li class="feed-item">
                            <span class="span-date">Booking Approved by Admin<br>Dec 02 2022 - 6 PM
                            </span>
                            <!-- <time class="date"> </time>
                            <span class="text"></span> -->
                          </li> 
                          <li class="feed-item">
                            <span class="span-date">Booking Edited by Suresh<br>Dec 03 2022 - 6 PM
                            </span>
                            <!-- <time class="date"> </time>
                            <span class="text"></span> -->
                          </li> 
                          <li class="feed-item">
                            <span class="span-date">Booking Rejected by Admin<br>Dec 03 2022 - 8 PM
                            </span>
                            <!-- <time class="date"> </time>
                            <span class="text"></span> -->
                          </li> 
                        </ol>
                      </div>
                </div>
            </div>
         
          </div>
    </div>
  </section>