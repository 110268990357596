import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/customeradmin.service';
import { HelperService } from '@services/helper.service';
import { Managecustomer } from '@services/managecustomerguest.service';
import e from 'express';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
  selector: 'app-add-contractinvoice',
  templateUrl: './add-contractinvoice.component.html',
  styleUrls: ['./add-contractinvoice.component.scss']
})
export class AddContractinvoiceComponent implements OnInit {

  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  isLoader: boolean = false;
  isShowFile: boolean = false;
  isShowFile1: boolean = false;
  upload: boolean = true;
  fileupload: boolean = false;
  public files: any[] = [];
  public files1: any[] = [];
  listOfFiles: any;
  excelJSON: any = []
  failexcel: any = []
  item: any;
  contractinvoice !: FormGroup;
  submitted = false;
  branches: any;
  customersgroup: any;
  customerrole: any;
  base_Location: any;
  customer_Group: any;
  customer_Name: any;
  customer: any;
  msg: any;
  invoice_type: any;
  invoice_month: any;
  invoice_year: any;
  forzaadmin: any = [];
  user_id: any;
  public id: string;
  getcustomergroupdatas = [];
  getcustomergroupdata = [];
  public custrole: boolean = false;
  locationlist: any = [];
  getlocation = [];
  public filteredListLocation = [];
  public filteredListGroups = [];
  public filteredCompanyName = [];
  respolocation: any;
  respocustomersgroup: any;
  respocustomerGroupName: any;
  selectedLocation: string;
  customerdata: any = [];
  getcustomer = [];
  getcustomers = [];
  base_Location_selected: any;
  monthList: any;
  monthlist: any;
  editId: any;
  type: string = 'add';
  isDisableBtn = false;
  locationd_selected: any;
  invoice_comments: any;
  fileArr: any = [];
  storeArr: any = [];
  filterlist: any;
  isServerShowFile = false;
  isFileUpload = false;
  isFileUpload1 = false;

  isComments = false;
  display = true;
  range: any = [];
  startYear = new Date().getFullYear();
  yearlist: [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private appService: AppService,
    public activatedRoute: ActivatedRoute,
    public toast: ToastrService,
    private managecustomerguests: Managecustomer,
    public helperService: HelperService,
  ) {
    this.type = this.activatedRoute.snapshot.url[1].path;
  }

  ngOnInit(): void {
    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.user_id = localStorage.getItem('userId');
    this.id = localStorage.getItem('Id');


    if (this.forzaadmin == 'Forza Admin') {
      this.custrole = true;
    }
    else {

    }

    for (let i = 0; i < 2; i++) {
      this.range.push(this.startYear - i);
    }







    this.getlocationfilter();
    this.getcustomersgroup();

    this.buildMonths();
    this.contractinvoice = this.formBuilder.group({
      base_Location: [null, Validators.required],
      customer_Group: [null, Validators.required],
      customer_Name: [null, Validators.required],
      invoice_type: [null, Validators.required],
      invoice_month: [null, Validators.required],
      invoice_comments: [null, Validators.required],
      invoice_year: [null, Validators.required]
    })



    if (this.activatedRoute.snapshot.url[1].path == 'add') {
      this.type == 'add'
      this.helperService.pageAccess('r_create')
    } else {
      this.editId = atob(this.activatedRoute.snapshot.url[1].path);
      this.type = this.activatedRoute.snapshot.url[2].path;
      this.helperService.pageAccess('r_update')
      this.getInvoiceById();
    }

  }


  getInvoiceById = () => {
    var idarray = {
      id: this.editId,
      user_id: this.user_id
    };
    //idarray['id'] = this.id1;
    // idarray['user_id'] = this.user_id;
    this.appService.getInvoicebyId(idarray).subscribe(
      (response) => {
        let responses: any = response;
        if (responses.success == true) {



          this.isShowFile = (responses?.result?.invoice_fileName_display != '') ? true : false;

          // this.locationd_selected = loc;
          // this.contractinvoice.get("base_Location").setValue(loc);
          this.customer_Group = responses?.result?.customer_Group;
          this.locationd_selected = JSON.parse(responses?.result?.location);
          // this.customer_Name_array = responses?.result?.customer_Name_array

          //   this.contractinvoice.get("customer_Name").setValue(JSON.parse(responses?.result?.customer_Name));
          const loc = responses?.result?.location

          const companyArray = JSON.parse(responses?.result?.customer_Name);
          this.selectedLocation = loc;
          this.getcustomerGroupName(Number(this.customer_Group), companyArray)
          this.invoice_type = responses?.result?.invoice_type;
          this.invoice_month = responses?.result?.invoice_month;
          // this.invoice_month = responses?.result?.invoice_month;
          this.invoice_year = responses?.result?.invoice_year;
          this.invoice_comments = responses?.result?.invoice_comments;
          if (responses.result.invoice_fileName_display != '') {
            this.fileArr = responses.result.invoice_fileName_display.split(',');
            this.storeArr = responses.result.invoice_fileName_stored.split(',');
          }

          if (this.fileArr.length > 0) {
            this.isServerShowFile = true
          }
        } else {
          this.toast.error(responses.message, 'Error');
          console.log("error1");

          this.isDisableBtn = false;
        }

      },
      (error) => {
        this.toast.error(error, 'Error');
      }
    );
  };

  delete(id: any) {

    this.appService.deleteInvoiceImage({ fileName: this.storeArr[id] }).subscribe(response => {
      let responses: any = response;
      if (responses.success == true) {
        this.fileArr = this.fileArr.filter((x: any) => x !== this.fileArr[id]);
        this.storeArr = this.storeArr.filter((x: any) => x !== this.storeArr[id]);
        const data = {
          invoice_fileName_display: this.fileArr.toString(),
          invoice_fileName_stored: this.storeArr.toString(),
          id: this.editId
        }

        this.appService.updateInvoiceFiles(data).subscribe(
          res => {
            if (this.fileArr.length > 0) {
              this.isServerShowFile = true
            } else {
              this.isShowFile = true;
              this.isFileUpload = true;
            }
            this.toast.success("Deleted the Files Successfully");
            this.isShowFile = false;
            this.upload = false;
            //this.isServerShowFile=false
          }
        )
      }
    });
  }

  downloadFile(id: any) {
    this.appService.getInvoiceimageId(this.storeArr[id])
      .subscribe(data => {
        const response = data as Blob;
        let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.fileArr[id];
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }

  buildMonths = () => {
    // const month = ["January", "February", "March", "April", "May", "June", "July",
    //   "August", "September", "October", "November", "December"];
    // this.monthList = month.slice(-5);


    this.appService.getmonthList({}).subscribe(
      (response) => {
        this.msg = response;


        if (this.msg.success == true) {
          this.monthlist = this.msg.result;
          // this.monthlist = this.monthlist.sort((a, b) => {
          //   if (a.month < b.month) { return -1; }
          //   if (a.month > b.month) { return 1; }
          // })
          this.filterlist = this.monthlist.slice();






        }
      },
      (error) => {
        // this.toastr.error(error, 'Error');
      }
    );

  }

  onFileChange(pFileList: File[]) {
    let filedd = Object.keys(pFileList).map(key => pFileList[key]);
    this.files = this.files.concat(filedd);

    var extn = this.files[0].name.split(".").pop();
    this.isLoader = true;
    this.isShowFile = true;

    // setTimeout(() => {

    if (this.files.length > 0) {
      // this.validation();
      this.isFileUpload1 = false;
      const reader = new FileReader();
      const file = this.files[0];

      reader.onload = (event) => {
        const data = reader.result;

      }

      reader.readAsBinaryString(file);
      this.isLoader = false;


    }
    else {
      this.isLoader = false;

      this.isShowFile = false;
      this.failexcel = [];
      this.excelJSON = [];
      this.files = [];
      this.toast.error('Please Upload a Valid File');
    }
    // }, 1000);

  }


  onFileChange1(pFileList: File[]) {


    this.files1 = Object.keys(pFileList).map(key => pFileList[key]);
    console.log(this.files1);

    if (this.files1.length > 0) {
      this.isLoader = true;
      this.isFileUpload1 = true;


      console.log(this.files1.length);

      let da: any = []
      for (let i = 0; i < this.files1.length; i++) {
        da.push(this.files1[i]);
      }

      let m
      const files = new FormData();
      da.map(x => {
        files.append("files", x)
        m = x
      });


      let fileNamesStored = [];
      let fileNamesDisplay = [];
      this.appService.uploadInvoice(files).subscribe(res => {
        let responses: any = res;
        if (responses.success == true) {
          fileNamesStored = responses.result.map(x => x.filename);
          fileNamesDisplay = responses.result.map(x => x.originalname);
          if (fileNamesStored.length > 0) {
            let cond = {
              id: this.editId,
              invoice_fileName_display: fileNamesDisplay,
              invoice_fileName_stored: fileNamesStored
            }
            this.appService.getmorefile(cond).subscribe(
              (response) => {
                this.isLoader = false;

                this.msg = response;
                console.log(response);
                this.toast.success(" Files update Successfully");

                this.getInvoiceById()
                let abcd = this.files1.filter(item => item.name != m.name);
                console.log(m);

                setTimeout(() => {
                  this.files1 = abcd;
                  if (this.files1.length == 0) {
                    this.isShowFile = false;
                  }
                }, 100);

              }
            );
          }
        }
      });
    }
  }

  saveFile(e, id: any) {
    this.isLoader = true;

    console.log(e, id);

    var iddata = []
    iddata.push(this.storeArr[id])


    let da = [e]
    console.log(da);
    return
    let m
    const files = new FormData();
    da.map(x => {
      files.append("files", x)
      m = x
    });

    let fileNamesStored = [];
    let fileNamesDisplay = [];

    if (da.length > 0) {

      this.appService.uploadInvoice(files).subscribe(res => {
        let responses: any = res;
        if (responses.success == true) {
          this.isLoader = false;

          fileNamesStored = responses.result.map(x => x.filename);
          fileNamesDisplay = responses.result.map(x => x.originalname);
          if (fileNamesStored.length > 0) {
            let cond = {
              id: this.editId,
              invoice_fileName_display: fileNamesDisplay,
              invoice_fileName_stored: fileNamesStored
            }
            this.appService.getmorefile(cond).subscribe(
              (response) => {
                this.msg = response;
                console.log(response);
                this.toast.success(" Files update Successfully");

                this.getInvoiceById()
                let abcd = this.files1.filter(item => item.name != m.name);
                console.log(m);

                setTimeout(() => {
                  this.files1 = abcd;
                  if (this.files1.length == 0) {
                    this.isShowFile = false;
                  }
                }, 100);

              }
            );
          }
        }
      });
    }
  }

  deleteFile(f) {
    let abcd = this.files.filter(item => item.name != f.name);
    setTimeout(() => {
      this.files = abcd;
      console.log(this.files.length);
      if (this.files.length == 0) {

        this.isShowFile = false;
      }
    }, 100);

  }
  deletefile1(f) {
    let abcd = this.files1.filter(item => item.name != f.name);
    setTimeout(() => {
      this.files1 = abcd;
      console.log(this.files1.length);
      if (this.files1.length == 0) {

        this.isShowFile = false;
      }
    }, 100);

  }

  get f() {
    return this.contractinvoice.controls
  }
  morefiles() {
    this.fileupload = true
  }


  submit() {

    this.submitted = true;
    this.isDisableBtn = true;
    // this.isFileUpload = true;

    const files = new FormData();

    
    
    this.files.map(x => {
      files.append("files", x)
    })
    console.log(this.files);
    if(this.files.length == 0)
    {
      this.isFileUpload1 = true;

    }
    else{
      this.isFileUpload1 = false;

    }

    let fileNamesStored = [];
    let fileNamesDisplay = [];
    if (this.type != 'edit') {


      if (this.files.length > 0 && this.contractinvoice.valid) {

        // this.isFileUpload1 = false;




        this.appService.uploadInvoice(files).subscribe(res => {
          let responses: any = res;


          if (responses.success == true) {



            fileNamesStored = responses.result.map(x => x.filename);
            fileNamesDisplay = responses.result.map(x => x.originalname);
            if (fileNamesStored.length > 0) {
              const data = {
                location: JSON.stringify(this.contractinvoice.value.base_Location),
                customer_Group: this.contractinvoice.value.customer_Group,
                customer_Name: JSON.stringify(this.contractinvoice.value.customer_Name),
                invoice_type: this.contractinvoice.value.invoice_type,
                invoice_comments: this.contractinvoice.value.invoice_comments,
                invoice_month: this.contractinvoice.value.invoice_month,
                invoice_year: this.contractinvoice.value.invoice_year,
                invoice_fileName_display: fileNamesDisplay,
                invoice_fileName_stored: fileNamesStored,

              }


              this.appService.createInvoice(data).subscribe(result => {
                this.toast.success(responses.message, 'Success');
                console.log("hh");
                this.isFileUpload = false;
                // this.isFileUpload1 = false;

                this.router.navigate(['/invoice']);
              },
                (error) => {

                  this.toast.error(responses.message, 'Error');
                // this.isFileUpload1 = true;


                  this.isDisableBtn = false;
                }
              );

            }
          } else {
            this.toast.error(responses.message, 'Error');
            // this.isFileUpload1 = true;



            this.isDisableBtn = false;
          }
        });
      } else {

        this.isFileUpload = false;
        this.isDisableBtn = false;
      }
    }

    if (this.type === 'edit') {

      // if (this.files.length > 0 && this.contractinvoice.valid) {

      this.submitted = true;
      this.isDisableBtn = false;
      // this.isFileUpload = true;

      if (this.files.length > 0 || this.fileArr.length > 0) {
      } else {
        this.toast.error('Please Upload a File');


        return
      }

      if (this.contractinvoice.invalid) {
        const invalid = [];
        console.log("invalid", invalid);
        const controls = this.contractinvoice.controls;
        for (const name in controls) {
          if (controls[name].invalid) {
            invalid.push(name);
          }
        }
        this.contractinvoice.markAllAsTouched();
        console.log(invalid);
        // this.submitted = false;
        this.isDisableBtn = false;
        return
      } else {


        if (this.files.length > 0 && this.contractinvoice.valid) {
          this.appService.uploadInvoice(files).subscribe(res => {
            let responses: any = res;
            if (responses.success == true) {
              fileNamesStored = responses.result?.map(x => x.filename);
              fileNamesDisplay = responses.result?.map(x => x.originalname);

              const data = {
                id: this.editId,
                location: JSON.stringify(this.contractinvoice.value.base_Location),
                customer_Group: this.contractinvoice.value.customer_Group,
                customer_Name: JSON.stringify(this.contractinvoice.value.customer_Name),
                invoice_type: this.contractinvoice.value.invoice_type,
                invoice_comments: this.contractinvoice.value.invoice_comments,
                invoice_month: this.contractinvoice.value.invoice_month,
                invoice_year: this.contractinvoice.value.invoice_year,
                invoice_fileName_display: fileNamesDisplay?.toString(),
                invoice_fileName_stored: fileNamesStored?.toString(),
              }
              this.appService.updateInvoice(data).subscribe(result => {
                this.toast.success('Invoice Updated Successfully', 'Success');

                this.router.navigate(['/invoice']);
              },
                (error) => {

                  this.toast.error(responses.message, 'Error');
                  this.isDisableBtn = false;
                }
              );
            }
            else {
              this.toast.error(responses.message, 'Error');

              this.isDisableBtn = false;
            }
          });
        } else {

          const data = {
            id: this.editId,
            location: JSON.stringify(this.contractinvoice.value.base_Location),
            customer_Group: this.contractinvoice.value.customer_Group,
            customer_Name: JSON.stringify(this.contractinvoice.value.customer_Name),
            invoice_type: this.contractinvoice.value.invoice_type,
            invoice_comments: this.contractinvoice.value.invoice_comments,
            invoice_month: this.contractinvoice.value.invoice_month,
            invoice_year: this.contractinvoice.value.invoice_year,

          }
          this.appService.updateInvoice(data).subscribe(result => {
            this.toast.success('Invoice Updated ', 'Success');


            this.router.navigate(['/invoice']);
          },
            (error) => {
              this.isDisableBtn = false;
              this.toast.error('gjygj', 'Error');
            }
          );
        }
      }

    }

  }

  public locclose() {
    this.locationd_selected = ''

  }

  public cusgroupclose() {
    this.customer_Group = ''
  }

  public invoiceclose() {
    this.invoice_type = ''
  }

  public monthclose() {
    this.invoice_month = ''
  }

  locationclose() {
    this.base_Location_selected = ''
    this.customer_Group = ''
    this.customer_Name = ''
    this.filteredCompanyName = []
    if (this.filteredListGroups.length > 0) {
      this.filteredListGroups = []

    }
  }

  clearCustomerName = () => { this.customer_Name = "" };

  getlocationfilter() {
    this.getlocation = this.locationlist = [];
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }
    this.managecustomerguests.getCustomerlocation(data).subscribe(
      (response) => {
        this.respolocation = response;
        if (this.respolocation.success == true) {
          this.locationlist = [...this.locationlist, ...this.respolocation.Location_List]
          this.getlocation = this.locationlist;
          //this.getlocations = this.locationlist;
          this.getlocation = this.getlocation.sort((a, b) => {
            if (a.location_name < b.location_name) { return -1; }
            if (a.location_name > b.location_name) { return 1; }
          })
          this.filteredListLocation = this.getlocation.slice();
        }
      })
  }
  locationChanged = (location) => {
    this.selectedLocation = JSON.stringify(location);
  }
  getcustomersgroup = () => {
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }
    this.appService.getcustomersgroup(data).subscribe(
      response => {
        this.respocustomersgroup = response
        if (this.respocustomersgroup.success == true) {
          this.customersgroup = this.respocustomersgroup.result;
          this.getcustomergroupdata = this.customersgroup;
          this.getcustomergroupdatas = this.customersgroup;
          this.getcustomergroupdata = this.getcustomergroupdata.sort((a, b) => {
            if (a.customer_group_name < b.customer_group_name) { return -1; }
            if (a.customer_group_name > b.customer_group_name) { return 1; }
          })
          this.filteredListGroups = this.getcustomergroupdata.slice();
        }
      });
  }

  customersgroupChanged = (group) => {
    this.getcustomerGroupName(JSON.stringify(group));
  }

  getcustomerGroupName(customer_group_id, companyArray?: any) {
    // console.log(companyArray, "copma*******************")
    let data = {
      customer_group: customer_group_id,
      location: [this.selectedLocation],
      type: this.forzaadmin,
      user_id: this.id
    }
    this.managecustomerguests.getCustomer(data).subscribe(
      (response) => {
        this.respocustomerGroupName = response;

        if (this.respocustomerGroupName.success == true) {
          this.customerdata = this.respocustomerGroupName.Customer_Name;
          this.getcustomer = this.customerdata;
          this.getcustomers = this.customerdata;
          this.getcustomer = this.getcustomer.sort((a, b) => {
            if (a.customer_name < b.customer_name) { return -1; }
            if (a.customer_name > b.customer_name) { return 1; }
          })
          this.filteredCompanyName = this.getcustomer.slice();
          this.customer_Name = companyArray;
        }
      },
    );
  }

  yearvalue(value) {
    this.yearlist = value;


  }
}
