import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Managecustomer } from '@services/managecustomerguest.service';

@Component({
  selector: 'app-viewmode',
  templateUrl: './viewmode.component.html',
  styleUrls: ['./viewmode.component.scss']
})
export class ViewmodeComponent implements OnInit {
  msg:any;
  triplist:any;
  id:any;
  constructor(private managecustomer: Managecustomer, public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.id = this.activatedRoute.snapshot.url[1].path;
      console.log(this.id);
      
      
    });

    this.getviewmodebyId();

  }



  getviewmodebyId = () => {
    var obj={
      "booking_id": this.id
     }
    
   
     this.managecustomer.getmodeviewdetail(obj).subscribe(
         (response) => {
          this.msg=response;
             if (this.msg.success == true) {
                this.triplist =this.msg.Trip_Sheet_List[0];
                console.log( this.triplist);
                
                
                
                
               
                
               
               
                 
             }
         }
     );
 };
}
