import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Managecustomer } from './managecustomerguest.service';

@Injectable()
export class HelperService {
  public rba: any = JSON.parse(localStorage.getItem('rba'));
  public canAccess: any = false;
  public respo: any;

  public childData = { resource: "CBS_Dashboard", r_create: 0, r_read: 0, r_update: 0, r_delete: 0, r_download: 0 };

  constructor(
    private router: Router,
    private managecustomerguests: Managecustomer,) {
    this.getPrevillage()
    this.rba = JSON.parse(localStorage.getItem('rba'));
  }


  getPrevillage(){
    setTimeout(() => {
    let cond = {
      "userid": localStorage.getItem("Id"),
      "role": localStorage.getItem("customer_Role_name"),//(localStorage.getItem("customer_Role_name") != 'Forza Admin') ? 'Customer Admin': localStorage.getItem("customer_Role_name")
    }
    // Forza Admin
    this.managecustomerguests.getPrevillage(cond).subscribe(
      (response) => {
        this.respo = response;
        if (this.respo.success == true) {
          this.rba = this.respo.rba;
          let data = JSON.stringify(this.respo.rba);
          localStorage.setItem('rba', data);
        }
      });
    }, 500);
    }
    
  setMenuAccess(resource) {   
    this.getPrevillage()
    if(resource == 'CBS_Manage_Booking') {
      this.childData = { resource: "CBS_Manage_Booking", r_create: 1, r_read: 1, r_update: 1, r_delete: 1, r_download: 1 };
      return this.childData
    } else {

    this.rba = JSON.parse(localStorage.getItem('rba'));    
    var parentData = this.rba.filter(x => (x.resource).toLowerCase() === resource.toLowerCase())[0];
    if (parentData != undefined) {
      this.childData = parentData;
    } else {
      this.childData = { resource: "CBS_Dashboard", r_create: 0, r_read: 0, r_update: 0, r_delete: 0, r_download: 0 };
    }
    return this.childData
    }
  }

  isMenuShow(resource) {

    let menuResult: boolean = false;

    if(resource == 'CBS_Manage_Booking') {
      return true
    } else {
      this.rba = JSON.parse(localStorage.getItem('rba'));      
      if (typeof(resource) == 'string') {
        var parentData = this.rba.filter(x => (x.resource).toLowerCase() === resource.toLowerCase())[0];        
        if (parentData != undefined) {
          if (Number(parentData.r_create) == 0 && Number(parentData.r_read) == 0 && Number(parentData.r_update) == 0 && Number(parentData.r_delete) == 0 && Number(parentData.r_download) == 0) {
            menuResult = false;
          } else {
            menuResult = true;
          }
        }
      } else {
        menuResult = false;
        let subResult = [];
        for (let i = 0; i < resource.length; i++) {
          const element = resource[i];
          var parentData = this.rba.filter(x => (x.resource).toLowerCase() === element.toLowerCase())[0];
          if (parentData != undefined) {
            if (Number(parentData.r_create) == 0 && Number(parentData.r_read) == 0 && Number(parentData.r_update) == 0 && Number(parentData.r_delete) == 0 && Number(parentData.r_download) == 0) {
            subResult.push(false)
          } else {
            subResult.push(true)
          }
        } else {
          subResult.push(false)
        }
      }
      let uniqueChars = [...new Set(subResult)];
      if (uniqueChars.length == 1) {
        menuResult = uniqueChars[0];
      } else if (uniqueChars.length > 0) {
        menuResult = true;
      }
    }
    return menuResult;
  }
  
  }

  checkMenuAccess(resource) {    
    return this.childData[resource]
  }

  pageAccess(type) {
    // this.getPrevillage()
      let menuResult: boolean = false;      
      if (type == 'all') {
        if (Number(this.childData.r_create) == 0 && Number(this.childData.r_read) == 0 && Number(this.childData.r_update) == 0 && Number(this.childData.r_delete) == 0 && Number(this.childData.r_download) == 0) {
          menuResult = false;
        } else {
          menuResult = true;
        }
      } else {
        menuResult = this.childData[type]
      }
           
      if (!menuResult) {
        window.history.go(-1)
        // this.router.navigate(['/dashboard']);
      }
      return menuResult
    }


}
