import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {BackendserviceService} from './backendservice.service';

@Injectable({
  providedIn: 'root'
})
export class TripSheetService {

  constructor(   private backendService: BackendserviceService) { }


  public getFilteredList(data): Observable<{}> {
    return this.backendService
        .post('api/booking/getTripsheetFilterDropdown', data)
        .pipe(retry(1), catchError(this.handleError));
  }

  public getTripSheetList(data): Observable<{}> {
    return this.backendService
        .post('api/booking/TripSheet', data)
        .pipe(retry(1), catchError(this.handleError));
  }

  public getTripCounts(data): Observable<{}> {
    return this.backendService
        .post('api/booking/TripSheetCount', data)
        .pipe(retry(1), catchError(this.handleError));
  }
 

  private handleError(error: HttpErrorResponse) {
      let errorMessage: string = '';
      if (error.error instanceof ErrorEvent) {
          //ClientSide Error--------
          errorMessage = `Error:${error.error.message}`;
      } else {
          //Server side Error---------
          errorMessage = `Status:${error.status}\n
                    Message:${error.message}`;
      }
      return throwError(errorMessage);
  }
}
