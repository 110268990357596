<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span>Dashboard</span> / <span class="breadcrumb-item activebreadcrumb-item active">Manage Customer Guest</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-customerguest.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Manage Customer Guest</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <!-- <div class="col-md-12"> -->
            <div class="row justify-content-between">
              <div class="col-md-6">
                <h4 style="font-weight:600; color: #233771;">Details</h4>
              </div>
              <div class="col-md-6">
                <div class="row justify-content-end">
                  <div class="col-md-7">
                    <div class="search-box pull-center">
                      <div class="search-input">
                        <mat-icon role="img" matprefix="true"
                          class="mat-icon notranslate material-icons mat-icon-no-color icon-grey" aria-hidden="true"
                          data-mat-icon-type="font">search</mat-icon><input placeholder="Search" class="form-control"
                          (keyup)="applyFilter($event.target.value)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 d-flex justify-content-end">
                    <mat-icon class="text-right" *ngIf="helperService.checkMenuAccess('r_download')" title="Excel"
                      type="button"
                      (click)="exportIt('xlsx','Manage_customer_guest', 'Manage_customer_guest_data','Forza')"
                      class="mat-button-submit c-btn  icon-img"
                      style="background-image:url('assets/images/XLS.png');"></mat-icon>
                    <mat-icon class="text-right" *ngIf="helperService.checkMenuAccess('r_download')" title="Upload"
                      type="button" routerLink="/import-customerguest" class="mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/export.png');"></mat-icon>
                    <mat-icon *ngIf="helperService.checkMenuAccess('r_create')" title="Add" type="button"
                      (click)="addcustomerguest()" class="text-right plus mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/Add.png');"></mat-icon>
                  </div>
                </div>
              </div>
            </div>

          <!-- </div> -->

          <!-- table -->
          <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
            <table [border]="1" style="text-align: center;" matTableExporter mat-table [dataSource]="dataSource"
              [hidden]="isLoading" class="pointer table-striped" #exporter="matTableExporter" matSort>

              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>S.No</th>
                <td mat-cell *matCellDef="let element; let i = index" (click)="edit(element.id)"> {{paginator.pageIndex
                  == 0 ? i + 1 : 1 + i + paginator.pageIndex * paginator.pageSize}}</td>

              </ng-container>



              <ng-container matColumnDef="Customer_name">
                <th mat-header-cell *matHeaderCellDef> Customer</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Customer_name"
                    [matTooltipPosition]="'above'">{{element.Customer_name}}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="Employee_Location_name">
                <th mat-header-cell *matHeaderCellDef> Employee Location</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Employee_Location_name"
                    [matTooltipPosition]="'above'">{{element.Employee_Location_name}}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="Employee_Name">
                <th mat-header-cell *matHeaderCellDef> Employee Name </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Employee_Name" [matTooltipPosition]="'above'">{{element.Employee_Name |
                    titlecase}}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="Employee_Mobile_Number">
                <th mat-header-cell *matHeaderCellDef> Employee Mobile No. </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Employee_Mobile_Number"
                    [matTooltipPosition]="'above'">{{element.Employee_Mobile_Number | titlecase}}</span>
                </td>
              </ng-container>


              <ng-container matColumnDef="Employee_Email_ID">
                <th mat-header-cell *matHeaderCellDef> Employee Email ID</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Employee_Email_ID"
                    [matTooltipPosition]="'above'">{{element.Employee_Email_ID}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="Employee_Id">
                <th mat-header-cell *matHeaderCellDef> Employee Id </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)"> {{element.Employee_Id}}
                </td>
              </ng-container>

              <ng-container matColumnDef="Vehicle_name">
                <th mat-header-cell *matHeaderCellDef> Vehicle</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Vehicle_name" [matTooltipPosition]="'above'">{{element.Vehicle_name |
                    titlecase}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="Rental_Type_name">
                <th mat-header-cell *matHeaderCellDef>Rental Type</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Rental_Type_name"
                    [matTooltipPosition]="'above'">{{element.Rental_Type_name
                    |
                    titlecase}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="Location_name">
                <th mat-header-cell *matHeaderCellDef>Location</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Location_name" [matTooltipPosition]="'above'">{{element.Location_name |
                    titlecase}}</span>
                </td>
              </ng-container>


              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"><span class="green-action pointer"
                    [ngClass]="{'green-danger':element.status !== 'ACTIVE', 'green-action':element.status === 'ACTIVE'}"
                    (click)="statusManager(element)
                  ">{{element.status | titlecase}}</span></td>
              </ng-container>

              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef> ACTION </th>
                <td class="edit" mat-cell *matCellDef="let element">
                  <div
                    *ngIf="helperService.checkMenuAccess('r_read') || helperService.checkMenuAccess('r_update') || helperService.checkMenuAccess('r_delete')">


                    <button mat-icon-button [matMenuTriggerFor]="menu" class="action"
                      aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <!-- <mat-menu #menu="matMenu" xPosition="before" yPosition="above"> -->
                    <mat-menu #menu="matMenu">

                      <button (click)="view(element.id)" *ngIf="helperService.checkMenuAccess('r_read')" mat-menu-item>
                        <mat-icon role="img"
                          class="mat-icon notranslate material-icons eye align-middle pointer hover-color"
                          aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon>View
                      </button>
                      <button (click)="edit(element.id)" *ngIf="helperService.checkMenuAccess('r_update')"
                        mat-menu-item>
                        <mat-icon class="edit align-middle pointer hover-color">edit</mat-icon>Edit
                      </button>
                      <button mat-menu-item *ngIf="helperService.checkMenuAccess('r_delete')"
                        (click)="delete(element.id)">
                        <mat-icon class="delete align-middle pointer hover-color">delete</mat-icon>Delete
                      </button>

                    </mat-menu>
                  </div>
                  <div
                    *ngIf="!helperService.checkMenuAccess('r_read') && !helperService.checkMenuAccess('r_update') && !helperService.checkMenuAccess('r_delete')">
                    -
                  </div>
                </td>

              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

            <div *ngIf="this.dataSource.filteredData.length === 0" style="text-align: center">
              No records found
            </div>

          </div>
          <!-- <mat-paginator class="" [pageSizeOptions]="[10,25,50]" [pageSize]="10"></mat-paginator> -->


          <div class="col-md-12 mt-2 table" [hidden]="!isLoading">

            <table class="norecordfoundtable">
              <tr>
                <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}</th>
              </tr>
              <tr>
                <td style="text-align: center;" [attr.colspan]="toppingList.length">
                  {{'Loading.......'}}
                </td>
              </tr>
            </table>
          </div>

          <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading" [pageSizeOptions]="[10,25,50]"
            justify-content="space-between"></custom-paginator>


        </div>

      </div>
    </div>
  </section>
</div>