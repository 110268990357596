import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Managecustomer } from '@services/managecustomerguest.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-viewtraking',
  templateUrl: './viewtraking.component.html',
  styleUrls: ['./viewtraking.component.scss']
})
export class ViewtrakingComponent implements OnInit {
  msg: any;
  loglist: any;
  trip_no: any;
  vechile: any;
  trip_id: any;

  startLatitude: any;
  startLongitude: any;
  endLatitude: any;
  endLongitude: any;
  isShowMap: boolean = false;
  frameUrl: string;

  constructor(private managecustomer: Managecustomer,
    public location: Location,
    public sanitizer : DomSanitizer,
    public activatedRoute: ActivatedRoute
  ) {
    // this.frameUrl = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBW5AhPv_84R8i4zBch-o9EP0z7X7XfR3Q&q=Space+Needle,Seattle+WA';

  }

  ngOnInit(): void {
    this.trip_no = this.activatedRoute.snapshot.url[1].path

    // this.frameUrl = "https://maps.google.com/maps?q=12.9387231,80.14607749999999&t=&z=13&ie=UTF8&iwloc=&output=embed&saddr=12.9387231,80.14607749999999&daddr=12.993374,80.1725867"

    this.viewtrackmap(this.getGoogleMapsUrl(this.startLatitude, this.startLongitude, this.endLatitude, this.endLongitude))
  }
  back() {
    this.location.back()
  }
  public viewtrackmap(datafn) {

    let obj = {
      trip_no: this.trip_no
    }
    this.managecustomer.viewtrack(obj).subscribe((res => {
      this.msg = res;




      if (this.msg.success == true) {
        this.loglist = this.msg.Trip_Details;
        this.vechile = this.loglist[0].vehicle_no;
        this.trip_id = this.loglist[0].trip_id;
        this.startLatitude = this.loglist[0].lat1;
        this.startLongitude = this.loglist[0].lon1;
        this.endLatitude = this.loglist[0].lat2;
        this.endLongitude = this.loglist[0].lon2;

        // this.frameUrl = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBW5AhPv_84R8i4zBch-o9EP0z7X7XfR3Q&q='+this.startLatitude+','+this.startLongitude+'&output=embed&saddr='+this.startLatitude+','+this.startLongitude+'&daddr='+this.endLatitude+','+this.endLongitude;

        this.frameUrl = 'https://maps.google.com/maps?q='+this.startLatitude+','+this.startLongitude+'&output=embed&saddr='+this.startLatitude+','+this.startLongitude+'&daddr='+this.endLatitude+','+this.endLongitude;
        // setTimeout(() => {
          this.isShowMap = true;
        // }, 1000);
      }
    }))
    // this.getGoogleMapsUrl(this.startLatitude ,this.startLongitude ,this.endLatitude ,this.endLongitude );
    // this.frameUrl = datafn()

  }

  getSanitizedURL() {
    console.log(this.sanitizer.bypassSecurityTrustUrl(this.frameUrl));
    
    return this.sanitizer.bypassSecurityTrustUrl(this.frameUrl);
  }

  getGoogleMapsUrl(startLat: any, startLng: any, endLat: any, endLng: any): string {
    let url = `https://maps.google.com/maps?q=${startLat},${startLng}&t=&z=13&ie=UTF8&iwloc=&output=embed` +
      `&saddr=${startLat},${startLng}&daddr=${endLat},${endLng}`;
    console.log(url);
    url = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBW5AhPv_84R8i4zBch-o9EP0z7X7XfR3Q&q=Space+Needle,Seattle+WA';
    return url


  }

}
