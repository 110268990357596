import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    submitted=false
    public forgotPasswordForm: UntypedFormGroup;
    public isAuthLoading = false;
    forget:any=[]

    constructor(
        private formBuilder: FormBuilder,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        public activatedRoute: ActivatedRoute,
        private router: Router,
    
    
    ) {}

    ngOnInit(): void {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.forgotPasswordForm = this.formBuilder.group({
            username: [null, Validators.required]
        });
    }

   
    forgotPassword() {
       this.submitted=true

        if(this.forgotPasswordForm.invalid){
            return
        }
        this.appService.forgetpassword(this.forgotPasswordForm.value).subscribe(
          response => {
            this.forget = response
            if (this.forget.success == true) {
                this.toastr.success(this.forget.message, 'success');
                this.router.navigate(['/login']);
             } else{
                this.toastr.error(this.forget.message, 'Error');
             }
          },
          error => {
            this.toastr.error(error, 'Error');
          });
      }
      get f() {
        return this.forgotPasswordForm.controls
      }

    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
