<div class="mx-4">

<section class="content-header">
  <div class="container-fluid">
    <div>
      <span routerLink="/">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
        routerLink="/managedirectguest">Manage Direct guest</span> / <span
        class="breadcrumb-item activebreadcrumb-item active">View</span>
    </div>
    <div class="row mt-md-2">
      <div class="col-auto">
        <img src="assets/images/mainicon-webbooking.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
      </div>
      <div class="col">
        <div class="row flex-column justify-content-center mt-md-3">
          <div class="col">
            <h4 style="font-weight:600; color: #233771;">View Direct Guest</h4>
          </div>
        </div>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <h6 class="pointer pt-3" routerLink="/managedirectguest"><span class="fas fa-angle-left p-2"></span>Back
        </h6>
      </div>
    </div>
  </div>
</section>
  
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">      
      <div class="card">
        <div class="card-body">
          <div class="container-fluid">
              <section>
                <h2 class="form-heading">Guest</h2>
                
                <div class="form-group row mb-0">
                  <label for="" class="col-sm-2 col-form-label view-label">Guest Name </label>
                  <div class="col-sm-8">
                    <div class="input-group mb-2 mr-sm-2">
                      <p class="m-0 py-2 view-data">{{customeradminresp?.Guest_Name}}</p>
                    </div>
                  </div>
                </div>

                <div class="form-group row mb-0">
                  <label for="" class="col-sm-2 col-form-label view-label">Guest Mobile</label>
                  <div class="col-sm-8">
                    <div class="input-group mb-2 mr-sm-2">
                      <p class="m-0 py-2 view-data">{{customeradminresp?.Guest_Mobile_Number}}</p>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <label for="" class="col-sm-2 col-form-label view-label">Guest Mail  </label>
                  <div class="col-sm-8">
                    <div class="input-group mb-2 mr-sm-2">
                      <p class="m-0 py-2 view-data">{{customeradminresp?.Guest_Email}}</p>
                    </div>
                  </div>
                </div>
               
                <div class="form-group row mb-0">
                    <label for="" class="col-sm-2 col-form-label view-label">Refered By  </label>
                    <div class="col-sm-8">
                      <div class="input-group mb-2 mr-sm-2">
                        <p class="m-0 py-2 view-data">{{customeradminresp?.Refered_By}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0">
                    <label for="" class="col-sm-2 col-form-label view-label">Location </label>
                    <div class="col-sm-8">
                      <div class="input-group mb-2 mr-sm-2">
                        <p class="m-0 py-2 view-data" style="display:flex;">{{customeradminresp?.Location_name}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0">
                    <label for="" class="col-sm-2 col-form-label view-label">Rental City </label>
                    <div class="col-sm-8">
                      <div class="input-group mb-2 mr-sm-2">
                        <p class="m-0 py-2 view-data">{{customeradminresp?.Rental_City_name}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-0">
                    <label for="" class="col-sm-2 col-form-label view-label">Credit Limit</label>
                    <div class="col-sm-8">
                      <div class="input-group mb-2 mr-sm-2">
                        <p class="m-0 py-2 view-data">{{customeradminresp?.Credit_Limit}}</p>
                      </div>
                    </div>
                  </div>
                
                
                
                  <div class="form-group row mb-0">
                    <label for="" class="col-sm-2 col-form-label view-label">Status </label>
                    <div class="col-sm-4">
                      <div class="input-group mb-2 mr-sm-2">
                        <mat-slide-toggle class="status" disabled [checked]="customeradminresp?.status === 'ACTIVE'" formControlName="status">
                       </mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                <div class="aligh-button-right">
                  <button routerLink="/managedirectguest" class="mat-button-cancel">Cancel</button>
                  <button mat-button class="mat-button-submit" *ngIf="helperService.checkMenuAccess('r_update')" (click)="edit(customeradminresp.id)">Edit</button>
              </div>
            
              </section>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card -->
  </section>
  </div>
  <!-- /.content -->
  
  <!-- /.card -->
  
  <!-- /.content -->
  
