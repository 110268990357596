<div class="mx-4">
  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/managedirectguest">Manage Direct Guest</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">{{(type === 'edit') ? 'Edit' : 'Add'}}</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/mainicon-webbooking.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">{{(type === 'edit') ? 'Edit' : 'Add'}} Direct Guest</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/managedirectguest"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="container-fluid">
            <form [formGroup]="directguest">
              <div class="row justify-content-between mx-4">
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Guest Name<span class="text-danger">*</span></label>
                    <input type="text" maxlength="50" [ngModel]="Guest_Name" formControlName="Guest_Name"
                      class="form-control" placeholder="Enter Guest Name"
                      [ngClass]="{'is-invalid': submitted && f['Guest_Name'].errors }"
                      (keypress)="general.removingSpaceFirst($event,f.Guest_Name.value)">
                    <div *ngIf="submitted && f['Guest_Name'].errors" class="invalid-feedback">
                      <div *ngIf="f['Guest_Name'].errors">Guest Name is required</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="form-group">
                    <label>Guest Mobile Number <span class="text-danger">*</span></label>
                    <input type="text" [ngModel]="Guest_Mobile_Number" formControlName="Guest_Mobile_Number"
                      class="form-control" placeholder="Guest Mobile Number" maxlength="13"
                      (keyup)="onkeypress($event.target.value)" appOnlyNumber
                      [ngClass]="{'is-invalid': submitted && f['Guest_Mobile_Number'].errors }"
                      (keypress)="general.removingSpaceFirst($event, f.Guest_Mobile_Number.value)">
                    <div *ngIf="submitted && f['Guest_Mobile_Number'].errors" class="invalid-feedback">
                      <div *ngIf="f['Guest_Mobile_Number'].errors.required">Guest Mobile Number is required</div>
                    </div>
                    <!-- {{f['Guest_Mobile_Number'].errors | json}} -->
                    <div *ngIf="f['Guest_Mobile_Number'].errors !== null">
                      <div
                        *ngIf="f['Guest_Mobile_Number'].errors.minlength && !f['Guest_Mobile_Number'].errors.pattern?.requiredPattern"
                        class="cinvalid-feedback">
                        Guest Mobile Number Minimum 10 to 13 digits
                      </div>
                      <div *ngIf="f['Guest_Mobile_Number'].errors.pattern?.requiredPattern" class="cinvalid-feedback">
                        Please enter the valid mobile number
                      </div>
                    </div>
                    <div *ngIf="mobexist === false" class="cinvalid-feedback">
                      Employee Mobile Number is already exist
                    </div>
                  </div>
                </div>




                <!-- </div> -->
                <!-- <div class="row center-form"> -->
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Guest Email<span class="text-danger">*</span></label>
                    <input type="text" maxlength="50" (keyup)="checkemail($event.target.value)"
                      formControlName="Guest_Email" class="form-control" placeholder="Enter Guest Email"
                      [(ngModel)]="Guest_Email" [ngClass]="{'is-invalid': submitted && f['Guest_Email'].errors }"
                      (keypress)="general.removingSpaceFirst($event, f.Guest_Email.value)">
                    <div *ngIf="submitted && f['Guest_Email'].errors" class="invalid-feedback">
                      <div *ngIf="f['Guest_Email'].errors">Guest Email is required</div>
                    </div>
                    <div *ngIf="emailexist === false" class="cinvalid-feedback">
                      Guest Email is already exist
                    </div>
                  </div>
                </div>




                <div class="col-md-5">
                  <div class="form-group">
                    <label>Refered By<span class="text-danger">*</span></label>
                    <input type="text" [ngModel]="Refered_By" formControlName="Refered_By" class="form-control"
                      placeholder="Enter Refered By" [ngClass]="{'is-invalid': submitted && f['Refered_By'].errors }"
                      (keypress)="general.removingSpaceFirst($event, f.Refered_By.value)">
                    <div *ngIf="submitted && f['Refered_By'].errors" class="invalid-feedback">
                      <div *ngIf="f['Refered_By'].errors">Refered By Number is required</div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
                <!-- <div class="row center-form"> -->
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Location<span class="text-danger">*</span></label>
                    <div class="input-group-append">
                      <mat-select formControlName="Location" [(ngModel)]="Location" class="form-control"
                        [ngClass]="{'is-invalid': submitted && f['Location'].errors }" placeholder="Please Location"
                        #locationdr>
                        <mat-select-filter *ngIf="locationdr.focused" [placeholder]="'Search'"
                          [displayMember]="'branch_name'" [array]="dataloc" (filteredReturn)="filtersearch = $event">
                        </mat-select-filter>
                        <mat-option *ngFor="let branch of filtersearch" [value]="branch.branch_id">
                          {{branch.branch_name }}
                        </mat-option>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="Location">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="Location=''; $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                    <div *ngIf="submitted && f['Location'].errors" class="invalid-feedback">
                      <div *ngIf="f['Location'].errors">Location is required</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="form-group">
                    <label>Rental City<span class="text-danger">*</span></label>
                    <div class="input-group-append">
                      <mat-select #select [ngClass]="{ 'is-invalid': submitted && f['Rental_City'].errors }"
                        formControlName="Rental_City" [(ngModel)]="Rental_City" class="form-control"
                        placeholder="Please Rental City" multiple #locationdr1>

                        <div class="select-all">
                          <!--*ngIf="locationdr1.focused"-->
                          <mat-select-filter [placeholder]="'Search'" *ngIf="locationdr1.focused"
                            [displayMember]="'branch_name'" [array]="dataloc" (filteredReturn)="filtersearch1 = $event">
                          </mat-select-filter>

                          <mat-checkbox [(ngModel)]="allSelected" class="drop" [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection()">Select All</mat-checkbox>
                        </div>
                        <mat-option (click)="optionClick()" *ngFor="let branch of filtersearch1"
                          [value]="branch.branch_id">
                          {{branch.branch_name}}
                        </mat-option>
                        <div style="margin: 10px; float: right;">
                        </div>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="Rental_City">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="Rental_City=''; $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                    <div *ngIf="submitted && f['Rental_City'].errors" class="invalid-feedback">
                      <div *ngIf="f['Rental_City'].errors">Rental City is required</div>
                    </div>
                  </div>
                </div>

                <!-- </div> -->
                <!-- <div class="row center-form"> -->
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Credit Limit<span class="text-danger">*</span></label>
                    <input type="text" maxlength="10" [ngModel]="Credit_Limit" formControlName="Credit_Limit"
                      class="form-control" placeholder="Enter creditlimit"
                      [ngClass]="{ 'is-invalid': submitted && f['Credit_Limit'].errors }"
                      (keypress)="general.removingSpaceFirst($event, f.Credit_Limit.value)">

                    <div *ngIf="submitted && f['Credit_Limit'].errors" class="invalid-feedback">
                      <div *ngIf="f['Credit_Limit'].errors">credit limit is required</div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row center-form"> -->

                <div class="col-md-5 form-group">

                  <div class="col-md-5 form-group" *ngIf="type != 'edit'">
                    <label>Status <span class="mandatory"></span></label>
                    <div>
                      <mat-slide-toggle class="status" formControlName="status" [checked]="status === 'ACTIVE'">
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 form-group">

                  <div class="col-md-5 form-group" *ngIf="type == 'edit'">
                    <label>Status <span class="mandatory"></span></label>
                    <div>
                      <mat-slide-toggle class="status" formControlName="status" [checked]="status === 'ACTIVE'">
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>

              </div>



              <div class="aligh-button-right">
                <button mat-button class="mat-button-cancel" [disabled]="isDisableBtn"
                  *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="logdata()">Logs</button>
                <button routerLink="/managedirectguest" class="mat-button-cancel">Cancel</button>
                <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
                  *ngIf="type != 'edit' && helperService.checkMenuAccess('r_create')" (click)="submit()">Submit</button>
                <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
                  *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="submit()">Update</button>
              </div>
            </form>
          </div>


        </div>
      </div>
    </div>

    <ng-template #secondDialog>
      <div class="d-flex justify-content-between custom-dialog-height">
        <label style="color: black; font-weight: bold !important;">Direct Guest Logs</label>
        <mat-icon (click)="closeDialog()" class="pointer">close</mat-icon>
      </div>
      <div class="table" style="overflow-x: auto;max-height: 350px;overflow-y: scroll;">
        <table id="customers" style="border: 1px solid black;border-collapse: collapse; width: 100%;">
          <tr style="height: 10%;">
            <th>sl.no</th>
            <th style="width: 30%;">User Name</th>
            <th style="width: 70%;">Date and Time</th>
            <th>Action</th>


          </tr>
          <tr *ngFor="let data of loglist; let i = index" class="pointer" (click)="viewdata(data)">
            <td style="font-size: 14px;word-break: break-all !important;
            white-space: pre-wrap !important;">{{i+1}}</td>
            <td style="font-size: 14px;word-break: break-all !important;
            white-space: pre-wrap !important;">{{data.user_name}}</td>
            <td style="font-size: 14px;word-break: break-word !important;
            white-space: pre-wrap !important;">{{data.date_time | date: 'dd-MM-yyyy h:mm a'}}</td>
            <td style="font-size: 14px;">{{data.action}}</td>

          </tr>
        </table>
        <div *ngIf="this.loglist?.length === 0" style="text-align: center">
          No records found
        </div>
      </div>
    </ng-template>

  </section>
</div>