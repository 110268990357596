import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';
import { DialogComponentComponent } from '@pages/dialog-component/dialog-component.component';
import { AppService } from '@services/customeradmin.service';
import { HelperService } from '@services/helper.service';
import { HistoryConfirmationService } from '@services/history-confirmation-services';
import { Managecustomer } from '@services/managecustomerguest.service';
import { ToastrService } from 'ngx-toastr';
import { json } from 'node:stream/consumers';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';



export interface PeriodicElement {

  id: number;
  location: any;
  customer_Group_name: any;
  customer_Name_array: any;
  invoice_type: any;
  invoice_month: any;
  invoice_comments: any;

}


@Component({
  selector: 'app-contractinvoice',
  templateUrl: './contractinvoice.component.html',
  styleUrls: ['./contractinvoice.component.scss']
})
export class ContractinvoiceComponent implements OnInit {

  contractInvoicelist: PeriodicElement[] = [];

  searchform !: FormGroup;

  displayedColumns: string[] = ['position', 'location', 'customer_group', 'customer', 'invoice_type', 'month', 'comments', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>();
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent;
  @ViewChild(MatSort) sort = new MatSort();

  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

  dataSourceLength: any;
  id: any;
  forzaadmin: any;
  userid: any;
  invoiceResponse: any;
  customer_group_id:any;
  pageEvent: PageEvent;
  base_location: any
  customer_group: any;
  filterlist4:any;
  invoicemonth:any;
  Customer: any;
  branch_name: any;
  Search: any;
  locationvalue:any;
  getlocation = [];
  locationlist: any = [];
  filterlist: any = [];
  msg:any;
  monthlist:any
  respolocation: any;
  public filteredListLocation = [];
  respocustomersgroup: any;
  getcustomergroupdata = [];
  filteredListGroups = [];
  customersgroup: any = [];
  getcustomergroupdatas = [];
  getcustomer: any;
  getcustomers: any;
  respocustomerGroupName: any;
  customerdata: any = [];
  filteredCompanyName = [];
  monthList: any;
  month: any;
  year:any;
  invoice_type: any;
  custrole = false;
  customer: any;
  isLoading: boolean = true;
  addbutton:boolean =false;
  toppingList = []
  startYear = new Date().getFullYear();
  range = [];
  display = true;
  yearlist:[]
  invoice_year:[]
  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private appService: AppService,
    public helperService: HelperService,
    private toastr: ToastrService,
    private managecustomerguests: Managecustomer,
    private historyService: HistoryConfirmationService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,

  ) {

  }

  ngOnInit(): void {
    this.searchform = this.formBuilder.group({
      base_location: [null, Validators.required],
      customer_group: [null, Validators.required],
      customer: [null, Validators.required],
      invoice_type: [null, Validators.required],
      month: [null, Validators.required],
      invoice_year: [null, Validators.required]
    })


    this.helperService.pageAccess('all')
    this.pageEvent;


    
    for (let i = 0; i < 2; i++) {
      this.range.push(this.startYear - i);
    
    }

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.userid = localStorage.getItem('userId');
    this.id = localStorage.getItem('Id');
    this.toppingList = this.displayedColumns;
    if (this.forzaadmin == 'Forza Admin') {
      this.custrole = true;
    }
    else {

    }
    this.getlocationfilter()
    this.getInvoiceList();
    this.getcustomersgroup(this.locationvalue);
    this.buildMonths();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  buildMonths = () => {
    // const month = ["January", "February", "March", "April", "May", "June", "July",
    //   "August", "September", "October", "November", "December"];
    // this.monthList = month;
    this.appService.getmonthList({}).subscribe(
      (response) => {
        this.msg = response;
       
        
        if (this.msg.success == true) {
          this.monthlist = this.msg.result;
          // this.monthlist = this.monthlist.sort((a, b) => {
          //   if (a.month < b.month) { return -1; }
          //   if (a.month > b.month) { return 1; }
          // })
          this.filterlist = this.monthlist.slice();
        
         
        }
      },
      (error) => {
        this.toastr.error(error, 'Error');
      }
    );
  }

  exportIt(excelType,name, sheetname,authorName) {

    this.isLoading = true

    var exportType = ExportType.CSV;

    if(excelType == 'xlsx'){
      exportType = ExportType.XLSX;
    }

    if(excelType == 'csv'){
      exportType = ExportType.CSV;
    }

   
    this.exporter.exportTable(exportType, {
      fileName: name,
      sheet: sheetname,
      Props: {
        Author: authorName
      }
    })

    setTimeout(() => {
      this.isLoading = false
    }, Number(this.contractInvoicelist.length) * 2);
  }

  getInvoiceList() {
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }
    this.appService.getInvoiceList(data).subscribe(
      (response) => {
        this.invoiceResponse = response;
        if (this.invoiceResponse.success == true) {
          this.contractInvoicelist = this.invoiceResponse.result;
          const result = this.invoiceResponse.result
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSourceLength = result.length;
          this.isLoading = false;
        }
      },
      (error) => {
        this.toastr.error(error, 'Error');
      }
    );

  }

  locationChanged(customer_group_id) {

    
    const data = {
      customer_group: customer_group_id,
      type:this.forzaadmin,
      user_id: this.id,
      location: this.locationvalue
    }
    
    
    this.historyService.getCustomers(data).subscribe(
      loc => {
        const response: any = loc;
        if (response.success == true) {
          
          
          this.filteredCompanyName = response.Customer_Name;
          
          this.filterlist4 = this.filteredCompanyName.slice();
        }
      }
    )
  }

  yearvalue(value){
this.invoice_year=value;


  }

  // delete(deleteid){
  //   var idarray = {};
  //   idarray['id'] = deleteid;
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = {
  //       body: 'Are you sure want to Delete?'
  //   };
  //   let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //         this.appService.delete(JSON.stringify(idarray)).subscribe(
  //             (response) => {
  //                 let responses: any = response;
  //                 if (responses.success === true) {
  //                     this.getInvoiceList();
  //                     this.toastr.success(responses.message, 'Success');
  //                 }
  //             },
  //             (error) => {
  //                 this.toastr.error(error, 'Error');
  //             }
  //         );
  //     }
  // });
  // }

 
  deleteInvoice(deleteid) {
    var idarray = {};
    idarray['id'] = deleteid;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
        body: 'Are you sure want to Delete?'
    };
    let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
    this.appService.deleteInvoice(JSON.stringify(idarray)).subscribe(
      (response) => {
        let responses: any = response;
        if (responses.success === true) {
          this.getInvoiceList();
          this.toastr.success(responses.message, 'Success');

        }
      },
      (error) => {
        this.toastr.error(error, 'Error');
      }
    );
  }
});
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
  }
  addguestbooking() {
    this.router.navigate(['/contractinvoice', 'add']);
  }
  submit() {
    this.isLoading = true;
    const data = {
      type:this.forzaadmin,
      user_id:this.id,
      "location": this.searchform.value.base_location != null ? this.searchform.value.base_location : '',
      "customer_Group": this.searchform.value.customer_group != null ? this.searchform.value.customer_group : '',
      "customer_Name": this.searchform.value.customer != null ? this.searchform.value.customer : '',
      "invoice_type": this.searchform.value.invoice_type != null ? this.searchform.value.invoice_type : '',
      "invoice_month": this.searchform.value.month != null ? this.searchform.value.month : '',
      "invoice_year":this.searchform.value.invoice_year != null ? this.searchform.value.invoice_year : '',
    }
console.log(data);

   
    
    this.appService.searchInvoice(data).subscribe(
      (response) => {
        this.invoiceResponse = response;
        if (this.invoiceResponse.success == true) {
          const result = this.invoiceResponse.result
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
          this.dataSourceLength = result.length;
        }
      },
      (error) => {
        this.toastr.error(error, 'Error');
      }
    )
  }
  clear() {
    this.searchform.reset();
    this.getInvoiceList();
  }

  view(id) {
    if (Number(this.helperService.checkMenuAccess('r_read')) > 0) {
      this.router.navigate(['/contractinvoice', btoa(id), 'view']);
    }
  }
  edit(id) {
    if (Number(this.helperService.checkMenuAccess('r_update')) > 0) {
      this.router.navigate(['/contractinvoice', btoa(id), 'edit']);
    }
  }

  getlocationfilter() {
    this.getlocation = this.locationlist = [];
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }
    this.managecustomerguests.getCustomerlocation(data).subscribe(
      (response) => {
        this.respolocation = response;
        if (this.respolocation.success == true) {
          this.locationlist = [...this.locationlist, ...this.respolocation.Location_List]
          this.getlocation = this.locationlist;
          //this.getlocations = this.locationlist;
          this.getlocation = this.getlocation.sort((a, b) => {
            if (a.location_name < b.location_name) { return -1; }
            if (a.location_name > b.location_name) { return 1; }
          })
          this.filteredListLocation = this.getlocation.slice();
        }
      })
  }
  getcustomersgroup = (value) => {
  
    this.locationvalue =value;
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }

    this.appService.getcustomersgroup(data).subscribe(
      response => {
        this.respocustomersgroup = response
        if (this.respocustomersgroup.success == true) {
          this.customersgroup = this.respocustomersgroup.result;
          this.getcustomergroupdata = this.customersgroup;
          this.getcustomergroupdatas = this.customersgroup;
          this.getcustomergroupdata = this.getcustomergroupdata.sort((a, b) => {
            if (a.customer_group_name < b.customer_group_name) { return -1; }
            if (a.customer_group_name > b.customer_group_name) { return 1; }
          })
          this.filteredListGroups = this.getcustomergroupdata.slice();
        }
      });
  }


  removeUnderScore = (val) => {
    return val.toString().replace(/_/gi, " ");
  }

}
