<div *ngIf="helperService.checkMenuAccess('r_read') && helperService.checkMenuAccess('r_create') && helperService.checkMenuAccess('r_update') && helperService.checkMenuAccess('r_delete') && helperService.checkMenuAccess('r_download')">
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
           
            <div class="col-sm-6">
                
            </div>
           
        </div>
      
    </div>
   
</div>



<section class="content" style="margin-bottom: -15px;margin-left: -15px;">
    <div class="container-fluid">
    
      <div class="row">
        <div class="col-md-2">
        
          <div class="small-box info">
            <div class="inner">
              <p>Request<br><b style="font-size: 30px;color: #434343;">{{RequestCount}}</b></p>
            

            </div>
            <div class="icon">
              <i class="fa-brands fa-telegram" style="font-size: 45px; color: #7874747d;"></i>
             </div>
           
          </div>
        </div>
       
        <div class="col-md-2">
         <div class="small-box bg-success">
            <div class="inner" style="height: 143px;">
              <p>Confirmed<br><b style="font-size: 30px;color: #434343;">{{ConfirmedCount}}</b></p>
           </div>
            <div class="icon">
            
              <i class="fa-regular fa-circle-check" style="font-size: 45px;color: #3f9b3f;"></i>
            </div>
          </div>
        </div>
       <div class="col-md-2">
         <div class="small-box bg-warning">
            <div class="inner">
              <p>Pending<br><b style="font-size: 30px;color: #434343;">{{PendingCount}}</b></p>
             </div>
            <div class="icon">
             <i class="fa-regular fa-clock" style="font-size: 45px;color:#ff8b40;"></i>
             </div>
           
          </div>
        </div>
        
        <div class="col-md-2">
         
          <div class="small-box bg-warning-CBS">
            <div class="inner">
              <p>APP Request<br><b style="font-size: 30px;color: #434343;">{{PendingCBSCount}}</b></p>
             

            </div>
            <div class="icon">
              
              <i class="fa-regular fa-clock" style="font-size: 40px;color:#e5b600;"></i>

            </div>
            
          </div>
        </div>
       
        <div class="col-md-2">
         
          <div class="small-box bg-danger">
            <div class="inner">
              <p>Rejected <br><b style="font-size: 30px;color: #434343;">{{RejectedCount}}</b></p>


            </div>
            <div class="icon">
              <i class="fa-regular fa-circle-xmark" style="font-size: 45px;color:#ff5151;"></i>
            </div>
           
          </div>
        </div>

       
      </div>
     
    </div>
  
  </section>

</div>