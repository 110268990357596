<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span>Dashboard</span> / <span class="activebreadcrumb-item active pointer" routerLink="/managebooking">Manage
          Bookings</span> / <span class="breadcrumb-item activebreadcrumb-item active">Web Bookings</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/mainicon-webbooking.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Web Booking</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12">


          </div>

          <form [formGroup]="searchform">
            <div class="row">
              <div class="col-md-2">
                <label>From</label>
                <div class="form-group">
                  <mat-form-field class="form-control" floatLabel=never>

                    <!-- <input autocomplete="off" matInput placeholder="dd-mm-yyyy"   (click)="picker.open();reset()" matInput [matDatepicker]="picker"  formControlName="from_date" 
                  (ngModelChange)="maxdate = $event" readonly> -->
                    <input autocomplete="off" matInput [(ngModel)]="from_Date" placeholder="dd-mm-yyyy"
                      (click)="picker.open();reset()" matInput [matDatepicker]="picker" formControlName="from_date"
                      readonly>

                    <mat-datepicker-toggle (click)="reset()" matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

                </div>
              </div>
              <div class="col-md-2">
                <label>To</label>
                <div class="form-group">
                  <mat-form-field class="form-control" floatLabel=never>

                    <input autocomplete="off" matInput placeholder="dd-mm-yyyy" (click)="picker1.open()" matInput
                      [matDatepicker]="picker1" [min]="from_Date" [(ngModel)]="end_date" formControlName="end_date"
                      readonly>

                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <!-- <input type="date" [min]="maxdate" [max]="CurrentDate" formControlName="end_date" class="form-control"> -->

                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label style="white-space:nowrap; text-overflow: ellipsis;">Billing location</label>
                  <div class="input-group-append">
                    <mat-select *ngIf="baselocationlist.length > 1" formControlName="base_Location"
                      [(ngModel)]="base_Location" class="form-control" placeholder="Please Billing location"
                      #baselocation>
                      <mat-select-filter *ngIf="baselocation.focused" [placeholder]="'Search'"
                        [displayMember]="'branch_name'" [array]="getCountryMasterData"
                        (filteredReturn)="baselocationlist = $event"></mat-select-filter>

                      <mat-option *ngFor="let branch of baselocationlist" [value]="branch.location_name">
                        {{branch.location_name}}
                      </mat-option>
                    </mat-select>
                    <div *ngIf="baselocationlist.length ==1">
                      <input type="text" formControlName="base_Location" class="form-control"
                        [(ngModel)]="locationFilter" readonly>
                    </div>
                    <a class="decoration-none input-group-text closeicon" *ngIf="base_Location">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="base_Location=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Filter</label>
                  <div class="input-group-append">
                    <!-- <mat-select-trigger  *ngIf="filter_option"  style=" position: absolute;padding: 43px 110px;">
                    <mat-icon class="icon" (click)="filter_option=''; $event.stopPropagation()">close</mat-icon>
                  </mat-select-trigger> -->
                    <mat-select placeholder="Please filter" formControlName="filter_option" [(ngModel)]="filter_option"
                      class="form-control" #filteroption>



                      <mat-select-filter *ngIf="filteroption.focused" [placeholder]="'Search'"
                        [displayMember]="'Filter'" [array]="filterdata"
                        (filteredReturn)="filterdata = $event"></mat-select-filter>
                      <!-- <mat-option>unselect</mat-option> -->
                      <mat-option *ngFor="let fil of filterdata" [value]="fil.Filter">
                        {{fil.Filter}}
                      </mat-option>

                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="filter_option">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="filter_option=''; key_word=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Keywords</label>
                  <input type="text" formControlName="key_word" placeholder="Search keywords" [(ngModel)]="key_word"
                    class="form-control" [readonly]="filter_option ? false : true">
                </div>
              </div>

              <div class="col-md-6">
                <div class="aligh-button">
                  <button mat-button class="mat-button-submit" style="margin-left: 0px !important;"
                    (click)="submit()">Search</button>
                  <button class="btn btn-default" style="margin-left: 10px;"
                    (click)="clear()">Reset</button>
                </div>
              </div>

              <!-- <div class="aligh-button">
              <button mat-button class="mat-button-submit" style="margin-top: 25px;" title="Search"
                (click)="submit()">Search</button>
                <button class="btn btn-default" style="margin-top: 25px;" title="Reset"
                (click)="clear()">Reset</button>

            </div> -->








            </div>
          </form>
        </div>
      </div>


      <div class="card">
        <div class="card-body">
          <!-- <div class="col-md-12"> -->
          <div class="row justify-content-between">
            <div class="col-md-6">
              <h4 style="font-weight:600; color: #233771;">Details</h4>
            </div>
            <div class="col-md-6">
              <div class="row justify-content-end">
                <div class="col-md-7">
                  <div class="search-box pull-center">
                    <div class="search-input">
                      <mat-icon role="img" matprefix="true"
                        class="mat-icon notranslate material-icons mat-icon-no-color icon-grey" aria-hidden="true"
                        data-mat-icon-type="font">search</mat-icon><input placeholder="Search" class="form-control"
                        (keyup)="applyFilter($event.target.value)">
                    </div>
                  </div>
                </div>

                <div class="col-md-5 d-flex justify-content-end">

                  <mat-icon *ngIf="helperService.checkMenuAccess('r_download')"
                    (click)="exportIt('csv','Web_booking', 'Web_booking_data','Forza')" title="CSV" type="button"
                    class="mat-button-submit c-btn mr-2 icon-img"
                    style="background-image:url('assets/images/CSV.png');"></mat-icon>

                  <mat-icon *ngIf="helperService.checkMenuAccess('r_download')"
                    (click)="exportIt('xlsx','Web_booking', 'Web_booking_data','Forza')" title="Excel" type="button"
                    class="mat-button-submit c-btn mr-2 icon-img"
                    style="background-image:url('assets/images/XLS.png');"></mat-icon>

                  <mat-icon class="text-right" *ngIf="helperService.checkMenuAccess('r_download')" title="Upload"
                    type="button" routerLink="/import-web-booking" class="mat-button-submit c-btn mr-2 icon-img"
                    style="background-image:url('assets/images/export.png');"></mat-icon>


                  <mat-icon *ngIf="helperService.checkMenuAccess('r_create')" title="Add" type="button"
                    (click)="addguestbooking()" class="text-right plus mat-button-submit c-btn icon-img"
                    style="background-image:url('assets/images/Add.png');"></mat-icon>




                </div>
              </div>
            </div>
          </div>





          <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
            <table [border]="1" [hidden]="isLoading" style="text-align: center;" id="tablerecords" matTableExporter
              #exporter="matTableExporter" mat-table [dataSource]="dataSource"
              class="table-striped pointer hidden-print" [hiddenColumns]="[14]">

              <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>sl.no</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{paginator.pageIndex == 0 ? i + 1 : 1 + i + paginator.pageIndex * paginator.pageSize}}
                </td>
              </ng-container>

              <!-- Name Column -->



              <ng-container matColumnDef="booking_id">
                <th mat-header-cell *matHeaderCellDef> BOOKING ID</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.booking_id" [matTooltipPosition]="'above'">{{element.booking_id}}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td style="text-align: center !important;" mat-cell *matCellDef="let element"
                  (click)="edit(element.booking_id)">
                  <span class="green-action pointer" [matTooltip]="element.status" [matTooltipPosition]="'above'"
                    [ngClass]="{'green':element.status === 'Pending Confirmation' ,'green-danger':element.status === 'Rejected', 'green-action':element.status === 'Confirmed'}"
                    (click)="statusManager(element)
                "> {{element.status}}</span>
                </td>
              </ng-container>



              <ng-container matColumnDef="pickup_date">
                <th mat-header-cell *matHeaderCellDef> PICKUP DATE </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.pickup_date | date: 'dd-MM-yyyy'" [matTooltipPosition]="'above'">
                    {{element.pickup_date | date: 'dd-MM-yyyy'}} </span>
                </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="pickup_time">
                <th mat-header-cell *matHeaderCellDef> Pickup Time </th>
                <!-- <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.pickup_time" [matTooltipPosition]="'above'">{{element.pickup_time |
                    convertFrom24To12Format}}</span>
                </td> -->
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.pickup_time" [matTooltipPosition]="'above'">{{element.pickup_time}}</span>
                </td>
              </ng-container>

              `
              <!-- <ng-container matColumnDef="booking_id">
                  <th mat-header-cell *matHeaderCellDef> booking id </th>
                  <td mat-cell *matCellDef="let element"> {{element.booking_id}} </td>
                </ng-container> -->

              <!-- Symbol Column -->
              <ng-container matColumnDef="guest_name">
                <th mat-header-cell *matHeaderCellDef> Guest Name </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.guest_name" [matTooltipPosition]="'above'">{{element.guest_name}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="guest_mobile">
                <th mat-header-cell *matHeaderCellDef> Guest Mobile </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.guest_mobile"
                    [matTooltipPosition]="'above'">{{element.guest_mobile}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="project_id">
                <th mat-header-cell *matHeaderCellDef> Project Id </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.project_id" [matTooltipPosition]="'above'">{{element.project_id}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="billing_location">
                <th mat-header-cell *matHeaderCellDef> Billing location </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.billing_location"
                    [matTooltipPosition]="'above'">{{element.billing_location}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="source_name">
                <th mat-header-cell *matHeaderCellDef> booking Source </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.source_name" [matTooltipPosition]="'above'">{{element.source_name}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="branch_name">
                <th mat-header-cell *matHeaderCellDef> rental city </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.branch_name" [matTooltipPosition]="'above'">{{element.branch_name}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="pickup_address">
                <th mat-header-cell *matHeaderCellDef> pickup address </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.pickup_address"
                    [matTooltipPosition]="'above'">{{element.pickup_address}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="rental_name">
                <th mat-header-cell *matHeaderCellDef>rental type </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.rental_name" [matTooltipPosition]="'above'">{{element.rental_name}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="vehicle_model">
                <th mat-header-cell *matHeaderCellDef> vehicle type</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.vehicle_model"
                    [matTooltipPosition]="'above'">{{element.vehicle_model}}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="spl_instructions">
                <th mat-header-cell *matHeaderCellDef>Special Instruction</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.booking_id)">
                  <span [matTooltip]="element.spl_instructions"
                    [matTooltipPosition]="'above'">{{(element.spl_instructions) ? element.spl_instructions :
                    '-'}}</span>
                </td>
              </ng-container>




              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef class="print-none"> ACTION </th>
                <td class="edit" mat-cell *matCellDef="let element" class="print-none">

                  <div
                    *ngIf="helperService.checkMenuAccess('r_read') || helperService.checkMenuAccess('r_update') || helperService.checkMenuAccess('r_delete')">
                    <button mat-icon-button [matMenuTriggerFor]="menu" class="action"
                      aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button (click)="view(element.booking_id)" *ngIf="helperService.checkMenuAccess('r_read')"
                        mat-menu-item>
                        <mat-icon role="img"
                          class="mat-icon notranslate material-icons eye align-middle pointer hover-color"
                          aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon>View
                      </button>
                      <button *ngIf="element.source_name === 'CBS' && helperService.checkMenuAccess('r_update')"
                        (click)="edit(element.booking_id)" mat-menu-item>
                        <mat-icon class="edit align-middle pointer hover-color">edit</mat-icon>Edit
                      </button>
                      <!-- <button mat-menu-item (click)="delete(element.id)">
                    <mat-icon class="delete align-middle pointer hover-color">delete</mat-icon>Delete
                  </button> -->
                    </mat-menu>
                  </div>
                </td>

              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="this.dataSource.filteredData.length === 0" style="text-align: center">
              No records found
            </div>
            <!-- <div class="footer"> -->
            <!-- <mat-paginator #paginator [pageSizeOptions]="[10,25,50]" [pageSize]="10">
            </mat-paginator> -->

            <!-- <custom-paginator [pageSizeOptions]="[10,25,50]" justify-content="space-between" ></custom-paginator> -->

            <!-- </div> -->

          </div>

          <div class="col-md-12 mt-2 table" [hidden]="!isLoading">
            <table class="norecordfoundtable">
              <tr>
                <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}</th>
              </tr>
              <tr>
                <td style="text-align: center;" [attr.colspan]="toppingList.length">
                  {{'Loading.......'}}
                </td>
              </tr>
            </table>
          </div>

          <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading" [pageSizeOptions]="[10,25,50]"
            justify-content="space-between"></custom-paginator>




          <!-- </div> -->

        </div>
      </div>
    </div>
  </section>
</div>