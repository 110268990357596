<div class="mx-4">


<section class="content-header">
  <div class="container-fluid">
    <div>
      <span>Dashboard</span> / <span class="activebreadcrumb-item active pointer"
        routerLink="/managecustomerguest">Manage Customer Guest</span> / <span
        class="breadcrumb-item activebreadcrumb-item active">{{(type === 'edit') ? 'Edit' : 'Add'}}</span>
    </div>
    <div class="row mt-md-2">
      <div class="col-auto">
        <img src="assets/images/main-customerguest.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
      </div>
      <div class="col">
        <div class="row flex-column justify-content-center mt-md-3">
          <div class="col">
            <h4 style="font-weight:600; color: #233771;">{{(type === 'edit') ? 'Edit' : 'Add'}} Customer Guest</h4>
          </div>
        </div>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <h6 class="pointer pt-3" routerLink="/managecustomerguest"><span class="fas fa-angle-left p-2"></span>Back
        </h6>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">    
    <div class="card">
      <div class="card-body">
        <div class="container-fluid">
          <form [formGroup]="customerguest">
            <div class="row justify-content-between mx-4">
              <div class="col-md-5">
                <div class="form-group">

                  <label>Employee Location<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [(ngModel)]="employee_Location" formControlName="employee_Location" class="form-control"
                      (selectionChange)="onchangelocation($event.value)"
                      [ngClass]="{'is-invalid': submitted && f['employee_Location'].errors }"
                      placeholder="Please Employee Location" #employe>
                      <mat-select-filter *ngIf="employe.focused" [placeholder]="'Search'"
                        [displayMember]="'location_name'" [array]="branches" (filteredReturn)="filteredList = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let branch of filteredList" [value]="branch.location_id">
                        {{branch.location_name}}
                      </mat-option>
                    </mat-select>


                    <a class="decoration-none input-group-text closeicon" *ngIf="employee_Location">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="employeelocation(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['employee_Location'].errors" class="text-danger">
                    <div *ngIf="f['employee_Location'].errors"> Employee location is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Customer Group<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [(ngModel)]="customer_Group" formControlName="customer_Group" class="form-control"
                      [ngClass]="{'is-invalid': submitted && f['customer_Group'].errors }"
                      placeholder="Please Customer Group" (selectionChange)="getcustomerGroupName($event.value)"
                      #cusgroup>
                      <mat-select-filter *ngIf="cusgroup.focused" [placeholder]="'Search'"
                        [displayMember]="'customer_group_name'" [array]="customersgroup"
                        (filteredReturn)="filtersearch1 = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let group of filtersearch1" [value]="group.customer_group_id">
                        {{group.customer_group_name}}
                      </mat-option>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="customer_Group">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="customerclose(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['customer_Group'].errors" class="text-danger">
                    <div *ngIf="f['customer_Group'].errors">Customer Group is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Customer Name<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [(ngModel)]="customer" formControlName="customer" class="form-control"
                      [ngClass]="{'is-invalid': submitted && f['customer'].errors }" placeholder="Please Customer"
                      (selectionChange)="getcustomerloc($event.value)" #select2>
                      <mat-select-filter *ngIf="select2.focused" [placeholder]="'Search'"
                        [displayMember]="'customer_name'" [array]="getcustomerdata"
                        (filteredReturn)="filtersearch2 = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let data of filtersearch2" [value]="data.customers_id">
                        {{data.customer_name}}
                      </mat-option>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="customer">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="customernameclose(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['customer'].errors" class="text-danger">
                    <div *ngIf="f['customer'].errors">customer Name is required</div>
                  </div>
                </div>
              </div>




              <div class="col-md-5">
                <div class="form-group">
                  <label>Employee Name<span class="mandatory">*</span></label>
                  <input type="text" maxlength="50" [ngModel]="employee_name" formControlName="employee_name"
                    class="form-control" placeholder="Employee Name"
                    [ngClass]="{'is-invalid': submitted && f['employee_name'].errors }"
                    (keypress)="general.removingSpaceFirst($event, f.employee_name.value)">
                  <div *ngIf="submitted && f['employee_name'].errors" class="text-danger">
                    <div *ngIf="f['employee_name'].errors">Employee Name is required</div>
                  </div>
                </div>
              </div>



              <div class="col-md-5">
                <div class="form-group">
                  <label>Employee Mobile Number<span class="mandatory">*</span></label>
                  <input type="text" [ngModel]="employee_Mobile_Number" formControlName="employee_Mobile_Number"
                    class="form-control" placeholder="Employee Mobile Number" maxlength="13"
                    (keyup)="onkeypress($event.target.value)" appOnlyNumber
                    [ngClass]="{'is-invalid': submitted && f['employee_Mobile_Number'].errors }"
                    (keypress)="general.removingSpaceFirst($event, f.employee_Mobile_Number.value)">
                  <div *ngIf="submitted && f['employee_Mobile_Number'].errors" class="text-danger">
                    <div *ngIf="f['employee_Mobile_Number'].errors.required">Employee Mobile Number is required</div>
                  </div>
                  <!-- {{f['employee_Mobile_Number'].errors | json}} -->
                  <div *ngIf="f['employee_Mobile_Number'].errors !== null">
                    <div
                      *ngIf="f['employee_Mobile_Number'].errors.minlength && !f['employee_Mobile_Number'].errors.pattern?.requiredPattern"
                      class="cinvalid-feedback">
                      Employee Mobile Number Minimum 10 to 13 digits
                    </div>
                    <div *ngIf="f['employee_Mobile_Number'].errors.pattern?.requiredPattern" class="cinvalid-feedback">
                      Please enter the valid mobile number
                    </div>
                  </div>
                  <div *ngIf="mobexist === false" class="cinvalid-feedback">
                    Employee Mobile Number is already exist
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Employee Email ID<span class="mandatory">*</span></label>
                  <input type="email" maxlength="50" [ngModel]="employee_Email_Id"
                    (keyup)="checkemail($event.target.value)" formControlName="employee_Email_Id" class="form-control"
                    placeholder="Enter Employee Email ID"
                    [ngClass]="{'is-invalid': submitted && f['employee_Email_Id'].errors }"
                    (keypress)="general.removingSpaceFirst($event, f.employee_Email_Id.value)">
                  <div *ngIf="submitted && f['employee_Email_Id'].errors" class="text-danger">
                    <div *ngIf="f['employee_Email_Id'].errors">Employee Email ID is required</div>
                  </div>
                  <div *ngIf="emailexist === false" class="cinvalid-feedback">
                    Employee Email ID is already exist
                  </div>
                </div>
              </div>



              <div class="col-md-5">
                <div class="form-group">
                  <label>Employee Id</label>
                  <input type="text" maxlength="20" [ngModel]="employee_Id" formControlName="employee_Id"
                    class="form-control" placeholder="Employee Id"
                    [ngClass]="{'is-invalid': submitted && f['employee_Id'].errors }"
                    (keypress)="general.removingSpaceFirst($event, f.employee_Id.value)">
                  <!-- <div *ngIf="submitted && f['employee_Id'].errors" class="text-danger">
                    <div *ngIf="f['employee_Id'].errors">Employee Id is required</div>
                  </div> -->
                </div>
              </div>



              <div class="col-md-5" [hidden]="showdrpdwn === false">
                <div class="form-group">
                  <label>Customer Admin Name<span class="mandatory" *ngIf="showdrpdwn === true">*</span></label>
                  <div class="input-group-append">
                    <mat-select formControlName="customer_Admin" [(ngModel)]="Customer_Admin" class="form-control"
                      [ngClass]="{'is-invalid': submitted && f['customer_Admin'].errors }"
                      placeholder="Please Customer Admin Name" #select3>
                      <mat-select-filter *ngIf="select3.focused" [placeholder]="'Search'"
                        [displayMember]="'customer_Admin_Name'" [array]="adminlist"
                        (filteredReturn)="filtersearch3 = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let data of filtersearch3" [value]="data.customer_Admin_Name">
                        {{data.customer_Admin_Name}}
                      </mat-option>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="Customer_Admin">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="Customer_Admin=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['customer_Admin'].errors" class="text-danger">
                    <div *ngIf="f['customer_Admin'].errors">customer admin name is required</div>
                  </div>
                </div>
              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Rental Location<span class="locationmenu mandatory">*</span></label>
                  <div class="input-group-append">
                    <!-- {{location}} -->
                    <mat-select [(ngModel)]="location" formControlName="location" class="form-control select_data"
                      (selectionChange)="onchangevechile($event.value)"
                      [ngClass]="{'is-invalid': submitted && f['location'].errors }" placeholder="Please Location"
                      multiple #select6>
                      <mat-select-filter *ngIf="select6.focused" [placeholder]="'Search'"
                        [displayMember]="'branch_name'" [array]="locationlist"
                        (filteredReturn)="filtersearch6 = $event"></mat-select-filter>
                      <mat-option *ngFor="let branch of filtersearch6" class="multiselect" [value]="branch.branch_id">
                        {{branch.branch_name}}
                      </mat-option>

                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="location">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="locationclose(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['location'].errors" class="text-danger">
                    <div *ngIf="f['location'].errors">Location is required</div>
                  </div>
                </div>
              </div>



              <div class="col-md-5" *ngIf="creditlimit">
                <div class="form-group">
                  <label>Credit Limit<span class="mandatory">*</span></label>
                  <input type="text" maxlength="10" [ngModel]="Credit_Limit" formControlName="Credit_Limit"
                    class="form-control" placeholder="Enter Credit Limit"
                    [ngClass]="{'is-invalid': submitted && f['Credit_Limit'].errors }"
                    (keypress)="general.removingSpaceFirst($event, f.Credit_Limit.value)">
                  <div *ngIf="submitted && f['Credit_Limit'].errors" class="text-danger">
                    <div *ngIf="f['Credit_Limit'].errors">Credit Limit is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Select Vehicle<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [(ngModel)]="Vehicle" #select formControlName="Vehicle"
                      (selectionChange)="changerental($event.value)" class="form-control select_data"
                      [ngClass]="{'is-invalid': submitted && f['Vehicle'].errors }" placeholder="Please Select Vehicle"
                      multiple #vehicleselect>
                      <mat-select-filter *ngIf="vehicleselect.focused" [placeholder]="'Search'"
                        [displayMember]="'vehicle_model'" [array]="vechile"
                        (filteredReturn)="filtersearch4 = $event"></mat-select-filter>
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allSelected" class="drop" [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection()">Select All</mat-checkbox>
                      </div>

                      <mat-option (click)="optionClick()" *ngFor="let data of filtersearch4"
                        [value]="data.vehicle_model_id">

                        {{data.vehicle_model}}
                      </mat-option>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="Vehicle">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="vehicleclose(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['Vehicle'].errors" class="text-danger">
                    <div *ngIf="f['Vehicle'].errors">Select Vehicle is required</div>
                  </div>
                </div>
              </div>



              <div class="col-md-5">
                <div class="form-group">
                  <label>Rental Type<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [(ngModel)]="rental_Type" #select1 formControlName="rental_Type"
                      (selectionChange)="change($event)" class="form-control select_data"
                      [ngClass]="{'is-invalid': submitted && f['rental_Type'].errors }" placeholder="Please Rental Type"
                      multiple #rentaltype>
                      <mat-select-filter *ngIf="rentaltype.focused" [placeholder]="'Search'"
                        [displayMember]="'rental_name'" [array]="vechileist"
                        (filteredReturn)="filtersearch5 = $event"></mat-select-filter>
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allSelected1" class="drop" [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection1()">Select All</mat-checkbox>
                      </div>
                      <mat-option (click)="optionClick1()" *ngFor="let data of filtersearch5" [value]="data.rental_id">
                        {{data.rental_name}}
                      </mat-option>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="rental_Type">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="rental_Type=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['rental_Type'].errors" class="text-danger">
                    <div *ngIf="f['rental_Type'].errors">Rental Type is required</div>
                  </div>
                </div>
              </div>





              <div class="col-md-5" [hidden]="showdrpdwn === false">
                <div class="form-group">
                  <label>Direct Booking<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [(ngModel)]="Direct_Booking" 
                      [ngClass]="{ 'is-invalid': submitted && f['Direct_Booking'].errors }"
                      formControlName="Direct_Booking" class="form-control" placeholder="Please "
                      placeholder="Please Direct Booking">

                      <mat-option value="Direct">Not Required
                      </mat-option>
                      <mat-option value="Indirect">
                        Required </mat-option>
                      <div style="margin: 10px; float: right;">
                      </div>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="Direct_Booking">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="Direct_Booking=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['Direct_Booking'].errors" class="text-danger">
                    <div *ngIf="f['Direct_Booking'].errors">Direct Booking is required</div>
                  </div>
                </div>
              </div>




              <div *ngIf="type != 'edit'" class="col-md-5 form-group">
                <label>Status</label>
                <div>
                  <mat-slide-toggle class="status" (change)="changetoggle($event.checked)" formControlName="status"
                    [checked]="status === 'ACTIVE'">
                    <div>{{labelStatus}}</div>
                  </mat-slide-toggle>

                </div>
              </div>

              <div *ngIf="type == 'edit'" class="col-md-5 form-group">
                <label>Status</label>
                <div>
                  <mat-slide-toggle class="status" (change)="changetoggle($event.checked)" formControlName="status"
                    [checked]="status === 'DEACTIVE'">
                    <div>{{labelStatus}}</div>
                  </mat-slide-toggle>

                </div>
              </div>



              <div class="col-md-5 form-group">
                <section class="">
                  <mat-checkbox  formControlName='smssent'  [checked]='smssent'><span style="white-space:pre-wrap !important">Tick to send sms to Customer Admin on
                    raising booking request</span></mat-checkbox>

                </section>
              </div>


            </div>

            <div class="aligh-button-right">
              <button mat-button class="mat-button-cancel" [disabled]="isDisableBtn"
                *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="logdata()">Logs</button>
              <button routerLink="/managecustomerguest" class="mat-button-cancel">Cancel</button>

              <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
                *ngIf="type != 'edit' && helperService.checkMenuAccess('r_create')" (click)="submit()">Submit</button>
              <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
                *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="submit()">Update</button>
            </div>
          </form>
        </div>


      </div>
    </div>
  </div>

  <ng-template #secondDialog>
    <div class="d-flex justify-content-between custom-dialog-height">
      <label style="color: black; font-weight: bold !important;">Customer Guest Logs</label>
      <mat-icon (click)="closeDialog()" class="pointer">close</mat-icon>
    </div>
    <div class="table" style="overflow-x: auto;max-height: 350px;overflow-y: scroll;">
      <table id="customers" style="border: 1px solid black;border-collapse: collapse; width: 100%;">
        <tr style="height: 10%;">
          <th>sl.no</th>

          <th style="width: 30%;">User Name</th>
          <th style="width: 70%;">Date and Time</th>
          <th>Action</th>


        </tr>
        <tr *ngFor="let data of loglist; let i = index" class="pointer" (click)="viewdata(data)">
          <td style="font-size: 14px;word-break: break-all !important;
          white-space: pre-wrap !important;">{{i+1}}</td>
          <td style="font-size: 14px;word-break: break-all !important;
          white-space: pre-wrap !important;">{{data.user_name}}</td>
          <td style="font-size: 14px;word-break: break-word !important;
          white-space: pre-wrap !important;">{{data.date_time | date: 'dd-MM-yyyy h:mm a'}}</td>
          <td style="font-size: 14px;">{{data.action}}</td>

        </tr>
      </table>
      <div *ngIf="this.loglist?.length === 0" style="text-align: center">
        No records found
      </div>
    </div>
  </ng-template>

  <!-- /.card -->
</section>
</div>
<!-- /.content -->

<!-- /.card -->

<!-- /.content -->