import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Route, Router } from '@angular/router';
import { Managecustomer } from '@services/managecustomerguest.service';
import { response } from 'express';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
type AOA = any[][];

export interface PeriodicElement {
customer : any;
billing_location : any;
rental_city : any;
booking_type : any;
pickup_date : any;
end_date : any;
pickup_time : any;
vehicle_model : any;
rental_type : any;
paymode_default : any;
booking_source : any;
coordinator_name : any;
coordinator_mobile : any;
project_id : any;
request_id : any;
comments : any;
cc_mail : any;
prefix : any;
guest_name : any;
guest_mobile : any;
employee_id : any;
guest_mail_id : any;
prefix1 : any;
guest_name1 : any;
guest_mobile1 : any;
employee_id1 : any;
guest_mail_id1 : any;
prefix2 : any;
guest_name2 : any;
guest_mobile2 : any;
employee_id2 : any;
guest_mail_id2 : any;
prefix3 : any;
guest_name3 : any;
guest_mobile3 : any;
employee_id3 : any;
guest_mail_id3 : any;
pickup_address : any;
drop_address : any;
spl_instructions	 : any;
flight_details : any;
}

const ELEMENT_DATA: PeriodicElement[] = [];


@Component({
  selector: 'app-import-webbooking',
  templateUrl: './import-webbooking.component.html',
  styleUrls: ['./import-webbooking.component.scss']
})
export class ImportWebbookingComponent implements OnInit {

  isShowFile: boolean = false;
  public files: any[] = [];
  public isLoading = false;
  log_id: any;


  data: any = [["customer", "billing_location", "rental_city", "booking_type", "pickup_date", "end_date", "pickup_time", "vehicle_model", "rental_type", "paymode_default", "booking_source", "coordinator_name", "coordinator_mobile", "project_id", "request_id", "comments", "cc_mail", "prefix", "guest_name", "guest_mobile", "employee_id", "guest_mail_id", "prefix1", "guest_name1", "guest_mobile1", "employee_id1", "guest_mail_id1", "prefix2", "guest_name2", "guest_mobile2", "employee_id2", "guest_mail_id2", "prefix3", "guest_name3", "guest_mobile3", "employee_id3", "guest_mail_id3", "pickup_address", "drop_address", "spl_instructions", "flight_details"]];

  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'webbooking-import.xlsx';
  public data1 = [];
  tabledata: any[];
  table: boolean = false;
  button: boolean = false;
  isLoader: boolean = false;
  msg: any;

  displayedColumns: string[] = [
    'customer', 'billing_location', 'rental_city', 'booking_type', 'pickup_date', 'end_date', 'pickup_time', 'vehicle_model', 'rental_type', 'paymode_default', 'booking_source', 'coordinator_name', 'coordinator_mobile', 'project_id', 'request_id', 'comments', 'cc_mail', 'prefix', 'guest_name', 'guest_mobile', 'employee_id', 'guest_mail_id', 'prefix1', 'guest_name1', 'guest_mobile1', 'employee_id1', 'guest_mail_id1', 'prefix2', 'guest_name2', 'guest_mobile2', 'employee_id2', 'guest_mail_id2', 'prefix3', 'guest_name3', 'guest_mobile3', 'employee_id3', 'guest_mail_id3', 'pickup_address', 'drop_address', 'spl_instructions', 'flight_details'];

  displayedColumnsf: string[] = ['message',
    'customer', 'billing_location', 'rental_city', 'booking_type', 'pickup_date', 'end_date', 'pickup_time', 'vehicle_model', 'rental_type', 'paymode_default', 'booking_source', 'coordinator_name', 'coordinator_mobile', 'project_id', 'request_id', 'comments', 'cc_mail', 'prefix', 'guest_name', 'guest_mobile', 'employee_id', 'guest_mail_id', 'prefix1', 'guest_name1', 'guest_mobile1', 'employee_id1', 'guest_mail_id1', 'prefix2', 'guest_name2', 'guest_mobile2', 'employee_id2', 'guest_mail_id2', 'prefix3', 'guest_name3', 'guest_mobile3', 'employee_id3', 'guest_mail_id3', 'pickup_address', 'drop_address', 'spl_instructions', 'flight_details'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSource1 = new MatTableDataSource(ELEMENT_DATA);
  excelJSON: any = []
  failexcel: any = []
  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(public toast: ToastrService, private managecustomer: Managecustomer, private router: Router) { }

  ngOnInit(): void {
    this.log_id =localStorage.getItem('Id');
  }

  onFileChange(pFileList: File[]) {
    this.files = Object.keys(pFileList).map(key => pFileList[key]);
    var extn = this.files[0].name.split(".").pop();
    this.isShowFile = true;
    if (extn != 'xlsx') {
      this.isShowFile = false;
      this.files = [];
      this.failexcel = [];
      this.excelJSON = [];
      this.toast.error('Please Upload a XSLX File');
    }

    if (this.files.length > 0) {
      // this.validation();
      let workBook: any = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = this.files[0];

      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, { raw: false });
          return initial;
        }, {});

        let arrayData = jsonData.Sheet1;
        if (arrayData.length > 0) {
          this.validation(arrayData);
          this.button = true;
        } else {
          this.isShowFile = false;
          this.failexcel = [];
          this.excelJSON = [];
          this.files = [];
          this.toast.error('Please Upload a Valid File');
        }
      }
      reader.readAsBinaryString(file);
    } else {
      this.isShowFile = false;
      this.failexcel = [];
      this.excelJSON = [];
      this.files = [];
      this.toast.error('Please Upload a Valid File');
    }
  }

  toExportFileName(fileName: string): string {
    return `${fileName}_export_${new Date().getTime()}.xlsx`;
  }

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this.isShowFile = false;
    this.failexcel = [];
    this.excelJSON = [];
    this.button = false;
    this.table = false;
  }

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  submitimport() {
    this.isLoading = true;
    let obj = {
      user_id : Number(this.log_id),
      upload_array : this.excelJSON
      }
    this.managecustomer.createImportExportUploadWebBooking(obj).subscribe(response => {
      this.msg = response;
      if (this.msg.success == true) {
        this.toast.success(this.msg.message, 'Success');
        this.files = [];
        this.isLoading = false;
        this.table = false;
        setTimeout(() => {
          this.router.navigate(['/webbooking']);
        }, 500);
      } else {
        this.toast.error(this.msg.message, 'Error');
        this.isLoading = false;
      }
    },
    error => {
      this.isLoading = false;
    });

  }

  validation(e) {
    this.isLoader = true    
    let obj = {
    user_id : Number(this.log_id),
    upload_array : e
    }
    this.managecustomer.validationexcelWebBooking(obj).subscribe(response => {
      this.msg = response;
      if (this.msg.success == true) {

        if (this.msg.success_list.length > 0) {
          if (this.msg.success_list.length > 1) {
            this.toast.success(this.msg.success_list.length + ' records ready to upload.', 'Success');
          } else {
            this.toast.success(this.msg.success_list.length + ' record ready to upload', 'Success');
          }
          this.excelJSON = this.msg.success_list;
          this.dataSource = new MatTableDataSource(this.excelJSON);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoader = false
        }

        if (this.msg.failed_list.length > 0) {
          if (this.msg.failed_list.length > 1) {
            this.toast.error(this.msg.failed_list.length + ' records are mismatch.', 'Error');
          } else {
            this.toast.error(this.msg.failed_list.length + ' record mismatch', 'Error');
          }


          this.failexcel = this.msg.failed_list;
          this.dataSource1 = new MatTableDataSource(this.failexcel);
          this.dataSource1.paginator = this.paginator;
          this.dataSource1.sort = this.sort;
          this.isLoader = false
        }

        if (this.msg.failed_list.length == 0 && this.msg.success_list.length == 0) {
          this.isShowFile = false;
          this.failexcel = [];
          this.excelJSON = [];
          this.files = [];
          this.toast.error('Please Upload a Valid File');
          this.isLoader = false
        }
        this.table = true;
      } else {
        this.toast.error(this.msg.message, 'Error');
        this.isLoader = false
      }
    });
  }

}
