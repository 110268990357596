<app-dropdown class="user-menu">
    <ng-container dropdown-button>
        <img
            [src]="user.picture || 'assets/img/default-profile.png'"
            class="user-image img-circle elevation-2"
            alt="User Image"
        />
    </ng-container>
    <ng-container dropdown-menu>
        <li class="user-header bg-primary">
            <img
                [src]="user.picture || 'assets/img/default-profile.png'"
                class="img-circle elevation-2"
                alt="User Image"
            />

            <p>
                <span>{{user_Name}}</span><br>
                <span>{{ user }}</span>
            </p>
            <mat-divider style="background-color: #FFFFFF;"></mat-divider>
            <!-- <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Sign out</a> -->
        </li>
        <!-- Menu Body -->
        <li class="user-footer  bg-primary">
            <a [routerLink]="['/profile']" style="text-decoration: underline;" class="btn-flat pointer">
                Profile
            </a>
            <a (click)="logout()"  style="text-decoration: underline;" class="btn-flat float-right pointer"
                >Log Out</a
            >
        </li>
    </ng-container>
</app-dropdown>
