
<a (click)="handleMainMenuAction()" 
    class="d-flex nav-link {{(forzaadmin == 'Forza Admin' && (menuItem.name).trim() == 'Manage bookings') ? 'displaynone' : ''}}"
    [ngClass]="{active: isMainActive || isOneOfChildrenActive}" *ngIf="helperService.isMenuShow(menuItem.resource)" >

    <i class="nav-icon {{ menuItem.iconClasses }}"></i>
    <p>{{ menuItem.name }}
        <i
            *ngIf="isExpandable && menuItem.children.length > 0"
            class="right fas fa-angle-right"
            [@rotate]="isMenuExtended"
        ></i>
    </p>
</a>
<ul class="nav nav-treeview sidebar-vertical-line" *ngFor="let item of menuItem.children" [@openClose]="isMenuExtended">
    <li class="nav-item" *ngIf="helperService.isMenuShow(item.resource)">
        <a
            [routerLink]="item.path"
            [routerLinkActive]="'active'"
            class="{{(item.active != '') ? 'nav-link active' : 'nav-link'}} "
            
        >
            <i class="nav-icon {{ item.iconClasses }}"></i>
            <p>{{ item.name }}</p>
        </a>
    </li>
</ul>