import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding
} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, FormGroup, FormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  password;

  show = false;
  isLoginFormSubmitted = false;
  @HostBinding('class') class = 'login-box';
  public loginForm: FormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  msg:any;
  userId:any;
  orgLogo:any;
  username:any;
  public baseUrl = 'lms';
  constructor(
      private renderer: Renderer2,
      private router: Router,
      private toastr: ToastrService,
      private appService: AppService,
      private cookieService: CookieService
  ) {}

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );

        this.loginForm = new FormGroup({
            username: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required)
        });

        let val = localStorage.getItem('Id');
        if(Number(val) > 0) {
            // this.router.navigate(['/dashboard']);
            this.router.navigate(['/managebooking']);

        }
    }

    async loginByAuth() {
      this.isLoginFormSubmitted = true;
      if (this.loginForm.valid) {
          this.isAuthLoading = true;
          this.appService.loginByAuth(this.loginForm.value).subscribe(
          response => {
          this.msg = response;
          if (this.msg.success == true) {
            localStorage.setItem('userId', this.loginForm.value.username);
            localStorage.setItem('Id', this.msg.User_Details.id );
            localStorage.setItem('emp_mob', this.msg.User_Details.customer_Admin_mobile );
            localStorage.setItem('emp_Id', this.msg.User_Details.Customer_Admin_Employee_ID );
            localStorage.setItem('name', this.msg.User_Details.customer_Admin_Name );
            localStorage.setItem('loginType', 'manual');
            localStorage.setItem('rba', JSON.stringify(this.msg.rba));
            localStorage.setItem('customer_Role_name', this.msg.User_Details.customer_Role_name);
            this.toastr.success(this.msg.message,'Success');

            setTimeout(() => {
            //   this.router.navigate(['/dashboard']);
              this.router.navigate(['/managebooking']);

            }, 500);


          } else {
              this.toastr.error(this.msg.message, 'Error');
              this.router.navigate(['/login']);
          }
          },
          error => {
          this.toastr.error(error, 'Error');
          });
          this.isAuthLoading = false;
      }
    //   else {
    //     this.toastr.error('Enter The UserName and password');
    //       return;
    //   }
  }

    async loginByGoogle() {
        this.isGoogleLoading = true;
        await this.appService.loginByGoogle();
        this.isGoogleLoading = false;
    }
    get f() {
        return this.loginForm.controls
      }

    async loginByFacebook() {
        this.isFacebookLoading = true;
        await this.appService.loginByFacebook();
        this.isFacebookLoading = false;
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
