import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { TripSheetService } from '@services/trip-sheet-services';
import { HistoryConfirmationService } from '@services/history-confirmation-services';
import { TripSheetCount, TripSheetList } from '@/models/trip-sheet';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { Expression } from '@angular/compiler';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-trip-sheet',
  templateUrl: './trip-sheet.component.html',
  styleUrls: ['./trip-sheet.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})



export class TripSheetComponent implements OnInit {


  searchform !: FormGroup;
  maxdate: any;
  loglist: any;
  msg: any;
  from_Date: any;
  end_date: any;
  CurrentDate:any;
  to_date: any;
  filter_list = [];
  trip_location_list = [];
  filteredList:any=[];
  forzaadmin: string;
  userid: string;
  id: string;
  gridlist:any;
  filter_option = false;
  iskeyword = true;
  dataSource = new MatTableDataSource<TripSheetList>();
  dataSourceLength: any;
  toppingList: any;
  isLoading = true;

  start_date:any
  
  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;
  tipsList: TripSheetList[];
  displayedColumns: string[] = ['Actions', 'booking_source', 'trip_no', 'trip_date', 'trip_time', 'customer_name', 'guest_name',
    'rental_type', 'vehicle_type',  'booking_id', 'bill_amount',  'vehicle_no', 'ce_name',
    'ce_no'];
  countList: TripSheetCount = { success: true, billed: 0, non_billed: 0, closed_trip: 0, non_closed_trip: 0 };
 
  bookingSource = new FormControl('');
  trip_status = new FormControl('');
  trip_no = new FormControl('');
  trip_date = new FormControl('');
  trip_time = new FormControl('');
  customer_name = new FormControl('');
  guest_name = new FormControl('');
  rental_type = new FormControl('');
  vehicle_type = new FormControl('');
  // sent_vehicle_type = new FormControl('');
  booking_id = new FormControl('');
  // send_vehicle_type = new FormControl('');
  bill_amount = new FormControl('');
  exkm_rate = new FormControl('');
  vendor = new FormControl('');
  vehicle_no = new FormControl('');
  ce_name = new FormControl('');
  ce_no = new FormControl('');



  filterValues = {
    trip_status: '',
    booking_source: '',
    trip_no: "",
    trip_date: "",
    trip_time: "",
    customer_name: "",
    guest_name: "",
    rental_type: "",
    vehicle_type: "",
    //  sent_vehicle_type: "",
    booking_id: "",
    bill_amount: "",
    exkm_rate: "",
    vendor: "",
    vehicle_no: "",
    ce_name: "",
    ce_no: ""
  };


  constructor(
    private formBuilder: FormBuilder,
    private tripService: TripSheetService,
    private histroyService: HistoryConfirmationService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,

  ) { }

  ngOnInit(): void {

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.userid = localStorage.getItem('userId');
    this.id = localStorage.getItem('Id');
    this.CurrentDate =  new Date();
    this.from_Date = new Date();
    this.end_date = new Date();
    this.toppingList = this.displayedColumns;

    this.searchform = this.formBuilder.group({
      from_date: [this.from_Date, null],
      end_date: [this.end_date, null],
      filter_option: ['', null],
      key_word: ['', null],
      trip_location: ['', null]
    })


    this.getFilterList();
    this.getLocationList();
    this.getTripsListCount();
    this.getTripsList();

    this.headerFilter();

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  getTripsList = () => {
    const data = {
      "user_id": this.id,
      "from_date": this.setDateToForm(this.searchform.get("from_date").value),
      "end_date": this.setDateToForm(this.searchform.get("end_date").value),
      "filter": this.searchform.get("filter_option").value,
      "keyword": this.searchform.get("key_word").value,
      "location": this.setLocation(this.searchform.get("trip_location").value)
    }

    this.tripService.getTripSheetList(data).subscribe(
      res => {
        const response: any = res;
        if (response.success == true) {
          
          this.tipsList = response.Trip_Sheet_List;
          this.dataSource = new MatTableDataSource(this.tipsList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSourceLength = this.tipsList.length;
          this.isLoading = false;
          this.dataSource.filterPredicate = this.createFilter();
        }
      }
    )
  }

  getTripsListCount = () => {
    const data = {
      "user_id": this.id,
      "from_date": this.setDateToForm(this.searchform.get("from_date").value),
      "end_date": this.setDateToForm(this.searchform.get("end_date").value),
      "filter": this.searchform.get("filter_option").value,
      "keyword": this.searchform.get("key_word").value,
      "location": this.setLocation(this.searchform.get("trip_location").value)
    }

    this.tripService.getTripCounts(data).subscribe(
      res => {
        const response: any = res;
        this.countList = response;
      }
    )
  }

  getFilterList = () => {
    const data = {
    }
    this.tripService.getFilteredList(data).subscribe(
      res => {
      
        
        //const response: any = res;
        this.msg =res
      
        
        if (this.msg.success == true) {
          // const list = response.Filter;
          // this.filter_list = list

           this.gridlist =this.msg.Filter
           this.filteredList= this.gridlist.slice();
        }
      }
    )
  }

  getLocationList = () => {
    const data = {
      type: 'Accounts',
      user_id: this.id
    }
    this.histroyService.getLocationList(data).subscribe(
      loc => {
        const response: any = loc;
        if (response.success == true) {
          this.trip_location_list = response.Location_List;
        }
      }
    )
  }

  search = () => {
    this.isLoading = true;
    this.getTripsListCount();
    this.getTripsList();


  }
  clear = () => {
    this.searchform.reset();
    this.getTripsList();
    this.getTripsListCount();
  }

  reset(){
    this.end_date = null;
    this.from_Date = null;    
  }

  filterChanged = (value: string) => {
    if (value != "") {
      this.iskeyword = false;
    }
  }

  resetCalendar = () => {
    this.end_date = null;
    this.from_Date = null;

  }

  setDateToForm = (date: any) => {
    if (date != "") {

      return this.datepipe.transform(date, 'dd-MM-yyyy');
    }
    return date;
  }

  setLocation = (location) => {
    if (location != "" && location != null) {
      return location;
    }

    return "All Location"
  }

  removeUnderScore = (val) => {
    return val.toString().replace(/_/gi, " ");
  }

  // createFilter(): (data: any, filter: string) => boolean {
  //   let filterFunction = function (data, filter): boolean {
  //     let searchTerms = JSON.parse(filter);
  //     return data.trip_status.toLowerCase().indexOf(searchTerms.trip_status) !== -1
  //       && data.booking_source.toLowerCase().indexOf(searchTerms.booking_source) !== -1
  //       && data.trip_no.toString().toLowerCase().indexOf(searchTerms.trip_no) !== -1
  //       && data.trip_date.toLowerCase().indexOf(searchTerms.trip_date) !== -1
  //       && data.trip_time.toLowerCase().indexOf(searchTerms.trip_time) !== -1
  //       && data.customer_name.toLowerCase().indexOf(searchTerms.customer_name) !== -1
  //       && data.guest_name.toLowerCase().indexOf(searchTerms.guest_name) !== -1
  //       && data.rental_type.toLowerCase().indexOf(searchTerms.rental_type) !== -1
  //       && data.vehicle_type.toLowerCase().indexOf(searchTerms.vehicle_type) !== -1
  //       //  && data.sent_vehicle_type.toLowerCase().indexOf(searchTerms.sent_vehicle_type) !== -1
  //       && data.booking_id.toString().toLowerCase().indexOf(searchTerms.booking_id) !== -1
  //    //   && data.bill_amount.toLowerCase().indexOf(searchTerms.bill_amount) !== -1
  //    && data.bill_amount == null ? false : data.bill_amount.toLowerCase().indexOf(searchTerms.bill_amount) !== -1
  //       && data.exkm_rate.toLowerCase().indexOf(searchTerms.exkm_rate) !== -1
  //       && data.vendor.toLowerCase().indexOf(searchTerms.vendor) !== -1
  //       && data.vehicle_no.toLowerCase().indexOf(searchTerms.vehicle_no) !== -1
  //       && data.ce_name.toLowerCase().indexOf(searchTerms.ce_name) !== -1
  //       && data.ce_no.toLowerCase().indexOf(searchTerms.ce_no) !== -1;
  //   }
  //   return filterFunction;
  // }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      console.log(searchTerms.trip_date, data.trip_date);
      return (
        data.trip_status.toLowerCase().indexOf(searchTerms.trip_status) !== -1
        && (data.booking_source == null ? true : data.booking_source.toLowerCase().indexOf(searchTerms.booking_source) !== -1)
        && (data.trip_no == null ? true : data.trip_no.toString().toLowerCase().indexOf(searchTerms.trip_no) !== -1)
        && (data.trip_date == null ? false : data.trip_date.toLowerCase().indexOf(searchTerms.trip_date) !== -1)
        && (data.trip_time == null ? true : data.trip_time.toLowerCase().indexOf(searchTerms.trip_time) !== -1)
        && (data.customer_name == null ? true : data.customer_name.toLowerCase().indexOf(searchTerms.customer_name) !== -1)
        && (data.guest_name == null ? true : data.guest_name.toLowerCase().indexOf(searchTerms.guest_name) !== -1)
        && (data.rental_type == null ? true : data.rental_type.toLowerCase().indexOf(searchTerms.rental_type) !== -1)
        && (data.vehicle_type == null ? true : data.vehicle_type.toLowerCase().indexOf(searchTerms.vehicle_type) !== -1)
        //   && data.sent_vehicle_type == null ? false : data.sent_vehicle_type.toLowerCase().indexOf(searchTerms.sent_vehicle_type) !== -1
        && (data.booking_id == null ? true : data.booking_id.toString().toLowerCase().indexOf(searchTerms.booking_id) !== -1)
        && (data.bill_amount == null ? false : data.bill_amount.toLowerCase().indexOf(searchTerms.bill_amount) !== -1)
        && (data.exkm_rate == null ? true : data.exkm_rate.toLowerCase().indexOf(searchTerms.exkm_rate) !== -1)
        && (data.vendor == null ? true : data.vendor.toLowerCase().indexOf(searchTerms.vendor) !== -1)
        && (data.vehicle_no == null ? true : data.vehicle_no.toLowerCase().indexOf(searchTerms.vehicle_no.toLowerCase()) !== -1)
        && (data.ce_name == null ? true : data.ce_name.toLowerCase().indexOf(searchTerms.ce_name) !== -1)
        && (data.ce_no == null ? true : data.ce_no.toLowerCase().indexOf(searchTerms.ce_no) !== -1)
      );
    }
    return filterFunction;
  }

  checkEmpty(value) {
    if (value = ! "") {
      return value.toLowerCase();
    }

    return value;

  }

  fullExcel = () => {
    this.isLoading = true
    let Heading = [[
      'trip status', 'booking source', 'trip no', 'booking id', 'trip date', 'trip time', 'customer name', 'guest name', 'vehicle no', 'vendor', 'vehicle type','Sent vehicle Type',
      'rental type','ce name','ce mobile', 'bill amount', 'exkm rate', 'pickup location', 'invoice no', 'annexue no', 'billing location', 'booking type', 'slab rate',
      'ex hr amt', 'ex km amt', 'Parking', 'Permit', 'os batta', 'night allowance', 'local allowance', 'others', 'cgst', 'sgst', 'igst', 'card amt', 'net amt', 'reporting place',
      'start date', 'close date', 'trip start time', 'trip end time', 'total hrs', 'ex hr', 'open km', 'close km', 'total km', 'ex km', 'ex hr rate', 'ex km rate','payment mode', 'ordered by',
      'booker mobile2', 'booker mobile', 'booker mail', 'project id', 'request id', 'comments', 'cc mail', 'coordinator name', 'guest mail id', 'guest mobile', 'employee id', 'guest2 name',
      'employee id2', 'guest2 mobile', 'guest2 mail id', 'guest3 name', 'employee id3', 'guest3 mobile', 'guest3 mail id', 'guest4 name', 'employee id4', 'guest4 mobile',
      'guest4 mail id', 'pickup address', 'drop address', 'spl instructions', 'coordinator mbl',
    ]];
  
    
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tipsList);//converts a DOM TABLE element to a worksheet
   

    const wb: XLSX.WorkBook = XLSX.utils.book_new();


    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.book_append_sheet(wb, ws, 'TripSheets');
    /* save to file */
    XLSX.writeFile(wb, 'TripSheets.xlsx');
    setTimeout(() => {
      this.isLoading = false
    }, Number(this.tipsList.length) * 2);
  }

  export = (type) => {
    const skip = this.paginator.pageSize * this.paginator.pageIndex;
    const paged: TripSheetList[] = this.tipsList.filter((u, i) => i >= skip)
      .filter((u, i) => i < this.paginator.pageSize);
    const exportData = paged.map(list => {
      console.log("ajkksgsgsgsdsdki",list);
      
      return ({
        trip_status: list.trip_status,
        booking_source: list.booking_source,
        trip_no: list.trip_no,
        trip_date: list.trip_date,
        trip_time: list.trip_time,
        customer_name: list.customer_name,
        guest_name: list.guest_name,
        rental_type: list.rental_type,
        vehicle_type: list.vehicle_type,
         booking_id: list.booking_id,
        bill_amount: list.bill_amount,
       vehicle_no: list.vehicle_no,
        ce_name: list.ce_name,
        ce_no:list.ce_no,
        // send_vehicle_type:list.send_vehicle_type,
      })


    })
    this.isLoading = true
    let Heading = [['trip status', 'booking source', 'trip no', 'trip date', 'trip time', 'customer name', 'guest name', 'rental type', 
    'vehicle type', 'booking id', 'bill amount',  'vehicle no', 'ce name','ce_no','send_vehicle_type']];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);//converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.book_append_sheet(wb, ws, 'TripSheets');
    if (type == 'xlsx') {
      /* save to file */
      XLSX.writeFile(wb, 'TripSheets.xlsx');
    } else {
      XLSX.writeFile(wb, 'TripSheets.csv', { bookType: 'csv', type: 'binary' });
    }
    setTimeout(() => {
      this.isLoading = false
    }, Number(exportData.length) * 2);

  }

  printPage = () => {
    var divToPrint = document.getElementById("tablerecords");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
    newWin.print();
    newWin.close();
  }

  headerFilter = () => {
    this.bookingSource.valueChanges
      .subscribe(
        source => {
          this.filterValues.booking_source = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });

    this.trip_no.valueChanges
      .subscribe(
        source => {
          this.filterValues.trip_no = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });

    this.trip_time.valueChanges
      .subscribe(
        source => {
          this.filterValues.trip_time = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });

    this.customer_name.valueChanges
      .subscribe(
        source => {
          this.filterValues.customer_name = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });

    this.trip_status.valueChanges
      .subscribe(
        source => {
          this.filterValues.trip_status = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });

    this.trip_date.valueChanges
      .subscribe(
        source => {
          this.filterValues.trip_date = this.datepipe.transform(source, 'yyyy-MM-dd');
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });

    this.guest_name.valueChanges
      .subscribe(
        source => {
          this.filterValues.guest_name = source;
          console.log(JSON.stringify(this.filterValues));
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });

    this.rental_type.valueChanges
      .subscribe(
        source => {
          this.filterValues.rental_type = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    this.vehicle_type.valueChanges
      .subscribe(
        source => {
          this.filterValues.vehicle_type = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    // this.sent_vehicle_type.valueChanges
    //   .subscribe(
    //     source => {
    //       this.filterValues.sent_vehicle_type = source;
    //       this.dataSource.filter = JSON.stringify(this.filterValues);
    //     });
    this.booking_id.valueChanges
      .subscribe(
        source => {
          this.filterValues.booking_id = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    this.bill_amount.valueChanges
      .subscribe(
        source => {
          this.filterValues.bill_amount = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    this.exkm_rate.valueChanges
      .subscribe(
        source => {
          this.filterValues.exkm_rate = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    this.vendor.valueChanges
      .subscribe(
        source => {
          this.filterValues.vendor = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    this.vehicle_no.valueChanges
      .subscribe(
        source => {
          this.filterValues.vehicle_no = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    this.ce_name.valueChanges
      .subscribe(
        source => {
          this.filterValues.ce_name = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    this.ce_no.valueChanges
      .subscribe(
        source => {
          this.filterValues.ce_no = source;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
  }




  public opentrack(trip_no) {

    this.router.navigate(['/viewtraking/' + trip_no]);
    // const dialogRef = this.dialog.open(ViewtrakingComponent,{

    //   height: '600px',
    //   //autoFocus:'false',
    //   width: '800px',
    // //  data:{id:id}

    // }); 
  }


  viewpagedetails(id){
    this.router.navigate(['/viewmode', id]);
  }
}
