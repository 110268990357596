import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { HistoryConfirmationRejection, LogList } from "@/models/history-confirmation";
import { Component, OnInit } from "@angular/core";
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { HistoryConfirmationService } from '@services/history-confirmation-services';
import { ActivatedRoute } from '@angular/router';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-history-report',
  templateUrl: './history-report.component.html',
  styleUrls: ['./history-report.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})
export class HistoryReport implements OnInit {

  forzaadmin: string;
  userid: string;
  id: string;
  bookingId: string;
  logList: LogList;
  historyConfirmationRejection: HistoryConfirmationRejection = {
    "booking_id": null,
    "customer": "",
    "customer_name": "",
    "billing_location": "",
    "rental_city_name": "",
    "pickup_date": "",
    "rental_type_name": "",
    "guest_name": "",
    "pickup_time": "",
    "vehicle_model_name": ""
  }

  constructor(
    public datepipe: DatePipe,
    private historyService: HistoryConfirmationService,
    public activatedRoute: ActivatedRoute,
  ) { }


  ngOnInit(): void {

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.userid = localStorage.getItem('userId');
    this.id = localStorage.getItem('Id');
    this.bookingId = this.activatedRoute.snapshot.url[3].path;
    console.log(this.bookingId);
    this.getHistoryConfirmationbyId();
  }

  getHistoryConfirmationbyId() {
    const data = {
      booking_id: this.bookingId
    }
    this.historyService.gethistoryConfirmationbyId(data).subscribe(
      data => {
        const response: any = data;
        if (response.success == true) {
          this.historyConfirmationRejection = response.result;
        }
      }
    )

    this.historyService.getBookingLogById(data).subscribe(
      res => {
        const response: any = res;
        if (response.success == true) {
          this.logList = response.Log_List;
        }
      }
    )
  }

  getStatus = (value: string) => {
    if (value == "edit") {
      return "Edited";
    }
    if (value === "create") {
      return "Created"
    }
    if (value === "view") {
      return "Viewed"
    }
    if (value === "view_generation") {
      return "View Generated"
    }
    return value;
  }

}
