import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Managecustomer} from '@services/managecustomerguest.service';
import {ToastrService} from 'ngx-toastr';
import { HelperService } from '@services/helper.service';
@Component({
    selector: 'app-view-customerguest',
    templateUrl: './view-customerguest.component.html',
    styleUrls: ['./view-customerguest.component.scss']
})
export class ViewCustomerguestComponent implements OnInit {
    // customeradmin : FormGroup;
    // submitted = false
    public formType: any;
    public creditlimit: boolean = false;
    customerguestresp: any=[];
    idarray: any[] = [];
    locationArr :[]
    direct_booking:any
    forzaadmin: any;
    public id: string;
    constructor(
        private router: Router,
        private location: Location,
        public activatedRoute: ActivatedRoute,
        private managecustomer: Managecustomer,
        private toastr: ToastrService,
        public helperService: HelperService
    ) {}

    ngOnInit(): void {
        this.helperService.pageAccess('r_read')
        this.id = atob(this.activatedRoute.snapshot.url[1].path);
        this.getCustomerGuestbyId();

        this.forzaadmin = localStorage.getItem('customer_Role_name');

        if (this.forzaadmin == 'Forza Admin') {
            this.creditlimit = true;
          }

          
    }

    getCustomerGuestbyId = () => {
        var idarray = {};
        idarray['id'] = this.id;
        this.managecustomer.getCustomerGuestbyId(idarray).subscribe(
            (response) => {
                let responses: any = response;
                if (responses.success == true) {
                    this.customerguestresp = responses.result;
                      this.locationArr = responses.result.Location_name.split(',').join(',  ');
                      
                if(!responses.result.Direct_Booking.includes("[")){
                    this.direct_booking = [this.capitalizeFirstLetter(responses.result.Direct_Booking)]
                  } else {
                    this.direct_booking = JSON.parse(responses.result.Direct_Booking)
                  }
                }
            },
            (error) => {
                this.toastr.error(error, 'Error');
            }
        );
    };
    

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    back() {
        this.location.back();
    }
    cancel() {}

    edit(id) {
        this.router.navigate(['/managecustomerguest', btoa(id), 'edit']);
    }
}
