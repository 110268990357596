import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Managecustomer } from '@services/managecustomerguest.service';
import { ElementRef, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-viewpanic',
  templateUrl: './viewpanic.component.html',
  styleUrls: ['./viewpanic.component.scss']
})
export class ViewpanicComponent implements OnInit {
  isshow:boolean = true
  alertdate:any;
  billloc:any;
  id:any;
  cename:any;
  actiontaken:any;
  actionform: FormGroup;
  rental:any;
  trip:any
  vechiledat:any;
  submitted = false;
  guestname:any;
  resposubmit:any;
  viewpanicdata:any=[]
  constructor(public location:Location, private managecustomer: Managecustomer,
    @Optional() @Inject(MAT_DIALOG_DATA) public formData: any = null,
    public dialog: MatDialog,
    private fb: FormBuilder, private router: Router, private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
   this. getCustomerAdminbyId();
   
this.id=this.formData.id
    this.actionform = this.fb.group({
      action_taken: ['', Validators.required],
     

    })


    
    
  }
 
  getCustomerAdminbyId = () => {
   var obj={
    id : this.formData.id
   }
  
    this.managecustomer.getpanicalertId(obj).subscribe(
        (response) => {
        
          
            let responses: any = response;
           
            
            if (responses.success == true) {
                this.viewpanicdata = responses.result;
               this.alertdate= this.viewpanicdata.alert_date
               this.billloc= this.viewpanicdata.billing_location
               this.cename= this.viewpanicdata.ce_name
               this.guestname= this.viewpanicdata.guest_name
               this.rental= this.viewpanicdata.rental_city
               this.trip= this.viewpanicdata.trip_no
               this.vechiledat=this.viewpanicdata.vehicle
               this.actiontaken=this.viewpanicdata.action_taken
              
               
              
              
                
            }
        }
    );
};


public submit(){
  this.submitted = true;
  if (this.actionform.valid){
    this.actionform.value.id = this.id
    this.managecustomer.createpanic(this.actionform.value).subscribe(
      (response) => {
       
        this.resposubmit = response;
        if (this.resposubmit.success == true) {
          this.router.navigate(['/reports/panic-alert']);

          this.toastr.success(this.resposubmit.message, 'success');
          this.dialog.closeAll()

        } else {
          this.toastr.error(this.resposubmit.message, 'Error');
         
        }
         
          
         
      }
  );
  }
}


}
