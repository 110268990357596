<div class="mx-4">

    <section class="content-header">
        <div class="container-fluid">
            <div>
                <span routerLink="/dashboard">Dashboard</span> / <span
                    class="breadcrumb-item activebreadcrumb-item active">History of Confirmation and Rejection
                    Report</span>
            </div>
            <div class="row mt-md-2">
                <div class="col-auto">
                    <img src="assets/images/main-hisofconfirmation.png" alt="Forza Logo"
                        style="object-fit:fill;width:50px;">
                </div>
                <div class="col">
                    <div class="row flex-column justify-content-center mt-md-3">
                        <div class="col">
                            <h4 style="font-weight:600; color: #233771;">History of Confirmation and Rejection Report
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="content">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="filterForm">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Booking Id</label>
                                <div class="input-group-append">
                                    <mat-select #select1 formControlName="booking_id" class="form-control"
                                        placeholder="Please select Booking Id">
                                        <mat-select-filter *ngIf="select1.focused" [placeholder]="'Search'"
                                            [array]="booking_id_list"
                                            (filteredReturn)="booking_id_list_filter = $event">
                                        </mat-select-filter>
                                        <mat-option *ngFor="let booking_id of booking_id_list" [value]="booking_id"
                                            [class.hide]="!isFiltered_booking(booking_id)">
                                            {{ booking_id }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Pickup Date</label>
                                <mat-form-field class="form-control" floatLabel=never>
                                    <input autocomplete="off" matInput placeholder="dd-mm-yyyy"
                                        (click)="picker.open();resetCalendar()" matInput [matDatepicker]="picker"
                                        formControlName="pickup_date" (ngModelChange)="maxdate = $event" readonly>
                                    <mat-datepicker-toggle (click)="resetCalendar()" matSuffix [for]="picker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label style="white-space:nowrap; text-overflow: ellipsis;">Billing Location</label>
                                <div class="input-group-append">
                                    <mat-select #select2 formControlName="billing_location" class="form-control"
                                        placeholder="Please select Billing Location"
                                        (selectionChange)="locationChanged($event.value)">
                                        <mat-select-filter *ngIf="select2.focused" [placeholder]="'Search'"
                                            [array]="billing_location_list"
                                            (filteredReturn)="billing_location_list_filter = $event"
                                            [displayMember]="'location_name'">
                                        </mat-select-filter>
                                        <mat-option *ngFor="let location of billing_location_list"
                                            [value]="location.location_name"
                                            [class.hide]="!isFiltered_location(location)">
                                            {{location.location_name}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label style="white-space:nowrap; text-overflow: ellipsis;">Customer Name</label>
                                <div class="input-group-append">
                                    <mat-select #select3 formControlName="customer_name" class="form-control"
                                        placeholder="Please select Customer Name"
                                        (selectionChange)="customerChanged($event.value)">
                                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Search'"
                                            [array]="customer_list" (filteredReturn)="customer_list_filter = $event"
                                            [displayMember]="'customer_name'">
                                        </mat-select-filter>
                                        <mat-option *ngFor="let customer of customer_list"
                                            [value]="customer.customer_name"
                                            [class.hide]="!isFiltered_customer(customer)">
                                            {{customer.customer_name}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Rental City</label>
                                <div class="input-group-append">
                                    <mat-select #select4 formControlName="rental_city" class="form-control"
                                        placeholder="Please select  City">
                                        <mat-select-filter *ngIf="select4.focused" [placeholder]="'Search'"
                                            [array]="rental_city_list"
                                            (filteredReturn)="rental_city_list_filter = $event"
                                            [displayMember]="'branch_name'">
                                        </mat-select-filter>
                                        <mat-option *ngFor="let city of rental_city_list" [value]="city.branch_name"
                                            [class.hide]="!isFiltered_rental(city)">
                                            {{city.branch_name}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="aligh-button">
                                <button mat-button class="mat-button-submit" style="margin-left: 0px !important;"
                                    title="Search" (click)="search()">Search</button>
                                <button class="btn btn-default" style="margin-left: 10px; width: 35%;"
                                    title="Reset" (click)="clear()">Reset</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-7">
                            <h4 style="font-weight:600; color: #233771;">Details</h4>
                        </div>
                        <div class="col-5">
                            <div class="row justify-content-end">
                                <!-- <div class="col-6">
                                    <div class="search-box pull-center">
                                        <div class="search-input">
                                            <mat-icon role="img" matprefix="true"
                                                class="mat-icon notranslate material-icons mat-icon-no-color icon-grey"
                                                aria-hidden="true" data-mat-icon-type="font">search</mat-icon><input
                                                placeholder="Search" class="form-control"
                                                (keyup)="applyFilter($event.target.value)">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <mat-icon (click)="printPage()" title="Print" type="button"
                                        class="mat-button-submit c-btn"
                                        style="background-image:url('assets/images/print.png');width: 40px;height: 40px;background-size: cover;"></mat-icon>

                                    <mat-icon *ngIf="helperService.checkMenuAccess('r_download')"
                                        (click)="exportIt('csv','Manage_booking', 'Manage_booking_data','Forza')"
                                        title="CSV" type="button" class="mat-button-submit c-btn"
                                        style="background-image:url('assets/images/CSV.png');width: 40px;height: 40px;background-size: cover;"></mat-icon>



                                    <mat-icon *ngIf="helperService.checkMenuAccess('r_download')" title="Excel"
                                        type="button"
                                        (click)="exportIt('xlsx','Manage_booking', 'Manage_booking_data','Forza')"
                                        class="mat-button-submit c-btn"
                                        style="background-image:url('assets/images/XLS.png');width: 40px;height: 40px;background-size: cover;"></mat-icon>



                                    <mat-icon *ngIf="helperService.checkMenuAccess('r_create')" title="Add"
                                        type="button" (click)="addguestbooking()"
                                        class="text-right plus mat-button-submit c-btn"
                                        style="background-image:url('assets/images/Add.png');width: 40px;height: 40px;background-size: cover;"></mat-icon>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
                            <table [hidden]="isLoading" [border]="1" style="text-align: center;" matTableExporter
                                mat-table [dataSource]="dataSource" class="table-striped" #exporter="matTableExporter"
                                matSort>

                                <ng-container matColumnDef="booking_id">
                                    <th mat-header-cell *matHeaderCellDef> Booking Id
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.booking_id}} </td>
                                </ng-container>
                                <ng-container matColumnDef="pickup_date">
                                    <th mat-header-cell *matHeaderCellDef> Pickup Date
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.pickup_date}} </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="customer_name">
                        <th mat-header-cell *matHeaderCellDef> Customer Name
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
                    </ng-container> -->
                                <ng-container matColumnDef="guest_name">
                                    <th mat-header-cell *matHeaderCellDef> Guest Name
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.guest_name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="billing_location">
                                    <th mat-header-cell *matHeaderCellDef> Billing Locaion
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.billing_location}} </td>
                                </ng-container>
                                <ng-container matColumnDef="rental_city">
                                    <th mat-header-cell *matHeaderCellDef> Rental City
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.rental_city}} </td>
                                </ng-container>
                                <ng-container matColumnDef="vehicle_type">
                                    <th mat-header-cell *matHeaderCellDef> Vehicle Type
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.vehicle_type}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Action">
                                    <th mat-header-cell *matHeaderCellDef> Action </th>
                                    <td mat-cell *matCellDef="let element" (click)="view(element.booking_id)"
                                        class="text-center action pointer">
                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            </table>

                            <div *ngIf="this.dataSource.filteredData.length === 0" style="text-align: center">
                                No records found
                            </div>

                            <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading"
                                [pageSizeOptions]="[10,25,50]" justify-content="space-between"></custom-paginator>
                        </div>
                        <div class="col-md-12 mt-2 table" [hidden]="!isLoading">

                            <table class="norecordfoundtable">
                                <tr>
                                    <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}
                                    </th>
                                </tr>
                                <tr>
                                    <td style="text-align: center;" [attr.colspan]="toppingList.length">
                                        {{'Loading.......'}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>