import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {Observable, throwError} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {BackendserviceService} from './backendservice.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    constructor(
        private router: Router,
        private backendService: BackendserviceService,
        private cookieService: CookieService
    ) {}

    // public loginByAuth(data): Observable<{}> {
    //     return this.backendService
    //         .post('api/user/login', data)
    //         .pipe(retry(1), catchError(this.handleError));
    // }


    public loginByAuth(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/login', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public forgetpassword(data): Observable<{}> {
        return this.backendService
            .post('api/user/forgotpassword', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getBranches(): Observable<{}> {
        return this.backendService
            .get('api/common/getbranches')
            .pipe(retry(1), catchError(this.handleError));
    }
  

    public getlocation(data): Observable<{}> {
        return this.backendService
            .post('api/booking/locationList',data)
            .pipe(retry(1), catchError(this.handleError));
    }


    public getfilterdata(data): Observable<{}> {
        return this.backendService
            .post('api/booking/ManageBookingFilter',data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getfilterwebdata(data): Observable<{}> {
        return this.backendService
            .post('api/booking/WebBookingFilter',data)
            .pipe(retry(1), catchError(this.handleError));
    }


    public getdropdown(data): Observable<{}> {
        return this.backendService
            .post('api/booking/pagedropdown',data)
            .pipe(retry(1), catchError(this.handleError));
    }




    private handleError(error: HttpErrorResponse) {
        let errorMessage: string = '';
        if (error.error instanceof ErrorEvent) {
            //ClientSide Error--------
            errorMessage = `Error:${error.error.message}`;
        } else {
            //Server side Error---------
            errorMessage = `Status:${error.status}\n
                      Message:${error.message}`;
        }
        return throwError(errorMessage);
    }

    async registerByAuth({email, password}) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            // this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
            const token = await Gatekeeper.loginByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            // this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
            const token = await Gatekeeper.registerByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            // this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
            const token = await Gatekeeper.loginByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            // this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        try {
            const token = await Gatekeeper.registerByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            // this.toastr.error(error.message);
        }
    }

  

    async getProfile() {
        try {
            if(localStorage.getItem('userId')){
                this.user = localStorage.getItem('userId');               
            }else{
            this.logout();
            }
            this.user = localStorage.getItem('userId');               
            } catch (error) {
            this.logout();
            throw error;
        }
    } 

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('rba');
        localStorage.removeItem('userId');
        localStorage.removeItem('gatekeeper_token');
        this.user = null; 
        localStorage.clear();
        this.router.navigate(['/login']);
    }
}
