import { Component, OnInit, ViewChild, ElementRef,Optional,Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { jsPDF } from "jspdf";
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Managecustomer } from '@services/managecustomerguest.service';
// import printJS from 'print-js'

@Component({
  selector: 'app-printpage',
  templateUrl: './printpage.component.html',
  styleUrls: ['./printpage.component.scss']
})
export class PrintpageComponent implements OnInit {
  id:any;
  msg:any;
  datetime:any;
  webbookinglist:any;
  @ViewChild('reportContent') reportContent: ElementRef;
 
  // public config = {
  //   printMode: 'template-popup',
  //   popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
  //   pageTitle: 'Hello World',
  //   templateString: '<header>Forza CBS</header>{{printBody}}<footer>Copyright © 2022 forza.com. All rights reserved</footer>',
  //   stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],
  //   styles: ['td { border: 1px solid black; }', 'th {background-color: #edf2f9;}', 'table { border: 1px solid black; padding: 10px; }', 'header, table, footer { margin: auto; text-align: center; }']
  // }

 
  constructor(private dialog: MatDialog,@Optional() @Inject(MAT_DIALOG_DATA) public data: any,private managecustomer: Managecustomer) { }

  ngOnInit(): void {
    console.log(this.data);
    this.id =this.data.id
    this.getwebbooking()
  }
  
  printPage() { 
    // printJS(this.src);
    // var divToPrint = document.getElementById("tablerecords");
    // let newWin = window.open("");
    // newWin.document.write(divToPrint.outerHTML);
    // newWin.print();
    // newWin.close();
    // newWin.print();
    // newWin.close();




    // const doc = new jsPDF("p", "pt", "a4");
    // const source = document.getElementById("content");    
    // doc.setFontSize(5)
    // doc.html(source, {
    //   callback: function(pdf) {
    //     doc.output("dataurlnewwindow"); // preview pdf file when exported
    //   }
    // });


    var data = document.getElementById('content');
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('Trip_Sheet.pdf'); // Generated PDF
    });
  }

  getwebbooking() {
    let data = {
      booking_id: this.id
    }
    this.managecustomer.getprint(data).subscribe((res => {
    console.log(res);
    this.msg=res;
    
      if ( this.msg.success == true) {
        this.webbookinglist = this.msg.Trip_Sheet_List[0];
       console.log(this.msg.current_date);
        this.datetime =this.msg.current_date;
       
      }
    }))
  }




  }


  

