<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span>Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/managecustomeradmin">Manage Customer Booking</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">View</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-customeradmin.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">View Customer Admin</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/managecustomeradmin"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="container-fluid">
            <section>
              <h2 class="form-heading">Customer</h2>
              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Base Location </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.base_Location_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Location Authorised </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data ">{{locationArr}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Role </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.customer_Role_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Group </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.customer_Group_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>


              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Name </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.customer_Name_array}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <h2 class="form-heading">Customer Admin</h2>
              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Admin Name </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.customer_Admin_Name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Admin Mobile </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.customer_Admin_mobile}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Admin Mail ID </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.customer_Admin_MailId}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view view-label">Customer Admin Employee ID </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.Customer_Admin_Employee_ID}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Admin Department </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.Customer_Admin_Department}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Admin Designation </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.Customer_Admin_Designation}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Admin DOB</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customeradminresp?.CustomerAdminDOB | date:'dd/MM/yyyy'}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Status </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <mat-slide-toggle class="status" disabled [checked]="customeradminresp?.status === 'ACTIVE'"
                      formControlName="status">
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider><br>

              <div class="aligh-button-right">
                <button routerLink="/managecustomeradmin" class="mat-button-cancel">Cancel</button>
                <button *ngIf="helperService.checkMenuAccess('r_update')" (click)="edit(customeradminresp.id)"
                  mat-button class="mat-button-submit">Edit</button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card -->
  </section>
</div>
<!-- /.content -->

<!-- /.card -->

<!-- /.content -->