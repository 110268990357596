<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">Panic Alert Report</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-hisofconfirmation.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Panic Alert Report
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12">


          </div>

          <form [formGroup]="searchform">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>From</label>
                  <mat-form-field class="form-control" floatLabel=never>

                    <input autocomplete="off" matInput placeholder="dd-mm-yyyy" (click)="picker.open();reset()" matInput
                      [matDatepicker]="picker" formControlName="from_date" (ngModelChange)="maxdate = $event" readonly
                      [(ngModel)]="start_date">

                    <mat-datepicker-toggle (click)="reset()" matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>To</label>
                  <mat-form-field class="form-control" floatLabel=never>

                    <input autocomplete="off" matInput placeholder="dd-mm-yyyy" (click)="picker1.open();reset()"
                      matInput [matDatepicker]="picker1" [min]="start_date" [(ngModel)]="end_date"
                      formControlName="end_date" readonly>

                    <mat-datepicker-toggle (click)="reset()" matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <!-- <input type="date" [min]="maxdate" [max]="CurrentDate" formControlName="end_date" class="form-control"> -->

                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Action Taken</label>
                  <div class="input-group-append">
                    <mat-select formControlName="action_taken" [(ngModel)]="action_taken" class="form-control"
                      placeholder="Select" #action>


                      <mat-option value="yes">Yes</mat-option>
                      <mat-option value="no">No</mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>





              <div class="col-md-7">
                <div class="aligh-button">
                  <button mat-button class="mat-button-submit" style="margin-left: 0px !important;"
                    (click)="submit()">Search</button>
                  <button class="btn btn-default" style="margin-left: 10px; width: 30%;"
                    (click)="clear()">Reset</button>
                </div>
              </div>



            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <!-- <div class="col-md-12"> -->
            <div class="row justify-content-between">
              <div class="col-md-6">
                <h4 style="font-weight:600; color: #233771;">Details</h4>
              </div>
              <div class="col-md-6">
                <div class="row justify-content-end">
                  <div class="col-md-7">
                  </div>
                  <div class="col-md-5 d-flex justify-content-end">

                    <mat-icon title="Excel" type="button"
                      (click)="exportIt('xlsx','Panic-alert', 'Manage_booking_data','Forza')"
                      class="mat-button-submit c-btn"
                      style="background-image:url('assets/images/XLS.png');width: 40px;height: 40px;background-size: cover;"></mat-icon>
                  </div>
                </div>
              </div>


              <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
                <table [border]="1" [hidden]="isLoading" style="text-align: center;" id="tablerecords" matTableExporter
                  #exporter="matTableExporter" mat-table [dataSource]="dataSource"
                  class="pointer table-striped hidden-print" [hiddenColumns]="[14]">

                  <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                  <!-- Position Column -->


                  <!-- Name Column -->








                  <ng-container matColumnDef="alert_date">
                    <th mat-header-cell *matHeaderCellDef> Alert DATE </th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.alert_date | date: 'dd-MM-yyyy'" [matTooltipPosition]="'above'">
                        {{element.alert_date | date: 'dd-MM-yyyy'}} </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billing_location">
                    <th mat-header-cell *matHeaderCellDef> Billing Location</th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.billing_location" [matTooltipPosition]="'above'">
                        {{element.billing_location}} </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="rental_city">
                    <th mat-header-cell *matHeaderCellDef> Rental City </th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.rental_city" [matTooltipPosition]="'above'"> {{element.rental_city}}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="trip_no">
                    <th mat-header-cell *matHeaderCellDef> Trip No </th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.trip_no" [matTooltipPosition]="'above'"> {{element.trip_no}} </span>
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="guest_name">
                    <th mat-header-cell *matHeaderCellDef> Guest Name</th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.guest_name" [matTooltipPosition]="'above'"> {{element.guest_name}}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ce_name">
                    <th mat-header-cell *matHeaderCellDef> CE NAME </th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.ce_name" [matTooltipPosition]="'above'"> {{element.ce_name}} </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="customer_name">
                    <th mat-header-cell *matHeaderCellDef> CUSTOMER NAME </th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.customer_name" [matTooltipPosition]="'above'">
                        {{element.customer_name}}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vehicle">
                    <th mat-header-cell *matHeaderCellDef> VEHICLE </th>
                    <td mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.vehicle" [matTooltipPosition]="'above'"> {{element.vehicle}} </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action_taken">
                    <th mat-header-cell *matHeaderCellDef> ACTION TAKEN </th>
                    <td mat-cell *matCellDef="let element">
                      <span (click)="openpage(element.action_taken,element.id)"><a
                          class="{{(element.action_taken == null ||  element.action_taken == '') ? 'class1' : 'class2'}}">{{(element.action_taken
                          == null || element.action_taken == '' ) ? "No Action Taken" :element.action_taken }}
                        </a></span>
                    </td>
                  </ng-container>

                  `








                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div *ngIf="this.dataSource.filteredData.length === 0" style="text-align: center">
                  No records found
                </div>
                <!-- <div class="footer"> -->
                <!-- <mat-paginator #paginator [pageSizeOptions]="[10,25,50]" [pageSize]="10">
              </mat-paginator> -->



                <!-- </div> -->

              </div>

              <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading" [pageSizeOptions]="[10,25,50]"
                justify-content="space-between"></custom-paginator>







            </div>


          <!-- </div> -->

        </div>
      </div>
    </div>
  </section>
</div>