<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/managebooking">Manage Booking</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">Guest booking</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/mainicon-webbooking.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Guest booking</h4>
            </div>
          </div>
        </div>  
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" (click)="back()"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>      
      </div>
    </div>
  </section>

<section class="content">
  <div class="container-fluid">    
    <div class="card">
      <div class="card-body">
        <div class="container-fluid">
          <form [formGroup]="webbooking">
            <div class="form-heading p-0 pl-2 d-flex justify-content-between">
              <h2 class="form-heading2">Booking Information </h2>
              <span class="bookid" *ngIf="type == 'edit'">Booking ID :{{webbookinglist?.booking_id}}</span>
            </div><br>
            <div class="row justify-content-between mx-4">
              <div class="col-md-5">
                <div class="form-group">
                  <label>Customer<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="customer" [(ngModel)]="customer_Name" readonly>
                </div>
              </div>

             

              <div class="col-md-5">
                <div class="form-group">
                  <label>Billing Location<span class="text-danger">*</span></label>
                 
                  <input type="text" class="form-control"  formControlName="billing_location"  [(ngModel)]="billing_Location" readonly>
                </div>

              </div>

              

              <div class="col-md-5">
                <div class="form-group">
                  <label>Rental City<span class="text-danger">*</span></label>
                  <input type="text"  class="form-control"  formControlName="rental_city" [(ngModel)]="rental_city"   readonly>
                 
                </div>
              </div>
              
              <div class="col-md-5">
                <div class="form-group">
                  <label>Booking Type<span class="text-danger">*</span></label>
                  <input type="text"  formControlName="booking_type" class="form-control"   [(ngModel)]="booking_Type" readonly>
                 
                </div>
              </div>

              

              
              
              <div class="col-md-5">
                <div class="form-group">
                  <label>Pickup Date<span class="text-danger">*</span></label>
                  <input type="text"  formControlName="pickup_date"  class="form-control"   [(ngModel)]="pickup_Date" readonly>
                 
                 
                </div>
              </div>


              
              
              <div class="col-md-5">
                <div class="form-group">
                  <label>End Date<span class="text-danger">*</span></label>
                  <input type="text"  formControlName="end_date" class="form-control"   [(ngModel)]="end_Date"  readonly>
                </div>
              </div>


              
              <div class="col-md-5">
                <div class="form-group">
                  <label>Pickup Time<span class="text-danger">*</span></label>
                  <input type="time"  formControlName="pickup_time"  class="form-control"   [(ngModel)]="pickup_Time"  readonly>
                 
                </div>
              </div>

              
              <div class="col-md-5">
                <div class="form-group">
                  <label>Vehicle Type<span class="text-danger">*</span></label>
                 
                  <input type="text"  formControlName="Vehicle_model" class="form-control"   [(ngModel)]="Vehicle_Model"   readonly>
                 
                </div>
              </div>


              
              <div class="col-md-5">
                <div class="form-group">
                  <label>Rental Type<span class="text-danger">*</span></label>
                  <input type="text"  formControlName="rental_type"  class="form-control"   [(ngModel)]="rental_Type"   readonly>
                </div>
              </div>

              

              <div class="col-md-5">
                <div class="form-group">
                  <label> Paymode Default - Bill to Company<span class="text-danger">*</span></label>
                  <input type="text"  formControlName="paymode_default"  class="form-control"   [(ngModel)]="paymode_Default"   readonly>
                 
                </div>
              </div>
              
              <div class="col-md-5">
                <div class="form-group">
                  <label>Booking Source - Default CBS<span class="text-danger">*</span></label>
                  <input type="text"  formControlName="booking_source"   class="form-control"   [(ngModel)]="booking_source"  readonly>
                 
                </div>
              </div>

              
            </div><br>
          </form><br>
            

          

            <div class="row">
              <div class="col-md-12">
                <h2 class="form-heading">Guest Details</h2>
              </div>
            </div><br>

            <div class="row justify-content-between mx-4" *ngFor="let childval of childForm; let j = index; let last = last;">
              <div class="col-md-5" *ngIf="childval.prefix">
                <div class="form-group">
                  <label>Prefix<span class="text-danger">*</span></label>
                  <input name="prefix{{j}}" [(ngModel)]="childval.prefix" class="form-control" placeholder="Please Select Prefix" readonly>
                   
               
                 
                </div>
                <!-- <div class="form-group">
                  <label>Prefix<span class="text-danger">*</span></label>
                  <mat-select name="prefix{{j}}" [(ngModel)]="childval.prefix" class="form-control" placeholder="Please Select Prefix"  >
                    <mat-option *ngFor="childval pre of prefix" [value]="childval.prefix_id"> {{childval.prefix === 1 ? 'MR' : childval.prefix === 2 ? 'MRS' : childval.prefix === 3 ? 'MS' : '' }} </mat-option>
                  </mat-select>
                 
                </div> -->
              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Guest Name<span class="text-danger">*</span></label>
                  <input type="text" name="guest_name{{j}}" maxlength="50" [(ngModel)]="childval.guest_name" class="form-control"  placeholder="Enter Guest Name" readonly>
                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Guest EmpID</label>
                  <input type="text" name="guest_Employeeid{{j}}" maxlength="50" [(ngModel)]="childval.guest_Employeeid" class="form-control" placeholder="Enter Guest Employee" readonly>

                </div>

              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Guest Mobile<span class="text-danger">*</span></label>
                  <input type="text" maxlength="50" [(ngModel)]="childval.guest_mobile" class="form-control" name="guest_mobile{{j}}"  placeholder="Enter Guest Mobile" readonly>
                </div>

              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Guest Mail ID</label>
                  <input type="text" maxlength="50" [(ngModel)]="childval.guest_Email" class="form-control" name="guest_Email{{j}}"  placeholder="Enter Guest Mail" readonly>

                </div>

              </div>


              <div class="col-md-5 mt-2">
                <div class="mt-4">
                 

                  <!-- <button type="button" *ngIf="last" [disabled]="!childval.prefix || !childval.guest_mobile || !childval.guest_name" (click)="addQuantity()" class="btn btn-success pull-right"><i class="fa fa-plus" aria-hidden="true"></i> </button> -->

                </div>
              </div>
            </div>
          <mat-divider></mat-divider>
          <br>


            <div class="row justify-content-between mx-4">
              <div class="col-md-5">
                <div class="form-group">
                  <label>Pickup Address<span class="text-danger">*</span></label>
                  <input type="text" maxlength="50"   [(ngModel)]="pickup_address"    class="form-control" readonly
                  
                    >
                
                </div>

              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Drop Address</label>
                  <input type="text" maxlength="50"   [(ngModel)]="drop_address"    class="form-control" readonly
                    >

                </div>

              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Spl Instructions</label>
                  <input type="text" maxlength="200"  [(ngModel)]="spl_instruction"   class="form-control" readonly
                   >

                </div>

              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Flight Details</label>
                  <input type="text" maxlength="50"   [(ngModel)]="flight_details"   class="form-control"
                    placeholder="Enter Guest Employee" readonly>
                </div>
              </div>

            </div><br>

            <h2 class="form-heading">Coordinatore Details</h2><br>
            <div class="row justify-content-between mx-4">
              <div class="col-md-5">
                <div class="form-group">
                  <label>Coordinator Name</label>
                  <input type="text"    [(ngModel)]="coordinate_name" class="form-control"  readonly>
                </div>

              </div>
              
              <div class="col-md-5">
                <div class="form-group">
                  <label>Coordinator Mobile</label>
                  <input type="text" [(ngModel)]="coordinate_mobile"  class="form-control"  readonly
                  >

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Project Id</label>
                  <input type="text"  class="form-control"
                  [(ngModel)]="project_id" readonly
                 >

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Request Id</label>
                  <input type="text"   [(ngModel)]="request_id" class="form-control" readonly
                    >

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Comments</label>
                  <!-- <input type="textarea"  formControlName="comments"  [(ngModel)]="Comments"  class="form-control" readonly> -->
                  <textarea  [(ngModel)]="comments"  class="form-control" readonly></textarea>

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>CC Mail IDs</label>
                  <input type="text" maxlength="50"   [(ngModel)]="mail_id"  class="form-control" readonly
                     >

                </div>

              </div>



            </div>

            <div class="aligh-button-right">
              <button mat-button class="mat-button-approve" [disabled]="isDisableBtn"  (click)="approve()">Approve</button>
              <button mat-button class="mat-button-regret" [disabled]="isDisableBtn" (click)="reject()">Reject</button> &nbsp;
              <button routerLink="/guestbooking" class="mat-button-cancel" >Cancel</button>
              <!-- <button mat-button class="mat-button-submit" *ngIf="type == 'add'"  (click)="submit()">Submit</button> -->
             
            
             
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>