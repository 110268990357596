import { OutstandingDetails } from "@/models/outstanding-payment";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from '@angular/router';
import { CustomPaginatorComponent } from "@pages/custom-paginator/custom-paginator.component";
import { OutstandingService } from '@services/outstanding-services';


@Component({
  selector: 'app-outstanding-payments',
  templateUrl: './outstanding-payments.component.html',
  styleUrls: ['./outstanding-payments.component.scss']

})
export class OutstandingPayment implements OnInit {

  forzaadmin: string;
  userid: string;
  id: string;
  cusomterId: string;
  location: string;
  outstandingDetails: OutstandingDetails;
  month: string;
  customerName: string;
  dataSourceLength: any;
  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
  OutstandingDetailsList: OutstandingDetails[];
  dataSource = new MatTableDataSource<OutstandingDetails>();
  displayedColumns: string[] = ['bill_no', 'trip_no', 'bill_date', 'passenger', 'location', 'bill_amt', 'adj_amt', 'bal_amt'];
  toppingList: any;
  isLoading = true;
  year: any;
  constructor(
    private outstandingService: OutstandingService,
    public activatedRoute: ActivatedRoute,
  ) {
    // const month = ["January", "February", "March", "April", "May", "June", "July",
    //   "August", "September", "October", "November", "December"];
    // this.month = month[new Date().getMonth()];
  }


  ngOnInit(): void {

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.userid = localStorage.getItem('userId');
    this.id = localStorage.getItem('Id');
    this.cusomterId = this.activatedRoute.snapshot.url[2].path;
    this.location = this.activatedRoute.snapshot.url[3].path;
    this.customerName = this.activatedRoute.snapshot.url[4].path;
    this.toppingList = this.displayedColumns;
    this.getOutstandingdetailsbyId();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getOutstandingdetailsbyId() {
    const data = {
      user_id: this.id,
      customer_id: this.cusomterId,
      location: this.location
    }
    this.outstandingService.getOutStandingPaymentDetails(data).subscribe(
      res => {
        const response: any = res;
        if (response.success == true) {
          let lockdate = response.lock_date
          this.OutstandingDetailsList = response.OutstandingPayment;
          this.dataSource = new MatTableDataSource(this.OutstandingDetailsList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSourceLength = this.OutstandingDetailsList.length;
          this.isLoading = false;


          const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

          var date = new Date(lockdate);
          var month1 = month[date.getMonth()] 

          console.log(month1);
          this.month = month1;
          this.year = date.getFullYear()
        }
      }
    )
  }
  removeUnderScore = (val) => {
    return val.toString().replace(/_/gi, " ");
  }
  applyFilter = (filterValue: string) => {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.dataSourceLength = this.dataSource.filteredData.length
}

}
