<div class="mx-4">


  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span
          class="activebreadcrumb-item active pointer" routerLink="/trip-sheet">Generated Trip Sheet</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">View</span>
          
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-gengtripsheet.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Trip Close</h4>
            </div>
          </div>          
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/trip-sheet"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>


  <section class="content">
    <div class="container-fluid">      
      <div class="card">
        <div class="card-body">
            <div class="container-fluid">
                <section>
                    <div class="form-heading1 d-flex justify-content-between">
                      <h2 class="form-heading1" >Trip Close - Tripsheet has been locked</h2>
                     
                    </div>                  
                   <div class="row">
                        <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Trip No</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.trip_no ? triplist?.trip_no : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Customer</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.customer_name ? triplist?.customer_name : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Payment Mode</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.payment_mode ? triplist?.payment_mode : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Location</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.billing_location ? triplist?.billing_location : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Open Date</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.open_date ? triplist?.open_date : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Open Time
                        </label>
                        <p class="m-0 py-2 view-data"> {{triplist?.open_time ? triplist?.open_time : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Open KMS</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.open_km ? triplist?.open_km : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Close Date</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.close_date ? triplist?.close_date : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Close Time</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.close_time ? triplist?.close_time : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Close KMS</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.close_km ? triplist?.close_km : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Rental City</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.rental_city ? triplist?.rental_city : '-'}} </p>
                        </div>
                      </div>
                      <!-- <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2">Vendor</label>
                          <p class="m-0 py-2"> {{triplist?.vendor}} </p>
                        </div>
                      </div> -->
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Vehicle No</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.vehicle_no ? triplist?.vehicle_no : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Vehicle Model</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.vehicle_model ? triplist?.vehicle_model : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Ordered By</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.ordered_by ? triplist?.ordered_by : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Guest</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.guest ? triplist?.guest : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Reporting Place</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.reporting_place ? triplist?.reporting_place : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">CE Name</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.ce_name ? triplist?.ce_name : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">CE Mobile</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.ce_mobile ? triplist?.ce_mobile : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Billing Address</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.billing_location ? triplist?.billing_location : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Comments</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.comments ? triplist?.comments : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Invoice No</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.invoice_no ? triplist?.invoice_no : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Receipt Adjusment No</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.receipt_adj_no ? triplist?.receipt_adj_no : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Annexue No</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.annexue_no ? triplist?.annexue_no : '-'}} </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label class="pb-2 view-label">Rental Type</label>
                          <p class="m-0 py-2 view-data"> {{triplist?.rental_type ? triplist?.rental_type : '-'}} </p>
                        </div>
                      </div>
                      
                     
                   </div>
                   <div class="row">
                    <div class="col-md-6">
                        
                        <div class="row" style="border-style: groove;">
                            
                            <div class=" col-md-12 ">
                                <h5 class="form-heading" style="margin-right: -7px !important;
                                margin-left: -8px !important;" ><b>Customer Bill</b></h5>
                               
                              </div> 
                              <br>
                              <br>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">No of Days</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_no_of_days ? triplist?.c_no_of_days : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Total Hours</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_total_hrs ? triplist?.c_total_hrs : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Total Kms</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_total_km ? triplist?.c_total_km : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Slab Day</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_slab_day ? triplist?.c_slab_day : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Slab Hr</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_slab_hr ? triplist?.c_slab_hr : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Slab Kms</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_slab_km ? triplist?.c_slab_km : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Slab Rate</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_slab_rate ? triplist?.c_slab_rate : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Extra Hours</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_ex_hr ? triplist?.c_ex_hr : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Ex-Hr Rate</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_ex_hr_rate ? triplist?.c_ex_hr_rate : '-'}} </p>
                                </div>
                            </div><div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Ex-Hr Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_ex_hr_amt ? triplist?.c_ex_hr_amt : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Extra Kms</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_ex_km ? triplist?.c_ex_km : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Ex-Km Rate</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_ex_km_rate ? triplist?.c_ex_km_rate : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Ex-Km Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_ex_km_amt ? triplist?.c_ex_km_amt : '-'}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Parking </label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2 view-data"> {{triplist?.parking ? triplist?.parking : '-'}} </p>
                                  
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Permit</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2 view-data"> {{triplist?.permit ? triplist?.permit : '-'}} </p>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Local Allowance</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2 view-data"> {{triplist?.loc_allowance ? triplist?.loc_allowance : '-'}} </p>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">OS Allowance</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2 view-data"> {{triplist?.os_allowance ? triplist?.os_allowance : '-'}} </p>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">OS Night Allow</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2 view-data"> {{triplist?.os_night_allowance ? triplist?.os_night_allowance : '-'}} </p>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Others</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2 view-data"> {{triplist?.others ? triplist?.others : '-'}} </p>
                                 
                                </div>
                            </div>
                           
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Discount%</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.discount_per ? triplist?.discount_per : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_discount_amt ? triplist?.c_discount_amt : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">CGST%</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_cgst_per ? triplist?.c_cgst_per : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_cgst_amt ? triplist?.c_cgst_amt : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">SGST%</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_sgst_per ? triplist?.c_sgst_per : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_sgst_amt ? triplist?.c_sgst_amt : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">IGST%</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_igst_per ? triplist?.c_igst_per : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_igst_amt ? triplist?.c_igst_amt : '-'}} </p>
                                </div>
                              </div>
                             
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Invoice Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.invoice_amt ? triplist?.invoice_amt : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Card %</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_card_per ? triplist?.c_card_per : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.c_card_amt ? triplist?.c_card_amt : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p></p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2 view-label">Net Amt</label>
                                  <p class="m-0 py-2 view-data"> {{triplist?.net_amt ? triplist?.net_amt : '-'}} </p>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                    <label class="pb-2 view-label">Naration</label>
                                    <p class="m-0 py-2 view-data"> {{triplist?.naration ? triplist?.naration : '-'}} </p>
                                  </div>
                              </div>
                       
                            </div>
                            
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="row" style="border-style: groove;">
                            <div class=" col-md-12 ">
                                <h5><b>Vendor Bill</h5>
                               
                              </div> 
                              <br>
                              <br>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">No of Days</label>
                                  <p class="m-0 py-2"> {{triplist?.v_no_of_days}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Total Hours</label>
                                  <p class="m-0 py-2"> {{triplist?.v_total_hrs}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Total Kms</label>
                                  <p class="m-0 py-2"> {{triplist?.v_total_kms}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p><b></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Slab Day</label>
                                  <p class="m-0 py-2"> {{triplist?.v_slab_day}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Slab Hr</label>
                                  <p class="m-0 py-2"> {{triplist?.v_slab_hr}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Slab Kms</label>
                                  <p class="m-0 py-2"> {{triplist?.v_slab_km}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Slab Rate</label>
                                  <p class="m-0 py-2"> {{triplist?.v_slab_rate}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p><b></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Extra Hours</label>
                                  <p class="m-0 py-2"> {{triplist?.v_ex_hr}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Ex-Hr Rate</label>
                                  <p class="m-0 py-2"> {{triplist?.v_ex_hr_rate}} </p>
                                </div>
                            </div><div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Ex-Hr Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.v_ex_hr_amt}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p><b></p>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Extra Kms</label>
                                  <p class="m-0 py-2"> {{triplist?.v_ex_km}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Ex-Km Rate</label>
                                  <p class="m-0 py-2"> {{triplist?.v_ex_km_rate}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Ex-Km Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.v_ex_km_amt}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p><b></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p><b></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Parking </label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2"> {{triplist?.v_parking}} </p>
                                  
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Permit</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2"> {{triplist?.v_permit}} </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Local Allowance</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2"> {{triplist?.v_local_allowance}} </p>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">OS Allowance</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2"> {{triplist?.v_os_allowance}} </p>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">OS Night Allow</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2"> {{triplist?.v_os_night_allowance}} </p>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Others</label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <p class="m-0 py-2"> {{triplist?.v_others}} </p>
                                 
                                </div>
                            </div>
                           
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Discount%</label>
                                  <p class="m-0 py-2"> {{triplist?.v_others}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.v_others}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">CGST%</label>
                                  <p class="m-0 py-2"> {{triplist?.v_cgst_per}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.v_cgst_amt}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">SGST%</label>
                                  <p class="m-0 py-2"> {{triplist?.v_sgst_per}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.v_sgst_amt}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">IGST%</label>
                                  <p class="m-0 py-2"> {{triplist?.v_igst_per}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.v_igst_amt}} </p>
                                </div>
                              </div>
                             
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Invoice Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.invoice_amt}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Card %</label>
                                  <p class="m-0 py-2"> {{triplist?.c_card_per}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.c_card_amt}} </p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                  <p><b></p>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2"></label>
                                 
                                </div>
                            </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label class="pb-2">Net Amt</label>
                                  <p class="m-0 py-2"> {{triplist?.v_net_amount}} </p>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                    <label class="pb-2">Naration</label>
                                    <p class="m-0 py-2"> {{triplist?.v_naration}} </p>
                                  </div>
                              </div>
                       
                            </div>
                            
                    </div> -->
                   </div>
                   
                      
                     
                 
                    


                    
                   
                     
                     
                    
                     

                      
                      <div class="aligh-button-right">
                        <!-- <button routerLink="/managebooking" class="mat-button-cancel">Cancel</button> -->
                     
                    </div>
                </section>
            </div>
        </div>
      </div>
    </div>
  </section>
  </div>
