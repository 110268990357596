import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user;
    public user_Name;


    constructor(private appService: AppService, private router: Router) {}

    ngOnInit(): void {            
            this.user = this.appService.user;
            this.user_Name = localStorage.getItem('name');               

    }

    logout() {
         this.appService.logout();
         localStorage.clear();
        localStorage.removeItem('userId');
        this.router.navigate(['/login']);

    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
