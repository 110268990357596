import {Component, OnInit} from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { Managecustomer } from '@services/managecustomerguest.service';
import { MustMatch } from '@services/must-match.validators';
import { response } from 'express';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit{
  toggle:  boolean= true
  toggle1: boolean = true;
  toggle2: boolean = true;

    smile:any;
    public files:any;
    isEdited:false;
    name :any;
    num:any;
    email:any;
    id:any;
    number:any;
    submitted = false;
    msg:any;
    password;
    password1
    password2
    okay:any=[];
    passHide = true;
    npassHide = true;
    cpassHide = true;
    show1 = false;
    show2 = false;
    show3 = false;
    public isDisableBtn: boolean = false;
    public loginType:any;
    public changepassform : FormGroup;
    constructor( private formBuilder: FormBuilder, public toast:ToastrService, private managecustomerguests: Managecustomer) { }
    ngOnInit(): void {
        this.loginType = localStorage.getItem("loginType")
        this.password = 'password';
        this.password1 = 'password';
        this.password2 = 'password';
         this.getuserdetails();


       this.changepassform = this.formBuilder.group({

        id:[localStorage.getItem('Id')],
        o_password:["",[Validators.required]],
        n_password:["",[Validators.required]],
        c_password:["",[Validators.required]],

       },{
        validator: MustMatch('n_password', 'c_password')
       })
        }
        get passwordControls() { return this.changepassform.controls; }
        get f() {
            return this.changepassform.controls
          }

        onFileChange(pFileList: File[]){   
            this.files = Object.keys(pFileList).map(key => pFileList[key]);
           //this.Submit()
          } 

          
public getuserdetails(){
 
    //this.num = localStorage.getItem("password")
    this.name = localStorage.getItem("name"),
   
    this.email = localStorage.getItem("userId")   
    
    this.id = localStorage.getItem("emp_Id")
    this.number = localStorage.getItem("emp_mob")
}
onClick1() {
  if (this.password === 'password') {
    this.password = 'text';
    this.show1 = true;
  } else {
    this.password = 'password';
    this.show1 = false;
  }
}

 changeType(input_field_password, num){
  if(input_field_password.type=="password")
    input_field_password.type = "text";
  else
    input_field_password.type = "password";

  if(num == 1)
    this.toggle1 = !this.toggle1;
    else if(num == 0)
    this.toggle = !this.toggle;
  else
    this.toggle2 = !this.toggle2;
}

public sumbit(){
    this.changepassform.value
    this.submitted = true;
    if( this.changepassform.valid){
      this.isDisableBtn = true
        this.managecustomerguests.getpassword( this.changepassform.value).subscribe(
            (response) => {
             this.msg = response;
             if (this.msg.success == true) {
                this.toast.success(this.msg.message,'Success');
                 this.changepassform.reset()
                 this.submitted = false;
             }
             else {
                this.toast.error(this.msg.message, 'Error');
                this.isDisableBtn = false
             }
            }
           
        );
        
    }

}


}
