import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { Managecustomer } from '@services/managecustomerguest.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@services/helper.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GeneralService } from '@services/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface PeriodicElement {
  position: number;
  employee_id: string;
  guest_mail_id: string;
  guest_mobile: string;
  guest_name: string;
  pickup_address: string;
}

@Component({
  selector: 'app-add-blank',
  templateUrl: './add-blank.component.html',
  styleUrls: ['./add-blank.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class AddBlankComponent implements OnInit {
  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;
  @ViewChild('thirdDialog', { static: true }) thirdDialog: TemplateRef<any>;
  @ViewChild('fourthDialog', { static: true }) fourthDialog: TemplateRef<any>;
  
  displayedColumns: string[] = ['position', 'employee_id', 'guest_mail_id', 'guest_mobile', 'guest_name', 'pickup_address'];
  dataSource = new MatTableDataSource<PeriodicElement>();
  public datalist: [];
  value = '';

  public type = 'add';
  Vehicle_model: any;

  editmode:boolean = false;

  myplaceHolder1: string = 'dd-mm-yyyy'
  date: any;
  maxdate: any;
  CurrentDate: any;
  respo: any;
  loc1:any;
  webbooking: FormGroup;
  guest_search: any;
  submitted = false;
  forzaadmin: any;
  customersgroup: any;
  customerrole: any;
  branches: any;
  base_Location: any;
  customer_Role: any;
  customer_Group: any;
  guest_Name: any;
  guest_Admin_Name:any;
  getlocation: any = [];
  public filteredList4 = [];
  guest_MailId: any;
  pickup_Address: any;
  locationlist: any = [];
  guest_Employee: any;
  special_Instructions: any;
  pick_add: any;
  controls: any;
  msg: any;
  customer: any = [];
  rentalcity: any = [];
  booking: any = [];
  vehicle: any = [];
  rental: any = [];
  payment: any = [];
  prefix: any = [];
  bill: any = [];
  billing_location: any;
  log_id: any = [];
  getcustomerdata: any[];
  filterlist: any = [];
  filterlist1: any = [];
  vehiclelist: any = [];
  childForm: any = [
    {
      prefix: '',
      guest_name: '',
      guest_Employeeid: '',
      guest_mobile: '',
      guest_Email: ''
    }
  ];

  prefix_ar: any = [];
  guest_mobile_ar: any = [];
  guest_name_ar: any = [];
  guest_email_ar: any = [];

  customeridDrop: any;
  pickup_address: any
  spl_instruction: any
  drop_address: any
  flight_details: any
  id: any;
  buttonText: string = 'submit'

  bookingId: any;
  webbookinglist: any;
  customer_Name: any;
  billing_Location: any;
  rental_city: any;
  booking_Type: any;
  pickup_Date: any = [];
  end_Date: any = [];
  pickup_Time: any = [];
  pickup_min: any = [];
  pickup_hour: any = [];
  Vehicle_Model: any;
  location_id:any;
  rental_Type: any;
  paymode_Default: any;
  booking_source: any;
  loname:any;  


  coordinate_name: any = '';
  coordinate_mobile: any = '';
  project_id: any = '';
  request_id: any = '';
  comments: any = '';
  mail_id: any = '';





  loca_id:any;
  loc_name:any;
  city_name:any;
  pickup_ADdress: any = [];
  filterlist2: any = [];
  bookinglist: any = [];
  rentallist: any = [];
  public isDisableBtn: boolean = false;
  flight_Details: any = [];
  Prefix: any = [];
  guest_name: any = [];
  guest_Employeeid: any = [];
  guest_mobile: any = [];
  paymentmode: any = [];


  hourArray:any = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
  filteredHourList: any = [] = this.hourArray;
  minutesArray:any = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]


  formattedaddress = "";
  options = {
    componentRestrictions: {
      country: ["IN"]
    }
  }

  myControl = new FormControl('');
  searchMobData: any = [];
  searchNameData: any = [];
  filteredOptions: Observable<string[]>;
  customerDeleteid: any;

  constructor(
    private matDialog: MatDialog,
    private managecustomer: Managecustomer,
    public datepipe: DatePipe,
    public location: Location,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public helperService: HelperService,
    public generalService: GeneralService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private managecustomerguests: Managecustomer,
  ) { }

  ngOnInit(): void {
    this.CurrentDate = new Date().toISOString().split('T')[0];
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    if (this?.activatedRoute?.snapshot?.url[1]?.path) {
      if (this.activatedRoute.snapshot.url[1].path == 'add') {
        this.type == 'add'
        this.helperService.pageAccess('r_create')

      } else {
        console.log("edit page hit");
        
        this.bookingId = atob(this.activatedRoute.snapshot.url[1].path);

        this.type = this.activatedRoute.snapshot.url[2].path;
        this.helperService.pageAccess('r_update')
        this.getwebbooking()
        // this.prefixDropdown();

        // this.booking_Type = JSON.parse(responses?.Webbooking_List.booking_type);
        setTimeout(() => {
          
          console.log(this.booking_Type);
          if(this.booking_Type == 1)
          {
            console.log("1");
            
            this.bookingtypeDropdownfresh();
          }
          else if(this.booking_Type == 2)
          {
            this.bookingtypeDropdowncontinue();
          }
        }, 1000);
        

      }

      if (this.type == "edit1")
      {
        console.log("prakash");
        this.editmode = true;
        
      }
    }

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.log_id = localStorage.getItem('Id');
    this.webbooking = this.fb.group({
      customer: ['', Validators.required],
      billing_location: ['', Validators.required],
      rental_city: ['', Validators.required],
      booking_type: [1, Validators.required],
      pickup_date: ['', Validators.required],
      end_date: ['', Validators.required],
      pickup_time: ['', Validators.required],
      Vehicle_model: ['', Validators.required],
      rental_type: ['', Validators.required],
      paymode_default: ['BILL TO COMPANY', Validators.required],
      pickup_address: ['', Validators.required],
      booking_source: ['CBS', Validators.required],
      coordinate_name: [''],
      coordinate_mobile: [''],
      project_id: [''],
      request_id: [''],
      comments: [''],
      mail_id: ['',[this.commaSepEmail]],
      quantities: [''],
      drop_address: [''],
      spl_instruction: [''],
      flight_details: [''],
      pickup_hour: ['', Validators.required],
      pickup_min: ['', Validators.required],

    })
    
    this.booking_Type = 1;

    this.bookingtypeDropdown1();
  
    this.paymentDropdown();
    setTimeout(() => {
      
      this.prefixDropdown();
    }, 1000);
    this.rentalcityDropdown();
  }
  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(',').map(e=>e.trim());
    const forbidden = emails.some(email => Validators.email(new FormControl(email)));
    return forbidden ? { 'mail_id': { value: control.value } } : null;
  };
  openDialog() {
    this.dialog.open(this.secondDialog, {
      width: '70%',
    });
  }

  closeDialog() {
    this.dialog.closeAll()
    this.searchMobData = []
  }

  reset() {
    this.end_Date = null;
  }

  public viewdata(data: any) {
    this.searchMobData = []
    this.dialog.closeAll()
    this.pickup_address = data.pickup_address;
    //this.bill = Number(data.billing_location);
    //console.log(this.bill);
    this.billing_Location =Number(data.billing_location)
    //this.billing_Location = Number(responses?.Webbooking_List.billing_location);        
    this.customerDropdown(this.billing_Location)
    this.customer_Name = data.customer;
    this.customer_Name = Number(data.customer)
    this.rentaldropdown(this.customer_Name)
    // console.log(this.customer_Name);
   
    //this.rentalcityDropdown(this.customer_Name)
    let obj = {
      prefix: '',
      guest_name: data.guest_name,
      guest_Employeeid: data.employee_id,
      guest_mobile: data.guest_mobile,
      guest_Email: data.guest_mail_id
    }
    this.childForm = [];
    this.childForm.push(obj);
  }

  getMob(event: any) {
    let obj = {
      user_id: this.log_id,
      Keyword: event.target.value
    }
    this.managecustomer.getGuestSearch(obj).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        let objArray = this.msg.Name_List;
        this.searchMobData = objArray.map(a => a.guest_mobile);
        this.searchNameData = objArray.map(a => a.guest_name);
      }
    }))
  }

  private _filter(value) {
    let values = Number(value)
    const filterValue = value
    if (values > 0) {
      return this.searchMobData.filter(street => street.includes(filterValue));
    } else {
      if (Number(values) > 0) {
        return this.searchNameData.filter(street => street.toLowerCase().includes(filterValue));
      }
    }
  }

  getPosts(data: any) {
    let obj = {
      user_id: this.log_id,
      Keyword: data.option.value
    }

    this.managecustomer.getGuestDataSearch(obj).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.datalist = this.msg.Guest_List;
        console.log(this.datalist);
        
        this.openDialog();
      }
    }))
  }

  view(id) {
    // this.router.navigate(['/view-booking', btoa(id),'view']);
  }

  customerDropdown(location_name) {
    this.loname=location_name    
    const element = this.loname; 
    let arg =  this.locationlist.filter(item => item.location_name == element)[0];
    if(arg != undefined) {
      this.location_id = Number(arg.location_id)
     
      console.log(this.location_id);
      
    this.customer_Name = ''
    this.rental_city = ''
    this.Vehicle_Model = ''
    this.rental_Type = ''
    this.loca_id = this.location_id;
    let data = {
      type: this.forzaadmin,
      user_id: this.log_id,
      location: this.location_id
    }

    this.managecustomer.customerdropdown(data).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.customer = this.msg.Customer_List;
        this.customer = this.customer.sort((a, b) => {
          if (a.customer_name < b.customer_name) { return -1; }
          if (a.customer_name > b.customer_name) { return 1; }
        })
        this.filterlist = this.customer.slice();
      }      
      
    }))
  } 

  }

  public AddressChange(address: any) {
    //setting address from API to local variable
    let formattedaddress1 = address.name
    let formattedaddress2 = address.formatted_address
    this.pickup_address = formattedaddress1 + ",".concat(formattedaddress2.toString())
    this.webbooking.get("pickup_address").setValue(this.pickup_address);
  }

  public AddressChange1(address: any) {
    //setting address from API to local variable
    let formattedaddress1 = address.name
    let formattedaddress2 = address.formatted_address
    this.drop_address = formattedaddress1 + ",".concat(formattedaddress2.toString())
    this.webbooking.get("drop_address").setValue(this.drop_address);
  }

  get f() {
    return this.webbooking.controls
  }

  back() {
    this.location.back()
  }

  public customerclose() {
    this.customer_Name = ''
    this.billing_Location = ''
    this.rental_city = ''
    this.Vehicle_Model = ''
    this.rental_Type = ''
    this.filteredList4 = []
    this.filterlist1 = []
    this.vehiclelist = []
    this.rentallist = []
  }

  public rentalclose() {
    this.rental_city = ''
    this.Vehicle_Model = ''
    this.rental_Type = ''
    this.vehiclelist = []
    this.rentallist = []
  }

  public vehicleclose() {
    this.Vehicle_Model = ''
    this.rental_Type = ''
    this.rentallist = []
  }

  public rentalcityDropdown() {
    let data = {
      type: this.forzaadmin,
      user_id: this.log_id
    }
    this.managecustomerguests.getCustomerlocation(data).subscribe(
      (response) => {
        this.respo = response;
        if (this.respo.success == true) {
          this.locationlist = this.respo.Location_List
          this.locationlist = this.locationlist.sort((a, b) => {
            if (a.location_name < b.location_name) { return -1; }
            if (a.location_name > b.location_name) { return 1; }
          })
          this.filteredList4 = this.locationlist.slice();
         
        
          //let data = this.locationlist.filter(item => item.branch_id == Number(element))[0];
      
        }
      })
    }



    public rentalcity1(value){
      this.loc1 = value
      const element = value; 
      let data =  this.rentalcity.filter(item => item.branch_id == Number(element))[0];
      if(data != undefined) {
      this.city_name=data.branch_name
      }  


      var idarray = {
        loc_id:this.loca_id,
        customers_id:this.customeridDrop,
        location: this.city_name
      };
    
      this.managecustomer.vechilemodeldropdown(idarray).subscribe((res => {
        this.msg = res;
        if (this.msg.success == true) {
          this.vehicle = this.msg.vehicle_model;
          this.vehicle = this.vehicle.sort((a, b) => {
            if (a.vehicle_model < b.vehicle_model) { return -1; }
            if (a.vehicle_model > b.vehicle_model) { return 1; }
          })
          this.vehiclelist = this.vehicle.slice();
        }
      }))
    }


public rentaldropdown(customers_id){
  this.rental_city = ''
  this.Vehicle_Model = ''
  this.rental_Type = ''
  this.filterlist1 = [];
  this.vehiclelist = [];
  this.rentallist = [];
  var idarray = {
    loc_id:this.loca_id,
    customers_id:customers_id
  };
  // idarray['customers_id'] = customers_id;
   this.customeridDrop = customers_id
  
    this.managecustomer.rentalcitydropdown(idarray).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.rentalcity = this.msg.Rental_City;
        this.rentalcity = this.rentalcity.sort((a, b) => {
          if (a.branch_name < b.branch_name) { return -1; }
          if (a.branch_name > b.branch_name) { return 1; }
        })
        this.filterlist1 = this.rentalcity.slice();
      }
    }))

    

}

  

  public removeForm(index: any) {
    this.childForm.splice(index, 1);
    if (this.childForm.length == 0) {
      this.addQuantity();
    }
  }

  public isValidEmail(emailString: string): boolean {
    try {
      // let pattern = new RegExp("^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$");
      let pattern = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
      let valid = pattern.test(emailString);
      return valid;
    } catch (TypeError) {
      return false;
    }
  }

  test(i) {
    const element = this.childForm[i];
    this.prefix_ar[i] = false;
    this.guest_mobile_ar[i] = false;
    this.guest_name_ar[i] = false;
    this.guest_email_ar[i] = false;

    Object.keys(element).forEach(key => {
      if ((key == 'guest_Email') && (element[key] != '')) {
        this.guest_email_ar[i] = !this.isValidEmail(element[key]);
        this.isDisableBtn = false;
      }

      if ((key == 'prefix') && (element[key] === '' || element[key] === null)) {
        this.prefix_ar[i] = true;
        this.isDisableBtn = false;
        return
      } else if ((key == 'guest_name') && (element[key] === '' || element[key] === null)) {
        this.guest_name_ar[i] = true;
        this.isDisableBtn = false;
        return
      } else if ((key == 'guest_mobile') && (element[key] === '' || element[key] === null)) {
        this.guest_mobile_ar[i] = true;
        this.isDisableBtn = false;
        return
      }
    });
  }


  addQuantity() {
    this.childForm.push({
      prefix: '',
      guest_name: '',
      guest_Employeeid: '',
      guest_mobile: '',
      guest_Email: ''
    });
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }


  submit() {

    this.submitted = true;
    this.webbooking.get("pickup_address").setValue(this.pickup_address);
    this.webbooking.get("drop_address").setValue(this.drop_address);
    this.webbooking.get("spl_instruction").setValue(this.spl_instruction);
    this.webbooking.get("flight_details").setValue(this.flight_details);
    this.webbooking.get("quantities").setValue(this.childForm);
    this.webbooking.get("pickup_time").setValue(`${this.webbooking.get('pickup_hour').value}:${this.webbooking.get('pickup_min').value}`);
    let isFormSubmit = []
    for (let i = 0; i < this.childForm.length; i++) {
      const element = this.childForm[i];
      this.prefix_ar[i] = false;
      this.guest_mobile_ar[i] = false;
      this.guest_name_ar[i] = false;
      this.guest_email_ar[i] = false;

      Object.keys(element).forEach(key => {
        if ((key == 'guest_Email') && (element[key] != '')) {
          this.guest_email_ar[i] = !this.isValidEmail(element[key]);
          this.isDisableBtn = false;
          if (this.isValidEmail(element[key]) == false) {
            isFormSubmit.push(this.isValidEmail(element[key]))
          }
        }

        if ((key == 'prefix') && (element[key] === '' || element[key] === null)) {
          this.prefix_ar[i] = true;
          this.isDisableBtn = false;
          isFormSubmit.push(false)
        }

        if ((key == 'guest_name') && (element[key] === '' || element[key] === null)) {
          this.guest_name_ar[i] = true;
          this.isDisableBtn = false;
          isFormSubmit.push(false)
        }

        if ((key == 'guest_mobile') && (element[key] === '' || element[key] === null)) {
          this.guest_mobile_ar[i] = true;
          this.isDisableBtn = false;
          isFormSubmit.push(false)
        }
      });
    }

    if (isFormSubmit.length > 0) {
      return
    }

    if (this.webbooking.invalid) {
      const invalid = [];
      const controls = this.webbooking.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.webbooking.markAllAsTouched();
      console.log(invalid);
      return
    } else {
      this.webbooking.get("paymode_default").setValue(Number(2));
      this.webbooking.get("pickup_date").setValue(this.datepipe.transform(this.pickup_Date, 'yyyy-MM-dd'));
      this.webbooking.get("end_date").setValue(this.datepipe.transform(this.end_Date, 'yyyy-MM-dd'));
      this.webbooking.get("pickup_time").setValue(`${this.webbooking.get('pickup_hour').value}:${this.webbooking.get('pickup_min').value}`);
      this.webbooking.value.login_id = Number(this.log_id);
      this.webbooking.value.user_type = this.forzaadmin
      this.managecustomer.createwebbook(this.webbooking.value).subscribe((res => {
        console.log("jhjh");
        
        
        this.msg = res;
        if (this.msg.success == true) {
          this.toastr.success(this.msg.message, 'success');
          this.router.navigate(['/managebooking']);
        }
        error => {
          this.toastr.error(error, 'Error');
        }
        this.customerDeleteid = true;
        this.matDialog.open(this.thirdDialog, {
        });
      }))
    }
  }

  deleteCustomer() {
  }

  func_pickup_address() {
    // this.webbooking.get("pickup_address").setValue(this.pickup_address);
  }

  func_dropup_address() {
    // this.webbooking.get("drop_address").setValue(this.drop_address);
  }

  func_dropup_search(data) {
    // this.guest_search = data
  }

  convertTime = timeStr => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
       hours = '00';
    }
    if (modifier === 'PM') {
       hours = parseInt(hours, 10) + 12;
    }
    hours = (hours.length == 1)? '0'+hours : hours
    return `${hours}:${minutes}`;
 };


  update() {
    this.submitted = true;
    this.webbooking.get("pickup_address").setValue(this.pickup_address);
    this.webbooking.get("drop_address").setValue(this.drop_address);
    this.webbooking.get("spl_instruction").setValue(this.spl_instruction);
    this.webbooking.get("flight_details").setValue(this.flight_details);
    this.webbooking.get("quantities").setValue(this.childForm);
    let isFormSubmit = []

    this.webbooking.get("pickup_time").setValue(`${this.webbooking.get('pickup_hour').value}:${this.webbooking.get('pickup_min').value}`);

    for (let i = 0; i < this.childForm.length; i++) {
      const element = this.childForm[i];
      this.prefix_ar[i] = false;
      this.guest_mobile_ar[i] = false;
      this.guest_name_ar[i] = false;
      this.guest_email_ar[i] = false;

      Object.keys(element).forEach(key => {

        if ((key == 'guest_Email') && (element[key] != '')) {
          this.guest_email_ar[i] = !this.isValidEmail(element[key]);
          this.isDisableBtn = false;
          if (this.isValidEmail(element[key]) == false) {
            isFormSubmit.push(this.isValidEmail(element[key]))
          }
        }

        if ((key == 'prefix') && (element[key] === '' || element[key] === null)) {

          this.prefix_ar[i] = true;
          this.isDisableBtn = false;
          isFormSubmit.push(false)
        }

        if ((key == 'guest_name') && (element[key] === '' || element[key] === null)) {
          this.guest_name_ar[i] = true;
          this.isDisableBtn = false;
          isFormSubmit.push(false)
        }

        if ((key == 'guest_mobile') && (element[key] === '' || element[key] === null)) {
          this.guest_mobile_ar[i] = true;
          this.isDisableBtn = false;
          isFormSubmit.push(false)
        }
      });
    }

    if (isFormSubmit.length > 0) {
      return
    }

    if (this.webbooking.invalid) {
      const invalid = [];
      const controls = this.webbooking.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.webbooking.markAllAsTouched();
      console.log(invalid);
      this.submitted = false;
      this.isDisableBtn = false;

    } else {
      this.webbooking.get("paymode_default").setValue(Number(2));
      this.webbooking.get("pickup_date").setValue(this.datepipe.transform(this.pickup_Date, 'yyyy-MM-dd'));
      this.webbooking.get("end_date").setValue(this.datepipe.transform(this.end_Date, 'yyyy-MM-dd'));
      this.webbooking.get("pickup_time").setValue(`${this.webbooking.get('pickup_hour').value}:${this.webbooking.get('pickup_min').value}`);
      this.isDisableBtn = true;
      if (this.type === 'edit') {
        // alert("hgj")
        // console.log("prakash");

        // if(this.type === 'edit1'){
        //   console.log("sadasd");
                    
        //   this.editmode = true;
        // }
        this.webbooking.value.booking_id = Number(this.bookingId);
        this.webbooking.value.login_id = Number(this.log_id);
        this.webbooking.value.user_type = this.forzaadmin
        this.isDisableBtn = true;
        this.managecustomer.updatedata(this.webbooking.value).subscribe(
          response => {
            this.bookinglist = response;
            if (this.bookinglist.success == true) {
              this.toastr.success(this.bookinglist.message, 'Success');
              this.router.navigate(['/managebooking']);
            } else {
              this.toastr.error(this.bookinglist.message, 'Error');
              this.isDisableBtn = false;
            }
          },
          error => {
            this.toastr.error(error, 'Error');
            this.isDisableBtn = false;
          });
      }
      this.submitted = false;
    }
  }

  getwebbooking() {
    let data = {
      booking_id: this.bookingId
    }
    this.managecustomer.getbookingdata(data).subscribe((res => {
      let responses: any = res;

      console.log(responses);
      
      if (responses.success == true) {
        this.webbookinglist = responses.Webbooking_List;
       
        setTimeout(() => {
        this.billing_Location = responses?.Webbooking_List.billing_location;     
          this.customerDropdown(this.billing_Location)
          this.customer_Name = JSON.parse(responses?.Webbooking_List?.customer);
          
          this.rentaldropdown(this.customer_Name)
          this.rental_city = JSON.parse(responses?.Webbooking_List.rental_city);
          this.booking_Type = JSON.parse(responses?.Webbooking_List.booking_type);      
        }, 1000);   
        
        this.pickup_Date = responses?.Webbooking_List.pickup_date;
        this.end_Date = responses?.Webbooking_List.end_date;
        this.pickup_Time = responses?.Webbooking_List.pickup_time;

        this.pickup_hour = responses?.Webbooking_List.pickup_time.slice(0,2);
        this.pickup_min = responses?.Webbooking_List.pickup_time.slice(3,5);


        this.Vehicle_Model = responses?.Webbooking_List.Vehicle_model;                
       
      
        this.paymode_Default = responses?.Webbooking_List.paymode_defult_name;
        this.booking_source = responses?.Webbooking_List.booking_source_name;
        this.coordinate_name = responses?.Webbooking_List.coordinate_name;
        this.coordinate_mobile = responses?.Webbooking_List.coordinate_mobile;
        this.project_id = responses?.Webbooking_List.project_id;
        this.request_id = responses?.Webbooking_List.request_id;
        this.comments = responses?.Webbooking_List.comments;
        this.mail_id = responses?.Webbooking_List.mail_id;
        this.pickup_address = responses?.Webbooking_List.pickup_address;
        this.drop_address = responses?.Webbooking_List.drop_address;
        this.spl_instruction = responses?.Webbooking_List.spl_instruction;
        this.flight_details = responses?.Webbooking_List.flight_details;
        this.prefix = responses?.Webbooking_List.quantities.prefix;
        this.guest_name = responses?.Webbooking_List.quantities[0].guest_name;
        this.guest_Employeeid = responses?.Webbooking_List.quantities[0].guest_Employeeid;
        this.guest_mobile = responses?.Webbooking_List.quantities[0].guest_mobile;
        let childForm = responses?.Webbooking_List.quantities
        this.childForm = [];
        this.rental_Type = responses?.Webbooking_List.rental_type;

        setTimeout(() => {
        this.customer_Name = JSON.parse(responses?.Webbooking_List?.customer);

        // this.billing_Location = Number(responses?.Webbooking_List.billing_location);        
          for (let i = 0; i < childForm.length; i++) {
            const element = childForm[i];
          if(element.prefix != '' && element.guest_name != '' && element.guest_mobile != '' ) {
            Object.keys(element).forEach(key => {
              if (element[key] === '' || element[key] === null) {
                delete element[key];
              } else {
                if (key == 'prefix') {
                  element[key] = element[key]
                }
              }
            });
            this.childForm.push(element)
          }
          }
          
          this.rental_city = JSON.parse(responses?.Webbooking_List.rental_city);
          this.rentalcity1(this.rental_city)

          setTimeout(() => {
          this.Vehicle_Model = responses?.Webbooking_List.Vehicle_model;                
          this.rentaltypeDropdown(this.Vehicle_Model)
          this.rental_Type = responses?.Webbooking_List.rental_type;

          this.childForm = this.childForm.filter(value => Object.keys(value).length !== 0);
          }, 800);
        }, 3000);

        // var time = this.pickup_Time;
        // if(time.match(/\s(.*)$/) != null) {          
        // var hours = Number(time.match(/^(\d+)/)[1]);
        // var minutes = Number(time.match(/:(\d+)/)[1]);
        // var AMPM = time.match(/\s(.*)$/)[1];          
        // if(AMPM == "PM" && hours<12) hours = hours+12;
        // if(AMPM == "AM" && hours==12) hours = hours-12;
        // var sHours = hours.toString();
        // var sMinutes = minutes.toString();
        // if(hours<10) sHours = "0" + sHours;
        // if(minutes<10) sMinutes = "0" + sMinutes;
        // this.webbooking.get("pickup_time").setValue(sHours + ":" + sMinutes);
        // }
      }
    }))
  }

  rentaltypeDropdown(vehicle_model_id) {
    
    const element = this.loca_id;
    let datas = this.locationlist.filter(item => item.location_id == Number(element))[0]; 
    if(datas != undefined)  {
      this.loc_name = datas.location_name;
    }
    
    let data = {
      'customers_id': this.customeridDrop,
      'vehicle_model_id': vehicle_model_id,
      'loc_id':this.city_name,
    }
    
    this.managecustomer.rentaltypedropdown(data).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.rental = this.msg.rental_type;
        this.rental = this.rental.sort((a, b) => {
          if (a.rental_name < b.rental_name) { return -1; }
          if (a.rental_name > b.rental_name) { return 1; }
        })
        this.rentallist = this.rental.slice();        
      }
    }))
    // this.rental_Type = '';
  }

  bookingtypeDropdown1() {
    this.managecustomer.bookingtypedropdown({}).subscribe((res => {
      this.msg = res;
      
      
      if (this.msg.success == true) {
        this.booking = this.msg.booking_type;
        this.booking = this.booking.sort((a, b) => {
          if (a.booking_type < b.booking_type) { return -1; }
          if (a.booking_type > b.booking_type) { return 1; }
        })
        this.filterlist2 = this.booking.slice();
        // const countryDefault = this.filterlist2.find(c => c.booking_type_id === 1);
        // this.webbooking.get('booking_type').setValue(countryDefault.booking_type_id);
      
        
      }
    }))
  }

  bookingtypeDropdownfresh() {
    this.managecustomer.bookingtypedropdown({}).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        let booking = this.msg.booking_type;
        booking = booking.sort((a, b) => {
          if (a.booking_type < b.booking_type) { return -1; }
          if (a.booking_type > b.booking_type) { return 1; }
        })
        // this.filterlist2 = this.booking.slice();
        this.filterlist2 = this.booking = booking.filter((el)=>{
          return el.booking_type_id!==2  
        })
        // const countryDefault = this.filterlist2.find(c => c.booking_type_id === 1);
        // this.webbooking.get('booking_type').setValue(countryDefault.booking_type_id);    
      }
    }))
  }
  
  bookingtypeDropdowncontinue() {
    this.managecustomer.bookingtypedropdown({}).subscribe((res => {
      this.msg = res;     
      
      if (this.msg.success == true) {
        let booking = this.msg.booking_type;
        booking = booking.sort((a, b) => {
          if (a.booking_type < b.booking_type) { return -1; }
          if (a.booking_type > b.booking_type) { return 1; }
        })
        this.filterlist2 = booking.slice();
        this.filterlist2 = this.booking = booking.filter((el)=>{
          return el.booking_type_id!==1  
        })
        // const countryDefault = this.filterlist2.find(c => c.booking_type_id === 1);
        // this.webbooking.get('booking_type').setValue(countryDefault?.booking_type_id);
      }
    }))
  }

  paymentDropdown() {
    this.managecustomer.paymentdropdown({}).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.payment = this.msg.payment_type;
        this.payment = this.payment.sort((a, b) => {
          if (a.payment_name < b.payment_name) { return -1; }
          if (a.payment_name > b.payment_name) { return 1; }
        })
        this.paymentmode = this.payment.slice();
      }
    }))
  }

  prefixDropdown() {
    this.managecustomer.prefixdropdown({}).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.prefix = this.msg.prefix;
      }
    }))
  }

  reject() {
    let data = {
      booking_id: this.bookingId
    }
    this.managecustomer.rejectlist(data).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.toastr.error(this.msg.message, 'Success');
        this.router.navigate(['/webbooking']);
      }
    }))
  }

  checkPlaceHolder1() {
    if (this.myplaceHolder1) {
      this.myplaceHolder1 = null
      return;
    } else {
      this.myplaceHolder1 = 'dd-mm-yyyy'
      return
    }
  }


  print(){
    const dialogRef = this.dialog.open(this.fourthDialog, {
      width: '70%',
      height:'80%',
     autoFocus: false,
      restoreFocus: false

    });
  }

  
}