<div class="mx-4">
<section class="content-header">
  <div class="container-fluid">
    <div>
      <span routerLink="/dashboard">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
        routerLink="/managebooking">Manage Bookings</span> / <span class="activebreadcrumb-item active pointer"
        routerLink="/webbooking">Web Booking</span> / <span
        class="breadcrumb-item activebreadcrumb-item active">{{(type === 'edit') ? 'Edit' : 'Add'}}</span>
    </div>
    <div class="row mt-md-2">
      <div class="col-auto">
        <img src="assets/images/mainicon-webbooking.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
      </div>
      <div class="col">
        <div class="row flex-column justify-content-center mt-md-3">
          <div class="col">
            <h4 style="font-weight:600; color: #233771;">{{(type === 'edit') ? 'Edit' : 'Add'}} Web Booking</h4>
          </div>
        </div>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <h6 class="pointer pt-3" (click)="back()"><span class="fas fa-angle-left p-2"></span>Back
        </h6>
      </div>
    </div>
  </div>
</section>
<section class="content">
  <div class="container-fluid">    
    <div class="card">
      <div class="card-body">
        <div style="font-size: 12px;" class="d-flex justify-content-end">
          <!-- <mat-label>Search Guest</mat-label>
          <input type="text" aria-label="Number" matInput [formControl]="myControl" (keyup)="getMob($event)" [matAutocomplete]="auto"
            [(ngModel)]="value"> -->

          <div class="search-box pull-center">
            <div class="search-input">
              <mat-icon role="img" matprefix="true"
                class="mat-icon notranslate material-icons mat-icon-no-color icon-grey" aria-hidden="true"
                data-mat-icon-type="font">search</mat-icon>
              <input placeholder="Search Guest" [formControl]="myControl" class="form-control" (keyup)="getMob($event)"
                [matAutocomplete]="auto" [(ngModel)]="value">
              <mat-icon matSuffix *ngIf="value" (click)="value = ''" class="pointer">close</mat-icon>
              <mat-autocomplete class="mxwidth" #mySelect #auto="matAutocomplete" (optionSelected)='getPosts($event)'
                [hidden]="searchMobData.length == 0">

                <mat-option [hidden]="searchMobData.length == 0" *ngFor="let option of filteredOptions | async"
                  [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <!-- <button type="button" mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear"
            (click)="value = ''">
            <mat-icon>close</mat-icon>            
          </button> -->
        </div>
        <!-- <label>hiii</label> -->
        <div class="container-fluid">
          <form [formGroup]="webbooking">
            <!-- <div class="row justify-content-between mx-4"> -->

            <div class="form-heading p-0 pl-2 d-flex justify-content-between">
              <h2 class="form-heading2">Booking Information </h2>
              <span class="bookid" *ngIf="type == 'edit'">Booking ID :{{webbookinglist?.booking_id}}</span>
            </div><br>


            <div class="row justify-content-between mx-4">


              <div class="col-md-5">
                <div class="form-group">
                  <label>Billing Location<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [ngClass]="{ 'is-invalid': submitted && f['billing_location'].errors }"
                      formControlName="billing_location" class="form-control" [(ngModel)]="billing_Location"
                      (selectionChange)="customerDropdown($event.value)" placeholder="Please Select City" #city>
                      <mat-select-filter *ngIf="city.focused" [placeholder]="'Search'" [displayMember]="'location_name'"
                        [array]="locationlist" (filteredReturn)="filteredList4 = $event"></mat-select-filter>

                      <mat-option *ngFor="let branchd of filteredList4" [value]="branchd.location_name">
                        {{branchd.location_name}}
                      </mat-option>

                    </mat-select>
                    <!-- <div  *ngIf="filterlist1.length == 1">
                  <div *ngFor="let branch of filterlist1">
                    <input type="text" maxlength="50" formControlName="billing_location" class="form-control"
                    placeholder="Enter Billing Location" [(ngModel)]="bill"
                    [ngClass]="{'is-invalid': submitted && f['billing_location'].errors }" >
                  </div>
                </div> -->

                    <a class="decoration-none input-group-text closeicon" *ngIf="billing_Location">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="billlocation(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['billing_location'].errors" class="text-danger">
                    <div *ngIf="f['billing_location'].errors">Billing Location is required</div>
                  </div>
                </div>

              </div>



              <div class="col-md-5">
                <div class="form-group">
                  <label>Customer<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select formControlName="customer" class="form-control" placeholder="Please Select Customer"
                      required [(ngModel)]="customer_Name" (selectionChange)="rentaldropdown($event.value)"
                      [ngClass]="{'is-invalid': submitted && f['customer'].errors }" #custom>
                      <mat-select-filter *ngIf="custom.focused" [placeholder]="'Search'"
                        [displayMember]="'customer_name'" [array]="customer" (filteredReturn)="filterlist = $event">
                      </mat-select-filter>

                      <mat-option *ngFor="let customers of filterlist" [value]="customers.customers_id">
                        {{customers.customer_name}}
                      </mat-option>
                    </mat-select>

                    <a class="decoration-none input-group-text closeicon" *ngIf="customer_Name">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="customerclose(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>

                  <div *ngIf="submitted && f['customer'].errors" class="text-danger">
                    <div *ngIf="f['customer'].errors">Customer is required</div>
                  </div>

                </div>
              </div>





              <div class="col-md-5">
                <div class="form-group">
                  <label>Rental City<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [ngClass]="{ 'is-invalid': submitted && f['rental_city'].errors }"
                      formControlName="rental_city" class="form-control" [(ngModel)]="rental_city"
                      placeholder="Please Select City" #renlcity (selectionChange)="rentalcity1($event.value)">
                      <mat-select-filter *ngIf="renlcity" [placeholder]="'Search'" [displayMember]="'branch_name'"
                        [array]="rentalcity" (filteredReturn)="filterlist1 = $event"></mat-select-filter>

                      <mat-option *ngFor="let rental of filterlist1" [value]="rental.branch_id">
                        {{rental.branch_name}}
                      </mat-option>

                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="rental_city">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="rentalclose(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['rental_city'].errors" class="text-danger">
                    <div *ngIf="f['rental_city'].errors">Rental City is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group drop">
                  <label>Booking Type<span class="mandatory">*</span></label>
                  <div class="input-group-append">


                    <mat-select [ngClass]="{ 'is-invalid': submitted && f['booking_type'].errors }"
                      formControlName="booking_type" class="form-control" [(ngModel)]="booking_Type"
                      placeholder="Please select booking type" #book readonly>

                      <mat-select-filter *ngIf="book.focused" [placeholder]="'Search'" [displayMember]="'booking_type'"
                        [array]="booking" (filteredReturn)="filterlist2 = $event"></mat-select-filter>
                      <mat-option *ngFor="let book of filterlist2" [value]="book.booking_type_id">
                        {{book.booking_type}}
                      </mat-option>

                    </mat-select>


                    <a class="decoration-none input-group-text closeicon" *ngIf="booking_Type">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="booking_Type=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['booking_type'].errors" class="text-danger">
                    <div *ngIf="f['booking_type'].errors">Booking Type is required</div>
                  </div>
                </div>
              </div>





              <div class="col-md-5">
                <div class="form-group">
                  <label>Pickup Date<span class="mandatory">*</span></label>

                  <!-- before model -->
                  <div class="input-group-append">
                    <input autocomplete="off" matInput class="form-control" placeholder="dd-mm-yyyy"
                      (click)="picker1.open();reset()" [matDatepicker]="picker1" formControlName="pickup_date"
                      (ngModelChange)="maxdate = $event" [(ngModel)]="pickup_Date" readonly>
                    <div class="decoration-none input-group-text dateClass">
                      <mat-datepicker-toggle (click)="reset()"
                        class="pointer material-icons-outlined pointerv form-control-clear" matSuffix
                        [for]="picker1"></mat-datepicker-toggle>
                    </div>
                    <mat-datepicker #picker1></mat-datepicker>
                  </div>

                 

                  <div *ngIf="submitted && f['pickup_date'].errors" class="text-danger">
                    <div *ngIf="f['pickup_date'].errors">Pickup Date is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>End Date<span class="mandatory">*</span></label>

                  <div class="input-group-append">
                    <input autocomplete="off" matInput class="form-control" placeholder="  dd-mm-yyyy"
                      (click)="picker2.open()" [matDatepicker]="picker2" [min]="maxdate" formControlName="end_date"
                      (ngModelChange)="maxdate = $event" [(ngModel)]="end_Date" readonly>
                    <div class="decoration-none input-group-text dateClass">
                      <mat-datepicker-toggle class="pointer material-icons-outlined pointerv form-control-clear"
                        matSuffix [for]="picker2"></mat-datepicker-toggle>
                    </div>
                    <mat-datepicker #picker2></mat-datepicker>
                  </div>
                  <div *ngIf="submitted && f['end_date'].errors" class="text-danger">
                    <div *ngIf="f['end_date'].errors">End Date is required</div>
                  </div>
                </div>
              </div>




              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label>End Date<span class="text-danger">*</span></label>
                  <mat-form-field class="form-control" floatLabel=never
                    [ngClass]="{'is-invalid': submitted && f['end_date'].errors }">

                    <input autocomplete="off" (click)="checkPlaceHolder1()" (blur)="checkPlaceHolder1()" matInput
                      placeholder="{{myplaceHolder1}}" (click)="picker2.open()" matInput [matDatepicker]="picker2"
                      [min]="maxdate" formControlName="end_date" (ngModelChange)="maxdate = $event"
                      [(ngModel)]="end_Date" readonly>

                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="submitted && f['end_date'].errors" class="text-danger">
                    <div *ngIf="f['end_date'].errors">End date is required</div>
                  </div>
                </div>
              </div> -->



              <div class="col-md-5">
                <div class="form-group">
                  <label>Pickup Time<span class="mandatory">*</span></label>
                  <!-- <div class="input-group-append">
                    <input [ngxTimepicker]="picker" [format]=24 placeholder="Select Time" formControlName="pickup_time"
                      class="form-control" [(ngModel)]="pickup_Time"
                      [ngClass]="{'is-invalid': submitted && f['pickup_time'].errors }">

                    <div class="decoration-none input-group-text timeClass">
                      <ngx-material-timepicker-toggle [for]="picker"></ngx-material-timepicker-toggle>
                    </div>
                  </div>
                  <ngx-material-timepicker [enableKeyboardInput]=true #picker></ngx-material-timepicker> -->
                  
                  <!-- <material-timepicker color="primary" label="Hour" [userTime]="exportTime" (change)="onChangeHour($event)" revertLabel="Remove" submitLabel="Ok"></material-timepicker> -->

                  <!-- <input type="time" formControlName="pickup_time" class="form-control" [(ngModel)]="pickup_Time" [ngClass]="{'is-invalid': submitted && f['pickup_time'].errors }"> -->


                  <!-- ============== last working -->
                  <!-- <div class="ngx-timepicker-field-example">
                    <ngx-timepicker-field [format]="24" formControlName="pickup_time" [(ngModel)]="pickup_Time" [defaultTime]="'00:00'" [ngClass]="{'is-invalid': submitted && f['pickup_time'].errors }"></ngx-timepicker-field>
                   </div>
                  <div *ngIf="submitted && f['pickup_time'].errors" class="text-danger">
                    <div *ngIf="f['pickup_time'].errors">Pickup Time is required</div>
                  </div> -->
                  <!-- ======== -->
                  <div class="input-group-append col-8 pl-0">
                    <input type="text"   class="d-none">
                  <mat-select class="form-control" formControlName="pickup_hour" placeholder="Hour" [(ngModel)]="pickup_hour">
                     <mat-option value="" *ngFor="let data of hourArray" [value]="data" >{{data}}</mat-option>
                   </mat-select>
                   <span style="vertical-align: middle;" class="pt-1 px-2">:</span>
                   <mat-select class="form-control" formControlName="pickup_min" placeholder="Minute" [(ngModel)]="pickup_min">
                     <mat-option value="" *ngFor="let data of minutesArray" [value]="data" >{{data}}</mat-option>
                   </mat-select>
                 </div>
                </div>
              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Vehicle Type<span class="mandatory">*</span></label>
                  <div class="input-group-append">


                    <mat-select [ngClass]="{ 'is-invalid': submitted && f['Vehicle_model'].errors }"
                      (selectionChange)="rentaltypeDropdown($event.value)" [(ngModel)]="Vehicle_Model"
                      formControlName="Vehicle_model" class="form-control" placeholder="Please Select Vehicle"
                      #vehiclemodel>
                      <mat-select-filter *ngIf="vehiclemodel.focused" [placeholder]="'Search'"
                        [displayMember]="'vehicle_model'" [array]="vehicle" (filteredReturn)="vehiclelist = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let type of vehiclelist" [value]="type.vehicle_model_id">
                        {{type.vehicle_model}}
                      </mat-option>

                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="Vehicle_Model">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="vehicleclose(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['Vehicle_model'].errors" class="text-danger">
                    <div *ngIf="f['Vehicle_model'].errors">Vehicle Model is required</div>
                  </div>
                </div>
              </div>



              <div class="col-md-5">
                <div class="form-group">
                  <label>Rental Type<span class="mandatory">*</span></label>
                  <!-- <mat-select-trigger *ngIf="rental_Type" style=" position: absolute;padding: 12% 72%;">
                    <mat-icon class="icon4" (click)="rental_Type=''; $event.stopPropagation()" >close</mat-icon>
                  </mat-select-trigger> -->
                  <div class="input-group-append">
                    <mat-select [ngClass]="{ 'is-invalid': submitted && f['rental_type'].errors }"
                      formControlName="rental_type" class="form-control" [(ngModel)]="rental_Type"
                      placeholder="Please Select rental type" #rentaltype>
                      <mat-select-filter *ngIf="rentaltype.focused" [placeholder]="'Search'"
                        [displayMember]="'rental_name'" [array]="rental" (filteredReturn)="rentallist = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let rentype of rentallist" [value]="rentype.rental_id">
                        {{rentype.rental_name}}
                      </mat-option>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="rental_Type">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="rental_Type=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['rental_type'].errors" class="text-danger">
                    <div *ngIf="f['rental_type'].errors">Rental Type is required</div>
                  </div>
                </div>
              </div>



              <div class="col-md-5">
                <div class="form-group">
                  <label>Payment mode<span class="mandatory">*</span></label>
                  <input type="text" maxlength="50" formControlName="paymode_default" class="form-control"
                    [ngClass]="{'is-invalid': submitted && f['paymode_default'].errors }" readonly>
                  <!-- <mat-select-trigger *ngIf="paymode_Default" style=" position: absolute;padding: 12% 72%;">
                    <mat-icon class="icon5" (click)="paymode_Default=''; $event.stopPropagation()" >close</mat-icon>
                  </mat-select-trigger> -->
                  <!-- <div class="input-group-append">
                    <mat-select (ngModelChange)="customer_Group" [(ngModel)]="paymode_Default"
                      [ngClass]="{ 'is-invalid': submitted && f['paymode_default'].errors }"
                      formControlName="paymode_default" class="form-control" placeholder="Please Paymode Default"
                      #paymodebill>
                      <mat-select-filter *ngIf="paymodebill.focused" [placeholder]="'Search'"
                        [displayMember]="'payment_name'" [array]="payment" (filteredReturn)="paymentmode = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let pay of paymentmode" [value]="pay.payment_type_id">
                        {{pay.payment_name}}
                      </mat-option>

                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="paymode_Default">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="paymode_Default=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div> -->
                  <div *ngIf="submitted && f['paymode_default'].errors" class="text-danger">
                    <div *ngIf="f['paymode_default'].errors">Paymode mode is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Booking source<span class="mandatory">*</span></label>
                  <input type="text" maxlength="50" formControlName="booking_source" class="form-control"
                    [ngClass]="{'is-invalid': submitted && f['booking_source'].errors }" readonly>
                  <!-- <div class="input-group-append">
                    <mat-select [ngClass]="{ 'is-invalid': submitted && f['booking_source'].errors }"
                      formControlName="booking_source" [(ngModel)]="booking_source" class="form-control"
                      placeholder="Enter Booking Source">

                      <mat-option value="CBS">
                        CBS
                      </mat-option>

                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="booking_source">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="booking_source=''; $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['booking_source'].errors" class="text-danger">
                    <div *ngIf="f['booking_source'].errors">Booking Source is required</div>
                  </div> -->
                </div>
              </div>


            </div><br>

            
            <!-- </div> -->

          </form><br>

          <div class="row">
            <div class="col-md-12">
              <h2 class="form-heading">Guest Details</h2>
            </div>
          </div><br>

          <div class="row justify-content-between mx-4" *ngFor="let childval of childForm; let j = index; let last = last;">
            <div class="col-md-5">
              <div class="form-group drop">
                <label>Prefix<span class="mandatory">*</span></label>
                <div class="input-group-append">


                  <mat-select name="prefix{{j}}" [(ngModel)]="childval.prefix" class="form-control"
                    placeholder="Please Select Prefix" (selectionChange)="test(j)">
                    <mat-option *ngFor="let pre of prefix" [value]="pre.prefix"> {{pre.prefix}} </mat-option>
                  </mat-select>


                  <a class="decoration-none input-group-text closeicon" *ngIf="childval.prefix">
                    <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                      (click)="childval.prefix=''; $event.stopPropagation();test(j)">close</mat-icon>
                  </a>
                </div>
                <div *ngIf="submitted && prefix_ar[j]" class="text-danger">
                  <div *ngIf="prefix_ar[j]">Prefix is required</div>
                </div>
              </div>
            </div>


            <div class="col-md-5">
              <div class="form-group">
                <label>Guest Name<span class="mandatory">*</span></label>
                <input type="text" name="guest_name{{j}}" maxlength="50" [(ngModel)]="childval.guest_name"
                  class="form-control" (keypress)="test(j)" placeholder="Enter Guest Name">
                <div *ngIf="submitted && guest_name_ar[j]" class="text-danger">
                  <div *ngIf="guest_name_ar[j]">Guest Name is required</div>
                </div>
              </div>

            </div>


            <div class="col-md-5">
              <div class="form-group">
                <label>Guest Emp ID</label>
                <input type="text" name="guest_Employeeid{{j}}" maxlength="50" [(ngModel)]="childval.guest_Employeeid"
                  class="form-control" placeholder="Enter Guest Employee">

              </div>

            </div>

            <div class="col-md-5">
              <div class="form-group">
                <div class="form-group">
                  <label>Guest Mobile<span class="mandatory">*</span></label>
                  <input type="text" maxlength="13" [(ngModel)]="childval.guest_mobile" class="form-control"
                    name="guest_mobile{{j}}" (keyup)="test(j)"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" ondragstart="return false;"
                    ondrop="return false;" placeholder="Enter Guest Mobile">

                  <div *ngIf="submitted && guest_mobile_ar[j]" class="text-danger">
                    <div *ngIf="guest_mobile_ar[j]">Guest Mobile is required</div>
                  </div>
                </div>


              </div>

            </div>

            <div class="col-md-5">
              <div class="form-group">
                <label>Guest Mail ID</label>
                <input type="text" maxlength="50" [(ngModel)]="childval.guest_Email" class="form-control"
                  name="guest_Email{{j}}" (keyup)="test(j)" placeholder="Enter Guest Mail">

                <div *ngIf="guest_email_ar[j]" class="text-danger">
                  <div *ngIf="guest_email_ar[j]">Invaild Email</div>
                </div>
              </div>

            </div>


            <div class="col-md-5 mt-2">
              <div class="mt-4">
                <button type="button" title="Remove Guest" (click)="removeForm(j)" *ngIf="childForm.length > 1"
                  class="btn btn-danger mr-2"><i class="fa fa-minus" aria-hidden="true"></i> </button>

                <button type="button" title="Add More Guests" *ngIf="last && childForm.length < 4"
                  [disabled]="!childval.prefix || !childval.guest_mobile || !childval.guest_name"
                  (click)="addQuantity()" class="btn btn-success pull-right"><i class="fa fa-plus"
                    aria-hidden="true"></i> </button>

              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <br>

          <div class="row justify-content-between mx-4">
            <div class="col-md-5">
              <div class="form-group">
                <label>Pickup Address<span class="mandatory">*</span></label>
                <!-- <input type="text" maxlength="50" (keyup)="func_pickup_address()"  [(ngModel)]="pickup_address" class="form-control"
                    placeholder="Enter Pickup Address" 
                    > -->
                <input ngx-google-places-autocomplete [options]='options' (onAddressChange)="AddressChange($event)"
                  type="text" [(ngModel)]="pickup_address" class="form-control" placeholder="Enter Pickup  Address">
                <div *ngIf="submitted && f['pickup_address'].errors" class="text-danger">
                  <div *ngIf="f['pickup_address'].errors">Pickup Address is required</div>
                </div>

              </div>

            </div>

            <div class="col-md-5">
              <div class="form-group">
                <label>Drop Address</label>
                <!-- <input type="text" maxlength="50"   [(ngModel)]="drop_address" class="form-control"
                    placeholder="Enter drop address"> -->
                <input ngx-google-places-autocomplete [options]='options' (onAddressChange)="AddressChange1($event)"
                  type="text" maxlength="50" [(ngModel)]="drop_address" class="form-control"
                  placeholder="Enter Drop Address">

              </div>

            </div>

            <div class="col-md-5">
              <div class="form-group">
                <label>Spl Instructions</label>
                <input type="text" maxlength="200" [(ngModel)]="spl_instruction" class="form-control"
                  placeholder="Enter spl instructions">

              </div>

            </div>

            <div class="col-md-5">
              <div class="form-group">
                <label>Flight Details</label>
                <input type="text" maxlength="50" [(ngModel)]="flight_details" class="form-control"
                  placeholder="Enter Flight details">
              </div>
            </div>

          </div><br>

          <h2 class="form-heading">Coordinator Details</h2><br>
            <div class="row justify-content-between mx-4">
              <div class="col-md-5">
                <div class="form-group">
                  <label>Coordinator Name</label>
                  <input type="text" maxlength="50"
                    (keypress)="general.removingSpaceFirst($event,coordinate_name.value)"
                    class="form-control" [(ngModel)]="coordinate_name"
                    placeholder="Enter Coordinator name">

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Coordinator Mobile</label>
                  <input type="text" maxlength="13" class="form-control"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" ondragstart="return false;"
                    ondrop="return false;" [(ngModel)]="coordinate_mobile" placeholder="Enter Coordinator mobile">

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Project Id</label>
                  <input type="text" maxlength="50" class="form-control"
                    placeholder="Enter Project id" [(ngModel)]="project_id" [ngClass]="project_id">

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Request Id</label>
                  <input type="text" maxlength="50" class="form-control"
                    placeholder="Enter Request id" [(ngModel)]="request_id">

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Comments</label>
                  <!-- <input type="textarea" maxlength="50" formControlName="comments" class="form-control" [ngModel]="Comments"
                    placeholder="Enter Guest Employee"> -->
                  <textarea maxlength="50" class="form-control" [(ngModel)]="comments"
                    placeholder="Enter comments"></textarea>

                </div>

              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>CC Mail IDs</label>
                  <input type="email" maxlength="100" class="form-control"
                    placeholder="Enter mail id" [(ngModel)]="mail_id">
                  <div *ngIf="submitted && f['mail_id'].errors" class="text-danger">
                    <div *ngIf="f['mail_id'].errors">Some CC Mail IDs Invaild</div>
                  </div>

                </div>

              </div>



            </div>

          <div class="aligh-button-right">
            <!-- <button mat-button class="mat-button-approve" [disabled]="isDisableBtn" *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="update()">Update</button> -->
            <button mat-button class="mat-button-cancel" [disabled]="isDisableBtn"
              *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="logdata()">Logs</button>
            <button routerLink="/webbooking" *ngIf="type == 'add' && helperService.checkMenuAccess('r_create')"
              class="mat-button-cancel">Cancel</button>

            <button routerLink="/webbooking" *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')"
              class="mat-button-cancel">Close</button>

            <button mat-button class="mat-button-submit" *ngIf="type == 'add'" (click)="submit()">Submit</button>
            <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
              *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="update()">Update</button>


          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #secondDialog>
    <div class="d-flex justify-content-between custom-dialog-height">
      <label style="color: black; font-weight: bold !important;">Booking Log</label>
      <mat-icon (click)="closeDialog()" class="pointer">close</mat-icon>
    </div>
    <div class="table" style="overflow-x: auto;max-height: 350px;overflow-y: scroll;">
      <table id="customers" style="border: 1px solid black;border-collapse: collapse; width: 100%;">
        <tr style="height: 10%;">
          <th>#</th>
          <!-- <th>Employee Id</th> -->
          <th style="width: 30%;">Guest Name</th>
          <th style="width: 70%;">Pickup Address</th>
          <th>Guest Mobile</th>
          <th style="width: 30%;">Guest Mail Id</th>

        </tr>
        <tr *ngFor="let data of datalist; let i = index" class="pointer" (click)="viewdata(data)">
          <td>{{i+1}}</td>
          <!-- <td>{{data.employee_id}}</td> -->
          <td style="font-size: 14px;word-break: break-all !important;
          white-space: pre-wrap !important;">{{data.guest_name}}</td>
          <td style="font-size: 14px;word-break: break-word !important;
          white-space: pre-wrap !important;">{{data.pickup_address}}</td>
          <td style="font-size: 14px;">{{data.guest_mobile}}</td>
          <td style="font-size: 14px;word-break: break-all !important;
          white-space: pre-wrap !important;">{{data.guest_mail_id}}</td>
        </tr>
      </table>
      <div *ngIf="this.datalist.length === 0" style="text-align: center">
        No records found
      </div>
    </div>
  </ng-template>

  <ng-template #fourthDialog>
    <div class="d-flex justify-content-between custom-dialog-height">
      <label style="color: black; font-weight: bold !important;">Web Booking Logs</label>
      <mat-icon (click)="closeDialog()" class="pointer">close</mat-icon>
    </div>
    <div class="table" style="overflow-x: auto;max-height: 350px;overflow-y: scroll;">
      <table id="customers" style="border: 1px solid black;border-collapse: collapse; width: 100%;">
        <tr style="height: 10%;">
          <th>sl.no</th>

          <th style="width: 30%;">User Name</th>
          <th style="width: 70%;">Date and Time</th>
          <th>Action</th>


        </tr>
        <tr *ngFor="let data of loglist; let i = index" class="pointer" (click)="viewdatalog(data)">
          <td style="font-size: 14px;word-break: break-all !important;
          white-space: pre-wrap !important;">{{i+1}}</td>
          <td style="font-size: 14px;word-break: break-all !important;
          white-space: pre-wrap !important;">{{data.user_name}}</td>
          <td style="font-size: 14px;word-break: break-word !important;
          white-space: pre-wrap !important;">{{data.date_time | date: 'dd-MM-yyyy h:mm a'}}</td>
          <td style="font-size: 14px;">{{data.action}}</td>

        </tr>
      </table>
      <div *ngIf="this.loglist?.length === 0" style="text-align: center">
        No records found
      </div>
    </div>
  </ng-template>

</section>
</div>


<ng-template #thirdDialog>
  <mat-icon class="close-button" [mat-dialog-close]="false" style="float: right;">close</mat-icon>
  <i class="fa-solid fa-check" style="font-size: 37px;color: #3f9b3f;margin-left:46%;    margin-left: 47%;
  padding-top: 10px;"></i>
  <p style="margin-left:35%;"><b>Confirmation Successfully</b></p>
  <h2 matDialogTitle>Booking request accepted and booking confirmation details will be sent shortly</h2>
  <mat-dialog-content>
    <input type="button" class="mat-button-submit" (click)="deleteCustomer()" value="ok" [mat-dialog-close]="false"
      style="float: right;padding: 0px 17px;" />
    <!-- <button class="mat-button-cancel" (click)="deleteCustomer()"> confirm</button> -->
  </mat-dialog-content>
  <mat-dialog-actions>

  </mat-dialog-actions>
</ng-template>