import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  passwordPattern = "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[0-9A-Za-zd$@$!%*?&].{7,}";
  numberPattern = "^[0-9]*$";
  datePattern = "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$";
  // numberDecimalPattern = "^[0-9]+(\.[0-9]{1,2})?$";
  numberDecimalPattern = "^([1-9][0-9]*)+(\.[0-9]{1,2})?$";

  alphabetsPattern = "^[a-zA-Z ]*$";
  alphanumbericPattern = "^[a-zA-Z0-9]*$";
  phoneNumberPattern = "^\\+?(?:[0-9] ?){5,15}[0-9]$";
  ipAddressPattern = "^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  
  constructor() { }
  removingSpace(event) {
    if (event.which === 32) {
      event.preventDefault();
    }
  }
  removingSpaceFirst(event, val, number?: any) {
    if (event.which === 32 && !val.length) {
      event.preventDefault();
    }
  }
  checkError(val, val2, pattern) {
    if (val.errors.hasOwnProperty("required")) {
      return val2 + " is required";
    } else if (val.errors.hasOwnProperty("email")) {
      return "Please enter a valid email address";
    } else if (val.errors.pattern) {
      if (pattern == "specialChar") {
        return "Special characters not allowed";
      } else if (pattern == "password") {
        return "Password must contain atleast one uppercase, one lowercase, one numeric digit, one special character, 8 characters";
      } else if (pattern == "cPassword") {
        return "Password Mismatch";
      } else if (pattern == "cPin") {
        return "Pin Mismatch";
      } else if (pattern == "number") {
        return val2 + " should be numerical";
      }
      else if(pattern=="numberWitOutZero"){
        return val2 + " should not start with zero";
      } 
      
      else if (pattern == "numberDecimal") {
        return val2 + " should be numerical with two decimal place";
      } else if (pattern == "alphabets") {
        return val2 + " should be alphabets";
      } else if (pattern == "phoneNumber") {
        return val2 + " should be number, minlength should be 10 & maxlength should be 13";
      }
      else if (pattern == "ipAddress") {
        return val2 + " should be a valid IP Address";
      }
     
    } else if (val.errors.minlength) {
      return "Minimum length is " + val.errors.minlength.requiredLength + " characters";
    } else if (val.errors.maxlength) {
      return "Maximum length is " + val.errors.maxlength.requiredLength + " characters";
    } else if (val.errors.whitespace) {
      return "White Space ";
    }
  }
}
