<div class="mx-4">
  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span>Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/managecustomerguest">Manage Customer Guest</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">View</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-customerguest.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">View Customer Guest</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/managecustomerguest"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="container-fluid">
            <section>
              <h2 class="form-heading">Customer</h2>
              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Group </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Customer_Group_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Customer_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Employee Location</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Employee_Location_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>


              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Employee Name</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Employee_Name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Employee Mobile Number</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Employee_Mobile_Number}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Employee Email ID</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Employee_Email_ID}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Employee Id</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Employee_Id}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer Admin </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Customer_Admin}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0" *ngIf="creditlimit">
                <label for="" class="col-md-3 col-form-label view-label">Credit Limit </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Credit_Limit}}</p>
                  </div>
                </div>
              </div>
              <mat-divider *ngIf="creditlimit"></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Vehicle</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Vehicle_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Rental Type</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{customerguestresp?.Rental_Type_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Location</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{locationArr}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Direct Booking </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{(direct_booking == "Direct") ? "Not Required" : "Required" }}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>


              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Status </label>
                <div class="col-sm-4">
                  <div class="input-group mb-2 mr-sm-2">
                    <mat-slide-toggle class="status" disabled [checked]="customerguestresp?.status === 'ACTIVE'"
                      formControlName="status">
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <br>

              <div class="form-group row mb-0">

                <div class="col-md-12">
                  <div class="input-group mb-2 mr-sm-2">
                    <section class="example-section">
                      <mat-checkbox formControlName='smssent' disabled
                        [checked]="customerguestresp.smssent == 1"></mat-checkbox> &nbsp;
                      <label><b>Tick to send sms to Customer Admin on raising booking request</b></label>
                    </section>
                  </div>
                </div>
              </div>
              


              <div class="aligh-button-right">
                <button routerLink="/managecustomerguest" class="mat-button-cancel">Cancel</button>
                <button (click)="edit(customerguestresp.id)" *ngIf="helperService.checkMenuAccess('r_update')"
                  mat-button class="mat-button-submit">Edit</button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card -->
  </section>
</div>
<!-- /.content -->

<!-- /.card -->

<!-- /.content -->