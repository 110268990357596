<div class="mx-4">
  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">Invoice Page</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/contractinvoice.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Contract Invoice</h4>
            </div>
          </div>
        </div>
        <!-- <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/webbooking"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div> -->
      </div>
    </div>
  </section>


  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">

          <form [formGroup]="searchform">
            <div class="row">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Location</label>
                    <div class="input-group-append">
                      <mat-select formControlName="base_location" class="form-control" placeholder="Please Location"
                        (selectionChange)="getcustomersgroup($event.value)" #loca>
                        <mat-select-filter *ngIf="loca.focused" [placeholder]="'Search'"
                          [displayMember]="'location_name'" [array]="getlocation"
                          (filteredReturn)="filteredListLocation = $event"></mat-select-filter>
                        <mat-option *ngFor="let location of filteredListLocation" [value]="location.location_id">
                          {{location.location_name}}
                        </mat-option>
                      </mat-select>

                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label style="white-space:nowrap; text-overflow: ellipsis;">Customer Group</label>
                    <div class="input-group-append">
                      <mat-select formControlName="customer_group" class="form-control"
                        placeholder="Please Customer Group" (selectionChange)="locationChanged($event.value)" #cusgroup>
                        <!-- <mat-select-filter [placeholder]="'Search'"
                      [displayMember]="'branch_name'"></mat-select-filter> -->
                        <mat-select-filter *ngIf="cusgroup.focused" [placeholder]="'Search'"
                          [displayMember]="'customer_group_name'" [array]="getcustomergroupdata"
                          (filteredReturn)="filteredListGroups = $event"></mat-select-filter>
                        <mat-option *ngFor="let group of filteredListGroups" [value]="group.customer_group_id">
                          {{group.customer_group_name}}
                        </mat-option>
                      </mat-select>

                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Customer</label>
                    <div class="input-group-append">
                      <mat-select formControlName="customer" class="form-control" placeholder="Please Customer"
                        #customer>
                        <mat-select-filter *ngIf="customer.focused" [placeholder]="'Search'"
                          [displayMember]="'customer_name'" [array]="filteredCompanyName"
                          (filteredReturn)="filterlist4 = $event"></mat-select-filter>
                        <mat-option *ngFor="let company of filterlist4" [value]="company.customers_id">
                          {{company.customer_name}}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Invoice Type</label>
                    <div class="input-group-append">
                      <mat-select formControlName="invoice_type" class="form-control" placeholder="Please Invoice Type">
                        <mat-option value="generated">Generated</mat-option>
                        <mat-option value="contract">Contract</mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Month</label>
                    <div class="input-group-append">
                      <mat-select formControlName="month" class="form-control" placeholder="Please month" #month>
                        <!-- <mat-select-filter  [placeholder]="'Search'"
                      [displayMember]="'branch_name'"></mat-select-filter> -->

                        <mat-select-filter *ngIf="month.focused" [placeholder]="'Search'" [displayMember]="'month'"
                          [array]="monthlist" (filteredReturn)="filterlist = $event"></mat-select-filter>

                        <mat-option *ngFor="let months of filterlist" [value]="months.month_id">
                          {{months.month}}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Year
                    </label>
                    <mat-select (selectionChange)="yearvalue($event.value)" formControlName="invoice_year"
                      class="form-control" *ngIf="display" name="selectedYear" placeholder="Select Year">
                      <mat-option value="{{item}}" *ngFor="let item of range">
                        {{item}}
                      </mat-option>
                    </mat-select>

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-auto">
                  <div class="aligh-button">

                    <button mat-button class="mat-button-submit" style="margin-left: 0px !important;"
                      (click)="submit()">Search</button>
                    <button class="btn btn-default" style="margin-left: 10px; " (click)="clear()">Reset</button>
                  </div>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <!-- <div class="col-md-12"> -->
          <div class="row justify-content-between">
            <div class="col-md-6">
              <h4 style="font-weight:600; color: #233771;">Booking Details</h4>
            </div>
            <div class="col-md-6">
              <div class="row justify-content-end">
                <!-- <div class="col-md-7">                    
                  </div> -->

                <div class="col-md-12 d-flex justify-content-end">
                  <mat-icon *ngIf="helperService.checkMenuAccess('r_download')"
                    (click)="exportIt('xlsx','Contract_Invoice', 'Contract_Invoice_data','Forza')" title="Excel"
                    type="button" class="mat-button-submit c-btn icon-img"
                    style="background-image:url('assets/images/XLS.png');"></mat-icon>
                  <mat-icon *ngIf="custrole" title="Add" type="button" (click)="addguestbooking()"
                    class="text-right plus mat-button-submit c-btn icon-img"
                    style="background-image:url('assets/images/Add.png');"></mat-icon>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->


          <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
            <table [border]="1" [hidden]="isLoading" style="text-align: center;" matTableExporter mat-table
              [dataSource]="dataSource" class="table-striped pointer" #exporter="matTableExporter">


              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>sl.no</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{paginator.pageIndex == 0 ? i + 1 : 1 + i + paginator.pageIndex * paginator.pageSize}}
              </ng-container>


              <!-- Name Column -->
              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef> Location
                </th>
                <td mat-cell *matCellDef="let element"> {{element.location_Name}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="customer_group">
                <th mat-header-cell *matHeaderCellDef>Customer Group
                </th>
                <td mat-cell *matCellDef="let element"> {{element.customer_Group_name}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="customer">
                <th mat-header-cell *matHeaderCellDef> Customer
                </th>
                <td mat-cell *matCellDef="let element"> {{element.customer_Name_array}} </td>
              </ng-container>
              <ng-container matColumnDef="invoice_type">
                <th mat-header-cell *matHeaderCellDef> Invoice Type
                </th>
                <td mat-cell *matCellDef="let element"> {{element.invoice_type}} </td>
              </ng-container>
              <ng-container matColumnDef="month">
                <th mat-header-cell *matHeaderCellDef>Month
                </th>
                <td mat-cell *matCellDef="let element"> {{element.invoice_month}} </td>
              </ng-container>
              <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef>Comments
                </th>
                <td mat-cell *matCellDef="let element"> {{element.invoice_comments }} </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> ACTION </th>
                <td class="edit" mat-cell *matCellDef="let element">
                  <button mat-icon-button [matMenuTriggerFor]="menu" class="action"
                    aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="view(element.id)">
                      <mat-icon role="img"
                        class="mat-icon notranslate material-icons eye align-middle pointer hover-color"
                        aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon>View
                    </button>
                    <button mat-menu-item *ngIf="custrole" (click)="edit(element.id)">
                      <mat-icon role="img"
                        class="mat-icon notranslate material-icons edit align-middle pointer hover-color"
                        aria-hidden="true" data-mat-icon-type="font">edit</mat-icon>Edit
                    </button>
                    <button mat-menu-item *ngIf="helperService.checkMenuAccess('r_delete')"
                      (click)="deleteInvoice(element.id)">
                      <mat-icon class="delete align-middle pointer hover-color">delete</mat-icon>Delete
                    </button>
                    <!-- <button mat-menu-item *ngIf="helperService.checkMenuAccess('r_delete')" (click)="delete(element.id)">
                    <mat-icon class="delete align-middle pointer hover-color">delete</mat-icon>Delete
                  </button> -->
                  </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



            </table>
            <div *ngIf="this.dataSource.filteredData.length === 0" style="text-align: center">
              No records found
            </div>

            <custom-paginator [datalength]="dataSourceLength" [hidden]="!(dataSource.filteredData.length != 0)"
              [hidden]="isLoading" [pageSizeOptions]="[10,25,50]" justify-content="space-between"></custom-paginator>
          </div>
          <div class="col-md-12 mt-2 table" [hidden]="!isLoading">

            <table class="norecordfoundtable">
              <tr>
                <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}</th>
              </tr>
              <tr>
                <td style="text-align: center;" [attr.colspan]="toppingList.length">
                  {{'Loading.......'}}
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>