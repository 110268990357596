
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CustomerDirectguestService } from '@services/customer-directguest.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-view-directguest',
  templateUrl: './view-directguest.component.html',
  styleUrls: ['./view-directguest.component.scss']
})
export class ViewDirectguestComponent implements OnInit {
  //  directguest !: FormGroup;
  // submitted = false
  id:any
  customeradminresp:any
  public formType:any
  constructor(private router: Router, private location: Location,  public activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    private customerDirectguestService: CustomerDirectguestService, public toastr: ToastrService,
    public helperService: HelperService,) { }

  ngOnInit(): void {
    this.id = atob(this.activatedRoute.snapshot.url[1].path);
       this.helperService.pageAccess('r_read')
       this.getdirectguestbyId()
 

  }
  getdirectguestbyId = () => {
    var idarray = {};
    idarray['id'] = this.id;
    this.customerDirectguestService.getdirectguestbyId(idarray).subscribe(
        (response) => {
          let responses :any = response;
            if (responses.success == true) {
                this.customeradminresp = responses.result
                
            }
           
        },
        (error) => {
            this.toastr.error(error, 'Error');
        }
    );
};
  back(){
    this.location.back();
  }
  edit(id) {
    
    this.router.navigate(['/managedirectguest', btoa(id),'edit']);
}


}
