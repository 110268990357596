import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '@services/customeradmin.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerDirectguestService } from '@services/customer-directguest.service';
import { HelperService } from '@services/helper.service';
import { GeneralService } from '@services/general.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Managecustomer } from '@services/managecustomerguest.service';

@Component({
  selector: 'app-add-managedirectguest',
  templateUrl: './add-managedirectguest.component.html',
  styleUrls: ['./add-managedirectguest.component.scss']
})
export class AddManagedirectguestComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  allSelected=false;
  directguest !: FormGroup;
  i:any
  submitted = false
  respo: any;
  branches: any;
  buttonText:string='Submit'
  status: any;
  directguestresp: any
  public id: string;
  public type: string = 'add'
  customeradminresp: any;
  emailexist :boolean;
  mobexist :boolean;
  resposubmit:any;
  Guest_Mobile_Number:any;
  Guest_Email: any;
  Guest_Name:any;
  Refered_By:any;
  Location:any;
  Rental_City: any;
  Credit_Limit: any;
  old_email:any
  cstatus: string = 'ACTIVE'
  lessthanten = false
  lessthanten1 = false
  public isDisableBtn: boolean = false;
  public filtersearch:[];
  public filtersearch1:[];
  dataloc:any;
  userId: any;
  forzaadmin:any =[];
  public msg :any;
  public loglist: [];
  searchMobData: any = [];
  user_name:any;
  date_time:any;
  customer_Name: any;

  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;
 
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private appService: AppService,
    private toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    public helperService: HelperService,
    public customerDirectguestService: CustomerDirectguestService,
    public general:GeneralService,
    public dialog: MatDialog,
    private managecustomer: Managecustomer,

  ) { }

  ngOnInit(): void {
    if(this?.activatedRoute?.snapshot?.url[1]?.path){
      this.id = atob(this.activatedRoute.snapshot.url[1].path);
      if(this.activatedRoute.snapshot.url[1].path == 'add'){
        this.type == 'add'
       this.helperService.pageAccess('r_create')
      }else{
        this.type = this.activatedRoute.snapshot.url[2].path;
        this.directGuestId();
      }

  }
  this.forzaadmin =localStorage.getItem('customer_Role_name');
  this.userId = Number(localStorage.getItem('Id'));
  
    this.getBranches()
    this.directguest = this.formBuilder.group({
      Guest_Name: [null, Validators.required],
      Guest_Mobile_Number: ['', [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.minLength(10), Validators.maxLength(13)]],
      Guest_Email: [null, ([Validators.required, Validators.pattern(/^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i)])],
      Refered_By: [null, Validators.required],
      Location: [null, Validators.required],
      Rental_City: [null, Validators.required],
      Credit_Limit: [null, Validators.required],
      status: true,
    })
  }

  logdata(){
    
    this.getPosts()
    this.dialog.open(this.secondDialog, {
      width: '70%',
    });

  }
  closeDialog() {
    this.dialog.closeAll()
    this.searchMobData = []
  }

  public viewdata(data: any) {
    this.searchMobData = []
    this.dialog.closeAll()
    this.user_name = data.user_name;
    this.date_time = data.date_time; 
    // this.customerDropdown(this.billing_Location)
    this.customer_Name = data.customer;
    this.customer_Name = Number(data.customer)
    // this.rentaldropdown(this.customer_Name)
    let obj = {
      prefix: '',
      guest_name: data.guest_name,
      guest_Employeeid: data.employee_id,
      guest_mobile: data.guest_mobile,
      guest_Email: data.guest_mail_id
    }
    // this.childForm = [];
    // this.childForm.push(obj);
  }

  getPosts() {
    let obj = {
      id: this.id,
      user_id: this.userId,
    }

    this.managecustomer.directLogData(obj).subscribe((res => {
      this.msg = res;
     
      if (this.msg.success == true) {
        this.loglist = this.msg.Log_List;
       
      }
    }))
  }


  directGuestId = () => {
    this.buttonText='Update'

    var idarray = {
      id: this.id,
      user_id: this.userId
      
    };
    idarray['id'] = this.id;
    this.customerDirectguestService.getdirectguestbyId(idarray).subscribe(
        (response) => {
          let responses :any = response;
            if (responses.success == true) {
                this.customeradminresp = responses.result
                this.Guest_Email = this.customeradminresp?.Guest_Email
                this.old_email=this.customeradminresp?.Guest_Email
                this.Guest_Mobile_Number = responses?.result?.Guest_Mobile_Number
                this.Guest_Name = responses?.result?.Guest_Name
                this.Refered_By = responses?.result?.Refered_By
                this.Location =  JSON.parse(responses?.result?.Location)
                this.Credit_Limit = responses?.result?.Credit_Limit
                // this.status = responses?.result?.status
                this.Rental_City = JSON.parse(responses.result.Rental_City)
                this.directguest.get("status").setValue((responses?.result?.status == 'ACTIVE') ? true : false);

            }
        },
        (error) => {
            this.toastr.error(error, 'Error');
        }
    );
};

  get f() {
    return this.directguest.controls
  }
  submit() {
    
    
    this.submitted = true;
    this.directguest.get("status").setValue((this.directguest.value.status == true) ? 'ACTIVE' : 'DEACTIVATED');
    console.log(this.directguest.valid && this.emailexist === true && this.mobexist === true);
    
    if (this.directguest.valid) {
      if(this.type === 'edit'){
        console.log("hitting update");
        
        this.directguest.value.user_type=this.forzaadmin
      this.directguest.value.id = this.id
      this.directguest.value.user_id = this.userId
      this.directguest.value.Rental_City = JSON.stringify(this.directguest.value.Rental_City)
      this.isDisableBtn = true;
      this.customerDirectguestService.updateDirectguest(this.directguest.value).subscribe(
        response => {
          this.resposubmit = response;
          if (this.resposubmit.success == true) {
            this.router.navigate(['/managedirectguest']);
            this.toastr.success(this.resposubmit.message, 'success');
          }else{
            this.toastr.error(this.resposubmit.message, 'Error');
            this.isDisableBtn = false;
          }
        },
        error => {
          this.toastr.error(error, 'Error');
          this.isDisableBtn = false;
        });
      }
    else if (this.directguest.valid && this.emailexist === true && this.mobexist === true){
      this.isDisableBtn = true;
      this.directguest.value.user_type =  this.forzaadmin
      this.directguest.value.user_id = this.userId
      this.directguest.value.Rental_City = JSON.stringify(this.directguest.value.Rental_City)
      this.customerDirectguestService.createdirectguest(this.directguest.value).subscribe(
        response => {
          // this.resposubmit = response;
          let responses :any = response;
          if (responses.success == true) {
            this.router.navigate(['/managedirectguest']);
            this.toastr.success(responses.message, 'success');
          }else{
            this.toastr.error(responses.message, 'Error');
            this.isDisableBtn = false;
          }
        },
        error => {
          this.toastr.error(error, 'Error');
          this.isDisableBtn = false;
        });
    }
  } else {
    return;
}
  }

  getBranches = () => {
    let data = {
      user_id: this.userId
    }
    this.appService.getBranches().subscribe(
      response => {
        this.respo = response
      
        if (this.respo.success == true) {
          this.branches = this.respo.result.filter(item => item.branch_name !== "All Location");
          this.dataloc=this.branches ;
          this.dataloc = this.dataloc.sort((a,b) => {
            if(a.branch_id < b.branch_id) { return -1; }
            if(a.branch_id > b.branch_id) { return 1; }
           
          })
          this.filtersearch =this.dataloc.slice();
          this.filtersearch1 =this.dataloc.slice();
        }
      //  this.filtersearch =this.branches.slice();
      },
      error => {
        this.toastr.error(error, 'Error');
      });
  }
  checkemail = (email) => {

    var value = email
    var emailRegexp = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;

  if(emailRegexp.test(value)){
    if (this.old_email !== this.directguest?.value?.Guest_Email) {
      this.appService.checkdirectguestemail(this.directguest?.value).subscribe(
        response => {
          let responses: any = response;
          if (responses.success == true) {
            this.emailexist = true
          } else {
            this.emailexist = false
          }
        },
        error => {
          this.toastr.error(error, 'Error');
        });
    }
  }




  }


   onkeypress(e){
    this.lessthanten1 = false
    if(e.length < 10){
      this.lessthanten = true
    }else if(e.length == 10){
      this.lessthanten = false
    }
    if(e.length >= 10)    
    {
      this.appService.checkcustomeradminmobile(this.directguest?.value).subscribe(
        response => {
          let responses: any = response;
          if (responses.success == true) {
            this.mobexist = true
          } else {
            this.mobexist = false
          }
        },
        error => {
          this.toastr.error(error, 'Error');
        });
    }
  }

  toggleAllSelection() {

    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }



}
