<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" routerLink="/">Dashboard</li>
            <li class="breadcrumb-item" routerLink="/managebooking">Manage Bookings</li>
            <li class="breadcrumb-item" routerLink="/webbooking">Web Booking</li>
            <li class="breadcrumb-item active">Import</li>
  
          </ol>
        </div>
      </div>
    </div>
  </section>
  
  <section class="content mb-3">
    
    <div class="container-fluid">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h5>Import Web Booking</h5>
          </div>
          <div class="col-sm-6 text-right">
            <h6 class="pointer hover-color float-right" routerLink="/webbooking">Back</h6>
          </div>
        </div>
      </div>
      <mat-card class="mat-card">
          <div class="loader" *ngIf="isLoader"></div>
  
        <div class="form-group" *ngIf="!isLoader">
          <div>
            <div *ngIf="!isShowFile">
              <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
                <div class="text-wrapper">
                  <div class="centered">
                    <label for="upload_excel"><span class="textLink"><i class='fas fa-arrow-down'></i></span>Select your
                      file or
                      Drop it here!</label>
                    <input type="file" class="file_upload"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="upload_excel"
                      (change)="onFileChange($event.target.files)" />
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isShowFile">
  
              <h3 class="file">Files</h3>
              <div *ngFor="let file of files">
                <div class="">
                  <mat-icon mat-list-icon style="position: absolute;">folder</mat-icon>
                  <h4 style="font-size: 15px;margin-left: 30px;padding-top: 5px;">
                    {{file.name}}</h4>
                </div>
                <i class="fas fa-trash" (click)="deleteFile(file)"
                  style="color: #fb8542;  float: right;  margin-top: -23px;"></i>
              </div>
  
            </div>
  
          </div>
        </div>
  
  
        <div class="form-group" *ngIf="excelJSON.length == 0 && !isLoader">
          <mat-divider></mat-divider>
          <button class="btn btn-primary import-btnbtn mt-2" (click)="export()"><span class="download"></span>Sample Excel
            File</button>
        </div>
      </mat-card>
  
      <mat-card class="mat-card mt-2" *ngIf="excelJSON.length > 0">
        <div class="col-md-12 mt-2 table">
          <h5 class="card-header">Available To Import</h5>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 " matSort>
  
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
              </ng-container>
              <ng-container matColumnDef="customer">
                <th mat-header-cell *matHeaderCellDef> Customer</th>
                <td mat-cell *matCellDef="let element"> {{element.customer}} </td>
              </ng-container>
                
              <ng-container matColumnDef="billing_location">
                <th mat-header-cell *matHeaderCellDef> Billing Location</th>
                <td mat-cell *matCellDef="let element"> {{element.billing_location}} </td>
              </ng-container>
    
              <ng-container matColumnDef="rental_city">
                <th mat-header-cell *matHeaderCellDef> Rental City </th>
                <td mat-cell *matCellDef="let element"> {{element.rental_city}} </td>
              </ng-container>
    
              <ng-container matColumnDef="booking_type">
                <th mat-header-cell *matHeaderCellDef> Booking Type</th>
                <td mat-cell *matCellDef="let element"> {{element.booking_type}} </td>
              </ng-container>
              <ng-container matColumnDef="pickup_date">
                <th mat-header-cell *matHeaderCellDef>Pickup Date</th>
                <td mat-cell *matCellDef="let element"> {{element.pickup_date}} </td>
              </ng-container>
              <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef>End Date</th>
                <td mat-cell *matCellDef="let element"> {{element.end_date}} </td>
              </ng-container>
              <ng-container matColumnDef="pickup_time">
                <th mat-header-cell *matHeaderCellDef> Pickup Time</th>
                <td mat-cell *matCellDef="let element"> {{element.pickup_time}} </td>
              </ng-container>
              <ng-container matColumnDef="vehicle_model">
                <th mat-header-cell *matHeaderCellDef>Vehicle Model</th>
                <td mat-cell *matCellDef="let element"> {{element.vehicle_model }} </td>
              </ng-container>
              <ng-container matColumnDef="rental_type">
                <th mat-header-cell *matHeaderCellDef>Rental Type</th>
                <td mat-cell *matCellDef="let element"> {{element.rental_type}} </td>
              </ng-container>
              <ng-container matColumnDef="paymode_default">
                <th mat-header-cell *matHeaderCellDef>Payment Mode</th>
                <td mat-cell *matCellDef="let element"> {{element.paymode_default}} </td>
              </ng-container>
              <ng-container matColumnDef="booking_source">
                <th mat-header-cell *matHeaderCellDef>Booking Source</th>
                <td mat-cell *matCellDef="let element"> {{element.booking_source}} </td>
              </ng-container>
              <ng-container matColumnDef="coordinator_name">
                <th mat-header-cell *matHeaderCellDef>Coordinator Name</th>
                <td mat-cell *matCellDef="let element"> {{element.coordinator_name}} </td>
              </ng-container>
              <ng-container matColumnDef="coordinator_mobile">
                  <th mat-header-cell *matHeaderCellDef>Coordinator Mobile</th>
                  <td mat-cell *matCellDef="let element"> {{element.coordinator_mobile}} </td>
                </ng-container>
                <ng-container matColumnDef="project_id">
                  <th mat-header-cell *matHeaderCellDef>Project Id</th>
                  <td mat-cell *matCellDef="let element"> {{element.project_id}} </td>
                </ng-container>
                <ng-container matColumnDef="request_id">
                  <th mat-header-cell *matHeaderCellDef>Request Id</th>
                  <td mat-cell *matCellDef="let element"> {{element.request_id}} </td>
                </ng-container>
                <ng-container matColumnDef="comments">
                  <th mat-header-cell *matHeaderCellDef>Comments</th>
                  <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
                </ng-container>
                <ng-container matColumnDef="cc_mail">
                  <th mat-header-cell *matHeaderCellDef>CC Mail IDs</th>
                  <td mat-cell *matCellDef="let element"> {{element.cc_mail}} </td>
                </ng-container>
  
                <ng-container matColumnDef="prefix">
                  <th mat-header-cell *matHeaderCellDef>Prefix</th>
                  <td mat-cell *matCellDef="let element"> {{element.prefix}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_name">
                  <th mat-header-cell *matHeaderCellDef>Guest Name</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_name}} </td>
                </ng-container>
                <ng-container matColumnDef="employee_id">
                  <th mat-header-cell *matHeaderCellDef>Guest EmpID</th>
                  <td mat-cell *matCellDef="let element"> {{element.employee_id}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_mobile">
                  <th mat-header-cell *matHeaderCellDef>Guest Mobile</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_mobile}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_mail_id">
                  <th mat-header-cell *matHeaderCellDef>Guest Mail ID</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_mail_id}} </td>
                </ng-container>
  
                <ng-container matColumnDef="prefix1">
                  <th mat-header-cell *matHeaderCellDef>Prefix1</th>
                  <td mat-cell *matCellDef="let element"> {{element.prefix1}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_name1">
                  <th mat-header-cell *matHeaderCellDef>Guest Name1</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_name1}} </td>
                </ng-container>
                <ng-container matColumnDef="employee_id1">
                  <th mat-header-cell *matHeaderCellDef>Guest EmpID1</th>
                  <td mat-cell *matCellDef="let element"> {{element.employee_id1}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_mobile1">
                  <th mat-header-cell *matHeaderCellDef>Guest Mobile1</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_mobile1}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_mail_id1">
                  <th mat-header-cell *matHeaderCellDef>Guest Mail ID1</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_mail_id1}} </td>
                </ng-container>
  
                <ng-container matColumnDef="prefix2">
                  <th mat-header-cell *matHeaderCellDef>Prefix2</th>
                  <td mat-cell *matCellDef="let element"> {{element.prefix2}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_name2">
                  <th mat-header-cell *matHeaderCellDef>Guest Name2</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_name2}} </td>
                </ng-container>
                <ng-container matColumnDef="employee_id2">
                  <th mat-header-cell *matHeaderCellDef>Guest EmpID2</th>
                  <td mat-cell *matCellDef="let element"> {{element.employee_id2}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_mobile2">
                  <th mat-header-cell *matHeaderCellDef>Guest Mobile2</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_mobile2}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_mail_id2">
                  <th mat-header-cell *matHeaderCellDef>Guest Mail ID2</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_mail_id2}} </td>
                </ng-container>
  
                <ng-container matColumnDef="prefix3">
                  <th mat-header-cell *matHeaderCellDef>Prefix3</th>
                  <td mat-cell *matCellDef="let element"> {{element.prefix3}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_name3">
                  <th mat-header-cell *matHeaderCellDef>Guest Name3</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_name3}} </td>
                </ng-container>
                <ng-container matColumnDef="employee_id3">
                  <th mat-header-cell *matHeaderCellDef>Guest EmpID3</th>
                  <td mat-cell *matCellDef="let element"> {{element.employee_id3}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_mobile3">
                  <th mat-header-cell *matHeaderCellDef>Guest Mobile3</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_mobile3}} </td>
                </ng-container>
                <ng-container matColumnDef="guest_mail_id3">
                  <th mat-header-cell *matHeaderCellDef>Guest Mail ID3</th>
                  <td mat-cell *matCellDef="let element"> {{element.guest_mail_id3}} </td>
                </ng-container>
  
                <ng-container matColumnDef="pickup_address">
                  <th mat-header-cell *matHeaderCellDef>Pickup Address</th>
                  <td mat-cell *matCellDef="let element"> {{element.pickup_address}} </td>
                </ng-container>
                <ng-container matColumnDef="drop_address">
                  <th mat-header-cell *matHeaderCellDef>Drop Address</th>
                  <td mat-cell *matCellDef="let element"> {{element.drop_address}} </td>
                </ng-container>
                <ng-container matColumnDef="spl_instructions">
                  <th mat-header-cell *matHeaderCellDef>Spl Instructions</th>
                  <td mat-cell *matCellDef="let element"> {{element.spl_instructions}} </td>
                </ng-container>
                <ng-container matColumnDef="flight_details">
                  <th mat-header-cell *matHeaderCellDef>Flight Details</th>
                  <td mat-cell *matCellDef="let element"> {{element.flight_details}} </td>
                </ng-container>
  
            <!-- <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>status</th>
              <td mat-cell *matCellDef="let element">{{element.status}} </td>
            </ng-container> -->
  
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
          </table>
  
  
          <!-- <mat-paginator [pageSizeOptions]="[5, 10, 30, 50]" [pageSize]="10" ></mat-paginator> -->
  
  
        </div>
  
      </mat-card>
  
      <mat-card class="mat-card mt-2" *ngIf="failexcel.length > 0">
        <div class="col-md-12 mt-2 table">
          <h5 class="card-header">Fail To Import</h5>
          <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8" matSort>
  
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef> # </th>
              <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="message">
              <th mat-header-cell *matHeaderCellDef>Reason</th>
              <td mat-cell *matCellDef="let element"><span
                  class="text-danger">{{element.message}}</span> </td>
            </ng-container>
            <ng-container matColumnDef="customer">
              <th mat-header-cell *matHeaderCellDef> Customer</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.customer" [matTooltipPosition]="'above'"> {{element.customer}}</span></td>
            </ng-container>
              
            <ng-container matColumnDef="billing_location">
              <th mat-header-cell *matHeaderCellDef> Billing Location</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.billing_location" [matTooltipPosition]="'above'"> {{element.billing_location}}</span></td>
            </ng-container>
  
            <ng-container matColumnDef="rental_city">
              <th mat-header-cell *matHeaderCellDef> Rental City </th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.rental_city" [matTooltipPosition]="'above'"> {{element.rental_city}}</span></td>
            </ng-container>
  
            <ng-container matColumnDef="booking_type">
              <th mat-header-cell *matHeaderCellDef> Booking Type</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.booking_type" [matTooltipPosition]="'above'"> {{element.booking_type}}</span></td>
            </ng-container>
            <ng-container matColumnDef="pickup_date">
              <th mat-header-cell *matHeaderCellDef>Pickup Date</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.pickup_date" [matTooltipPosition]="'above'"> {{element.pickup_date}}</span></td>
            </ng-container>
            <ng-container matColumnDef="end_date">
              <th mat-header-cell *matHeaderCellDef>End Date</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.end_date" [matTooltipPosition]="'above'"> {{element.end_date}}</span></td>
            </ng-container>
            <ng-container matColumnDef="pickup_time">
              <th mat-header-cell *matHeaderCellDef> Pickup Time</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.pickup_time" [matTooltipPosition]="'above'"> {{element.pickup_time}}</span></td>
            </ng-container>
            <ng-container matColumnDef="vehicle_model">
              <th mat-header-cell *matHeaderCellDef>Vehicle Model</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.vehicle_model" [matTooltipPosition]="'above'"> {{element.vehicle_model}}</span></td>
            </ng-container>
            <ng-container matColumnDef="rental_type">
              <th mat-header-cell *matHeaderCellDef>Rental Type</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.rental_type" [matTooltipPosition]="'above'"> {{element.rental_type}}</span></td>
            </ng-container>
            <ng-container matColumnDef="paymode_default">
              <th mat-header-cell *matHeaderCellDef>Payment Mode</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.paymode_default" [matTooltipPosition]="'above'"> {{element.paymode_default}}</span></td>
            </ng-container>
            <ng-container matColumnDef="booking_source">
              <th mat-header-cell *matHeaderCellDef>Booking Source</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.booking_source" [matTooltipPosition]="'above'"> {{element.booking_source}}</span></td>
            </ng-container>
            <ng-container matColumnDef="coordinator_name">
              <th mat-header-cell *matHeaderCellDef>Coordinator Name</th>
              <td mat-cell *matCellDef="let element"><span [matTooltip]="element.coordinator_name" [matTooltipPosition]="'above'"> {{element.coordinator_name}}</span></td>
            </ng-container>
            <ng-container matColumnDef="coordinator_mobile">
                <th mat-header-cell *matHeaderCellDef>Coordinator Mobile</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.coordinator_mobile" [matTooltipPosition]="'above'"> {{element.coordinator_mobile}}</span></td>
              </ng-container>
              <ng-container matColumnDef="project_id">
                <th mat-header-cell *matHeaderCellDef>Project Id</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.project_id" [matTooltipPosition]="'above'"> {{element.project_id}}</span></td>
              </ng-container>
              <ng-container matColumnDef="request_id">
                <th mat-header-cell *matHeaderCellDef>Request Id</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.request_id" [matTooltipPosition]="'above'"> {{element.request_id}}</span></td>
              </ng-container>
              <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef>Comments</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.comments" [matTooltipPosition]="'above'"> {{element.comments}}</span></td>
              </ng-container>
              <ng-container matColumnDef="cc_mail">
                <th mat-header-cell *matHeaderCellDef>CC Mail IDs</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.cc_mail" [matTooltipPosition]="'above'"> {{element.cc_mail}}</span></td>
              </ng-container>

              <ng-container matColumnDef="prefix">
                <th mat-header-cell *matHeaderCellDef>Prefix</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.prefix" [matTooltipPosition]="'above'"> {{element.prefix}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_name">
                <th mat-header-cell *matHeaderCellDef>Guest Name</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_name" [matTooltipPosition]="'above'"> {{element.guest_name}}</span></td>
              </ng-container>
              <ng-container matColumnDef="employee_id">
                <th mat-header-cell *matHeaderCellDef>Guest EmpID</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.employee_id" [matTooltipPosition]="'above'"> {{element.employee_id}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_mobile">
                <th mat-header-cell *matHeaderCellDef>Guest Mobile</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_mobile" [matTooltipPosition]="'above'"> {{element.guest_mobile}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_mail_id">
                <th mat-header-cell *matHeaderCellDef>Guest Mail ID</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_mail_id" [matTooltipPosition]="'above'"> {{element.guest_mail_id}}</span></td>
              </ng-container>

              <ng-container matColumnDef="prefix1">
                <th mat-header-cell *matHeaderCellDef>Prefix1</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.prefix1" [matTooltipPosition]="'above'"> {{element.prefix1}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_name1">
                <th mat-header-cell *matHeaderCellDef>Guest Name1</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_name1" [matTooltipPosition]="'above'"> {{element.guest_name1}}</span></td>
              </ng-container>
              <ng-container matColumnDef="employee_id1">
                <th mat-header-cell *matHeaderCellDef>Guest EmpID1</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.employee_id1" [matTooltipPosition]="'above'"> {{element.employee_id1}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_mobile1">
                <th mat-header-cell *matHeaderCellDef>Guest Mobile1</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_mobile1" [matTooltipPosition]="'above'"> {{element.guest_mobile1}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_mail_id1">
                <th mat-header-cell *matHeaderCellDef>Guest Mail ID1</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_mail_id1" [matTooltipPosition]="'above'"> {{element.guest_mail_id1}}</span></td>
              </ng-container>

              <ng-container matColumnDef="prefix2">
                <th mat-header-cell *matHeaderCellDef>Prefix2</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.prefix2" [matTooltipPosition]="'above'"> {{element.prefix2}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_name2">
                <th mat-header-cell *matHeaderCellDef>Guest Name2</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_name2" [matTooltipPosition]="'above'"> {{element.guest_name2}}</span></td>
              </ng-container>
              <ng-container matColumnDef="employee_id2">
                <th mat-header-cell *matHeaderCellDef>Guest EmpID2</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.employee_id2" [matTooltipPosition]="'above'"> {{element.employee_id2}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_mobile2">
                <th mat-header-cell *matHeaderCellDef>Guest Mobile2</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_mobile2" [matTooltipPosition]="'above'"> {{element.guest_mobile2}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_mail_id2">
                <th mat-header-cell *matHeaderCellDef>Guest Mail ID2</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_mail_id2" [matTooltipPosition]="'above'"> {{element.guest_mail_id2}}</span></td>
              </ng-container>

              <ng-container matColumnDef="prefix3">
                <th mat-header-cell *matHeaderCellDef>Prefix3</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.prefix3" [matTooltipPosition]="'above'"> {{element.prefix3}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_name3">
                <th mat-header-cell *matHeaderCellDef>Guest Name3</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_name3" [matTooltipPosition]="'above'"> {{element.guest_name3}}</span></td>
              </ng-container>
              <ng-container matColumnDef="employee_id3">
                <th mat-header-cell *matHeaderCellDef>Guest EmpID3</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.employee_id3" [matTooltipPosition]="'above'"> {{element.employee_id3}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_mobile3">
                <th mat-header-cell *matHeaderCellDef>Guest Mobile3</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_mobile3" [matTooltipPosition]="'above'"> {{element.guest_mobile3}}</span></td>
              </ng-container>
              <ng-container matColumnDef="guest_mail_id3">
                <th mat-header-cell *matHeaderCellDef>Guest Mail ID3</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.guest_mail_id3" [matTooltipPosition]="'above'"> {{element.guest_mail_id3}}</span></td>
              </ng-container>

              <ng-container matColumnDef="pickup_address">
                <th mat-header-cell *matHeaderCellDef>Pickup Address</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.pickup_address" [matTooltipPosition]="'above'"> {{element.pickup_address}}</span></td>
              </ng-container>
              <ng-container matColumnDef="drop_address">
                <th mat-header-cell *matHeaderCellDef>Drop Address</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.drop_address" [matTooltipPosition]="'above'"> {{element.drop_address}}</span></td>
              </ng-container>
              <ng-container matColumnDef="spl_instructions">
                <th mat-header-cell *matHeaderCellDef>Spl Instructions</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.spl_instructions" [matTooltipPosition]="'above'"> {{element.spl_instructions}}</span></td>
              </ng-container>
              <ng-container matColumnDef="flight_details">
                <th mat-header-cell *matHeaderCellDef>Flight Details</th>
                <td mat-cell *matCellDef="let element"><span [matTooltip]="element.flight_details" [matTooltipPosition]="'above'"> {{element.flight_details}}</span></td>
              </ng-container>
              
  
            <!-- <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>status</th>
              <td mat-cell *matCellDef="let element">{{element.status}} </td>
            </ng-container> -->
  
            
  
  
            <tr mat-header-row *matHeaderRowDef="displayedColumnsf"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsf;"></tr>
  
          </table>
  
  
          <!-- <mat-paginator [pageSizeOptions]="[5, 10, 30, 50]" [pageSize]="10" ></mat-paginator> -->
  
  
        </div>
      </mat-card>
  
      <div class="row mt-2" *ngIf="excelJSON.length > 0">
        <div class="col-md-12">
          <button class="buttonload btn btn-primary import-btn" style="float:right;" disabled *ngIf="isLoading"><i
              class="fa fa-spinner fa-spin"></i>Loading </button>
  
          <button class="btn btn-primary import-btn" style="float:right;" (click)="submitimport()"
            *ngIf="!isLoading">Import</button>
        </div>
      </div>
    </div>
  </section>