<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/trip-sheet">Generated Trip Sheet</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">Track Vehicle</span>

      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-gengtripsheet.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Track Vehicle</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/trip-sheet"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <hr>
          <div class="row">
            <div class="col-md-3"><label><b>Vechile No :</b></label></div>
            <div class="col-md-3">
              <p>{{vechile}}</p>
            </div>
            <div class="col-md-3"><label><b>Trip ID :</b></label></div>
            <div class="col-md-3">
              <p>{{trip_id}}</p>{{startLatitude}}
            </div>
          </div>
          <div class="row">
            <!--Google map-->
            <div class="col-md-12">
              <div id="map-container-google-2" class="z-depth-1-half map-container" style="height:750px" *ngIf="isShowMap">
                <iframe *ngIf="isShowMap" [src]='frameUrl | safe' width="100%" height="100%" frameborder="0"
                  allowfullscreen webkitallowfullscreen mozallowfullscreen>
                </iframe>
              </div>
            </div>

            <div class="col-sm-6 text-right mt-3">
              <button class="mat-button-cancel" style="float: left;" (click)="back()">Back</button>
            </div>
            <!--Google Maps-->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>