<div class="mx-4">


  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">Generated Trip Sheet</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-gengtripsheet.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Generated Trip Sheet</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <!-- <div class="container-fluid">
      <div class="row">
        <div class="col-sm-4 ">
          <h5>Generated Trip Sheet</h5>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-3">
              <div class="card biller-section" style="background-color:#91be34">
                <p>Billed</p>
                <span>{{countList.billed}}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card biller-section" style="background-color:#f37862">
                <p>Non-Billed</p>
                <span>{{countList.non_billed}}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card biller-section" style="background-color:#91be34">
                <p>Closed Trip</p>
                <span>{{countList.closed_trip}}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card biller-section" style="background-color:#f37862">
                <p>Non-Closed trip</p>
                <span>{{countList.non_closed_trip}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      <div class="card">
        <div class="card-body">
          <form [formGroup]="searchform">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>From</label>
                  <mat-form-field class="form-control" floatLabel=never>

                    <input autocomplete="off" matInput placeholder="dd-mm-yyyy" (click)="picker.open();reset()" matInput
                      [matDatepicker]="picker" formControlName="from_date" (ngModelChange)="maxdate = $event" readonly>

                    <mat-datepicker-toggle (click)="reset()" matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>To</label>
                  <mat-form-field class="form-control" floatLabel=never>

                    <input autocomplete="off" matInput placeholder="dd-mm-yyyy" (click)="picker1.open()" matInput
                      [matDatepicker]="picker1" [min]="start_date" [(ngModel)]="end_date" formControlName="end_date"
                      readonly>

                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <!-- <input type="date" [min]="maxdate" [max]="CurrentDate" formControlName="end_date" class="form-control"> -->

                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Location</label>
                  <div class="input-group-append">
                    <mat-select formControlName="trip_location" class="form-control"
                      placeholder="Please select Location">
                      <mat-option *ngFor="let location of trip_location_list" [value]="location.location_name">
                        {{location.location_name}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Filter</label>
                  <div class="input-group-append">
                    <mat-select formControlName="filter_option" class="form-control" placeholder="Please select filter"
                      (selectionChange)="filterChanged($event.value)" #select5>

                      <mat-select-filter *ngIf="select5.focused" [placeholder]="'Search'" [displayMember]="'Filter'"
                        [array]="gridlist" (filteredReturn)="filteredList = $event"></mat-select-filter>
                      <mat-option *ngFor="let filter of filteredList" [value]="filter.Filter">
                        {{filter.Filter}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Keywords </label>
                  <input type="text" formControlName="key_word" placeholder="Search keywords" class="form-control"
                    [readonly]="iskeyword">
                </div>
              </div>

              <div class="col-md-9">
                <div class="aligh-button">
                  <button mat-button class="mat-button-submit" style="margin-left: 10px !important;" title="Search" (click)="search()">Search</button>
                  <button mat-button class="mat-button-submit" style="margin-left: 10px;" title="Excel" (click)="fullExcel()">Full Excel</button>
                  <button class="btn btn-default" style="margin-left: 10px;width: 37%;" title="Reset" (click)="clear()">Reset</button>
                </div>
              </div>
              
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="card-body">

          <div class="row justify-content-between">
            <div class="col-md-6">
              <h4 style="font-weight:600; color: #233771;">Details</h4>
            </div>
            <div class="col-md-6">
              <div class="row justify-content-end">
                <div class="col-md-7">
                  <!-- <div class="search-box pull-center">
                    <div class="search-input">
                      <mat-icon role="img" matprefix="true"
                        class="mat-icon notranslate material-icons mat-icon-no-color icon-grey" aria-hidden="true"
                        data-mat-icon-type="font">search</mat-icon><input placeholder="Search" class="form-control"
                        (keyup)="applyFilter($event.target.value)">
                    </div>
                  </div> -->
                </div>

                <div class="col-md-5 d-flex justify-content-end">

                  <mat-icon (click)="export('csv')" title="CSV" type="button"  class="mat-button-submit c-btn mr-2 icon-img" style="background-image:url('assets/images/CSV.png');"></mat-icon>
                  <mat-icon (click)="export('xlsx')" title="Excel" type="button" class="mat-button-submit c-btn mr-2 icon-img" style="background-image:url('assets/images/XLS.png');"></mat-icon>
                  <mat-icon (click)="printPage()" title="Print" type="button" class="mat-button-submit c-btn mr-2 icon-img" style="background-image:url('assets/images/print.png');"></mat-icon>
                  <mat-icon (click)="export('xlsx')" title="Copy" type="button" class="mat-button-submit c-btn icon-img" style="background-image:url('assets/images/Group 2 Copy 5.png');"></mat-icon>




                </div>
              </div>
            </div>
          </div>




          
          <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
            <table id="tablerecords" [hidden]="isLoading" [border]="1" style="text-align: center;" matTableExporter
              mat-table [dataSource]="dataSource" class="pointer table-striped" #exporter="matTableExporter"
              [hiddenColumns]="[0]" matSort>
              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label"> Actions</mat-label>
                    <input class="form-control" matInput [formControl]="trip_status">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="trip_status" [ngClass]="{
                    'trip-closed': element.trip_status === 'Trip Closed',
                    'trip-allocated': element.trip_status === 'Allocated',
                    'trip-activated': element.trip_status === 'Activated',
                    'trip-reported': element.trip_status === 'Reported',
                    'started': element.trip_status === 'Started',
                    'trip-end': element.trip_status === 'Trip End'
                    
                }" (click)="viewpagedetails(element.booking_id)">

                    {{element.trip_status}}
                  </div>
                  <div class="trip_btn_groups">
                    <!-- <button class="btn btn-outline-primary btn-sm" (click)="opentrack(element.trip_no)">Live
                      Track</button> -->
                    <button class="btn btn-outline-primary btn-sm"><a target="_blank" href="https://www.forzaenterprises.com/transaction/view_track/{{(element.trip_no)}}">Live Track</a></button>
                    <button class="btn btn-outline-primary btn-sm"><a target="_blank"
                        href="https://www.forzaenterprises.com/transaction/view_map/{{(element.trip_no)}}">View
                        Map</a></button>

                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="booking_source">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label">Booking Source</mat-label>
                    <input class="form-control" matInput [formControl]="bookingSource">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.booking_source}} </td>
              </ng-container>
              <ng-container matColumnDef="trip_no">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label"> Trip No</mat-label>
                    <input class="form-control" matInput [formControl]="trip_no">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.trip_no}} </td>
              </ng-container>
              <ng-container matColumnDef="trip_date">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter filter-date" floatLabel="never">
                    <mat-label class="filter-label"> Trip Date</mat-label>
                    <!-- <input matInput [formControl]="trip_date"> -->
                    <input class="form-control" autocomplete="off" matInput placeholder="dd-mm-yyyy"
                      (click)="picker2.open();" [matDatepicker]="picker2" [formControl]="trip_date">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.trip_date | date: 'dd-MM-yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="trip_time">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label">Trip Time</mat-label>
                    <input class="form-control" matInput [formControl]="trip_time">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.trip_time}} </td>
              </ng-container>
              <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label"> Customer Name</mat-label>
                    <input class="form-control" matInput [formControl]="customer_name">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
              </ng-container>


              <ng-container matColumnDef="guest_name">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label"> Guest Name</mat-label>
                    <input class="form-control" matInput [formControl]="guest_name">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.guest_name}} </td>
              </ng-container>

              <ng-container matColumnDef="rental_type">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label"> Rental Type</mat-label>
                    <input class="form-control" matInput [formControl]="rental_type">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.rental_type}} </td>
              </ng-container>

              <ng-container matColumnDef="vehicle_type">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label"> Vehicle Type</mat-label>
                    <input class="form-control" matInput [formControl]="vehicle_type">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.vehicle_type}} </td>
              </ng-container>

              <!-- <ng-container matColumnDef="send_vehicle_type">
              <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter" floatLabel="never">
                  <mat-label class="filter-label">Sent Vehicle Type</mat-label>
                  <input class="form-control" matInput [formControl]="send_vehicle_type">
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.send_vehicle_type}} </td>
            </ng-container> -->

              <ng-container matColumnDef="booking_id">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label">Booking No</mat-label>
                    <input class="form-control" matInput [formControl]="booking_id">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.booking_id}} </td>
              </ng-container>

              <ng-container matColumnDef="bill_amount">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label">Bill Amount</mat-label>
                    <input class="form-control" matInput [formControl]="bill_amount">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.bill_amount ? element.bill_amount : '-'}} </td>
              </ng-container>

              <!-- <ng-container matColumnDef="exkm_rate">
              <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter" floatLabel="never">
                  <mat-label class="filter-label">Ex km rate</mat-label>
                  <input class="form-control" matInput [formControl]="exkm_rate">
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.exkm_rate}} </td>
            </ng-container>

            <ng-container matColumnDef="vendor">
              <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter" floatLabel="never">
                  <mat-label class="filter-label">Vendor</mat-label>
                  <input class="form-control" matInput [formControl]="vendor">
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.vendor}} </td>
            </ng-container> -->

              <ng-container matColumnDef="vehicle_no">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label">Vehicle No</mat-label>
                    <input class="form-control" matInput [formControl]="vehicle_no">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.vehicle_no}} </td>
              </ng-container>

              <ng-container matColumnDef="ce_name">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label">CE Name</mat-label>
                    <input class="form-control" matInput [formControl]="ce_name">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.ce_name}} </td>
              </ng-container>

              <ng-container matColumnDef="ce_no">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-form-field class="filter" floatLabel="never">
                    <mat-label class="filter-label">CE No</mat-label>
                    <input class="form-control" matInput [formControl]="ce_no">
                  </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.ce_no}} </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

            <div *ngIf="dataSource.filteredData.length === 0" style="text-align: center">
              No records found
            </div>

            <!-- <custom-paginator [datalength]="dataSourceLength" [hidden]="!(dataSource.filteredData.length != 0)"
            [pageSizeOptions]="[10,25,50]" justify-content="space-between"></custom-paginator> -->
          </div>
          <div class="col-md-12 mt-2 table" [hidden]="!isLoading">

            <table class="norecordfoundtable">
              <tr>
                <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}</th>
              </tr>
              <tr>
                <td style="text-align: center;" [attr.colspan]="toppingList.length">
                  {{'Loading.......'}}
                </td>
              </tr>
            </table>
          </div>

        </div>
        <custom-paginator [datalength]="dataSourceLength" [hidden]="!(dataSource.filteredData.length != 0)"
          [pageSizeOptions]="[10,25,50]" justify-content="space-between"></custom-paginator>
      </div>

    </div>
  </section>
</div>