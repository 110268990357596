<div class="mx-4">

    <section class="content-header">
        <div class="container-fluid">
            <div>
                <span routerLink="/dashboard">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
                    routerLink="/Outstanding">Outstanding Summary</span> / <span
                    class="breadcrumb-item activebreadcrumb-item active">Outstanding Payment</span>
            </div>
            <div class="row mt-md-2">
                <div class="col-auto">
                    <img src="assets/images/main-outstandingsummary.png" alt="Forza Logo"
                        style="object-fit:fill;width:50px;">
                </div>
                <div class="col">
                    <div class="row flex-column justify-content-center mt-md-3">
                        <div class="col">
                            <h4 style="font-weight:600; color: #233771;">Outstanding Payment</h4>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-end align-items-center">
                    <h6 class="pointer pt-3" routerLink="/Outstanding"><span class="fas fa-angle-left p-2"></span>Back
                    </h6>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="col-md-12">
                        <div class="row">
                            <h6 style="font-weight:600; color: #233771 !important;">As on <span class="month-text" *ngIf="month">{{ month +","+ year }}</span></h6>
                        </div>
                        <div class="row">
                            <div class="col-md-6 details-text">
                                <label class="view-label">Location: </label> <br /> <span class="view-data"> {{location}} </span>
                            </div>
                            <div class="col-md-6 details-text">
                                <label class="view-label">Customer: </label> <br /> <span class="view-data">{{customerName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div class="search-box pull-center">
                            <div class="search-input">
                                <mat-icon role="img" matprefix="true"
                                    class="mat-icon notranslate material-icons mat-icon-no-color icon-grey"
                                    aria-hidden="true" data-mat-icon-type="font">search</mat-icon><input
                                    placeholder="Search" class="form-control"
                                    (keyup)="applyFilter($event.target.value)">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
                        <table id="tablerecords" [border]="1" [hidden]="isLoading" matTableExporter
                            #exporter="matTableExporter" mat-table [dataSource]="dataSource"
                            class="pointer table-striped">

                            <ng-container matColumnDef="bill_no">
                                <th mat-header-cell *matHeaderCellDef> Bill No
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.bill_no}} </td>
                            </ng-container>

                            <ng-container matColumnDef="trip_no">
                                <th mat-header-cell *matHeaderCellDef> Trip No
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.trip_no}} </td>
                            </ng-container>

                            <ng-container matColumnDef="bill_date">
                                <th mat-header-cell *matHeaderCellDef> Bill Date
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.bill_date}} </td>
                            </ng-container>

                            <ng-container matColumnDef="passenger">
                                <th mat-header-cell *matHeaderCellDef> Guest Name
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.passenger}} </td>
                            </ng-container>

                            <ng-container matColumnDef="location">
                                <th mat-header-cell *matHeaderCellDef> Location
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                            </ng-container>

                            <ng-container matColumnDef="bill_amt">
                                <th mat-header-cell *matHeaderCellDef> Bill Amount
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="element.bill_amt == null">
                                        0.00
                                    </span>
                                    <span *ngIf="element.bill_amt != null">
                                        {{element.bill_amt}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="adj_amt">
                                <th mat-header-cell *matHeaderCellDef> Adj Amount
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="element.adj_amt == null">
                                        0.00
                                    </span>
                                    <span *ngIf="element.adj_amt != null">
                                        {{element.adj_amt}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="bal_amt">
                                <th mat-header-cell *matHeaderCellDef> Bill Amount
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="element.bal_amt == null">
                                        0.00
                                    </span>
                                    <span *ngIf="element.bal_amt != null">
                                        {{element.bal_amt}}
                                    </span>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <div *ngIf="dataSource.filteredData.length === 0" style="text-align: center">
                            No records found
                        </div>
                    </div>

                    <div class="col-md-12 mt-2 table" [hidden]="!isLoading">

                        <table class="norecordfoundtable">
                            <tr>
                                <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}</th>
                            </tr>
                            <tr>
                                <td style="text-align: center;" [attr.colspan]="toppingList.length">
                                    {{'Loading.......'}}
                                </td>
                            </tr>
                        </table>
                    </div>

                    <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading"
                        [pageSizeOptions]="[10,25,50]" justify-content="space-between">
                    </custom-paginator>

                </div>
            </div>
        </div>
    </section>
</div>