import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponentComponent } from '@pages/dialog-component/dialog-component.component';
import { AppService } from '@services/app.service';
import { Managecustomer } from '@services/managecustomerguest.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HelperService } from '@services/helper.service';
import { DatePipe } from '@angular/common';


import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


export interface PeriodicElement {
  // booking_id:number;
  pickup_date: string;
  position: number;
  pickup_time: string;
  guest_name: string;
  guest_mobile: string;
  project_id: string;
  pickup_address: string;
  booking_source: number;
  status: string;
  Actions: string;
  source_name: string;
  rental_city: string;
  special_instruction: string;
  vehicle_no: string;
  ce_name: string;
  ce_mobile: number;
  rental_type: string;
  vehicle_type: string;

}

@Component({
  selector: 'app-managebooking',
  templateUrl: './managebooking.component.html',
  styleUrls: ['./managebooking.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ManagebookingComponent implements OnInit {
  toggle: boolean = true;
  searchform !: FormGroup;
  location: any;
  branches: any;
  filter: any;
  respo: any;
  public filterdatas = [];
  filterdata: any;
  getCountryMasterData = [];
  public baselocationlist = [];
  webbooklist: any = [];
  base_Location: any;
  filter_option: any;
  key_word: any;
  maxdate: any;
  CurrentDate: any;
  log_id: any;
  minDate = new Date();

  dataSourceLength: any;

  from_Date: any;
  end_date: any;

  forzaadmin: any = [];
  public id: string;
  userid: any = [];
  locationFilter: any;
  myplaceHolder: string = 'dd-mm-yyyy'
  myplaceHolder1: string = 'dd-mm-yyyy'
  //For print Config
  public config = {
    printMode: 'template-popup',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Hello World',
    templateString: '<header>Forza CBS</header>{{printBody}}<footer>Copyright © 2022 forza.com. All rights reserved</footer>',
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],
    styles: ['td { border: 1px solid black; }', 'th {background-color: #edf2f9;}', 'table { border: 1px solid black; padding: 10px; }', 'header, table, footer { margin: auto; text-align: center; }']
  }
  //Print Config

  toppingList = []
  isLoading: boolean = false;
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

  displayedColumns: string[] = ['position', 'booking_id', 'status', 'pickup_date', 'pickup_time', 'guest_name', 'guest_mobile', 'project_id', 'billing_location', 'source_name', 'branch_name', 'pickup_address', 'rental_name', 'vehicle_model', 'spl_instructions', 'Actions'];
  dataSource = new MatTableDataSource<PeriodicElement>();
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort = new MatSort();

  msg: any;
  managebooklist: any = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private managecustomer: Managecustomer,
    private matDialog: MatDialog,
    private appService: AppService,
    private formBuilder: FormBuilder,
    public helperService: HelperService,
    public datepipe: DatePipe,
  ) { }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {

    this.from_Date = new Date();
    this.end_date = new Date();


    this.route.queryParams.subscribe(params => {
      if (params.from != undefined) {
        if (params.from == 'reset') {
          this.from_Date = '';
        } else {
          this.from_Date = new Date(params.from);
        }
      }
      if (params.to != undefined) {
        if (params.from == 'reset') {
          this.end_date = '';
        } else {
          this.end_date = new Date(params.to);
        }
      }

    });
    console.log(this.from_Date, this.end_date);

    this.toppingList = this.displayedColumns;

    this.CurrentDate = new Date().toISOString().split('T')[0];

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.log_id = localStorage.getItem('Id');
    this.id = localStorage.getItem('Id');

    // this.getwebbookinglist();
    this.getFilter();
    this.getBranches();

    this.searchform = this.formBuilder.group({
      from_date: [this.from_Date, null],
      end_date: [this.end_date, null],
      base_Location: ['', null],
      filter_option: ['', null],
      key_word: ['', null],
    })

    const value = JSON.parse(localStorage.getItem('filterData_Webbooking'));

    console.log("datatatat", value);


    if (value !== null) {

      this.from_Date = new Date(value.from_date);
      this.end_date = new Date(value.end_date);

      this.base_Location = value.location;
      this.filter_option = value.filter;
      this.key_word = value.keyword;

      this.searchform = this.formBuilder.group({
        from_date: [value.from_date, null],
        end_date: [value.end_date, null],
        base_Location: [value.location, null],
        filter_option: [value.filter, null],
        key_word: [value.keyword, null],
      })
      this.submit();
    }
    this.submit();

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    this.dataSourceLength = this.dataSource.filteredData.length


  }

  changeType() {

  }

  addguestbooking() {
    this.router.navigate(['/add-webbooking']);
  }

  view(id) {
    if (Number(this.helperService.checkMenuAccess('r_read')) > 0) {
      this.router.navigate(['/view-webbooking', btoa(id), 'view']);
    }
  }

  edit(id) {
    if (Number(this.helperService.checkMenuAccess('r_update')) > 0) {
      this.router.navigate(['/edit-webbooking', btoa(id), 'edit']);
    }
  }

  delete(deleteid) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      body: 'Are you sure want to Delete?'
    };
    let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)

  }
  statusManager(element) {

  }

  getwebbookinglist() {
    let obj = { "user_id": Number(this.log_id) }
    this.managecustomer.getweblist(obj).subscribe((response) => {
      this.msg = response;
      if (this.msg.success == true) {
        this.managebooklist = this.msg.Webbooking_List;
        this.dataSource = new MatTableDataSource(this.managebooklist);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSourceLength = this.msg.Webbooking_List.length;
      }
    })
  }

  printPage() {
    var divToPrint = document.getElementById("tablerecords");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();

    // var divToPrint = document.getElementById("tablerecords").outerHTML;  
    //    let newWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');  
    //   newWin.document.write(`
    //   <html>
    //     <head>
    //       <title>Print tab</title>

    //       color: blue;
    //       <body onload="window.print();window.close()">${divToPrint}</body>
    //       <link rel="stylesheet" href=""> 
    //     </head>
    //   </html>`
    // );  
    newWin.print();
    newWin.close();
  }
  // window.print();

  // }


  getFilter = () => {
    this.appService.getfilterwebdata({}).subscribe(
      response => {

        this.respo = response

        if (this.respo.success == true) {
          this.filterdata = this.respo.Filter;
          //this.filterdata = this.branches;      
          this.filterdata = this.filterdata.sort((a, b) => {
            if (a.Filter < b.Filter) { return -1; }
            if (a.Filter > b.Filter) { return 1; }
          })
          this.filterdatas = this.filterdata.slice();


        }
      });

  }

  reset() {
    this.end_date = null;
    this.from_Date = null;
  }


  getBranches = () => {
    let data = {

      type: this.forzaadmin,
      user_id: this.id
    }
    this.appService.getlocation(data).subscribe(
      response => {



        this.respo = response

        if (this.respo.success == true) {
          this.getCountryMasterData = this.respo.Location_List;

          //this.branches = this.respo.result.filter(item => item.branch_name !== "All Location");
          // this.getCountryMasterData = this.branches;      
          this.getCountryMasterData = this.getCountryMasterData.sort((a, b) => {
            if (a.location_id < b.location_id) { return -1; }
            if (a.location_id > b.location_id) { return 1; }
          })
          this.baselocationlist = this.getCountryMasterData.slice();
          this.locationFilter = this.baselocationlist[0].location_name;

        }
      });

  }

  // getBranches = () => {
  //   let data = {
  //     type: this.forzaadmin,
  //     user_id: this.id
  //   }
  //   this.appService.getlocation(data).subscribe(
  //     response => {
  //       this.respo = response
  //       if (this.respo.success == true) {
  //         this.getCountryMasterData = this.respo.Location_List;
  //         this.getCountryMasterData = this.getCountryMasterData.sort((a, b) => {
  //           if (a.location_id < b.location_id) { return -1; }
  //           if (a.location_id > b.location_id) { return 1; }
  //         })
  //         this.baselocationlist = this.getCountryMasterData.slice();
  //         this.locationFilter = this.baselocationlist[0].location_name;
  //       }
  //     });
  // }

  submit() {

    var x = this.searchform.value.from_date;
    var y = this.searchform.value.end_date;


    //this.searchform.get("base_Location").setValue(this.locationFilter);



    let obj = {
      user_id: Number(this.log_id),
      from_date: this.datepipe.transform(x, 'dd-MM-yyyy'),
      end_date: this.datepipe.transform(y, 'dd-MM-yyyy'),
      location: (this.searchform.value.base_Location != '') ? this.searchform.value.base_Location : null,
      filter: (this.searchform.value.filter_option != '') ? this.searchform.value.filter_option : null,
      keyword: (this.searchform.value.key_word != '') ? this.searchform.value.key_word : null
    }



    this.managecustomer.getweblist(obj).subscribe((response) => {

      this.msg = response;

      if (this.msg.success == true) {

        var x = this.searchform.value.from_date;
        var y = this.searchform.value.end_date;
        obj.from_date = x;
        obj.end_date = y;

        console.log(obj);
        localStorage.setItem('filterData_Webbooking', JSON.stringify(obj));

        this.webbooklist = this.msg.Webbooking_List;
        this.dataSource = new MatTableDataSource(
          this.webbooklist
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSourceLength = this.msg.Webbooking_List.length;


      }


    })

  }

  clear() {
    localStorage.removeItem('filterData_Webbooking');

    this.searchform.reset();
    this.dataSource.filter = "0";
    this.getwebbookinglist();

    if (this.baselocationlist.length == 1) {
      this.locationFilter = this.baselocationlist[0].location_name;
      this.searchform.get("base_Location").setValue(this.locationFilter);
    }

  }

  checkPlaceHolder() {
    if (this.myplaceHolder) {
      this.myplaceHolder = null
      return;
    } else {
      this.myplaceHolder = 'dd-mm-yyyy'
      return
    }
  }

  checkPlaceHolder1() {
    if (this.myplaceHolder1) {
      this.myplaceHolder1 = null
      return;
    } else {
      this.myplaceHolder1 = 'dd-mm-yyyy'
      return
    }
  }


  exportIt(excelType, name, sheetname, authorName) {

    this.isLoading = true

    var exportType = ExportType.CSV;

    if (excelType == 'xlsx') {
      exportType = ExportType.XLSX;
    }

    if (excelType == 'csv') {
      exportType = ExportType.CSV;
    }


    this.exporter.exportTable(exportType, {
      fileName: name,
      sheet: sheetname,
      Props: {
        Author: authorName
      }
    })

    setTimeout(() => {
      this.isLoading = false
    }, Number(this.managebooklist.length) * 2);
  }

  removeUnderScore(val) {
    if (val == 'position') {
      val = 'ID';
    } else if (val == 'parent_name') {
      val = 'Parent Name';
    } else if (val == 'child_name') {
      val = 'Child Name';
    } else if (val == 'child_age') {
      val = 'Child Age';
    } else if (val == 'phone') {
      val = 'Phone';
    } else if (val == 'lead_date') {
      val = 'Created On';
    } else if (val == 'lead_status') {
      val = ' Lead Status';
    } else if (val == 'center_name') {
      val = 'CENTER';
    } else if (val == 'createdBy') {
      val = 'Created By';
    }

    return val.toString().replace(/_/gi, " ");
  }
}
