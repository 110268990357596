import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
import { filter } from 'rxjs/operators';
import { openCloseAnimation, rotateAnimation } from './menu-item.animations';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    animations: [openCloseAnimation, rotateAnimation]
})
export class MenuItemComponent implements OnInit {
    @Input() menuItem: any = null;
    public isExpandable: boolean = false;
    @HostBinding('class.nav-item') isNavItem: boolean = true;
    @HostBinding('class.menu-open') isMenuExtended: boolean = false;
    public isMainActive: boolean = false;
    public forzaadmin;
    public isOneOfChildrenActive: boolean = false;

    constructor(private router: Router, public helperService: HelperService) { }

    ngOnInit(): void {
        if (
            this.menuItem &&
            this.menuItem.children &&
            this.menuItem.children.length > 0
        ) {
            this.isExpandable = true;
        }
        this.calculateIsActive(this.router.url);
        this.forzaadmin = localStorage.getItem('customer_Role_name');



        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.calculateIsActive(event.url);
            });
    }


    public handleMainMenuAction() {


        if (this.isExpandable) {

            if ((this.menuItem.path != undefined)) {
                // if(this.helperService.isRouting(this.menuItem.name)) {
                this.router.navigate([this.menuItem.path[0]]);
                // }

            }
            this.toggleMenu();
            return;
        }
        this.router.navigate(this.menuItem.path);
        localStorage.removeItem('filterData_Guestbooking');
        localStorage.removeItem('filterData_Managebooking');
        localStorage.removeItem('filterData_Webbooking');
    }

    public toggleMenu() {
        this.isMenuExtended = !this.isMenuExtended;
    }

    public calculateIsActive(url: string) {
        this.isMainActive = false;
        this.isOneOfChildrenActive = false;        
        if (this.menuItem.path != undefined) {
            if (this.menuItem.path[0] != undefined) {
            if (this.isExpandable) {
                if ((this.menuItem.path != undefined)) {
                    if (url == this.menuItem.path[0]) {
                        this.isMainActive = true;
                        this.isOneOfChildrenActive = true;
                        this.helperService.setMenuAccess(this.menuItem.resource)
                        // this.helperService.checkMenuAccess(this.menuItem.name,item.name)
                    }

                    if (this.menuItem.path[0].split('/')[2] != undefined) {                       
                        if (this.checkInput(url, [this.menuItem.path[0].split('/')[2]]) == true) {
                            this.isMainActive = true;
                            this.isOneOfChildrenActive = true;
                            this.helperService.setMenuAccess(this.menuItem.resource)
                    }
                    }

                    this.menuItem.children.forEach((item) => {
                        item.active = '';
                        url = url.split('?')[0];
                        setTimeout(() => {                            
                        if (item.path[0] === url) {
                            this.isOneOfChildrenActive = true;
                            this.isMenuExtended = true;
                            item.active = 'active';
                            localStorage.removeItem('filterData_Guestbooking');
                            localStorage.removeItem('filterData_Managebooking');
                            localStorage.removeItem('filterData_Webbooking');


                            this.helperService.setMenuAccess(item.resource)
                        } else if (item.subpath) {                            
                            for (let index = 0; index < item.subpath.length; index++) {                                
                                if (item.subpath[index] == url) {
                                    this.isOneOfChildrenActive = true;
                                    this.isMenuExtended = true;
                                    item.active = 'active';
                                    this.helperService.setMenuAccess(item.resource)
                                } else if (item.subpath[index] == url.split('/')[1]) {
                                    this.isOneOfChildrenActive = true;
                                    this.isMenuExtended = true;
                                    item.active = 'active';                                    
                                    this.helperService.setMenuAccess(item.resource)
                                } else {
                                    if (this.checkInput(url, [item.subpath[index]]) == true) {
                                        this.isOneOfChildrenActive = true;
                                        this.isMenuExtended = true;
                                        item.active = 'active';
                                       this.helperService.setMenuAccess(item.resource)
                                }
                                }
                            }
                        }
                        }, 50);

                    });
                }
            } else if (this.menuItem.path[0] === url) {
                this.isMainActive = true;
                this.helperService.setMenuAccess(this.menuItem.resource)
            } else if (this.menuItem.subpath) {
                for (let index = 0; index < this.menuItem.subpath.length; index++) {
                    const element = this.menuItem.subpath[index];
                    if (this.checkInput(url, [element]) == true) {
                        this.isMainActive = true;
                        this.helperService.setMenuAccess(this.menuItem.resource)
                        break;
                    }
                }
            }
        }
        }

        if (!this.isMainActive && !this.isOneOfChildrenActive) {
            this.isMenuExtended = false;
        }

    }

    checkInput(input, words) {
        return words.some(word => new RegExp(word, "i").test(input));
    }
}
