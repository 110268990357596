import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '@services/customeradmin.service';
import { HelperService } from '@services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '@services/general.service';
import { Managecustomer } from '@services/managecustomerguest.service';
import { json } from 'stream/consumers';
import { MatDialog } from '@angular/material/dialog';

export interface User {
  name: string;
}

@Component({
  selector: 'app-add-managecustomeradmin',
  templateUrl: './add-managecustomeradmin.component.html',
  styleUrls: ['./add-managecustomeradmin.component.scss']
})

export class AddManagecustomeradminComponent implements OnInit {


  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;


  selected: boolean = false;
  customeradmin !: FormGroup;
  submitted = false;
  emailexist = false;
  branches: any;
  customers_id: any;
  buttonText: string = 'submit'
  brancheswithoutall: any;
  respo: any;
  respocustomersgroup: any;
  customersgroup: any;
  respocustomerrole: any;
  customerrole: any;
  public custrole: boolean = false;
  public isEdit: boolean = false;
  public isShowDrp: boolean = false;
  resposubmit: any;
  public id: string;
  customerdata: any = [];
  locationlist: any = [];
  adminlist: any = [];
  public baselocationlist = [];
  public filteredList2 = [];
  public filteredList3 = [];
  public filteredList4 = [];
  public filteredList5 = [];
  public filteredList7 = [];
  public filteredList8 = [];
  public filteredList6 = [];
  getCountryMasterData = [];
  getcustomergroupdata = [];
  getcustomergroupdatas = [];
  getcustomerroledata = [];
  getcustomerroledata1 = [];
  getcustomerroledatas = [];
  getcustomer = [];
  getcustomers = [];
  getlocation = [];
  location_name: any;
  getlocations = [];
  forzaadmin: any = [];
  userid: any = [];
  centreData: any;
  loc: any = [];
  id1: any;
  public type: string = 'add'
  customeradminresp: any;
  base_Location: any;
  customer_Role: any;
  customer_Role_Authorised: any;
  customer_Group: any;
  customer_Name_array: any;
  customer_Name: any;
  customer_Admin_Name: any;
  customer_Admin_mobile: any;
  customer_Admin_MailId: any;
  customer_Admin_Password: any;
  Customer_Admin_Employee_ID: any;
  Customer_Admin_Department: any;
  Customer_Admin_Designation: any;
  CustomerAdminDOB: any;
  userId: any;
  user_id: any;
  roleName: any;
  location: any;
  status: any;
  customer_name: any;
  old_email: any;
  cstatus: string = 'ACTIVE'
  lessthanten = false
  locationAuthorised_selectedFoods: any;
  locationAuthorised_selectedFoods1: any
  public isDisableBtn: boolean = false;

  searchMobData: any = [];
  public loglist: [];
  public msg: any;

  user_name: any;
  date_time: any;

  labelStatus: any;


  startDate = new Date(1990, 0, 1);

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private appService: AppService,
    private toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    public helperService: HelperService,
    public general: GeneralService,
    private managecustomerguests: Managecustomer,
    public dialog: MatDialog,
    private managecustomer: Managecustomer

  ) {

  }

  ngOnInit(): void {

    this.userId = Number(localStorage.getItem('Id'));



    if (this.userId == 309) {

    }

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.user_id = localStorage.getItem('userId');


    this.id = localStorage.getItem('Id');
    if (this.forzaadmin == 'Forza Admin') {
      this.custrole = true;
    }
    else {
      this.getcustomerauthorrole();
    }

    this.getBranches();
    this.getlocationfilter();
    this.getcustomerrole();
    this.getcustomersgroup();

    this.customeradmin = this.formBuilder.group({
      base_Location: [null, Validators.required],
      locationAuthorised: [null, Validators.required],
      customer_Role: [null, Validators.required],
      customer_Role_Authorised: [null],
      customer_Group: [null, Validators.required],
      customer_Name: [null, Validators.required],
      customer_Admin_Name: [null, Validators.required],
      customer_Admin_mobile: [null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.minLength(10), Validators.maxLength(13)]],
      customer_Admin_MailId: [null, ([Validators.required, Validators.pattern(/^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i)])],
      Customer_Admin_Employee_ID: ['', null],
      Customer_Admin_Department: ['', null],
      Customer_Admin_Designation: ['', null],
      CustomerAdminDOB: ['', null],
      customer_Admin_Password: ['', null],
      status: [true],
    })

    if (this.customeradmin.value.status === true) {
      this.labelStatus = "Active"
    }
    else if (this.customeradmin.value.status === false) {
      this.labelStatus = "Deactive"
    }

  

    if (this?.activatedRoute?.snapshot?.url[1]?.path) {
      this.id1 = atob(this.activatedRoute.snapshot.url[1].path);
      if (this.activatedRoute.snapshot.url[1].path == 'add') {
        this.type == 'add'
        this.helperService.pageAccess('r_create')
      } else {
        this.type = this.activatedRoute.snapshot.url[2].path;
        this.helperService.pageAccess('r_update')
        this.getCustomerAdminbyId();
      }
    }

  }


  getPosts() {
    let obj = {
      id: this.id1,
      user_id: this.userId,

      // Keyword: data.option.value
    }

    this.managecustomer.getLogData(obj).subscribe((res => {
      this.msg = res;


      if (this.msg.success == true) {
        this.loglist = this.msg.Log_List;




        // this.logdata();
      }
    }))
  }

  getCustomerAdminbyId = () => {
    this.buttonText = 'update'
    var idarray = {
      id: this.id1,
      user_id: this.userId
    };
    //idarray['id'] = this.id1;
    // idarray['user_id'] = this.user_id;
    this.appService.getCustomerAdminbyId(idarray).subscribe(
      (response) => {
        let responses: any = response;
        if (responses.success == true) {
          this.customeradminresp = responses.result
          this.base_Location = responses?.result?.base_Location
          this.customer_Role = responses?.result?.customer_Role

          this.customer_Role_Authorised = (responses?.result?.customer_Role_Authorised != null) ? JSON.parse(responses?.result?.customer_Role_Authorised) : responses?.result?.customer_Role_Authorised;
          this.roleName = responses?.result?.customer_Role_name
          this.isEdit = (responses?.result?.isEdit == 'false') ? true : false;



          // if(this.isEdit == true)
          // {
          //   this.isShowDrp = true
          // }
          // else{
          //   this.isShowDrp = false
          // }

          this.customer_Group = responses?.result?.customer_Group
          this.customer_Name_array = responses?.result?.customer_Name_array
          this.customer_Name = JSON.parse(responses?.result?.customer_Name)
          this.customer_Admin_Name = responses?.result?.customer_Admin_Name
          this.customer_Admin_mobile = responses?.result?.customer_Admin_mobile
          this.customer_Admin_MailId = responses?.result?.customer_Admin_MailId
          this.old_email = responses?.result?.customer_Admin_MailId
          this.Customer_Admin_Employee_ID = responses?.result?.Customer_Admin_Employee_ID
          this.Customer_Admin_Department = responses?.result?.Customer_Admin_Department
          this.Customer_Admin_Designation = responses?.result?.Customer_Admin_Designation
          this.CustomerAdminDOB = responses?.result?.CustomerAdminDOB

          this.locationAuthorised_selectedFoods = JSON.parse(responses?.result?.locationAuthorised)
          this.location = responses?.result?.locationAuthorised_name;
          this.loc = responses?.result?.locationAuthorised
          console.log(responses?.result?.locationAuthorised, "location Authorised");
          this.customeradmin.get("status").setValue((responses?.result?.status == 'ACTIVE') ? true : false);

          if (this.customeradmin.value.status === true) {
            this.labelStatus = "Active"
          }
          else if (this.customeradmin.value.status === false) {
            this.labelStatus = "Deactive"
          }



          this.customeradmin.get("locationAuthorised").setValue(this.locationAuthorised_selectedFoods);

          this.getcustomerGroupName(Number(this.customer_Group))
        }

      },
      (error) => {
        this.toastr.error(error, 'Error');
      }
    );
  };

  change(e) {

  }

  public locationclose() {

    this.locationAuthorised_selectedFoods = ''
    this.customer_Group = ''
    //  if (this.filteredList2.length > 0) {
    //      this.filteredList2 = []
    //    }
    // this.filteredList2 = []
    this.customer_Name = ''
    this.filteredList3 = []
    this.customer_Role = ''
    if (this.filteredList2.length > 0) {

      this.filteredList2 = []
      console.log(this.filteredList2);

    }
  }

  public customergroupclose() {
    this.customer_Group = ''
    this.customer_Name = ''
    this.customer_Role = ''
    this.filteredList3 = []

  }
  public cusname() {
    this.customer_Name = ''
    this.customer_Role = ''
  }

  onchangelocation(value) {
    this.customer_Group = ''
    this.customer_Name = ''
    this.customer_Role = ''
    this.getcustomersgroup();
    this.loc = JSON.stringify(value);

  }
  onchangebill(value) {

    this.locationAuthorised_selectedFoods = ''
    this.getlocationfilter();

    this.customer_Group = ''
    this.customer_Name = ''
    this.customer_Role = ''


  }

  public baseclose() {
    this.base_Location = ''
    this.locationAuthorised_selectedFoods = ''
    this.filteredList4 = []
    this.customer_Group = ''
    this.filteredList2 = []
    this.customer_Name = ''
    this.filteredList3 = []
    this.customer_Role = ''
    this.filteredList7 = []
  }


  public getcustoName(value) {
    this.customer_Role = ''
  }
  getcustomerGroupName(customer_group_id) {

    let data = {
      customer_group: customer_group_id,
      location: this.loc,
      type: this.forzaadmin,
      user_id: this.id
    }
    this.managecustomerguests.getCustomer(data).subscribe(
      (response) => {
        this.respo = response;

        if (this.respo.success == true) {
          this.customerdata = this.respo.Customer_Name;
          this.getcustomer = this.customerdata;
          this.getcustomers = this.customerdata;
          this.getcustomer = this.getcustomer.sort((a, b) => {
            if (a.customer_name < b.customer_name) { return -1; }
            if (a.customer_name > b.customer_name) { return 1; }
          })
          this.filteredList3 = this.getcustomer.slice();
        }
      },
    );
  }

  get f() {
    return this.customeradmin.controls
  }

  getBranches = () => {


    this.appService.getBranches().subscribe(
      response => {
        this.respo = response
        if (this.respo.success == true) {
          this.branches = this.respo.result.filter(item => item.branch_name !== "All Location");
          this.getCountryMasterData = this.branches;
          this.getCountryMasterData = this.getCountryMasterData.sort((a, b) => {
            if (a.branch_name < b.branch_name) { return -1; }
            if (a.branch_name > b.branch_name) { return 1; }
          })
          this.baselocationlist = this.getCountryMasterData.slice();
        }
      });
  }

  getlocationfilter() {
    this.getlocation = this.locationlist = [];
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }
    this.managecustomerguests.getCustomerlocation(data).subscribe(
      (response) => {
        this.respo = response;
        if (this.respo.success == true) {
          this.locationlist = [...this.locationlist, ...this.respo.Location_List]
          this.getlocation = this.locationlist;
          //this.getlocations = this.locationlist;
          this.getlocation = this.getlocation.sort((a, b) => {
            if (a.location_name < b.location_name) { return -1; }
            if (a.location_name > b.location_name) { return 1; }
          })
          this.filteredList4 = this.getlocation.slice();
        }
      })
  }

  getcustomerauthorrole = () => {
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }
    this.appService.getauthorisedrole(data).subscribe(
      response => {
        this.respocustomerrole = response
        if (this.respocustomerrole.success == true) {
          this.customerrole = this.respocustomerrole.Location_List;
          this.getcustomerroledata1 = this.customerrole;
          this.getcustomerroledata1 = this.getcustomerroledata1.sort((a, b) => {
            if (a.customer_Role_Authorised_name < b.customer_Role_Authorised_name) { return -1; }
            if (a.customer_Role_Authorised_name > b.customer_Role_Authorised_name) { return 1; }
          })
          this.filteredList7 = this.getcustomerroledata1.slice();
        }
      });
  }

  getcustomerrole = () => {
    this.appService.getcustomerrole().subscribe(
      response => {
        this.respocustomerrole = response
        if (this.respocustomerrole.success == true) {
          this.customerrole = this.respocustomerrole.result;
          this.getcustomerroledata = this.customerrole;
          this.getcustomerroledata = this.getcustomerroledata.sort((a, b) => {
            if (a.role_name < b.role_name) { return -1; }
            if (a.role_name > b.role_name) { return 1; }
          })
          this.filteredList8 = this.filteredList5 = this.getcustomerroledata.slice();
        }
      });
  }

  getcustomersgroup = () => {
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }

    this.appService.getcustomersgroup(data).subscribe(
      response => {

        this.respocustomersgroup = response
        if (this.respocustomersgroup.success == true) {
          this.customersgroup = this.respocustomersgroup.result;
          this.getcustomergroupdata = this.customersgroup;
          this.getcustomergroupdatas = this.customersgroup;
          this.getcustomergroupdata = this.getcustomergroupdata.sort((a, b) => {
            if (a.customer_group_name < b.customer_group_name) { return -1; }
            if (a.customer_group_name > b.customer_group_name) { return 1; }
          })
          this.filteredList2 = this.getcustomergroupdata.slice();
        }
      });
  }

  onkeypress(e) {
    if (e.length < 10) {
      this.lessthanten = true
    } else if (e.length == 10) {
      this.lessthanten = false
    }
  }

  submit = () => {
    this.submitted = true;
    if (this.customeradmin.valid && this.emailexist === false) {
      this.isDisableBtn = true;
      this.customeradmin.get("status").setValue((this.customeradmin.value.status == true) ? 'ACTIVE' : 'DEACTIVATED');

      this.customeradmin.get("customer_Admin_Password").setValue((this.customeradmin.value.customer_Admin_Password == undefined) ? "" : this.customeradmin.value.customer_Admin_Password);
      this.customeradmin.value.locationAuthorised = JSON.stringify(this.customeradmin.value.locationAuthorised)

      this.customeradmin.value.customer_Role_Authorised = (this.customer_Role_Authorised != null) ? JSON.stringify(this.customer_Role_Authorised) : this.customer_Role_Authorised

      this.customeradmin.value.customer_Name = JSON.stringify(this.customeradmin.value.customer_Name)

      if (this.type === 'edit') {
        this.customeradmin.value.id = this.id1
        this.customeradmin.value.user_id = this.userId
        this.customeradmin.value.user_type = this.forzaadmin


        this.isDisableBtn = true;
        this.appService.updateCustomerAdmin(this.customeradmin.value).subscribe(
          response => {
            this.resposubmit = response;
            if (this.resposubmit.success == true) {
              this.router.navigate(['/managecustomeradmin']);
              this.toastr.success(this.resposubmit.message, 'success');
            } else {
              this.toastr.error(this.resposubmit.message, 'Error');
              this.isDisableBtn = false;
            }
          },
          error => {
            this.toastr.error(error, 'Error');
            this.isDisableBtn = false;
          });
      } else {
        this.isDisableBtn = true;
        this.customeradmin.value.user_id = this.userId
        this.customeradmin.value.user_type = this.forzaadmin

        this.appService.createCustomerAdmin(this.customeradmin.value).subscribe(
          response => {
            this.resposubmit = response;
            if (this.resposubmit.success == true) {
              this.toastr.success(this.resposubmit.message, 'success');
              this.router.navigate(['/managecustomeradmin']);

            } else {
              this.toastr.error(this.resposubmit.message, 'Error');
              this.isDisableBtn = false;
            }
          },
          error => {
            this.toastr.error(error, 'Error');
            this.isDisableBtn = false;
          });
      }
      this.submitted = false;
    } else {
      return;
    }

  }

  closeDialog() {
    this.dialog.closeAll()
    this.searchMobData = []
  }

  logdata() {

    this.getPosts()
    this.dialog.open(this.secondDialog, {
      width: '70%',
    });

  }
  public viewdata(data: any) {
    this.searchMobData = []
    this.dialog.closeAll()
    this.user_name = data.user_name;
    this.date_time = data.date_time;
    // this.customerDropdown(this.billing_Location)
    this.customer_Name = data.customer;
    this.customer_Name = Number(data.customer)
    // this.rentaldropdown(this.customer_Name)
    // let obj = {
    //   prefix: '',
    //   guest_name: data.guest_name,
    //   guest_Employeeid: data.employee_id,
    //   guest_mobile: data.guest_mobile,
    //   guest_Email: data.guest_mail_id
    // }
    // this.childForm = [];
    // this.childForm.push(obj);
  }

  checkemail = (email) => {
    var value = email
    var emailRegexp = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
    if (emailRegexp.test(value)) {
      if (this.old_email !== this.customeradmin?.value?.customer_Admin_MailId) {
        this.appService.checkcustomeradminemail(this.customeradmin?.value).subscribe(
          response => {
            let responses: any = response;
            if (responses.success == true) {
              this.emailexist = true
            } else {
              this.emailexist = false
            }
          },
          error => {
            this.toastr.error(error, 'Error');
          });
      }
    }
  }
  changetoggle(event) {
    console.log(event);
    if (this.customeradmin.value.status === true) {
      this.labelStatus = "Active"
    }
    else if (this.customeradmin.value.status === false) {
      this.labelStatus = "Deactive"
    }

  }

}