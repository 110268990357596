<div class="mx-4">
  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/invoice">Invoice Page</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">{{(type === 'edit') ? 'Edit' : 'Add'}} Invoice</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/contractinvoice.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Upload Invoice</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/invoice"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>

<section class="content">
  <div class="container-fluid">    
    <div class="card">
      <div class="card-body">
        <div class="container-fluid">
          <form [formGroup]="contractinvoice">
            <div class="row justify-content-between mx-4">
              <div class="col-md-5">
                <div class="form-group">
                  <label>Location<span class="text-danger">*</span></label>

                  <div class="input-group-append">
                    <mat-select (ngModelChange)="locationd_selected" [ngModel]="locationd_selected"
                      [ngClass]="{ 'is-invalid': submitted && f['base_Location'].errors }" required
                      formControlName="base_Location" class="form-control" multiple placeholder="Please Location"
                      (selectionChange)="locationChanged($event.value)" #select4>
                      <mat-select-filter *ngIf="select4.focused" [placeholder]="'Search'"
                        [displayMember]="'location_name'" [array]="getlocation"
                        (filteredReturn)="filteredListLocation = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let location of filteredListLocation" [value]="location.location_id">
                        {{location.location_name}}
                      </mat-option>
                      <div style="margin: 10px; float: right;"></div>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="base_Location_selected">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="locationclose(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['base_Location'].errors" class="text-danger">
                    <div *ngIf="f['base_Location'].errors">Base location is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label>Customer group<span class="text-danger">*</span></label>
                  <mat-select (ngModelChange)="customer_Group" [ngModel]="customer_Group"
                    [ngClass]="{ 'is-invalid': submitted && f['customer_Group'].errors }" required
                    formControlName="customer_Group" class="form-control" placeholder="Please Customer group"
                    (selectionChange)="customersgroupChanged($event.value)" #cusgroup>

                    <mat-select-filter *ngIf="cusgroup.focused" [placeholder]="'Search'"
                      [displayMember]="'customer_group_name'" [array]="getcustomergroupdata"
                      (filteredReturn)="filteredListGroups = $event">
                    </mat-select-filter>

                    <mat-option *ngFor="let group of filteredListGroups" [value]="group.customer_group_id">
                      {{group.customer_group_name}}
                    </mat-option>
                  </mat-select>
                  <div *ngIf="submitted && f['customer_Group'].errors" class="invalid-feedback">
                    <div *ngIf="f['customer_Group'].errors">customer group is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Customer Name<span class="mandatory">*</span></label>
                  <div class="input-group-append">
                    <mat-select [(ngModel)]="customer_Name" formControlName="customer_Name" class="form-control"
                      [ngClass]="{'is-invalid': submitted && f['customer_Name'].errors }" required
                      placeholder="Please Customer Name" multiple #select3>
                      <mat-select-filter *ngIf="select3.focused" [placeholder]="'Search'"
                        [displayMember]="'customer_name'" [array]="getcustomer"
                        (filteredReturn)="filteredCompanyName = $event">
                      </mat-select-filter>
                      <mat-option *ngFor="let data of filteredCompanyName" [value]="data.customers_id">
                        {{data.customer_name}}
                      </mat-option>
                    </mat-select>
                    <a class="decoration-none input-group-text closeicon" *ngIf="customer_Name">
                      <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                        (click)="clearCustomerName(); $event.stopPropagation()">close</mat-icon>
                    </a>
                  </div>
                  <div *ngIf="submitted && f['customer_Name'].errors" class="text-danger">
                    <div *ngIf="f['customer_Name'].errors">customer Name is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Invoice Type
                    <span class="text-danger">*</span></label>
                  <mat-select (ngModelChange)="invoice_type" [ngModel]="invoice_type" required
                    [ngClass]="{ 'is-invalid': submitted && f['invoice_type'].errors }" formControlName="invoice_type"
                    class="form-control" placeholder="Please Invoice Type">
                    <mat-option value="generated">Generated</mat-option>
                    <mat-option value="contract">Contract</mat-option>
                  </mat-select>
                  <div *ngIf="submitted && f['invoice_type'].errors" class="invalid-feedback">
                    <div *ngIf="f['invoice_type'].errors">Invoice Type is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label>Month
                    <span class="text-danger">*</span></label>
                  <mat-select (ngModelChange)="invoice_month" [ngModel]="invoice_month" required
                    [ngClass]="{ 'is-invalid': submitted && f['invoice_month'].errors }" formControlName="invoice_month"
                    class="form-control" placeholder="Please Month" #select5>
                    <mat-select-filter *ngIf="select5.focused" [placeholder]="'Search'" [displayMember]="'month'"
                      [array]="monthlist" (filteredReturn)="filterlist = $event">
                    </mat-select-filter>
                    <mat-option *ngFor="let months of filterlist" [value]="months.month_id">
                      {{months.month}}
                    </mat-option>
                  </mat-select>
                  <div *ngIf="submitted && f['invoice_month'].errors" class="invalid-feedback">
                    <div *ngIf="f['invoice_month'].errors">Month is required</div>
                  </div>
                </div>
              </div>


              <div class="col-md-5">
                <div class="form-group">
                  <label>Year<span class="text-danger">*</span>
                  </label>
                  <mat-select (selectionChange)="yearvalue($event.value)" [(ngModel)]="invoice_year" required
                    [ngClass]="{ 'is-invalid': submitted && f['invoice_year'].errors }" formControlName="invoice_year"
                    class="form-control" name="selectedYear" placeholder="Select Year">
                    <mat-option [value]="invoice_year" *ngFor="let invoice_year of range">
                      {{invoice_year}}
                    </mat-option>
                  </mat-select>
                  <div *ngIf="submitted && f['invoice_year'].errors" class="invalid-feedback">
                    <div *ngIf="f['invoice_year'].errors">Year is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label>Comments
                    <span class="text-danger">*</span></label>
                  <textarea class="form-control" [ngModel]="invoice_comments" formControlName="invoice_comments"
                    cols="40" rows="4" [maxlength]="250" required></textarea>
                  <!-- <div *ngIf="isComments" class="text-danger">
                  Comments is required
                  </div> -->
                  <div *ngIf="submitted && f['invoice_comments'].errors" class="text-danger">
                    <div *ngIf="f['invoice_comments'].errors">Comments is required</div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">

                <div *ngIf="isServerShowFile != true || isFileUpload == true">
                  <label>Upload Invoice
                    <span class="text-danger">*</span> <span style="color: red;">only PDF, Excel, Word,JPG, JPEG and
                      PNG can upload</span></label>
                  <div class="loader" *ngIf="isLoader"></div>
                  <div class="form-group" *ngIf="!isLoader">
                    <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
                      <div class="text-wrapper">
                        <div class="centered">

                          <label for="upload_excel"><span class="textLink"><i
                                class='fas fa-arrow-down'></i></span>Select your
                            file or
                            Drop it here!</label>
                          <input type="file" class="file_upload" multiple="true" required
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpeg,image/jpg,image/png,application/pdf"
                            id="upload_excel" (change)="onFileChange($event.target.files)" />
                        </div>
                      </div>
                    </div>
                    <div *ngIf="isFileUpload1" class="text-danger">
                      <div>Upload any one file</div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isShowFile && fileArr.length == 0">
                  <div>
                    <h3 class="file">Files1</h3>
                  </div>
                  <div *ngFor="let file of files">
                    <div class="">
                      <mat-icon mat-list-icon style="position: absolute;">folder</mat-icon>
                      <h4 style="font-size: 15px;margin-left: 30px;padding-top: 5px;">
                        {{file.name}}</h4>
                    </div>
                    <i class="fas fa-trash pointer" (click)="deleteFile(file)"
                      style="color: #fb8542;  float: right;  margin-top: -23px;"></i>
                  </div>
                </div>

                <div *ngIf="fileArr.length > 0">
                  <div *ngIf="upload">
                    <h3 style=" margin-top: 1rem;"> Uploaded files </h3>
                  </div>
                  <ul class="file-list" *ngIf="isServerShowFile == true">
                    <li *ngFor="let file of fileArr; let i = index">
                      <span>{{file}} </span>
                      <div>
                        <button class="mat-button-submit btn-sm" style="margin-right: 1rem;" type="button"
                          (click)=downloadFile(i)>
                          Download
                        </button>
                        <button *ngIf="fileArr.length != 1" class="mat-button-regret btn-sm pointer" color="warn"
                          type="button" (click)=delete(i)>
                          Delete
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>


              <div class="row" *ngIf="isServerShowFile == true">
                <div class="col-md-12" [hidden]="fileupload">
                  <button mat-button class="mat-button-submit" (click)="morefiles()">Add more files</button>
                </div>
              </div>
              <!-- new    -->
              <div class="col-md-12">

                <div [hidden]="!fileupload">
                  <label>Upload Invoice
                    <span class="text-danger">*</span> <span style="color: red;">only PDF, Excel, Word,JPG, JPEG and
                      PNG can upload</span></label>
                  <div class="loader" *ngIf="isLoader"></div>
                  <div class="form-group" *ngIf="!isLoader">
                    <div class="dropzone" fileDragDrop (filesChangeEmiter)="onFileChange1($event)">
                      <div class="text-wrapper">
                        <div class="centered">
                          <label for="upload_excel"><span class="textLink"><i
                                class='fas fa-arrow-down'></i></span>Select your
                            file or
                            Drop it here!</label>
                          <input type="file" class="file_upload" multiple="true" required
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpeg,image/jpg,image/png,application/pdf"
                            id="upload_excel" (change)="onFileChange1($event.target.files)" />
                        </div>
                      </div>
                    </div>
                    <!-- <div *ngIf="isFileUpload1" class="text-danger">
                      <div>Upload any one file</div>
                    </div> -->
                    <div *ngIf="isShowFile">
                      <div>
                        <h3 class="file">Files</h3>
                      </div>
                      <div *ngFor="let file of files1;let i = index">
                        <div class="">
                          <mat-icon mat-list-icon style="position: absolute;">folder</mat-icon>
                          <h4 style="font-size: 15px;margin-left: 30px;padding-top: 5px;">
                            {{file.name}}</h4>
                        </div>
                        <button class="btn btn-primary btn-sm" style="margin-right: 1%;float: right;margin-top: -23px;"
                          type="button" (click)="saveFile(file,i)">
                          Save
                        </button>&nbsp;
                        <button class="btn btn-danger btn-sm pointer"
                          style="margin-right: 1%;float: right;margin-top: -23px;" color="warn" type="button"
                          (click)="deletefile1(file)">
                          delete
                        </button>
                      </div>
                    </div>


                  </div>
                </div>

              </div>
              <!-- new    -->
            </div><br>
            <div class="aligh-button-right mr-4">
              <button routerLink="/invoice" class="mat-button-cancel">Cancel</button>
              <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
                *ngIf="type != 'edit' && helperService.checkMenuAccess('r_create')" (click)="submit()">Submit</button>
              <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
                *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="submit()">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
</div>