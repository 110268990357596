import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponentComponent } from '@pages/dialog-component/dialog-component.component';
import { AppService } from '@services/customeradmin.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@services/helper.service';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';
// import { CustomPaginatorComponent } from './pages/custom-paginator/custom-paginator.component';

export interface Admin {
    id: string,
    base_Location_name: string,
    customer_Group_name: string,
}

@Component({
    selector: 'app-managecustomeradmin',
    templateUrl: './managecustomeradmin.component.html',
    styleUrls: ['./managecustomeradmin.component.scss']
})

export class ManagecustomeradminComponent implements OnInit {

    title: string;
    message: string;
    pgIndex = 2;
    idd = []
    firstLastButtons = true;
    pnDisabled = true;
    hdPageSize = true;
    customeradminresp: any;
    customeradminlist: Admin[] = [];
    statusdata: any[] = [];
    displayedColumns: string[] = ['id', 'base_Location_name', 'customer_Group_name', 'customer_Name', 'customer_Admin_Name', 'customer_Admin_mobile', 'customer_Admin_MailId', 'status', 'Actions'];
    idarray: any[] = [];
    id: any;
    forzaadmin: any;
    userid: any;
    customerDeleteid: any
    dataSourceLength: any;

    @Input() hasPagination = true
    // MatPaginator Inputs
    length = 0;
    pageSize = 10;
    pageSizeOptions = [5, 10, 25, 100];
    pageIndex = 0;

    // MatPaginator Output
    pageEvent: PageEvent;
    @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
    
    toppingList = []
    isLoading: boolean = false;
    @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

    @ViewChild(MatSort) sort = new MatSort();
    @ViewChild('thirdDialog', { static: true }) thirdDialog: TemplateRef<any>;
    // @ViewChild(MatPaginator) paginator!: MatPaginator;
    dataSource = new MatTableDataSource<Admin>();
    dialogRef: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private matDialog: MatDialog,
        private appService: AppService,
        public helperService: HelperService,
        private toastr: ToastrService
    ) { }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.pageEvent;
        this.toppingList = this.displayedColumns;

        this.forzaadmin = localStorage.getItem('customer_Role_name');
        this.userid = localStorage.getItem('userId');
        this.id = localStorage.getItem('Id');




        this.helperService.pageAccess('all')
        this.getCustomerAdminList();
    }


    exportIt(excelType,name, sheetname,authorName) {

        this.isLoading = true
    
        var exportType = ExportType.CSV;
    
        if(excelType == 'xlsx'){
          exportType = ExportType.XLSX;
        }
    
        if(excelType == 'csv'){
          exportType = ExportType.CSV;
        }
    
       
        this.exporter.exportTable(exportType, {
          fileName: name,
          sheet: sheetname,
          Props: {
            Author: authorName
          }
        })
    
        setTimeout(() => {
          this.isLoading = false
        }, Number(this.customeradminlist.length) * 2);
      }


    getCustomerAdminList() {
        let data = {
            type: this.forzaadmin,
            user_id: this.id
        }

        this.appService.getCustomerAdminList(data).subscribe(
            (response) => {
                this.customeradminresp = response;
                if (this.customeradminresp.success == true) {
                    this.customeradminlist = this.customeradminresp.result
                    this.dataSource = new MatTableDataSource(this.customeradminlist);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.dataSourceLength = this.customeradminresp.result.length;
                }
            },
            (error) => {
                this.toastr.error(error, 'Error');
            }
        );
    };


    addmanagecustomer() {
        this.router.navigate(['/managecustomeradmin', 'add']);
    }

    edit(id) {
        if(Number(this.helperService.checkMenuAccess('r_update')) > 0) {
        this.router.navigate(['/managecustomeradmin', btoa(id), 'edit']);
        }
    }

    view(id) {
        if(Number(this.helperService.checkMenuAccess('r_read')) > 0) {
        this.router.navigate(['/view-managecustomeradmin', btoa(id), 'view']);
        }
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        this.dataSourceLength = this.dataSource.filteredData.length
    }

    // delete(deleteid) {
    //     this.customerDeleteid = deleteid;
    //     this.matDialog.open(this.thirdDialog, {
    //     });
    // }

    delete(deleteid) {
        var idarray = {};
        idarray['id'] = deleteid;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            body: 'Are you sure want to Delete?'
        };
        let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)
    
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.appService.delete(JSON.stringify(idarray)).subscribe(
                    (response) => {
                        let responses: any = response;
                        if (responses.success === true) {
                            this.getCustomerAdminList();
                            this.toastr.success(responses.message, 'Success');
                        }
                    },
                    (error) => {
                        this.toastr.error(error, 'Error');
                    }
                );
            }
        });
    }

    // deleteCustomer() {
    //     let data = {
    //         'id': this.customerDeleteid
    //     }
    //     this.appService.delete(data).subscribe(
    //         (response) => {
    //             let responses: any = response;
    //             if (responses.success === true) {
    //                 this.getCustomerAdminList();
    //                 this.toastr.success(responses.message, 'Success');
    //                 this.matDialog.closeAll()
    //             }
    //         },
    //         (error) => {
    //             this.toastr.error(error, 'Error');
    //         }
    //     );
    // }

    viewData(type, _id) {
        this.router.navigate([
            '/add-managecustomeradmin/' + type + '/' + btoa(_id)
        ]);
    }

    statusManager(element) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            body: 'Are you sure want to change status?'
        };
        let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.appService.updateCustomerAdminStatus(element).subscribe(
                    (response) => {
                        let responses: any = response;
                        if (responses.success === true) {
                            this.toastr.success(responses.message, 'Success');
                            element.status = responses.result
                        }
                    },
                    (error) => {
                        this.toastr.error(error, 'Error');
                    }
                );
            }
        });
    }

    removeUnderScore(val) {
      if (val == 'position') {
        val = 'ID';
      } else if (val == 'parent_name') {
        val = 'Parent Name';
      } else if (val == 'child_name') {
        val = 'Child Name';
      } else if (val == 'child_age') {
        val = 'Child Age';
      } else if (val == 'phone') {
        val = 'Phone';
      } else if (val == 'lead_date') {
        val = 'Created On';
      } else if (val == 'lead_status') {
        val = ' Lead Status';
      } else if (val == 'center_name') {
        val = 'CENTER';
      } else if (val == 'createdBy') {
        val = 'Created By';
      }
  
      return val.toString().replace(/_/gi, " ");
    }
}