<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
  <img src="assets/images/forza-logo.png" alt="Forza Logo" class="logo-full"/>
  <img src="assets/images/Forza_fav.png" alt="Forza Logo" style="width: 38px;" class="logo-short"/>
</a>

<!-- Sidebar -->
<div class="sidebar">
  <!-- Sidebar user (optional) -->
  <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex"> -->
  <!-- <div class="image">
            <img
                [src]="user.picture || 'assets/img/default-profile.png'"
                class="img-circle elevation-2"
                alt="User Image"
            />
        </div> -->
  <!-- <div class="info">
            <a [routerLink]="['/profile']" class="d-block">
                {{ user.email }}
            </a>
        </div> -->
  <!-- </div> -->

  <!-- Sidebar Menu -->
  <nav class="mt-2"  >
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      
      <app-menu-item *ngFor="let item of menu" [menuItem]="item"  ></app-menu-item>
    </ul>
    <hr>
    <div class="side-footer text-center d-none d-sm-block"  *ngIf='isBackShow'>
      <li  class="nav-item d-none d-sm-inline-block" >
        <a href="https://www.forzaenterprises.com/admin/customers" class="nav-link onhover-ba side-footer-back"><i class="fa-solid fa-circle-chevron-left side-footer-back"></i> Back to Admin
        </a>
      </li>      
    </div><br><br>
    <li class="side-footer text-center d-none d-sm-block">
      <!-- <img src="assets/images/inner_logo_small.png" alt="Forza Logo" class="logo-full"/> -->
      <img src="assets/images/menu_illustratiob.png" alt="Forza Logo" style="width: 70% !important;" class="logo-full"/>

    </li>
   
    

  </nav>
</div>