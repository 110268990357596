import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {BackendserviceService} from './backendservice.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryConfirmationService {

  constructor(   private backendService: BackendserviceService) { }


  public getLocationList(data): Observable<{}> {
    return this.backendService
        .post('api/booking/locationList', data)
        .pipe(retry(1), catchError(this.handleError));
  }

  public getCustomers(data): Observable<{}> {
    return this.backendService
        .post('api/booking/getCustomerNameinvoice', data)
        .pipe(retry(1), catchError(this.handleError));
  }

  public getRentalCity(data): Observable<{}> {
    return this.backendService
        .post('api/booking/RentalCityDropdown', data)
        .pipe(retry(1), catchError(this.handleError));
  }

  public gethistoryConfirmation(data): Observable<{}> {
    return this.backendService
        .post('api/booking/getConfirmationandRejectionlist', data)
        .pipe(retry(1), catchError(this.handleError));
  }

  public getBookingLogById(data): Observable<{}> {
    return this.backendService
        .post('api/log/bookinglogs', data)
        .pipe(retry(1), catchError(this.handleError));
  }

  public gethistoryConfirmationbyId(data): Observable<{}> {
    return this.backendService
        .post('api/booking/getConfirmationandRejectionlistById', data)
        .pipe(retry(1), catchError(this.handleError));
  }
 
 

  private handleError(error: HttpErrorResponse) {
      let errorMessage: string = '';
      if (error.error instanceof ErrorEvent) {
          //ClientSide Error--------
          errorMessage = `Error:${error.error.message}`;
      } else {
          //Server side Error---------
          errorMessage = `Status:${error.status}\n
                    Message:${error.message}`;
      }
      return throwError(errorMessage);
  }
}
