import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
import { Managecustomer } from '@services/managecustomerguest.service';

@Component({
  selector: 'app-view-guestbooking',
  templateUrl: './view-guestbooking.component.html',
  styleUrls: ['./view-guestbooking.component.scss']
})
export class ViewGuestbookingComponent implements OnInit {
  customeradminresp: any;
  public type = 'add';
  bookingId: any;
  webbookinglist: any;
  quantitiesList: any = [];

  constructor(
    private router: Router,
    public helperService: HelperService,
    private managecustomer: Managecustomer,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this?.activatedRoute?.snapshot?.url[1]?.path) {
      if (this.activatedRoute.snapshot.url[1].path == 'add') {
        this.type == 'add'
        this.helperService.pageAccess('r_create')
      } else {
        this.bookingId = atob(this.activatedRoute.snapshot.url[1].path);
        this.type = this.activatedRoute.snapshot.url[2].path;
        this.helperService.pageAccess('r_update')
        this.getwebbooking()
      }
    }
  }

  edit(id) {
    this.router.navigate(['/edit-webbooking', btoa(id), 'edit']);
  }

  getwebbooking() {
    let data = {
      booking_id: this.bookingId
    }

    this.managecustomer.getbookingdata(data).subscribe((res => {
      let responses: any = res;
      if (responses.success == true) {
        this.webbookinglist = responses.Webbooking_List;
        let quantitiesList = this.webbookinglist.quantities
        this.quantitiesList = [];
        for (let i = 0; i < quantitiesList.length; i++) {
          const element = quantitiesList[i];
          // if(element.prefix != '' && element.guest_name != '' && element.guest_mobile != '' ) {
          if(element.guest_name != '' && element.guest_mobile != '' ) {

            Object.keys(element).forEach(key => {
            if (element[key] === '' || element[key] === null) {
              delete element[key];
            } else {
              if (key == 'prefix') {
                element[key] = element[key]
              }
            }
          });
          this.quantitiesList.push(element)
        }
        }

        this.quantitiesList = this.quantitiesList.filter(value => Object.keys(value).length !== 0);
      }
    }))
  }
}
