<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Sub Menu</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Blank Page</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Title</h3>

                <div class="card-tools">
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                    >
                        <i class="fa fa-minus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-tool"
                        data-widget="remove"
                        data-toggle="tooltip"
                        title="Remove"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                Start creating your amazing application!
            </div>
            <!-- /.card-body -->
            <div class="card-footer">Footer</div>
            <!-- /.card-footer-->
        </div>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->

<section class="content">

  <div class="box box-default" data-select2-id="16">
  <div class="box-header with-border">
  <h3 class="box-title">Select2</h3>
  <div class="box-tools pull-right">
  <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
  <button type="button" class="btn btn-box-tool" data-widget="remove"><i class="fa fa-remove"></i></button>
  </div>
  </div>

  <div class="box-body" data-select2-id="15">
  <div class="row">
  <div class="col-md-6" data-select2-id="14">
  <div class="form-group" data-select2-id="13">
  <label>Minimal</label>
  <select class="form-control select2 select2-hidden-accessible" style="width: 100%;" data-select2-id="1" tabindex="-1" aria-hidden="true">
  <option selected="selected" data-select2-id="3">Alabama</option>
  <option data-select2-id="21">Alaska</option>
  <option data-select2-id="22">California</option>
  <option data-select2-id="23">Delaware</option>
  <option data-select2-id="24">Tennessee</option>
  <option data-select2-id="25">Texas</option>
  <option data-select2-id="26">Washington</option>
  </select><span class="select2 select2-container select2-container--default select2-container--below" dir="ltr" data-select2-id="2" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-ha5u-container"><span class="select2-selection__rendered" id="select2-ha5u-container" role="textbox" aria-readonly="true" title="Alabama">Alabama</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
   </div>

  <div class="form-group">
  <label>Disabled</label>
  <select class="form-control select2 select2-hidden-accessible" disabled="" style="width: 100%;" data-select2-id="4" tabindex="-1" aria-hidden="true">
  <option selected="selected" data-select2-id="6">Alabama</option>
  <option>Alaska</option>
  <option>California</option>
  <option>Delaware</option>
  <option>Tennessee</option>
  <option>Texas</option>
  <option>Washington</option>
  </select><span class="select2 select2-container select2-container--default select2-container--disabled" dir="ltr" data-select2-id="5" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-labelledby="select2-v463-container"><span class="select2-selection__rendered" id="select2-v463-container" role="textbox" aria-readonly="true" title="Alabama">Alabama</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
  </div>

  </div>

  <div class="col-md-6" data-select2-id="29">
  <div class="form-group" data-select2-id="28">
  <label>Multiple</label>
  <select class="form-control select2 select2-hidden-accessible" multiple="" data-placeholder="Select a State" style="width: 100%;" data-select2-id="7" tabindex="-1" aria-hidden="true">
  <option data-select2-id="30">Alabama</option>
  <option data-select2-id="31">Alaska</option>
  <option data-select2-id="32">California</option>
  <option data-select2-id="33">Delaware</option>
  <option data-select2-id="34">Tennessee</option>
  <option data-select2-id="35">Texas</option>
  <option data-select2-id="36">Washington</option>
  </select><span class="select2 select2-container select2-container--default select2-container--below" dir="ltr" data-select2-id="8" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--multiple" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1"><ul class="select2-selection__rendered"><li class="select2-selection__choice" title="Alaska" data-select2-id="37"><span class="select2-selection__choice__remove" role="presentation">×</span>Alaska</li><li class="select2-search select2-search--inline"><input class="select2-search__field" type="search" tabindex="0" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="textbox" aria-autocomplete="list" placeholder="" style="width: 0.75em;"></li></ul></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
  </div>

  <div class="form-group" data-select2-id="39">
  <label>Disabled Result</label>
  <select class="form-control select2 select2-hidden-accessible" style="width: 100%;" data-select2-id="9" tabindex="-1" aria-hidden="true">
  <option selected="selected" data-select2-id="11">Alabama</option>
  <option data-select2-id="40">Alaska</option>
  <option disabled="disabled" data-select2-id="41">California (disabled)</option>
  <option data-select2-id="42">Delaware</option>
  <option data-select2-id="43">Tennessee</option>
  <option data-select2-id="44">Texas</option>
  <option data-select2-id="45">Washington</option>
  </select><span class="select2 select2-container select2-container--default select2-container--below" dir="ltr" data-select2-id="10" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-bl9h-container"><span class="select2-selection__rendered" id="select2-bl9h-container" role="textbox" aria-readonly="true" title="Alaska">Alaska</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
  </div>

  </div>

  </div>

  </div>

  <div class="box-footer">
  Visit <a href="https://select2.github.io/">Select2 documentation</a> for more examples and information about
  the plugin.
  </div>
  </div>

  <div class="row">
  <div class="col-md-6">
  <div class="box box-danger">
  <div class="box-header">
  <h3 class="box-title">Input masks</h3>
  </div>
  <div class="box-body">

  <div class="form-group">
  <label>Date masks:</label>
  <div class="input-group">
  <div class="input-group-addon">
  <i class="fa fa-calendar"></i>
  </div>
  <input type="text" class="form-control" data-inputmask="'alias': 'dd/mm/yyyy'" data-mask="">
  </div>

  </div>


  <div class="form-group">
  <div class="input-group">
  <div class="input-group-addon">
  <i class="fa fa-calendar"></i>
  </div>
  <input type="text" class="form-control" data-inputmask="'alias': 'mm/dd/yyyy'" data-mask="">
  </div>

  </div>


  <div class="form-group">
  <label>US phone mask:</label>
  <div class="input-group">
  <div class="input-group-addon">
  <i class="fa fa-phone"></i>
  </div>
  <input type="text" class="form-control" data-inputmask="&quot;mask&quot;: &quot;(999) 999-9999&quot;" data-mask="">
  </div>

  </div>


  <div class="form-group">
  <label>Intl US phone mask:</label>
  <div class="input-group">
  <div class="input-group-addon">
  <i class="fa fa-phone"></i>
  </div>
  <input type="text" class="form-control" data-inputmask="'mask': ['999-999-9999 [x99999]', '+099 99 99 9999[9]-9999']" data-mask="">
  </div>

  </div>


  <div class="form-group">
  <label>IP mask:</label>
  <div class="input-group">
  <div class="input-group-addon">
  <i class="fa fa-laptop"></i>
  </div>
  <input type="text" class="form-control" data-inputmask="'alias': 'ip'" data-mask="">
  </div>

  </div>

  </div>

  </div>

  <div class="box box-info">
  <div class="box-header">
  <h3 class="box-title">Color &amp; Time Picker</h3>
  </div>
  <div class="box-body">

  <div class="form-group">
  <label>Color picker:</label>
  <input type="text" class="form-control my-colorpicker1 colorpicker-element">
  </div>


  <div class="form-group">
  <label>Color picker with addon:</label>
  <div class="input-group my-colorpicker2 colorpicker-element">
  <input type="text" class="form-control">
  <div class="input-group-addon">
  <i></i>
  </div>
  </div>

  </div>


  <div class="bootstrap-timepicker">
  <div class="form-group">
  <label>Time picker:</label>
  <div class="input-group">
  <input type="text" class="form-control timepicker">
  <div class="input-group-addon">
  <i class="fa fa-clock-o"></i>
  </div>
  </div>

  </div>

  </div>
  </div>

  </div>

  </div>

  <div class="col-md-6">
  <div class="box box-primary">
  <div class="box-header">
  <h3 class="box-title">Date picker</h3>
  </div>
  <div class="box-body">

  <div class="form-group">
  <label>Date:</label>
  <div class="input-group date">
  <div class="input-group-addon">
  <i class="fa fa-calendar"></i>
  </div>
  <input type="text" class="form-control pull-right" id="datepicker">
  </div>

  </div>


  <div class="form-group">
  <label>Date range:</label>
  <div class="input-group">
  <div class="input-group-addon">
  <i class="fa fa-calendar"></i>
  </div>
  <input type="text" class="form-control pull-right" id="reservation">
  </div>

  </div>


  <div class="form-group">
  <label>Date and time range:</label>
  <div class="input-group">
  <div class="input-group-addon">
  <i class="fa fa-clock-o"></i>
  </div>
  <input type="text" class="form-control pull-right" id="reservationtime">
  </div>

  </div>


  <div class="form-group">
  <label>Date range button:</label>
  <div class="input-group">
  <button type="button" class="btn btn-default pull-right" id="daterange-btn">
  <span>
  <i class="fa fa-calendar"></i> Date range picker
  </span>
  <i class="fa fa-caret-down"></i>
  </button>
  </div>
  </div>

  </div>

  </div>


  <div class="box box-success">
  <div class="box-header">
  <h3 class="box-title">iCheck - Checkbox &amp; Radio Inputs</h3>
  </div>
  <div class="box-body">


  <div class="form-group">
  <label>
  <div class="icheckbox_minimal-blue checked" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="checkbox" class="minimal" checked="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="icheckbox_minimal-blue" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="checkbox" class="minimal" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="icheckbox_minimal-blue disabled" aria-checked="false" aria-disabled="true" style="position: relative;"><input type="checkbox" class="minimal" disabled="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  Minimal skin checkbox
  </label>
  </div>

  <div class="form-group">
  <label>
  <div class="iradio_minimal-blue checked" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="radio" name="r1" class="minimal" checked="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="iradio_minimal-blue" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="radio" name="r1" class="minimal" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="iradio_minimal-blue disabled" aria-checked="false" aria-disabled="true" style="position: relative;"><input type="radio" name="r1" class="minimal" disabled="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  Minimal skin radio
  </label>
  </div>


  <div class="form-group">
  <label>
  <div class="icheckbox_minimal-red checked" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="checkbox" class="minimal-red" checked="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="icheckbox_minimal-red" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="checkbox" class="minimal-red" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="icheckbox_minimal-red disabled" aria-checked="false" aria-disabled="true" style="position: relative;"><input type="checkbox" class="minimal-red" disabled="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  Minimal red skin checkbox
  </label>
  </div>

  <div class="form-group">
  <label>
  <div class="iradio_minimal-red checked" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="radio" name="r2" class="minimal-red" checked="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="iradio_minimal-red" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="radio" name="r2" class="minimal-red" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="iradio_minimal-red disabled" aria-checked="false" aria-disabled="true" style="position: relative;"><input type="radio" name="r2" class="minimal-red" disabled="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  Minimal red skin radio
  </label>
  </div>


  <div class="form-group">
  <label>
  <div class="icheckbox_flat-green checked" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="checkbox" class="flat-red" checked="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="icheckbox_flat-green" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="checkbox" class="flat-red" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="icheckbox_flat-green disabled" aria-checked="false" aria-disabled="true" style="position: relative;"><input type="checkbox" class="flat-red" disabled="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  Flat green skin checkbox
  </label>
  </div>

  <div class="form-group">
  <label>
  <div class="iradio_flat-green checked" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="radio" name="r3" class="flat-red" checked="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
  <label>
  <div class="iradio_flat-green" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="radio" name="r3" class="flat-red" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  </label>
   <label>
  <div class="iradio_flat-green disabled" aria-checked="false" aria-disabled="true" style="position: relative;"><input type="radio" name="r3" class="flat-red" disabled="" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
  Flat green skin radio
  </label>
  </div>
  </div>

  <div class="box-footer">
  Many more skins available. <a href="http://fronteed.com/iCheck/">Documentation</a>
  </div>
  </div>

  </div>

  </div>

  </section>
