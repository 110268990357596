<div class="mx-4">
  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/invoice">Contract Invoice</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">View</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/contractinvoice.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">View Contract Invoice</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/invoice"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>



<!-- Main content -->
<section class="content">
  <div class="container-fluid">    
    <div class="card">
      <div class="card-body">
        <div class="container-fluid">
          <section>
            <h2 class="form-heading">Invoice</h2><br>
            <div class="form-group row mb-0">
              <label for="" class="col-md-3 col-form-label view-label"> Location </label>
              <div class="col-sm-8">
                <div class="input-group mb-2 mr-sm-2">
                  <p class="m-0 py-2 view-data">{{invoiceResponse?.location_Name}}
                    </p>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0">
              <label for="" class="col-md-3 col-form-label view-label"> Customer Group </label>
              <div class="col-sm-8">
                <div class="input-group mb-2 mr-sm-2">
                  <p class="m-0 py-2 view-data">{{invoiceResponse?.customer_group_name}}</p>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0">
              <label for="" class="col-md-3 col-form-label view-label"> Customer Name </label>
              <div class="col-sm-8">
                <div class="input-group mb-2 mr-sm-2">
                  <p class="m-0 py-2 view-data">{{invoiceResponse?.customer_Name_array}}</p>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0">
              <label for="" class="col-md-3 col-form-label view-label"> Invoice Type
              </label>
              <div class="col-sm-8">
                <div class="input-group mb-2 mr-sm-2">
                  <p class="m-0 py-2 view-data">{{invoiceResponse?.invoice_type}}
                    </p>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0">
              <label for="" class="col-md-3 col-form-label view-label"> Month </label>
              <div class="col-sm-8">
                <div class="input-group mb-2 mr-sm-2">
                  <p class="m-0 py-2 view-data">{{invoiceResponse?.invoice_month_name}}</p>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0">
              <label for="" class="col-md-3 col-form-label view-label"> Year </label>
              <div class="col-sm-8">
                <div class="input-group mb-2 mr-sm-2">
                  <p class="m-0 py-2 view-data">{{invoiceResponse?.invoice_year}}</p>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0">
              <label for="" class="col-md-3 col-form-label view-label"> Comments </label>
              <div class="col-sm-8">
                <div class="input-group mb-2 mr-sm-2">
                  <p class="m-0 py-2 view-data">{{invoiceResponse?.invoice_comments}}</p>
                </div>
              </div>
            </div><br>

            <h2 class="form-heading">File List</h2><br>


            <div class="form-group row mb-0" *ngFor="let file of fileArr; let i = index">
              
              <span class="col-md-3 col-form-label view-data">{{file}} </span>

              <div class="col-sm-8">
                <div class="input-group mb-2 mr-sm-2">                 
                    <button class="mat-button-submit btn-sm m-0 py-2" type="buttone" (click)=downloadFile(i)>
                      Download
                    </button>
                </div>
              </div>
            </div><br>





            <!-- <ul class="file-list">
              <li *ngFor="let file of fileArr; let i = index">
                <span class="view-data">{{file}} </span>

                  <button class="btn btn-primary btn-sm" type="buttone" (click)=downloadFile(i)>
                  Download
                </button>
              </li>
            </ul> -->

            <div class="aligh-button-right">
              <button routerLink="/invoice" class="mat-button-cancel">Cancel</button>

            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <!-- /.card -->
</section>
</div>
<!-- /.content -->

<!-- /.card -->

<!-- /.content -->