import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { PrivacyPolicyComponent } from '@modules/privacy-policy/privacy-policy.component';
import { ManagecustomeradminComponent } from '@pages/managecustomeradmin/managecustomeradmin.component';
import { ViewManagecustomeradminComponent } from '@pages/managecustomeradmin/view-managecustomeradmin/view-managecustomeradmin.component';
import { ManagedirectguestComponent } from '@pages/managedirectguest/managedirectguest.component';
import { AddManagedirectguestComponent } from '@pages/managedirectguest/add-managedirectguest/add-managedirectguest.component';
import { AddManagecustomeradminComponent } from '@pages/managecustomeradmin/add-managecustomeradmin/add-managecustomeradmin.component';
import { ViewDirectguestComponent } from './pages/managedirectguest/view-directguest/view-directguest.component';
import { ManagecustomerguestComponent } from './pages/managecustomerguest/managecustomerguest.component';
import { AddManagecustomerguestComponent } from './pages/managecustomerguest/add-managecustomerguest/add-managecustomerguest.component';
import { ViewCustomerguestComponent } from './pages/managecustomerguest/view-customerguest/view-customerguest.component';
import { ImportExportComponent } from './pages/import-export/import-export.component';
import { GuestbookingComponent } from '@pages/guestbooking/guestbooking.component';
// import { AddGuestbookingComponent } from '@pages/guestbooking/add-guestbooking/add-guestbooking.component';
import { AddBlankComponent } from '@pages/blank/add-blank/add-blank.component';
import { ContractinvoiceComponent } from '@pages/contractinvoice/contractinvoice.component';
import { AddContractinvoiceComponent } from '@pages/contractinvoice/add-contractinvoice/add-contractinvoice.component';
import { ViewGuestbookingComponent } from '@pages/guestbooking/view-guestbooking/view-guestbooking.component';
import { EditGuestbookingComponent } from '@pages/guestbooking/edit-guestbooking/edit-guestbooking.component';
import { ViewBlankComponent } from '@pages/blank/view-blank/view-blank.component';
import { ManagebookingComponent } from '@pages/managebooking/managebooking.component';

import { AddWebbookingComponent } from '@pages/managebooking/add-webbooking/add-webbooking.component';
import { ViewWebbookingComponent } from '@pages/managebooking/view-webbooking/view-webbooking.component';
import { ImportWebbookingComponent } from '@pages/import-webbooking/import-webbooking.component';
import { OutstandingComponent } from '@pages/outstanding/outstanding.component';
import { TripSheetComponent } from '@pages/trip-sheet/trip-sheet.component';
import { ReportComponent } from '@pages/report/report.component';
import { ViewContractInvoiceComponent } from '@pages/contractinvoice/view-contractinvoice/view-contractinvoice.component';
import { HistoryConfirmation } from '@pages/history-confirmations/history-confirmations.component';
import { PanicAlert } from '@pages/panic-alert/panic-alert.component';
import { HistoryReport } from '@pages/history-confirmations/history-action/history-report.component';
import { OutstandingPayment } from '@pages/outstanding/outstanding-payments/outstanding-payments.component';
import { ViewtrakingComponent } from '@pages/viewtraking/viewtraking.component';
import { PrintpageComponent } from '@pages/printpage/printpage.component';
import { ViewmodeComponent } from '@pages/viewmode/viewmode.component';


const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'managecustomeradmin',
                component: ManagecustomeradminComponent
            },
            // {
            //     path: 'managecustomeradmin/:type',
            //     component: AddManagecustomeradminComponent
            // },
            {
                path: 'managecustomeradmin/add',
                component: AddManagecustomeradminComponent
            },

            {
                path: 'managecustomeradmin/:type/:id',
                component: AddManagecustomeradminComponent
            },

            {
                path: 'view-managecustomeradmin/:type/:id',
                component: ViewManagecustomeradminComponent
            },
            // {
            //     path: '',
            //     component: DashboardComponent
            // },
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'managebooking',
                component: BlankComponent
            },
            {
                path: 'managebooking',
                component: AddWebbookingComponent
            },
            {
                path: 'webbooking',
                component: ManagebookingComponent
            },
            // {
            //     path:'view-managebooking/:id/:type',
            //     component:ViewManagebookingComponent
            // },
            {
                path: 'edit-booking/:type/:id',
                component: AddBlankComponent
            },
            {
                path: 'add-booking',
                component: AddBlankComponent
            },
            {
                path: 'view-webbooking/:id/:type',
                component: ViewWebbookingComponent
            },
            {
                path: 'add-webbooking',
                component: AddWebbookingComponent
            },
            {
                path: 'edit-webbooking/:id/:type',
                component: AddWebbookingComponent
            },
            {
                path: 'view-booking/:id/:type',
                component: ViewBlankComponent
            },

            {
                path: 'managedirectguest',
                component: ManagedirectguestComponent
            },
            {
                path: 'managedirectguest/:type',
                component: AddManagedirectguestComponent
            },
            {
                path: 'managedirectguest/:id/:type',
                component: AddManagedirectguestComponent
            },
            {
                path: 'view-managedirectguest/:id/:type',
                component: ViewDirectguestComponent
            },
            {
                path: 'managecustomerguest',
                component: ManagecustomerguestComponent
            },
            {
                path: 'managecustomerguest/:id/:type',
                component: AddManagecustomerguestComponent
            },
            {
                path: 'managecustomerguest/:type',
                component: AddManagecustomerguestComponent
            },
            {
                path: 'view-managecustomerguest/:id/:type',
                component: ViewCustomerguestComponent
            },
            {
                path: 'import-customerguest',
                component: ImportExportComponent

            },
            {
                path: 'import-web-booking',
                component: ImportWebbookingComponent

            },
            {
                path: 'guestbooking',
                component: GuestbookingComponent
            },
            {
                path: 'view-guestbooking/:id/:type',
                component: ViewGuestbookingComponent
            },
            {
                path: 'edit-guestbooking/:id/:type',
                component: EditGuestbookingComponent
            },
            {
                path: 'invoice',
                component: ContractinvoiceComponent
            },
            {
                path: 'contractinvoice/add',
                component: AddContractinvoiceComponent
            },
            {
                path: 'contractinvoice/:id/view',
                component: ViewContractInvoiceComponent
            },
            {
                path: 'contractinvoice/:type/:id',
                component: AddContractinvoiceComponent
            },
            {
                path: 'Outstanding',
                component: OutstandingComponent
            }            ,
            {
                path: 'Outstanding/details/:customerId/:location/:name',
                component: OutstandingPayment
            },
            {
                path: 'trip-sheet',
                component: TripSheetComponent
            },
            {
                path: 'report',
                component: ReportComponent
            },
            {
                path: 'reports/history-confirmation',
                component: HistoryConfirmation
            },
            {
                path: 'reports/history-confirmation/report/:id',
                component: HistoryReport
            },
            {
                path: 'reports/panic-alert',
                component: PanicAlert
            },

            {
                path: 'viewtraking/:id',
                component: ViewtrakingComponent
            },
            {
                path: 'printpage',
                component: PrintpageComponent
            },
            {
                path: 'viewmode/:id',
                component: ViewmodeComponent
            }


        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
