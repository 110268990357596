import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AppService} from '@services/customeradmin.service';
import {ToastrService} from 'ngx-toastr';
import { HelperService } from '@services/helper.service';
@Component({
    selector: 'app-view-contract-invoice',
    templateUrl: './view-contractinvoice.component.html',
    styleUrls: ['./view-contractinvoice.component.scss']
})
export class ViewContractInvoiceComponent implements OnInit {

    public formType: any;
    invoiceResponse: any;
    idarray: any[] = [];
    public id: string;
    locationArr:[]
    fileArr:[];
    storeArr:[]
    userId:any;
    customer:[]
    constructor(
        private router: Router,
        private location: Location,
        public activatedRoute: ActivatedRoute,
        private appService: AppService,
        private toastr: ToastrService,
        public helperService: HelperService,
    ) {}

    ngOnInit(): void {
        this.userId = Number(localStorage.getItem('Id'));
  
        this.helperService.pageAccess('r_read')        
        this.id = atob(this.activatedRoute.snapshot.url[1].path);
        this.getCustomerAdminbyId();
    }

    getCustomerAdminbyId = () => {
        var idarray = {

            id:this.id,
            user_id:this.userId
        };
       // idarray['id'] = this.id;
        this.appService.getInvoicebyId(idarray).subscribe(
            (response) => {
                let responses: any = response;
               
                
                if (responses.success == true) {
                    this.invoiceResponse = responses.result;
                    this.fileArr= responses.result.invoice_fileName_display.split(',');
                    this.storeArr= responses.result.invoice_fileName_stored.split(',');
                  
                    
                }
            },
            (error) => {
                this.toastr.error(error, 'Error');
            }
        );
    }

    back() {
        this.location.back();
    }
  
    downloadFile(id: any){
        console.log(id);
        this.appService.getInvoiceimageId(this.storeArr[id])
        .subscribe( data => {
           const response = data as Blob;
           let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.fileArr[id];
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        });
    }
}
