<div class="mx-4">

    <section class="content-header">
        <div class="container-fluid">
            <div>
                <span routerLink="/dashboard">Home</span> / <span
                    class="breadcrumb-item activebreadcrumb-item active">User Profile</span>
            </div>
            <div class="row mt-md-2">
                <div class="col-auto">
                    <img src="assets/images/main-profile.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
                </div>
                <div class="col">
                    <div class="row flex-column justify-content-center mt-md-3">
                        <div class="col">
                            <h4 style="font-weight:600; color: #233771;">Profile</h4>
                        </div>
                    </div>
                </div>
                <!-- <div class="col d-flex justify-content-end align-items-center">
              <h6 class="pointer pt-3" routerLink="/guestbooking"><span class="fas fa-angle-left p-2"></span>Back
              </h6>
            </div>       -->
            </div>
        </div>
    </section>

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="container-fluid">
                        <section>
                            <h2 class="form-heading">Personal Information</h2>
                            <form class="body1 mt-2">
                                <div class="form-group row mb-0" *ngIf="loginType == 'manual'">
                                    <label for="" class="col-md-4 col-form-label">Employee ID </label>
                                    <div class="col-md-8">
                                        <div class="input-group mb-2 mr-sm-2">
                                            <p class="m-0 py-2"> <b><span class="d-none d-sm-inline"> :
                                                    </span>{{id}}</b> </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-0" *ngIf="loginType == 'manual'">
                                    <label for="" class="col-md-4 col-form-label">Customer Name </label>
                                    <div class="col-md-8">
                                        <div class="input-group mb-2 mr-sm-2">
                                            <p class="m-0 py-2"> <b> <span class="d-none d-sm-inline"> : </span>
                                                    {{name}} </b></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-0" *ngIf="loginType == 'manual'">
                                    <label for="" class="col-md-4 col-form-label">Email</label>
                                    <div class="col-md-8">
                                        <div class="input-group mb-2 mr-sm-2">
                                            <p class="m-0 py-2"> <b> <span class="d-none d-sm-inline"> : </span>
                                                    {{email}}</b> </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row mb-0" *ngIf="loginType != 'manual'">
                                    <label for="" class="col-md-4 col-form-label">Email</label>
                                    <div class="col-md-8">
                                        <div class="input-group mb-2 mr-sm-2">
                                            <p class="m-0 py-2"> <b> <span class="d-none d-sm-inline"> : </span>
                                                    {{email}}</b> </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row mb-0" *ngIf="loginType == 'manual'">
                                    <label for="" class="col-md-4 col-form-label">Mobile Number </label>
                                    <div class="col-md-8">
                                        <div class="input-group mb-2 mr-sm-2">
                                            <p class="m-0 py-2"> <b> <span class="d-none d-sm-inline"> : </span>
                                                    {{number}} </b></p>
                                        </div>
                                    </div>
                                </div>


                            </form>
                            <h2 class="form-heading">Change Password</h2>

                            <form [formGroup]="changepassform">


                                <div class=" body ">

                                    <div class="col-md-6">
                                        <div class="form-group prepend">
                                            <label>Old Password<span class="mandatory">*</span></label>
                                            <input class="input form-control " [type]="password" #password
                                                formControlName="o_password" placeholder="Old Password"
                                                [ngClass]="{ 'is-invalid': submitted && f.o_password.errors }">

                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="changeType(password, 0)">
                                                    <i [ngClass]="toggle ?  'fas fa-eye-slash':'fas fa-eye' "></i>
                                                </span>
                                            </div>
                                            <div *ngIf="submitted && f['o_password'].errors" class="invalid-feedback">
                                                <div *ngIf="f['o_password'].errors">Old password is required</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-6">
                                        <div class="form-group prepend">
                                            <label>New Password<span class="mandatory">*</span></label>

                                            <input class="input form-control " [type]="password1" #password1
                                                formControlName="n_password" placeholder="New Password"
                                                [ngClass]="{ 'is-invalid': submitted && f.n_password.errors }">

                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="changeType(password1, 1)">
                                                    <i [ngClass]="toggle1 ?  'fas fa-eye-slash':'fas fa-eye' "></i>
                                                </span>
                                            </div>
                                            <div *ngIf="submitted && f['n_password'].errors" class="invalid-feedback">
                                                <div *ngIf="f['n_password'].errors">New password is required</div>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="col-md-6">
                                        <div class="form-group prepend">
                                            <label>Confirm Password<span class="mandatory">*</span></label>

                                            <input class="input form-control " [type]="password2" #password2
                                                formControlName="c_password" placeholder="Confirm Password"
                                                [ngClass]="{ 'is-invalid': submitted && f.c_password.errors }">

                                            <div class="input-group-append">
                                                <span class="input-group-text" (click)="changeType(password2, 2)">
                                                    <i [ngClass]="toggle2 ?  'fa fa-eye-slash':'fa fa-eye'"></i></span>
                                            </div>
                                            <div *ngIf="submitted && f['c_password'].errors" class="invalid-feedback">
                                                <div *ngIf="f['c_password'].errors">Confirm password is required
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </div>

                                <div class="col-lg-9">
                                    <div class="p-2">
                                        <button id="btn" type="submit" class="mat-button-submit" [disabled]="isDisableBtn"
                                            (click)="sumbit()">Update</button>
                                    </div>
                                </div>
                            </form>

                        </section>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>