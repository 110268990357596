import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { LoginService } from '@services/login.service';

import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    loginemail: string;
    constructor(
        private route: ActivatedRoute,
        private loginservice: LoginService,
        private appService: AppService,
        private toastr: ToastrService,
        private router: Router,
    ) {
        this.route.queryParams.subscribe((params) => {
            if (params?.forzaadmin) {
                this.appService.logout();
                var idarray = {};
                idarray['id'] = params?.forzaadmin;
                this.loginservice.forzaadminlogin(JSON.stringify(idarray)).subscribe(
                    (response) => {
                        let responses: any = response;
                        if (responses.success == true) {
                            localStorage.setItem('rba', JSON.stringify(responses.rba));
                            localStorage.setItem('userId', responses.User_Details.email);
                            localStorage.setItem('loginType', 'auto');
                            localStorage.setItem('Id', responses.User_Details.id);
                            localStorage.setItem('customer_Role_name', "Forza Admin");
                            this.toastr.success(responses.message, 'success');
                            setTimeout(() => {
                                this.router.navigate(['/dashboard']);
                            }, 500);
                        } else {
                            this.toastr.error(responses.message, 'Error');
                        }
                    },
                    (error) => {
                        this.toastr.error(error, 'Error');
                    }
                );
            }
        });
    }

    ngOnInit() { }

}