<div class="card">
  <div class="container" id="content">
    <div class="row">
      <div class="col-md-6">
        <img src="assets/images/inner_logo.png" alt="Forza Logo" style="width: 60%;">
      </div>
      <div class="col-md-6" style="text-align: end;">
        <h6><b>FORZA ENTERPRISES PVT.LTD</b></h6>
        <h6>No 150 CHIKKA THAYAPPA REDDY LAYOUT, 2nd</h6>
        <h6>MAIN, 2nd CROSS, CHELEKERE , KALYAN NAGAR</h6>
        <h6>POST, NEAR TO ROYALE CONCORDE PU COLLEGE</h6>
        <h6>BANGALORE - 5600043</h6>
        <h6>+919972650303</h6>

        <h6> <a href="mailto:bangalore@forzaenterprises/www.forzaenterprises.com"
            style="color:#4b95d2">bangalore@forzaenterprises/www.forzaenterprises.com</a>
        </h6>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="table table-bordered">
          <thead>

          </thead>
          <tbody class="table-group-divider">
            <tr>
              <th>
                <p>Client </p>
                <p>BOOKED BY </p>
                <p>REPORT TO </p>
                <p>PHONE </p>
              </th>
              <td colspan="4">
                <p>{{webbookinglist?.customer_name}}</p>
                <p>{{webbookinglist?.ordered_by}}-{{webbookinglist?.booker_mobile}}</p>
                <p style="white-space:normal !important">{{webbookinglist?.guest}}-{{webbookinglist?.pickup_address}}</p>
                <p> </p>
                <!-- <p>{{webbookinglist?.guest_mobile}},{{webbookinglist?.guest2_mobile}},{{webbookinglist?.guest3_mobile}},{{webbookinglist?.guest4_mobile}} </p> -->
                <p>{{(webbookinglist?.guest_mobile) ? ((webbookinglist?.guest_mobile) + ((webbookinglist?.guest2_mobile) ? ',' : '') ) :''}}
                  {{(webbookinglist?.guest2_mobile) ? ((webbookinglist?.guest2_mobile) + ((webbookinglist?.guest3_mobile) ? ',' : '' )) :''}}
                  {{(webbookinglist?.guest3_mobile) ? ((webbookinglist?.guest3_mobile) + ((webbookinglist?.guest4_mobile) ? ',' : '' )) :''}}
                  {{(webbookinglist?.guest4_mobile) ? (webbookinglist?.guest4_mobile) :''}}
                  

                </p>
              </td>
              <td class="text-right">
                <p>Date :</p>
                <p>Duty Slip No:</p>
                <p>Reporting Time:</p>
                <p>Rental Type:</p>
                <p>Vehicle No:</p>
                <p>Vehicle Type:</p>
                <p>Customer Executive:</p>
                <p>Mobile No:</p>
              </td>
              <td><p><b>{{webbookinglist?.pickup_date}}</b></p>
                <p><b>{{webbookinglist?.trip_no}}</b></p>
              <p>{{webbookinglist?.report_time}}</p>
              <p>{{webbookinglist?.rental_type_name}}</p>
              <p>{{webbookinglist?.vehicle_no}}</p>
              <p>{{webbookinglist?.vehicle_model_name}}</p>
              <p>{{webbookinglist?.ce_name}}</p>
              <p>{{webbookinglist?.ce_mobile}}</p>
            </td>
            </tr>


            <tr>
              <th scope="row"></th>
              <td></td>
              <td><b>Opening</b></td>
              <td><b>Closing</b></td>
              <td><b>Total</b></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <th scope="row"></th>
              <td>Date</td>
              <td><p>{{webbookinglist?.pickup_date}}</p></td>
              <td>{{webbookinglist?.end_date}}</td>
              <td></td>
              <td colspan="2">Guest Releasing Place</td>
              

            </tr>
            <tr>
              <th scope="row"></th>
              <td>Time</td>
              <td><span class="d-none d-sm-inline"> </span>{{webbookinglist?.start_time}}</td>
              <td>{{webbookinglist?.close_time}}</td>
              <td></td>
              <td colspan="2">Guest Releasing Place</td>

            </tr>
            <tr>
              <th scope="row"></th>
              <td>Kilometer</td>
              <td>{{webbookinglist?.open_km}} </td>
              <td>{{webbookinglist?.close_km}}</td>
              <td>{{webbookinglist?.c_total_km}}</td>
              <td colspan="2">Guest Releasing Place</td>

            </tr>
            <tr>
              <th scope="row"></th>
              <td colspan="2">Parking/Toll/Permit/Bata</td>

              <td>Extra Hours</td>
              <td>Extra Kilometers</td>
              <td colspan="2">Mode of Payment DIGITAL</td>

            </tr>
            <tr>
              <th scope="row">Comments</th>
              <td colspan="6">{{webbookinglist?.comments}}</td>

            </tr>
            <tr>
              <th scope="row" colspan="2">for Forza Enterprises Pvt. Ltd</th>




              <td colspan="2">Guest Feedback / Suggestions</td>

              <td colspan="3">Customer Next Requirements</td>

            </tr>
            <tr>
              <th scope="row" colspan="2">Super Admin [{{datetime}}]</th>

              <td colspan="2"></td>

              <td colspan="3" style="text-align: end">Guest Signature</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
<div class="savegroup">
  <!-- <button [useExistingCss]="true" class="btn btn-primary" printSectionId="sectionToPrint" ngxPrint>Print</button> -->
  <button mat-button (click)="printPage()" class="mat-button-submit c-btn" style="padding: 6px 10px;" title="Print"
    type="button"><i class="fa-solid fa-print"></i></button>
</div>