<div class="mx-4">
  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span>Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/managecustomeradmin">Manage Customer Booking</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">{{(type === 'edit') ? 'Edit' : 'Add'}}</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-customeradmin.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">{{(type === 'edit') ? 'Edit' : 'Add'}} Customer Admin</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/managecustomeradmin"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>


  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="container-fluid">
            <form [formGroup]="customeradmin">
              <div class="row justify-content-between mx-4 ">
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Base Location<span class="mandatory">*</span></label>
                    <div class="input-group-append">
                      <mat-select formControlName="base_Location" [(ngModel)]="base_Location" class="form-control"
                        [ngClass]="{'is-invalid': submitted && f['base_Location'].errors }"
                        placeholder="Please Base Location" (selectionChange)="onchangebill($event.value)" #baselocation>
                        <mat-select-filter *ngIf="baselocation.focused" [placeholder]="'Search'"
                          [displayMember]="'branch_name'" [array]="getCountryMasterData"
                          (filteredReturn)="baselocationlist = $event"></mat-select-filter>

                        <mat-option *ngFor="let branch of baselocationlist" [value]="branch.branch_id">
                          {{branch.branch_name}}
                        </mat-option>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="base_Location">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="baseclose(); $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                    <div *ngIf="submitted && f['base_Location'].errors" class="text-danger">
                      <div *ngIf="f['base_Location'].errors">Base location is required</div>
                    </div>
                  </div>
                </div>


                <div class="col-md-5">
                  <div class="form-group">

                    <label>Location Authorised <span class="mandatory">*</span></label>
                    <div class="input-group-append">
                      <mat-select (ngModelChange)="locationAuthorised_selectedFoods"
                        [(ngModel)]="locationAuthorised_selectedFoods"
                        [ngClass]="{ 'is-invalid': submitted && f['locationAuthorised'].errors }"
                        formControlName="locationAuthorised" class="form-control select_data" multiple
                        placeholder="Please Location Authorised" (selectionChange)="onchangelocation($event.value)"
                        #select4>
                        <mat-select-filter *ngIf="select4.focused" [placeholder]="'Search'"
                          [displayMember]="'location_name'" [array]="getlocation"
                          (filteredReturn)="filteredList4 = $event">
                        </mat-select-filter>
                        <mat-option *ngFor="let branchd of filteredList4" [value]="branchd.location_id">
                          {{branchd.location_name}}
                        </mat-option>
                        <div style="margin: 10px; float: right;"></div>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="locationAuthorised_selectedFoods">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="locationclose(); $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                    <div *ngIf="submitted && f['locationAuthorised'].errors" class="text-danger">
                      <div *ngIf="f['locationAuthorised'].errors">Location Authorised is required</div>
                    </div>
                  </div>
                </div>


                <div class="col-md-5">
                  <div class="form-group">
                    <label>Customer Group<span class="mandatory">*</span></label>
                    <div class="input-group-append">
                      <mat-select [(ngModel)]="customer_Group" formControlName="customer_Group" class="form-control"
                        [ngClass]="{'is-invalid': submitted && f['customer_Group'].errors }"
                        (selectionChange)="getcustomerGroupName($event.value)" placeholder="Pease Customer Group"
                        #select>
                        <mat-select-filter *ngIf="select.focused" [placeholder]="'Search'"
                          [displayMember]="'customer_group_name'" [array]="getcustomergroupdata"
                          (filteredReturn)="filteredList2 = $event">
                        </mat-select-filter>
                        <mat-option *ngFor="let group of filteredList2" [value]="group.customer_group_id">
                          {{group.customer_group_name}}
                        </mat-option>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="customer_Group">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="customergroupclose(); $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                    <div *ngIf="submitted && f['customer_Group'].errors" class="text-danger">
                      <div *ngIf="f['customer_Group'].errors">Customer Group is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Customer Name<span class="mandatory">*</span></label>
                    <div class="input-group-append">
                      <mat-select [(ngModel)]="customer_Name" formControlName="customer_Name" class="form-control select_data"
                        [ngClass]="{'is-invalid': submitted && f['customer_Name'].errors }"
                        placeholder="Please Customer Name" (selectionChange)="getcustoName($event.value)" multiple
                        #select3>
                        <mat-select-filter *ngIf="select3.focused" [placeholder]="'Search'"
                          [displayMember]="'customer_name'" [array]="getcustomer"
                          (filteredReturn)="filteredList3 = $event">
                        </mat-select-filter>
                        <mat-option *ngFor="let data of filteredList3" [value]="data.customers_id">
                          {{data.customer_name}}
                        </mat-option>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="customer_Name">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="cusname(); $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                    <div *ngIf="submitted && f['customer_Name'].errors" class="text-danger">
                      <div *ngIf="f['customer_Name'].errors">customer Name is required</div>
                    </div>
                  </div>
                </div>



                <div class="col-md-5" *ngIf=" !custrole">
                  <div class="form-group">
                    <label>Customer Role<span class="mandatory">*</span></label>
                    <div class="input-group-append" [hidden]="type == 'edit' && this.isEdit == true">
                      <mat-select [(ngModel)]="customer_Role" formControlName="customer_Role" class="form-control"
                        [ngClass]="{'is-invalid': submitted && f['customer_Role'].errors }"
                        placeholder="Please Customer Role" #task>
                        <mat-select-filter *ngIf="task.focused" [placeholder]="'Search'"
                          [displayMember]="'customer_Role_Authorised_name'" [array]="getcustomerroledata1"
                          (filteredReturn)="filteredList7 = $event">
                        </mat-select-filter>
                        <mat-option *ngFor="let role of filteredList7" [value]="role.customer_Role_Authorised">
                          {{role.customer_Role_Authorised_name }}
                        </mat-option>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="customer_Role">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="customer_Role=''; $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>

                    <input type="text" class="form-control" *ngIf="type == 'edit' &&  this.isEdit == true"
                      value="{{roleName}}" readonly>

                    <div *ngIf="submitted && f['customer_Role'].errors" class="text-danger">
                      <div *ngIf="f['customer_Role'].errors">Customer Role is required</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-5" *ngIf="custrole">

                  <div class="form-group">
                    <label>Customer Role<span class="mandatory">*</span></label>
                    <div class="input-group-append" [hidden]="type == 'edit' && this.isEdit == true">
                      <mat-select [(ngModel)]="customer_Role" formControlName="customer_Role" class="form-control"
                        required [ngClass]="{'is-invalid': submitted && f['customer_Role'].errors }"
                        placeholder="Please Customer Role" #select5>
                        <mat-select-filter *ngIf="select5.focused" [placeholder]="'Search'"
                          [displayMember]="'role_name'" [array]="getcustomerroledata"
                          (filteredReturn)="filteredList8 = $event">
                        </mat-select-filter>
                        <mat-option *ngFor="let role of filteredList8" [value]="role.id">
                          {{role.role_name }}
                        </mat-option>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="customer_Role">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="customer_Role=''; $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>

                    <input type="text" class="form-control" *ngIf="type == 'edit' && this.isEdit == true"
                      value="{{roleName}}" readonly>

                    <div *ngIf="submitted && f['customer_Role'].errors" class="text-danger">
                      <div *ngIf="f['customer_Role'].errors">Customer Role is required</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-5" *ngIf="custrole">
                  <div class="form-group">
                    <label>Allocated Customer Role<span class="mandatory">*</span></label>
                    <div class="input-group-append">
                      <mat-select [(ngModel)]="customer_Role_Authorised" formControlName="customer_Role_Authorised"
                        required class="form-control" multiple
                        [ngClass]="{'is-invalid': submitted && f['customer_Role_Authorised'].errors }"
                        placeholder="Please Customer Role" #select5x>
                        <mat-select-filter *ngIf="select5x.focused" [placeholder]="'Search'"
                          [displayMember]="'role_name'" [array]="getcustomerroledata"
                          (filteredReturn)="filteredList5 = $event">
                        </mat-select-filter>
                        <mat-option *ngFor="let role of filteredList5" [value]="role.id">
                          {{role.role_name }}
                        </mat-option>
                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="customer_Role_Authorised">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="customer_Role_Authorised=''; $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                    <div *ngIf="submitted && f['customer_Role_Authorised'].errors" class="text-danger">
                      <div *ngIf="f['customer_Role_Authorised'].errors">Customer Role is required</div>
                    </div>
                  </div>
                </div>









                <div class="col-md-5">
                  <div class="form-group">
                    <label>Customer Admin Name<span class="mandatory">*</span></label>

                    <input type="text" maxlength="50" formControlName="customer_Admin_Name"
                      [(ngModel)]="customer_Admin_Name" class="form-control" placeholder="Enter Customer  Name"
                      (keypress)="general.removingSpaceFirst($event, f.customer_Name.value)"
                      [ngClass]="{'is-invalid': submitted && f['customer_Admin_Name'].errors }">

                    <div *ngIf="submitted && f['customer_Admin_Name'].errors" class="text-danger">
                      <div *ngIf="f['customer_Admin_Name'].errors">customer admin name is required</div>
                    </div>
                  </div>
                </div>



                <div class="col-md-5">
                  <div class="form-group">
                    <label>Admin Mobile<span class="mandatory">*</span></label>
                    <input type="text" [(ngModel)]="customer_Admin_mobile" formControlName="customer_Admin_mobile"
                      class="form-control" placeholder="Customer Admin Mobile" maxlength="13"
                      (keyup)="onkeypress($event.target.value)"
                      [ngClass]="{'is-invalid': submitted && f['customer_Admin_mobile'].errors }"
                      (keypress)="general.removingSpaceFirst($event, f.customer_Admin_mobile.value)">
                    <div *ngIf="submitted && f['customer_Admin_mobile'].errors" class="invalid-feedback">
                      <div *ngIf="f['customer_Admin_mobile'].errors.required">Admin Mobile is required</div>
                    </div>

                    <div *ngIf="f['customer_Admin_mobile'].errors !== null">
                      <div
                        *ngIf="f['customer_Admin_mobile'].errors.minlength && !f['customer_Admin_mobile'].errors.pattern?.requiredPattern"
                        class="cinvalid-feedback">
                        Admin Mobile Number Minimum 10 to 13 digits
                      </div>
                      <div *ngIf="f['customer_Admin_mobile'].errors.pattern?.requiredPattern" class="cinvalid-feedback">
                        Please enter the valid mobile number
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Customer Admin Mail ID<span class="mandatory">*</span></label>
                    <input type="text" maxlength="50" (keyup)="checkemail($event.target.value)"
                      formControlName="customer_Admin_MailId" class="form-control"
                      placeholder="Enter Customer Admin Mail ID" autocomplete="off" [(ngModel)]="customer_Admin_MailId"
                      (keypress)="general.removingSpaceFirst($event, f.customer_Admin_MailId.value)"
                      [ngClass]="{'is-invalid': submitted && f['customer_Admin_MailId'].errors }">
                    <div *ngIf="submitted && f['customer_Admin_MailId'].errors" class="invalid-feedback">
                      <div *ngIf="f['customer_Admin_MailId'].errors">Customer Admin Mail ID is required</div>
                    </div>
                    <div *ngIf="emailexist === true" class="cinvalid-feedback">
                      Customer Admin Mail ID is already exist
                    </div>
                  </div>
                </div>


                <div class="col-md-5">
                  <div class="form-group">
                    <label>Customer Admin Password</label>
                    <input type="password" maxlength="50" autocomplete="off" formControlName="customer_Admin_Password"
                      class="form-control" placeholder="Enter Customer Admin Password"
                      [(ngModel)]="customer_Admin_Password "
                      (keypress)="general.removingSpaceFirst($event, f.customer_Admin_Password.value)">
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">

                    <label>Customer Admin Employee ID <span class="mandatory"></span></label>
                    <input type="text" maxlength="10" formControlName="Customer_Admin_Employee_ID" class="form-control"
                      placeholder="Enter Customer Admin Employee ID" [(ngModel)]="Customer_Admin_Employee_ID"
                      (keypress)="general.removingSpaceFirst($event, f.Customer_Admin_Employee_ID.value)">
                  </div>
                </div>


                <div class="col-md-5">
                  <div class="form-group">
                    <label>Customer Admin Department <span class="mandatory"></span></label>
                    <input type="text" maxlength="50" formControlName="Customer_Admin_Department" class="form-control"
                      placeholder="Enter Customer Admin Department" [(ngModel)]="Customer_Admin_Department"
                      (keypress)="general.removingSpaceFirst($event, f.Customer_Admin_Department.value)">
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Customer Admin Designation <span class="mandatory"></span></label>
                    <input type="email" maxlength="50" formControlName="Customer_Admin_Designation" class="form-control"
                      placeholder="Enter Customer Admin Designation"
                      (keypress)="general.removingSpaceFirst($event, f.Customer_Admin_Designation.value)"
                      [(ngModel)]="Customer_Admin_Designation">
                  </div>
                </div>


                <div class="col-md-5">
                  <div class="form-group">

                    <label>Customer Admin DOB <span class="mandatory"></span></label>

                    <mat-form-field class="form-control" onkeydown="return false">

                      <input autocomplete="off" matInput (click)="picker.open()" [matDatepicker]="picker"
                        formControlName="CustomerAdminDOB" [ngModel]="CustomerAdminDOB | date:'yyyy-MM-dd'"
                        (ngModelChange)="CustomerAdminDOB = $event">

                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                  </div>
                </div>

                <div *ngIf="type != 'edit'" class="col-md-5 form-group">
                  <label>Status <span class="mandatory"></span></label>
                  <div>
                    <mat-slide-toggle class="status" (change)="changetoggle($event.checked)" formControlName="status"
                      [checked]="status === 'ACTIVE'">
                      <div>{{labelStatus}}</div>
                    </mat-slide-toggle>

                  </div>
                </div>

                <div *ngIf="type == 'edit'" class="col-md-5 form-group">
                  <label>Status <span class="mandatory"></span></label>
                  <div>
                    <mat-slide-toggle class="status" (change)="changetoggle($event.checked)" formControlName="status">
                      <div>{{labelStatus}}</div>

                    </mat-slide-toggle>
                  </div>
                </div>
              </div><br>




              <div class="aligh-button-right">
                <button mat-button class="mat-button-cancel" [disabled]="isDisableBtn"
                  *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="logdata()">Logs</button>

                  <button routerLink="/managecustomeradmin" class="mat-button-cancel">Cancel</button>
                  <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
                    *ngIf="type != 'edit' && helperService.checkMenuAccess('r_create')" (click)="submit()">Submit</button>
                  <button mat-button class="mat-button-submit" [disabled]="isDisableBtn"
                    *ngIf="type == 'edit' && helperService.checkMenuAccess('r_update')" (click)="submit()">Update</button>
              </div>

            </form>
          </div>


        </div>
      </div>
    </div>
    <ng-template #secondDialog>
      <div class="d-flex justify-content-between custom-dialog-height">
        <label style="color: black; font-weight: bold !important;">Customer Admin Logs</label>
        <mat-icon (click)="closeDialog()" class="pointer">close</mat-icon>
      </div>
      <div class="table" style="overflow-x: auto;max-height: 350px;overflow-y: scroll;">
        <table id="customers" style="border: 1px solid black;border-collapse: collapse; width: 100%;">
          <tr style="height: 10%;">
            <th>sl.no</th>
            <!-- <th>Employee Id</th> -->
            <th style="width: 30%;">User Name</th>
            <th style="width: 70%;">Date and Time</th>
            <th>Action</th>
            <!-- <th style="width: 30%;">Guest Mail Id</th> -->

          </tr>
          <tr *ngFor="let data of loglist; let i = index" class="pointer" (click)="viewdata(data)">
            <td style="font-size: 14px;word-break: break-all !important;
          white-space: pre-wrap !important;">{{i+1}}</td>
            <td style="font-size: 14px;word-break: break-all !important;
          white-space: pre-wrap !important;">{{data.user_name}}</td>
            <td style="font-size: 14px;word-break: break-word !important;
          white-space: pre-wrap !important;">{{data.date_time | date: 'dd-MM-yyyy h: mm a'}}</td>
            <td style="font-size: 14px;">{{data.action}}</td>

          </tr>
        </table>
        <div *ngIf="this.loglist?.length === 0" style="text-align: center">
          No records found
        </div>
      </div>
    </ng-template>

  </section>
</div>