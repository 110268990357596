import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BackendserviceService } from './backendservice.service';

@Injectable({
  providedIn: 'root'
})
export class Managecustomer {
  public user: any = null;

  constructor(
    private backendService: BackendserviceService,
  ) { }

  public getcustomerList(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/list', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public create(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/create', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getManageCustomerguestbyid(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/getbyid', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public updateManageCustomerguest(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/update', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getCustomer(data): Observable<{}> {
    return this.backendService
      .post('api/customeradmin/customerNamebyGroup', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getAllCustomer(data): Observable<{}> {
    return this.backendService
      .post('api/customeradmin/customerName/all', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getCustomers(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/customerNamebyGroup', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getCustomerlocation(data): Observable<{}> {
    return this.backendService
      .post('api/customeradmin/locationList', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getGuestSearch(data): Observable<{}> {
    return this.backendService
      .post('api/booking/GuestSearchDropdown', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getGuestDataSearch(data): Observable<{}> {
    return this.backendService
      .post('api/booking/GuestSearch', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getLogData(data): Observable<{}> {
    return this.backendService
      .post('api/log/customeradminlogs', data)
      .pipe(retry(1), catchError(this.handleError));
  }
  public guestLogData(data): Observable<{}> {
    return this.backendService
      .post('api/log/customerguestlogs', data)
      .pipe(retry(1), catchError(this.handleError));
  }
  public directLogData(data): Observable<{}> {
    return this.backendService
      .post('api/log/directguestlogs', data)
      .pipe(retry(1), catchError(this.handleError));
  }
  public webLogData(data): Observable<{}> {
    return this.backendService
      .post('api/log/bookinglogs', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getCustomeradmin(data): Observable<{}> {
    return this.backendService
      .post('api/customeradmin/customerAdminList', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getCustomerrental(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/rentalTypeList', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getCustomerlocationlist(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/getLocation', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getCustomervechile(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/vechicleList', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getPrevillage(data): Observable<{}> {
    return this.backendService
      .post('api/user/getrole', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public getVechileData(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/getvehicle', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public rentalData(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/getrentaltype', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public createCustomerGuest(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/getrentaltype', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public updateCustomerGuestStatus(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/updatestatus', data)
      .pipe(retry(1), catchError(this.handleError));
  }
  public getCustomerGuestbyId(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/getbyid', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public delete(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/delete', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public deletedata(data): Observable<{}> {
    return this.backendService
      .post('api/booking/DeleteBooking', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public checkcustomeradminemail(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/checkemail', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public checkcustomeradminmobile(data): Observable<{}> {
    return this.backendService
      .post('api/customerguest/checkemobile', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public createImportExportUpload(data): Observable<{}> {
    return this.backendService.post("api/customerguest/import", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public createImportExportUploadWebBooking(data): Observable<{}> {
    return this.backendService.post("api/booking/Import", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public getpassword(data): Observable<{}> {
    return this.backendService.post("api/customeradmin/changepassword", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public validationexcel(data): Observable<{}> {
    return this.backendService.post("api/customerguest/validateCustomerguest", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public validationexcelWebBooking(data): Observable<{}> {
    return this.backendService.post("api/booking/ValidateuploadFile", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public getweblist(data): Observable<{}> {
    return this.backendService.post("api/booking/WebBookingList", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public getGuestlist(data): Observable<{}> {
    return this.backendService.post("api/booking/GuestBookingList", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public getmanagelist(data): Observable<{}> {
    return this.backendService.post("api/booking/ManageBookingList", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public getManageListCount(data): Observable<{}> {
    return this.backendService.post("api/booking/ManageBookingCount", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public customerdropdown(data): Observable<{}> {
    return this.backendService.post("api/booking/CustomerDropdown", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public rentalcitydropdown(data): Observable<{}> {
    return this.backendService.post("api/booking/RentalCityDropdown", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public bookingtypedropdown(data): Observable<{}> {
    return this.backendService.post("api/booking/BookingTypeDropdown", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public vechilemodeldropdown(data): Observable<{}> {
    return this.backendService.post("api/booking/VehicleModelDropdown", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public rentaltypedropdown(data): Observable<{}> {
    return this.backendService.post("api/booking/RentalTypeDropdown", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public paymentdropdown(data): Observable<{}> {
    return this.backendService.post("api/booking/PaymentTypeDropdown", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public prefixdropdown(data): Observable<{}> {
    return this.backendService.post("api/booking/PrefixDropdown", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public billing(data): Observable<{}> {
    return this.backendService.post("api/booking/GetBillingLocation", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public rejectlist(data): Observable<{}> {
    return this.backendService.post("api/booking/WebBookingReject", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public guestrejectlist(data): Observable<{}> {
    return this.backendService.post("api/booking/Reject", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public guestapprovelist(data): Observable<{}> {
    return this.backendService.post("api/booking/GuestBookingUpdate", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public getbookingdata(id): Observable<{}> {
    return this.backendService
      .post('api/booking/GetBookingListById', id)
      .pipe(retry(1), catchError(this.handleError));
  }
  public getprint(id): Observable<{}> {
    return this.backendService
      .post('api/booking/printbookingdetails', id)
      .pipe(retry(1), catchError(this.handleError));
  }

  public updatedata(data): Observable<{}> {
    return this.backendService
      .post('api/booking/update', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  public createwebbook(data): Observable<{}> {
    return this.backendService.post("api/booking/create", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  
  public actiontakendata(data): Observable<{}> {
    return this.backendService.post("api/booking/getPanicAlertlist", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public getpanicalertId(data): Observable<{}> {
    return this.backendService.post("api/booking/getPanicAlertlistbyId", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public createpanic(data): Observable<{}> {
    return this.backendService.post("api/booking/updatePanicAlertlist", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public viewtrack(data): Observable<{}> {
    return this.backendService.post("api/booking/ViewTrack", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  public getmodeviewdetail(data): Observable<{}> {
    return this.backendService.post("api/booking/TripSheetbyid", data)
      .pipe(retry(1),
        catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage: string = '';
    if (error.error instanceof ErrorEvent) {
      //ClientSide Error--------
      errorMessage = `Error:${error.error.message}`;
    } else {
      //Server side Error---------
      errorMessage = `Status:${error.status}\n
                      Message:${error.message}`;
    }
    return throwError(errorMessage);
  }
}
