<div class="container-fluid">
    <div class="row">
    <div class="col-md-6"><b> {{(actiontaken === null || actiontaken === '' ) ? 'No Action Taken' : 'Action Taken'}} </b></div>
    <div class="col-md-6">
      
        <mat-icon class="material-icons-outlined pointer float-right"  [mat-dialog-close]="'close'">
          close</mat-icon>
      </div>
      </div>
</div>
<hr>
<div class="card">
    <div class="card-body">
        <div class="row">
          
               
                <div class="col-md-3">
                    <label class="view-label">Alert Date</label>
                   <p class="view-data">{{alertdate}}</p>
                </div>
                <div class="col-md-3">
                    <label class="view-label">Billing Location</label>
                    <p><b>{{billloc}}</b></p>
            
                </div>
                <div class="col-md-3">
                    <label class="view-label">Rental City</label>
                    <p class="view-data">{{rental}}</p>

                   
                </div>
                <div class="col-md-3">
                    <label class="view-label">Trip No</label>
                    <p class="view-data">{{trip}}</p>

                 
                </div>
                <div class="col-md-3">
                    <label class="view-label">Guest Name</label>
                    <p class="view-data">{{guestname}}</p>

                   
                </div>
                <div class="col-md-3">
                    <label class="view-label">CE Name</label>
                    <p class="view-data">{{cename}}</p>
                    

                   
                </div>
                <div class="col-md-3">
                    <label class="view-label">Vechile</label>
                    <p class="view-data">{{vechiledat}}</p>
                   
                </div>
           
    </div>
    <form [formGroup]="actionform">
        <div class="row " >
            <div class="col-md-12" >
                <label class="view-label">Action Taken</label><span class="mandatory"> *</span>
                <p  *ngIf="actiontaken != null || actiontaken != '' " ><b>{{actiontaken}}</b></p>
                <textarea *ngIf="actiontaken == null || actiontaken == '' "  class="form-control"  placeholder="Write here..." formControlName="action_taken" ></textarea>
            </div>
        </div>
 

    <div class="savegroup" >
        <button  routerLink="/reports/panic-alert"  [mat-dialog-close]="'close'"  class="btn btn-secondary grey">Cancel</button>&nbsp;
        <button *ngIf="actiontaken == null || actiontaken == '' " class="btn btn-primary"  (click)="submit()"
          >Submit</button>
       
      </div>
    </form>
    </div>
</div>

