import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {ButtonComponent} from './components/button/button.component';

import {DatePipe, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {DropdownMenuComponent} from './components/dropdown/dropdown-menu/dropdown-menu.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import { SelectComponent } from './components/select/select.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ManagecustomeradminComponent } from './pages/managecustomeradmin/managecustomeradmin.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { MatStepperModule} from '@angular/material/stepper';
import { MatExpansionModule} from '@angular/material/expansion';

import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
// import { SimplebarAngularModule } from 'simplebar-angular';

registerLocaleData(localeEn, 'en-EN');
import {MatPaginatorModule} from '@angular/material/paginator';
import { ViewManagecustomeradminComponent } from './pages/managecustomeradmin/view-managecustomeradmin/view-managecustomeradmin.component';
import { ManagedirectguestComponent } from './pages/managedirectguest/managedirectguest.component';
import { AddManagedirectguestComponent } from './pages/managedirectguest/add-managedirectguest/add-managedirectguest.component';
import { AddManagecustomeradminComponent } from '@pages/managecustomeradmin/add-managecustomeradmin/add-managecustomeradmin.component';
import { ViewDirectguestComponent } from './pages/managedirectguest/view-directguest/view-directguest.component';
import { ManagecustomerguestComponent } from './pages/managecustomerguest/managecustomerguest.component';
import { AddManagecustomerguestComponent } from './pages/managecustomerguest/add-managecustomerguest/add-managecustomerguest.component';
import { ViewCustomerguestComponent } from './pages/managecustomerguest/view-customerguest/view-customerguest.component';
import { DialogComponentComponent } from './pages/dialog-component/dialog-component.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ImportExportComponent } from './pages/import-export/import-export.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HelperService } from '@services/helper.service';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { GuestbookingComponent } from './pages/guestbooking/guestbooking.component';
import { AddBlankComponent } from './pages/blank/add-blank/add-blank.component';
import { ContractinvoiceComponent } from './pages/contractinvoice/contractinvoice.component';
import { AddContractinvoiceComponent } from './pages/contractinvoice/add-contractinvoice/add-contractinvoice.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ViewGuestbookingComponent } from './pages/guestbooking/view-guestbooking/view-guestbooking.component';
import { EditGuestbookingComponent } from './pages/guestbooking/edit-guestbooking/edit-guestbooking.component';
import { ViewBlankComponent } from './pages/blank/view-blank/view-blank.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatSelectFilterModule } from 'mat-select-filter';

import { MatNativeDateModule } from '@angular/material/core';

import { ManagebookingComponent } from './pages/managebooking/managebooking.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AddWebbookingComponent } from './pages/managebooking/add-webbooking/add-webbooking.component';
import { ViewWebbookingComponent } from './pages/managebooking/view-webbooking/view-webbooking.component';
import { ImportWebbookingComponent } from './pages/import-webbooking/import-webbooking.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
// import { NgxPrintElementModule } from 'ngx-print-element';
import { TimeformatPipe } from '@services/timeformat.pipe';
import { CustomPaginatorComponent } from './pages/custom-paginator/custom-paginator.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OutstandingComponent } from './pages/outstanding/outstanding.component';
import { TripSheetComponent } from './pages/trip-sheet/trip-sheet.component';
import { ReportComponent } from './pages/report/report.component';
import { ViewContractInvoiceComponent } from '@pages/contractinvoice/view-contractinvoice/view-contractinvoice.component';
import { HistoryConfirmation } from '@pages/history-confirmations/history-confirmations.component';
import { PanicAlert } from '@pages/panic-alert/panic-alert.component';
import { HistoryReport } from '@pages/history-confirmations/history-action/history-report.component';
import { ViewpanicComponent } from './pages/viewpanic/viewpanic.component';
import { OutstandingPayment } from '@pages/outstanding/outstanding-payments/outstanding-payments.component';
import { ViewtrakingComponent } from './pages/viewtraking/viewtraking.component';
import { PrintpageComponent } from './pages/printpage/printpage.component';
import { ViewmodeComponent } from './pages/viewmode/viewmode.component';
import { OnlyNumberDirective } from './services/common/only-number.directive';
import { SafePipe } from '@pages/viewtraking/safe.pipe';


//   import { NgxPrintModule } from 'ngx-print';

//import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

//import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        ButtonComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        PrivacyPolicyComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        DropdownComponent,
        DropdownMenuComponent,
        ControlSidebarComponent,
        SelectComponent,
        CheckboxComponent,
        ManagecustomeradminComponent,
        ViewManagecustomeradminComponent,
        ManagedirectguestComponent,
        ManagedirectguestComponent,
        AddManagedirectguestComponent,
        AddManagecustomeradminComponent,
        ViewDirectguestComponent,
        ManagecustomerguestComponent,
        AddManagecustomerguestComponent,
        ViewCustomerguestComponent,
        DialogComponentComponent,
        ImportExportComponent,
        GuestbookingComponent,
        AddBlankComponent,
        ContractinvoiceComponent,
        AddContractinvoiceComponent,
        ViewContractInvoiceComponent,
        ViewGuestbookingComponent,
        EditGuestbookingComponent,
        ViewBlankComponent,
        ManagebookingComponent,        
        AddWebbookingComponent,
        ViewWebbookingComponent,
        ImportWebbookingComponent,
        TimeformatPipe,
        CustomPaginatorComponent,
        OutstandingComponent,
        TripSheetComponent,
        ReportComponent,
        HistoryConfirmation,
        PanicAlert,
        HistoryReport,
        ViewpanicComponent,
        OutstandingPayment,
        ViewtrakingComponent,
        PrintpageComponent,
        ViewmodeComponent,
        OnlyNumberDirective, 
        SafePipe,
      
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        MatTableModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatIconModule,
        MatDividerModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatMenuModule,
        FormsModule,
        MatPaginatorModule,
        MatDialogModule,
        MatTabsModule,
        MatCheckboxModule,  
        MatStepperModule,   
        MatExpansionModule,   
        MatTableExporterModule.forRoot({xlsxLightWeight: true}),
        MatProgressSpinnerModule,
        // SimplebarAngularModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatSelectFilterModule,
        //   NgxPrintModule,
        //NgxMatSelectSearchModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        MatTooltipModule,
        MatNativeDateModule,
        GooglePlaceModule,
        NgxMaterialTimepickerModule,
        
        //  NgxPrintElementModule
        
    ],
    
    providers: [
        DatePipe,
        HelperService,
        TimeformatPipe
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
