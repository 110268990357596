<div class="mx-4">
  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/">Dashboard</span> / <span class="breadcrumb-item activebreadcrumb-item active">Manage Direct
          Guest</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-customerguest.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Manage Direct Guest</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">

      <div class="card">
        <div class="card-body">

          <!-- <div class="col-md-12"> -->
            <div class="row justify-content-between">
              <div class="col-md-6">
                <h4 style="font-weight:600; color: #233771;">Details</h4>
              </div>
              <div class="col-md-6">
                <div class="row justify-content-end">
                  <div class="col-md-7">
                    <div class="search-box pull-center">
                      <div class="search-input">
                        <mat-icon role="img" matprefix="true"
                          class="mat-icon notranslate  material-icons mat-icon-no-color icon-grey" aria-hidden="true"
                          data-mat-icon-type="font">search</mat-icon><input placeholder="Search" class="form-control"
                          (keyup)="applyFilter($event.target.value)">
                      </div>
                    </div>
                  </div>

                  <div class="col-auto">
                    <mat-icon *ngIf="helperService.checkMenuAccess('r_create')" title="Add" type="button"
                      (click)="addmanagedirectguest()" class="text-right plus mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/Add.png');"></mat-icon>
                  </div>
                </div>
              </div>
            </div>
          <!-- </div> -->

          <div class="col-md-12 mt-2 table-responsive">
            <table [border]="1" mat-table [dataSource]="dataSource" class="table-striped pointer" matSort>

              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell *matCellDef="let element; let i = index" (click)="edit(element.id)">
                  {{paginator.pageIndex == 0 ? i + 1 : 1 + i + paginator.pageIndex * paginator.pageSize}}</td>
              </ng-container>

              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef> Location </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Location_name"
                    [matTooltipPosition]="'above'">{{element.Location_name}}</span>
                </td>

              </ng-container>

              <ng-container matColumnDef="guest_name">
                <th mat-header-cell *matHeaderCellDef> Guest name</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Guest_Name" [matTooltipPosition]="'above'">
                    {{element.Guest_Name}}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="mobile">
                <th mat-header-cell *matHeaderCellDef> Guest Mobile</th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Guest_Mobile_Number" [matTooltipPosition]="'above'">
                    {{element.Guest_Mobile_Number}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="guest_MailID">
                <th mat-header-cell *matHeaderCellDef> Guest email </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Guest_Email" [matTooltipPosition]="'above'">
                    {{element.Guest_Email}} </span>
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="guest_ID">
              <th mat-header-cell *matHeaderCellDef> Guest ID </th>
              <td mat-cell *matCellDef="let element"   (click)="edit(element.id)"> {{element.id}} </td>
            </ng-container> -->

              <ng-container matColumnDef="creditlimit">
                <th mat-header-cell *matHeaderCellDef> Credit Limit (₹) </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                  <span [matTooltip]="element.Credit_Limit" [matTooltipPosition]="'above'">
                    {{element.Credit_Limit}} </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"><span class="green-action pointer"
                    [ngClass]="{'green-danger':element.status !== 'ACTIVE', 'green-action':element.status === 'ACTIVE'}"
                    (click)="statusManager(element)">{{element.status | titlecase}}</span></td>

              </ng-container>


              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef> ACTION </th>
                <td class="edit" mat-cell *matCellDef="let element">
                  <div
                    *ngIf="helperService.checkMenuAccess('r_read') || helperService.checkMenuAccess('r_update') || helperService.checkMenuAccess('r_delete')">

                    <button mat-icon-button [matMenuTriggerFor]="menu" class="action"
                      aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">

                      <button (click)="view(element.id)" *ngIf="helperService.checkMenuAccess('r_read')" mat-menu-item>
                        <mat-icon role="img"
                          class="mat-icon notranslate material-icons eye align-middle pointer hover-color"
                          aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon>View
                      </button>
                      <button *ngIf="helperService.checkMenuAccess('r_update')" (click)="edit(element.id)"
                        mat-menu-item>

                        <mat-icon class="edit align-middle pointer hover-color">edit</mat-icon>Edit
                      </button>
                      <button mat-menu-item *ngIf="helperService.checkMenuAccess('r_delete')"
                        (click)="delete(element.id)">
                        <mat-icon class="delete align-middle pointer hover-color">delete</mat-icon>Delete
                      </button>








                    </mat-menu>
                  </div>
                  <div
                    *ngIf="!helperService.checkMenuAccess('r_read') && !helperService.checkMenuAccess('r_update') && !helperService.checkMenuAccess('r_delete')">
                    -
                  </div>
                </td>

              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading" [pageSizeOptions]="[10,25,50]"
              justify-content="space-between"></custom-paginator>

            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 30, 50]" [pageSize]="10"></mat-paginator> -->

            <!-- mat-paginator -->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>