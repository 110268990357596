
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Managecustomer } from '@services/managecustomerguest.service';
import { AppService } from '@services/customeradmin.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@services/helper.service';
import { GeneralService } from '@services/general.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { log } from 'node:console';

@Component({
  selector: 'app-add-managecustomerguest',
  templateUrl: './add-managecustomerguest.component.html',
  styleUrls: ['./add-managecustomerguest.component.scss']
})
export class AddManagecustomerguestComponent implements OnInit {
  customerguest !: FormGroup;
  submitted = false
  emailexist: boolean;
  mobexist: boolean;

  branches: any;
  labelStatus: any;
  customers_id: any;
  customer_id_drop: any
  status: any
  statuss: any
  cstatus: string = 'ACTIVE'
  respo: any;
  customer_name: any;
  customer_Admin_Name: any;
  customersgroup: [];
  customerdata: any = [];
  vechleList: [];
  Customer_Admin: any;
  rentalTypeList: [];
  adminlist: any = [];
  vechileist: any = [];
  locationlist: any = [];
  vechile: any = [];
  managecustomerdata: any;
  smssent: any;
  public type: string = 'add'
  buttonText: string = 'Submit'
  public id: any;
  public userid: any;
  customer_Group: any;
  respocustomersgroup: any
  customer: any
  loc_name: any;
  employee_Location: any;
  employee_name: any;
  employee_Mobile_Number: any;
  employee_Email_Id: any;
  employee_Id: any;
  customer_Admin: any;
  Credit_Limit: any;
  Vehicle: any;
  customer_Name: any;
  rental_Type: any;
  location: any;
  Direct_Booking: any;
  old_email: any
  loc: any = [];
  loc1: any;
  loc2: any;
  locn_id: any;
  customer_value: any;
  vec: any = [];
  customersgroups: [];
  lessthanten = false
  public isDisableBtn: boolean = false;
  vechilemodel: any = [];
  public filteredList = [];
  public filtersearch1 = [];
  public filtersearch2 = [];
  public filtersearch3 = [];
  public filtersearch4 = [];
  public filtersearch5 = [];
  public filtersearch6 = [];
  branchesdata = [];
  getcustomerdata: [];
  getcustomerdatas: [];
  public creditlimit: boolean = false;
  forzaadmin: any;

  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;
  @ViewChild('select') select: MatSelect;
  @ViewChild('select1') select1: MatSelect;

  allSelected = false;
  allSelected1 = false;
  searchMobData: any = [];
  public loglist: [];
  user_name: any;
  date_time: any;
  userId: any;
  public msg: any;
  user_id: any;

  showdrpdwn: boolean = true;


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private managecustomerguests: Managecustomer,
    private appService: AppService,
    private toastr: ToastrService,
    public activatedRoute: ActivatedRoute,
    public helperService: HelperService,
    public general: GeneralService,
    public dialog: MatDialog,
    private managecustomer: Managecustomer,
  ) { }

  ngOnInit(): void {
    if (this?.activatedRoute?.snapshot?.url[1]?.path) {
      this.id = atob(this.activatedRoute.snapshot.url[1].path);


      if (this.activatedRoute.snapshot.url[1].path == 'add') {
        this.type == 'add'
        this.helperService.pageAccess('r_create')
      } else {
        this.helperService.pageAccess('r_update')
        this.type = this.activatedRoute.snapshot.url[2].path;
        console.log(this.type);

      }
    }
    // this.userid = Number(localStorage.getItem('Id'));

    this.userId = Number(localStorage.getItem('Id'));

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    if (this.forzaadmin == 'Forza Admin') {
      this.creditlimit = true;
    }

    if (this.type == 'edit') {
      this.directGuestId();
    }

    this.getBranches();
    this.getcustomersgroup();
    this.getVechile();
    this.rentalType();

    this.customerguest = this.formBuilder.group({
      customer_Group: [null, Validators.required],
      customer: [null, Validators.required],
      employee_Location: [null, Validators.required],
      employee_name: [null, Validators.required],
      employee_Mobile_Number: ['', [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.minLength(10), Validators.maxLength(13)]],
      employee_Email_Id: [null, ([Validators.required, Validators.pattern(/^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i)])],
      employee_Id: [null],
      customer_Admin: [null, this.setRequired()],
      Credit_Limit: [(this.Credit_Limit != null) ? this.customerguest.value.Credit_Limit : ''],
      Vehicle: [null, Validators.required],
      rental_Type: [null, Validators.required],
      location: [null, Validators.required],
      // Direct_Booking: ["Direct", Validators.required],
      Direct_Booking: ["Direct", this.setRequired()],

      status: [true],
      smssent: [true]
    })
    if (this.customerguest.value.status === true) {
      this.labelStatus = "Active"
    }
    else if (this.customerguest.value.status === false) {
      this.labelStatus = "Deactive"
    }


  }

  onchangelocation(value) {
    this.loc = JSON.stringify(value);
    this.customer_Group = ''
    this.getcustomersgroup();
    this.customer = ''
    // this.employee_name = ''
    // this.employee_Mobile_Number = ''
    // this.employee_Email_Id = ''
    // this.employee_Id = ''
    this.Customer_Admin = ''
    this.location = ''
    this.Vehicle = ''
    this.rental_Type = ''

    // this.customersgroup = [];
    this.adminlist = [];
    this.filtersearch6 = [];
    // this.vechileist = [];
    //this.vechile = [];
    this.getcustomerdata = [];
    this.customer = 0;
    // this.getcustomerGroupName(Number(this.customer_Group))


  }

  onchangevechile(value) {


    this.loc2 = JSON.stringify(value);

    this.loc1 = value
    let arrayVal = []
    for (let i = 0; i < value.length; i++) {
      const element = value[i];
      let data = this.locationlist.filter(item => item.branch_id == Number(element))[0];
      if (data != undefined) {
        arrayVal.push(data.branch_name)
        this.locn_id = arrayVal;
      }
    }

    this.vechile = [];
    this.vechileist = [];
    this.Vehicle = '';
    this.rental_Type = '';

    let obj = {
      customers_id: this.customer_value,
      location: arrayVal
    }
    this.managecustomerguests.getCustomervechile(obj).subscribe(
      (response) => {
        this.respo = response;
        if (this.respo.success == true) {
          this.vechile = this.respo.Vehicle_List;
          this.vechile = this.vechile.sort((a, b) => {
            if (a.vehicle_model < b.vehicle_model) { return -1; }
            if (a.vehicle_model > b.vehicle_model) { return 1; }
          })
          this.filtersearch4 = this.vechile.slice();


        }
      });
  }

  closeDialog() {
    this.dialog.closeAll()
    this.searchMobData = []
  }
  logdata() {

    this.getPosts()
    this.dialog.open(this.secondDialog, {
      width: '70%',
    });

  }
  public viewdata(data: any) {
    this.searchMobData = []
    this.dialog.closeAll()
    this.user_name = data.user_name;
    this.date_time = data.date_time;
    // this.customerDropdown(this.billing_Location)
    this.customer_Name = data.customer;
    this.customer_Name = Number(data.customer)
    // this.rentaldropdown(this.customer_Name)
    let obj = {
      prefix: '',
      guest_name: data.guest_name,
      guest_Employeeid: data.employee_id,
      guest_mobile: data.guest_mobile,
      guest_Email: data.guest_mail_id
    }
    // this.childForm = [];
    // this.childForm.push(obj);
  }

  getPosts() {
    let obj = {
      id: this.id,
      user_id: this.userId,

      // Keyword: data.option.value
    }

    this.managecustomer.guestLogData(obj).subscribe((res => {
      this.msg = res;


      if (this.msg.success == true) {
        this.loglist = this.msg.Log_List;




        // this.logdata();
      }
    }))
  }



  public altervechile(value) {

    let obj = {
      customers_id: this.customer_Group,
      location: value
    }
    this.managecustomerguests.getCustomervechile(obj).subscribe(
      (response) => {
        this.respo = response;
        if (this.respo.success == true) {
          this.vechile = this.respo.Vehicle_List;
          this.vechile = this.vechile.sort((a, b) => {
            if (a.vehicle_model < b.vehicle_model) { return -1; }
            if (a.vehicle_model > b.vehicle_model) { return 1; }
          })
          this.filtersearch4 = this.vechile.slice();


        }
      });

  }

  setRequired() {
    return [Validators.required];
  }

  manadchange() {
    this.showdrpdwn = true;
  }

  getcustomerGroupName(customer_group_id) {

    console.log(customer_group_id);

    if (customer_group_id == 22) {
      this.showdrpdwn = false;
      console.log(this.showdrpdwn);

      this.customerguest.controls.customer_Admin.setValidators(null);
      this.customerguest.controls.customer_Admin.updateValueAndValidity();

      this.customerguest.controls.Direct_Booking.setValidators(null);
      this.customerguest.controls.Direct_Booking.updateValueAndValidity();

      // this.customerguest.controls.customer_Admin.clearValidators();
      // this.customerguest.controls.Direct_Booking.clearValidators();

      console.log(this.customerguest.controls.Direct_Booking);




    }
    else {
      this.showdrpdwn = true;
      console.log(this.showdrpdwn);
      this.customerguest.controls.customer_Admin.setValidators(this.setRequired())
      this.customerguest.controls.Direct_Booking.setValidators(this.setRequired())




    }


    this.customer = ''
    this.adminlist = [];

    let data = {
      customer_group: customer_group_id,
      location: this.loc,
      type: this.forzaadmin,
      user_id: this.userId
    }


    this.managecustomerguests.getCustomers(data).subscribe(
      (response) => {
        this.respo = response;
        if (this.respo.success == true) {
          this.customerdata = this.respo.Customer_Name;
          this.getcustomerdata = this.customerdata;
          this.getcustomerdatas = this.customerdata;
          this.filtersearch2 = this.getcustomerdata.slice();


        }
      },
    );
  }

  getcustomerloc(value) {

    //this.adminlist = [];
    // this.vechileist = [];
    // this.vechile = [];
    // this.locationlist = [];
    const element = value;
    this.customer_value = element;
    let data = this.customerdata.filter(item => item.customers_id == Number(element))[0];
    if (data != undefined) {
      this.customer = element
      let data1 = {
        customers_id: Number(element),
        type: this.forzaadmin,
        user_id: this.userId
      }
      this.locationlist = [];
      this.vechile = [];
      this.vechileist = [];
      this.location = ''

      this.Vehicle = '';
      this.rental_Type = '';
      this.managecustomerguests.getCustomeradmin(data1).subscribe(
        (response) => {
          this.respo = response;
          if (this.respo.success == true) {
            this.adminlist = this.respo?.CustomerAdmin_List;
            this.filtersearch3 = this.adminlist;
          }
        });
    }

    let data1 = {
      customers_id: value,
    }
    this.managecustomerguests.getCustomerlocationlist(data1).subscribe(
      (response) => {
        this.respo = response;

        if (this.respo.success == true) {
          this.locationlist = this.respo.Location_List;
          console.log(this.locationlist);


          let location1 = this.locationlist.filter(x => x.branch_id != null)
          console.log(location1);
          this.locationlist = location1;

          this.filtersearch6 = this.locationlist;


        }
      });
  }

  changerental(value) {
    // this.Vehicle = '';


    this.rental_Type = this.customerguest.value.rental_Type;
    let data1 = {
      customers_id: this.customer,
      vehicle_model_id: JSON.stringify(value),
      loc_id: this.locn_id,
    }
    this.vechileist = [];
    this.rental_Type = '';


    this.managecustomerguests.getCustomerrental(data1).subscribe(
      (response) => {
        this.respo = response;
        if (this.respo.success == true) {
          this.vechileist = this.respo.Rentaltype_List;
          setTimeout(() => {
            let subResult = [];
            for (let i = 0; i < this.rental_Type?.length; i++) {
              const element = this.rental_Type[i];
              let abcd = this.vechileist.filter(item => item.rental_id == element)[0];
              if (abcd != undefined) {
                subResult.push(true)
              } else {
                subResult.push(false)
              }
            }

            let uniqueChars = [...new Set(subResult)];
            if (uniqueChars.length == 1) {
              if (uniqueChars[0] != true) {
                this.rental_Type = []
                this.customerguest.get("rental_Type").setValue('');
              }
            }
          });
          this.filtersearch5 = this.vechileist;
        }
      });

  }

  directGuestId = () => {
    this.buttonText = 'Update'
    var idarray = {
      id: this.id,
      user_id: this.userId
    }
    this.managecustomerguests.getManageCustomerguestbyid(idarray).subscribe(
      (response) => {
        let responses: any = response;
        if (responses.success == true) {
          this.managecustomerdata = responses.result
          this.employee_Location = JSON.parse(this.managecustomerdata.Employee_Location)
          this.onchangelocation(this.employee_Location)
          this.customer_Group = JSON.parse(this.managecustomerdata.Customer_Group)
          this.getcustomerGroupName(this.customer_Group)
          this.customer = Number(this.managecustomerdata.Customer)
          this.employee_name = this.managecustomerdata.Employee_Name
          this.employee_Mobile_Number = this.managecustomerdata.Employee_Mobile_Number
          this.employee_Email_Id = this.managecustomerdata.Employee_Email_ID
          this.employee_Id = this.managecustomerdata.Employee_Id
          this.Credit_Limit = this.managecustomerdata.Credit_Limit
          this.Customer_Admin = this.managecustomerdata.Customer_Admin
          //  this.location = JSON.parse(this.managecustomerdata.Location)
          //  this.altervechile(this.location)
          //  this.Vehicle = JSON.parse(this.managecustomerdata.Vehicle) 

          //  this.rental_Type = JSON.parse(this.managecustomerdata.Rental_Type)
          //  console.log( this.Vehicle);
          //  setTimeout(() => {
          //   this.getcustomerloc(this.customer)
          //   this.changerental(this.Vehicle)
          //   // this.onchangevechile(this.location)
          //  }, 100);

          this.Direct_Booking = this.managecustomerdata.Direct_Booking
          this.customerguest.get("status").setValue((responses?.result?.status == 'ACTIVE') ? true : false);



          if (this.customerguest.value.status === true) {
            this.labelStatus = "Active"
          }
          else if (this.customerguest.value.status === false) {
            this.labelStatus = "Deactive"
          }




          this.smssent = (responses?.result?.smssent == 1) ? true : false;

          //          




          //           
          //           this.old_email = this.managecustomerdata.Employee_Email_ID


          //  this.getcustomerGroupName(Number(this.customer_Group))
          // this.customer_id_drop = Number(this.customer_Group)

          if (typeof (JSON.parse(this.managecustomerdata.Rental_Type)) != 'object') {
            this.rental_Type = [Number(this.managecustomerdata.Rental_Type)]
          } else {
            this.rental_Type = JSON.parse(this.managecustomerdata.Rental_Type)
          }


          // this.customerguest.get("rental_Type").setValue(this.rental_Type);

          if (typeof (JSON.parse(this.managecustomerdata.Location)) != 'object') {
            this.location = [Number(this.managecustomerdata.Location)]
          } else {
            this.location = JSON.parse(this.managecustomerdata.Location)
          }

          if (typeof (JSON.parse(this.managecustomerdata.Vehicle)) != 'object') {
            this.Vehicle = [Number(this.managecustomerdata.Vehicle)]

          } else {
            this.Vehicle = JSON.parse(this.managecustomerdata.Vehicle)
            //this.changerental(this.Vehicle)

          }


          this.getcustomerloc(this.customer)
          setTimeout(() => {

            setTimeout(() => {
              this.onchangevechile(this.location)
              this.getcustomerloc(this.customer)



              // setTimeout(() => {    
              if (typeof (JSON.parse(this.managecustomerdata.Location)) != 'object') {
                this.location = [Number(this.managecustomerdata.Location)]
              } else {
                this.location = JSON.parse(this.managecustomerdata.Location)
              }

              if (typeof (JSON.parse(this.managecustomerdata.Vehicle)) != 'object') {
                this.Vehicle = [Number(this.managecustomerdata.Vehicle)]
              } else {
                this.Vehicle = JSON.parse(this.managecustomerdata.Vehicle)
              }
              this.changerental(this.Vehicle)

              if (typeof (JSON.parse(this.managecustomerdata.Rental_Type)) != 'object') {
                this.rental_Type = [Number(this.managecustomerdata.Rental_Type)]
              } else {
                this.rental_Type = JSON.parse(this.managecustomerdata.Rental_Type)
              }

              if (Number(this.Vehicle.length) == Number(this.vechile.length)) {
                this.allSelected = true;
              } else {
                this.allSelected = false;
              }
              // }, 50);
            }, 500);

            // this.changerental(this.rental_Type)
            setTimeout(() => {
              if (Number(this.vechileist.length) == Number(this.rental_Type.length)) {
                this.allSelected1 = true;
              } else {
                this.allSelected1 = false;
              }
            }, 500);
          }, 1000);
          //           this.Direct_Booking = this.managecustomerdata.Direct_Booking
          //           this.customerguest.get("status").setValue((responses?.result?.status == 'ACTIVE') ? true : false);
          //           this.smssent = (responses?.result?.smssent == 1) ? true : false;
        }
      },
      (error) => {
        this.toastr.error(error, 'Error');
      }
    );
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  change(e) {

  }

  get f() {
    return this.customerguest.controls
  }

  submit() {
    console.log("hitting submit");


    this.submitted = true;
    console.log(this.customerguest.valid, this.emailexist, this.mobexist);
    if (this.customerguest.valid) {

      this.customerguest.get("status").setValue((this.customerguest.value.status == true) ? 'ACTIVE' : 'DEACTIVATED');
      this.customerguest.get("smssent").setValue((this.customerguest.value.smssent == true) ? 1 : 0);
      if (this.type === 'edit') {
        console.log("hitting");
        this.customerguest.value.id = this.id
        this.customerguest.value.user_id = this.userId
        this.customerguest.value.user_type = this.forzaadmin


        this.customerguest.value.location = JSON.stringify(this.customerguest.value.location)
        this.customerguest.value.Direct_Booking = this.customerguest.value.Direct_Booking
        this.customerguest.value.Vehicle = JSON.stringify(this.customerguest.value.Vehicle)
        this.customerguest.value.rental_Type = JSON.stringify(this.customerguest.value.rental_Type)
        this.isDisableBtn = true;
        this.managecustomerguests.updateManageCustomerguest(this.customerguest.value).subscribe(
          response => {
            this.respo = response;
            if (this.respo.success == true) {
              this.router.navigate(['/managecustomerguest']);
              this.toastr.success(this.respo.message, 'success');
            } else {
              this.toastr.error(this.respo.message, 'Error');
              this.isDisableBtn = false;
            }
          },
          error => {
            this.toastr.error(error, 'Error');
          });
      }
      else if (this.customerguest.valid && this.emailexist === true && this.mobexist === true) {
        console.log("hitting elsse");

        this.customerguest.value.location = JSON.stringify(this.customerguest.value.location)
        this.customerguest.value.Direct_Booking = (this.customerguest.value.Direct_Booking) ? this.customerguest.value.Direct_Booking : 'Direct'
        this.customerguest.value.Vehicle = JSON.stringify(this.customerguest.value.Vehicle)
        this.customerguest.value.rental_Type = JSON.stringify(this.customerguest.value.rental_Type)
        this.isDisableBtn = true;
        this.customerguest.value.user_id = this.userId
        this.customerguest.value.user_type = this.forzaadmin
        this.managecustomerguests.create(this.customerguest.value).subscribe(
          response => {
            this.respo = response
            if (this.respo.success == true) {
              this.toastr.success(this.respo.message, 'success');
              this.router.navigate(['/managecustomerguest']);
            }
          },
          error => {
            this.toastr.error(error, 'Error');
            this.isDisableBtn = false;
          });
      }
    }
    else {
      console.log("hhhhh else");

      return;
    }
  }

  getBranches = () => {

    let cond = {
      "userid": localStorage.getItem("Id"),
      "role": localStorage.getItem("customer_Role_name"),//(localStorage.getItem("customer_Role_name") != 'Forza Admin') ? 'Customer Admin': localStorage.getItem("customer_Role_name")
    }
    // Forza Admin
    this.managecustomerguests.getPrevillage(cond).subscribe(
      (response) => {
        let respo: any = response;
        if (respo.success == true) {

          let locationAuthorised = [];
          if (respo.User_Details.locationAuthorised != undefined) {
            locationAuthorised = JSON.parse(respo.User_Details.locationAuthorised);
          }

          this.managecustomerguests.getCustomerlocation({}).subscribe(
            response => {
              this.respo = response
              if (this.respo.success == true) {
                this.branches = this.respo.Location_List;
                this.branchesdata = this.respo.Location_List;

                if (respo.User_Details.locationAuthorised != undefined) {
                  this.branches = this.branchesdata.filter(function (item) {
                    return locationAuthorised.indexOf(item.location_id) !== -1;
                  });
                }

                this.branches = this.branches.sort((a, b) => {
                  if (a.country_name < b.country_name) { return -1; }
                  if (a.country_name > b.country_name) { return 1; }
                })
                this.filteredList = this.branches.slice();


                const element = this.loc;

              }
            });
        }
      });
  }

  getcustomersgroup = () => {
    let data = {
      type: this.forzaadmin,
      user_id: this.userId
    }

    this.appService.getcustomersgroup(data).subscribe(
      response => {
        this.respocustomersgroup = response
        if (this.respocustomersgroup.success == true) {
          // console.log("hitting if condition");          
          this.customersgroup = this.respocustomersgroup.result;
          this.customersgroups = this.customersgroup
          this.filtersearch1 = this.customersgroup.slice();
        }
      },
      error => {
        this.toastr.error(error, 'Error');
      });
  }

  // Vehicle dropdown
  public getVechile() {
    this.managecustomerguests.getVechileData({}).subscribe(
      response => {
        this.respo = response
        if (this.respo.success == true) {
          this.vechleList = this.respo.result
        }
      },
      error => {
        this.toastr.error(error, 'Error');
      });
  }

  //Rental Type
  public rentalType() {
    this.managecustomerguests.rentalData({}).subscribe(
      response => {
        this.respo = response
        if (this.respo.success == true) {
          this.rentalTypeList = this.respo.result
        }
      },
      error => {
        this.toastr.error(error, 'Error');
      });
  }

  checkemail = (email) => {
    var value = email
    var emailRegexp = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
    if (emailRegexp.test(value)) {
      if (this.old_email !== this.customerguest?.value?.employee_Email_Id) {
        this.appService.checkcustomerguestemail(this.customerguest?.value).subscribe(
          response => {
            let responses: any = response;
            console.log(responses);

            if (responses.success == true) {
              this.emailexist = true
            } else {
              this.emailexist = false
            }
          },
          error => {
            this.toastr.error(error, 'Error');
          });
      }
    }
  }

  onkeypress(e) {
    var value = e
    if (e.length < 10) {
      this.lessthanten = true
    } else if (e.length == 10) {
      this.lessthanten = false
    }
    if (e.length >= 10) {
      this.appService.checkcustomeradminmobile(this.customerguest?.value).subscribe(
        response => {
          let responses: any = response;
          if (responses.success == true) {
            this.mobexist = true
          } else {
            this.mobexist = false
          }
        },
        error => {
          this.toastr.error(error, 'Error');
        });
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  toggleAllSelection1() {

    if (this.allSelected1) {
      this.select1.options.forEach((item: MatOption) => item.select());
    } else {
      this.select1.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick1() {
    let newStatus1 = true;
    this.select1.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus1 = false;
      }
    });
    this.allSelected1 = newStatus1;
  }

  public customerclose() {
    this.showdrpdwn = true;
    this.customer_Group = ''
    this.customer = ''
    this.Customer_Admin = ''
    this.location = ''
    this.Vehicle = ''
    this.rental_Type = ''
    // this.filtersearch2 = []
    this.filtersearch2 = []
    this.adminlist = []
    this.filtersearch6 = []
    this.filtersearch4 = []
    this.vechileist = []
  }

  public customernameclose() {
    this.customer = ''
    this.customer_Admin = ''
    this.location = ''
    this.Vehicle = ''
    this.rental_Type = ''
    this.adminlist = []
    this.locationlist = []
    this.filtersearch4 = []
    this.vechileist = []
  }

  public customeradminclose() {

  }

  public locationclose() {
    this.location = ''
    this.Vehicle = ''
    this.rental_Type = ''
    this.filtersearch4 = []
    this.vechileist = []
  }

  public vehicleclose() {
    this.Vehicle = ''
    this.rental_Type = ''
    this.vechileist = []
  }
  public employeelocation() {
    this.employee_Location = ''

    this.customer_Group = ''
    this.customer = ''
    this.customer_Admin = ''
    this.location = ''
    this.Vehicle = ''
    this.rental_Type = ''
    this.getcustomersgroup()
    this.customersgroup = [];
    this.filtersearch2 = [];
    this.adminlist = [];
    this.filtersearch6 = [];
    this.filtersearch4 = [];
    this.vechileist = [];

  }
  changetoggle(event) {
    console.log(event);
    if (this.customerguest.value.status === true) {
      this.labelStatus = "Active"
    }
    else if (this.customerguest.value.status === false) {
      this.labelStatus = "Deactive"
    }

  }
}
