import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {Observable, throwError} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {BackendserviceService} from './backendservice.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    public user: any = null;

    constructor(
        private router: Router,
        private backendService: BackendserviceService,
        private cookieService: CookieService
    ) {}

    public forzaadminlogin(data): Observable<{}> {
        return this.backendService
            .post('api/forzaadmin/login',data)
            .pipe(retry(1), catchError(this.handleError));
    }


    private handleError(error: HttpErrorResponse) {
        let errorMessage: string = '';
        if (error.error instanceof ErrorEvent) {
            //ClientSide Error--------
            errorMessage = `Error:${error.error.message}`;
        } else {
            //Server side Error---------
            errorMessage = `Status:${error.status}\n
                      Message:${error.message}`;
        }
        return throwError(errorMessage);
    }
}
