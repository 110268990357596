<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">Outstanding Summary</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-outstandingsummary.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Outstanding Summary</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">          
            <div class="row justify-content-between">
              <div class="col-md-6">
                <h6 style="font-weight:600; color: #233771 !important;">As on <span class="month-text" *ngIf="month">{{
                    month +","+ year }}</span></h6>
              </div>
              <div class="col-md-6">
                <div class="row justify-content-end">
                  <div class="col-md-7">
                    <div class="search-box pull-center">
                      <div class="search-input">
                        <mat-icon role="img" matprefix="true"
                          class="mat-icon notranslate material-icons mat-icon-no-color icon-grey" aria-hidden="true"
                          data-mat-icon-type="font">search</mat-icon><input placeholder="Search" class="form-control"
                          (keyup)="applyFilter($event.target.value)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 d-flex justify-content-end">

                    <mat-icon (click)="export('csv')" title="CSV" type="button" class="mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/CSV.png');"></mat-icon>


                    <mat-icon title="Excel" type="button" (click)="export('xlsx')" class="mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/XLS.png');"></mat-icon>

                    <mat-icon (click)="printPage()" title="Print" type="button" class="mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/print.png');"></mat-icon>


                    <mat-icon title="copy" type="button" (click)="export('xlsx')"
                      class="text-right plus mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/Group 2 Copy 5.png');"></mat-icon>


                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
              <table id="tablerecords" [border]="1" [hidden]="isLoading" matTableExporter #exporter="matTableExporter"
                mat-table [dataSource]="dataSource" class="pointer table-striped" [hiddenColumns]="[14]">

                <ng-container matColumnDef="customer_name">
                  <th mat-header-cell *matHeaderCellDef> Customer Name
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
                </ng-container>

                <ng-container matColumnDef="location">
                  <th mat-header-cell *matHeaderCellDef> Location
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                </ng-container>

                <ng-container matColumnDef="account_admin">
                  <th mat-header-cell *matHeaderCellDef> Account Admin
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element.account_admin}} </td>
                </ng-container>

                <ng-container matColumnDef="total_invoice_amt">
                  <th mat-header-cell *matHeaderCellDef> Total Invoice Amount
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.total_invoice_amt == null">
                      0.00
                    </span>
                    <span *ngIf="element.total_invoice_amt != null">
                      {{element.total_invoice_amt}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="total_receipt">
                  <th mat-header-cell *matHeaderCellDef> Total Receipt
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.total_receipt == null">
                      0.00
                    </span>
                    <span *ngIf="element.total_receipt != null">
                      {{element.total_receipt}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="total_outstanding">
                  <th mat-header-cell *matHeaderCellDef> Total OutStanding
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.total_outstanding == null">
                      0.00
                    </span>
                    <span *ngIf="element.total_outstanding != null">
                      {{element.total_outstanding}}
                    </span>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" (click)="view(element)" class="text-center action">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <div *ngIf="this.dataSource.filteredData.length === 0" style="text-align: center">
                No records found
              </div>
            </div>
            <div class="col-md-12 mt-2 table" [hidden]="!isLoading">

              <table class="norecordfoundtable">
                <tr>
                  <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}</th>
                </tr>
                <tr>
                  <td style="text-align: center;" [attr.colspan]="toppingList.length">
                    {{'Loading.......'}}
                  </td>
                </tr>
              </table>
            </div>
          <!-- </div> -->

          <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading" [pageSizeOptions]="[10,25,50]"
            justify-content="space-between">
          </custom-paginator>
        </div>
      </div>

    </div>
  </section>
</div>