<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span>Dashboard</span> / <span class="breadcrumb-item activebreadcrumb-item active">Manage Customer
          Booking</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/main-customeradmin.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Manage Customer Admin</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <!-- <div class="col-md-12"> -->
            <div class="row justify-content-between">
              <div class="col-md-6">
                <h4 style="font-weight:600; color: #233771;">Details</h4>
              </div>
              <div class="col-md-6">
                <div class="row justify-content-end">
                  <div class="col-md-7">
                    <div class="search-box pull-center">
                      <div class="search-input">
                        <mat-icon role="img" matprefix="true"
                          class="mat-icon notranslate material-icons mat-icon-no-color icon-grey" aria-hidden="true"
                          data-mat-icon-type="font">search</mat-icon><input placeholder="Search" class="form-control"
                          (keyup)="applyFilter($event.target.value)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 d-flex justify-content-end">
                    <mat-icon *ngIf="helperService.checkMenuAccess('r_download')" title="Excel" type="button"
                      (click)="exportIt('xlsx','Manage_customer_admin', 'Manage_customer_admin_data','Forza')"
                      class="mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/XLS.png');"></mat-icon>
                    <mat-icon *ngIf="helperService.checkMenuAccess('r_create')" title="Add" type="button"
                      (click)="addmanagecustomer()" class="text-right plus mat-button-submit c-btn icon-img"
                      style="background-image:url('assets/images/Add.png');"></mat-icon>
                  </div>
                </div>

                <!-- </div>
              <div class="col-md-auto"> -->



              </div>

              <!-- //table// -->

              <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
                <table [hidden]="isLoading" [border]="1" style="text-align: center;" matTableExporter mat-table
                  [dataSource]="dataSource" class="pointer table-striped" #exporter="matTableExporter" matSort>
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>S.No</th>
                    <td mat-cell *matCellDef="let element; let i = index" (click)="edit(element.id)">
                      {{paginator.pageIndex == 0 ? i + 1 : 1 + i + paginator.pageIndex * paginator.pageSize}}
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="base_Location_name">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                      <span [matTooltip]="element.base_Location_name"
                        [matTooltipPosition]="'above'">{{element.base_Location_name}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="customer_Group_name">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap;">Customer Group</th>
                    <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                      <span [matTooltip]="element.customer_Group_name"
                        [matTooltipPosition]="'above'">{{element.customer_Group_name}}</span>
                    </td>
                  </ng-container>



                  <ng-container matColumnDef="customer_Name">
                    <th mat-header-cell *matHeaderCellDef> Customer name </th>
                    <td style="
              white-space: pre-wrap !important;width: 200%;" mat-cell *matCellDef="let element"
                      (click)="edit(element.id)">
                      <span [matTooltip]="element.customer_Name_array"
                        [matTooltipPosition]="'above'">{{element.customer_Name_array}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="customer_Admin_Name">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap;"> Admin Name </th>
                    <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                      <span [matTooltip]="element.customer_Admin_Name"
                        [matTooltipPosition]="'above'">{{element.customer_Admin_Name}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="customer_Admin_mobile">
                    <th mat-header-cell *matHeaderCellDef style="white-space:nowrap;"> Admin Mobile </th>
                    <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                      <span [matTooltip]="element.customer_Admin_mobile"
                        [matTooltipPosition]="'above'">{{element.customer_Admin_mobile}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="customer_Admin_MailId">
                    <th mat-header-cell *matHeaderCellDef> Admin Mail </th>
                    <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
                      <span [matTooltip]="element.customer_Admin_MailId"
                        [matTooltipPosition]="'above'">{{element.customer_Admin_MailId}}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"><span class="green-action pointer"
                        [ngClass]="{'green-danger':element.status !== 'ACTIVE', 'green-action':element.status === 'ACTIVE'}"
                        (click)="statusManager(element)
                  ">{{element.status | titlecase}}</span></td>
                  </ng-container>

                  <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> ACTION </th>
                    <td class="edit" mat-cell *matCellDef="let element">
                      <div
                        *ngIf="helperService.checkMenuAccess('r_read') || helperService.checkMenuAccess('r_update') || helperService.checkMenuAccess('r_delete')">

                        <button mat-icon-button [matMenuTriggerFor]="menu" class="action"
                          aria-label="Example icon-button with a menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>

                        <mat-menu #menu="matMenu">
                          <button (click)="view(element.id)" *ngIf="helperService.checkMenuAccess('r_read')"
                            mat-menu-item>
                            <mat-icon role="img"
                              class="mat-icon notranslate material-icons eye align-middle pointer hover-color"
                              aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon>View
                          </button>
                          <button (click)="edit(element.id)" mat-menu-item
                            *ngIf="helperService.checkMenuAccess('r_update')">
                            <mat-icon class="edit align-middle pointer hover-color">edit</mat-icon>Edit
                          </button>
                          <button mat-menu-item (click)="delete(element.id)"
                            *ngIf="helperService.checkMenuAccess('r_delete')">
                            <mat-icon class="delete align-middle pointer hover-color">delete</mat-icon>Delete
                          </button>
                        </mat-menu>
                      </div>
                      <div
                        *ngIf="!helperService.checkMenuAccess('r_read') && !helperService.checkMenuAccess('r_update') && !helperService.checkMenuAccess('r_delete')">
                        -
                      </div>
                    </td>

                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>

                <div *ngIf="this.dataSource.filteredData.length === 0" style="text-align: center">
                  No records found
                </div>


              </div>
            </div>
          <!-- </div> -->

          <div class="col-md-12 mt-2 table" [hidden]="!isLoading">

            <table class="norecordfoundtable">
              <tr>
                <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}</th>
              </tr>
              <tr>
                <td style="text-align: center;" [attr.colspan]="toppingList.length">
                  {{'Loading.......'}}
                </td>
              </tr>
            </table>
          </div>
          <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading" [pageSizeOptions]="[10,25,50]"
            justify-content="space-between"></custom-paginator>


        </div>
      </div>
    </div>

  </section>
</div>



<!-- <ng-template #thirdDialog>
  <mat-icon class="close-button" [mat-dialog-close]="false" style="float: right;">close</mat-icon>
  <h2 matDialogTitle>Are you sure want to delete?</h2>
  <mat-dialog-content>
    <input type="button" class="mat-button-cancel" value="Cancel" [mat-dialog-close]="false" />
    <button class="btn btn-primary" (click)="deleteCustomer()"> Confirm</button>
  </mat-dialog-content>
  <mat-dialog-actions>

  </mat-dialog-actions>
</ng-template> -->