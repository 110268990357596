<!-- style="background-image: url(/assets/images/Login-Background.png); background-size: cover; background-position: center center;" -->
<div class="text-center"
  >


  <div class="text-center">
    <img 
      src="assets/images/inner_logo.png" 
      alt="Forza Logo" 
      class="img-fluid py-3 px-4"
      style="background-color: #ffff;">
  </div>
  <div class="card card-outline card-primary border-0">

    <div class="card-body" style="background-color: #FA6D0F;">
      <!-- <p class="login-box-msg">Sign in to start your session</p> -->

      <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()" class=" mx-4">
        <div class="input-group mb-3">
          <input formControlName="username" type="email" class="form-control"
            [ngClass]="{'is-invalid': isLoginFormSubmitted && f['username'].errors }" placeholder="Username" />

          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
          <div *ngIf="isLoginFormSubmitted && f['username'].errors" class="invalid-feedback">
            <div *ngIf="f['username'].errors">username is required</div>
          </div>
        </div>

        <div class="input-group mb-3">
          <input formControlName="password" type="password" class="form-control"
            [ngClass]="{'is-invalid': isLoginFormSubmitted && f['password'].errors }" placeholder="Password" />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
          <div *ngIf="isLoginFormSubmitted && f['password'].errors" class="invalid-feedback">
            <div *ngIf="f['password'].errors">password is required</div>
          </div>

        </div>
        <div class="row justify-content-start" >
          <div class="col-7">
            <div class="icheck-primary  col-orange">
              <input type="checkbox" id="remember" />
              <label for="remember"> Remember Me </label>
            </div>
          </div>

        </div>
        <div class="row justify-content-center">

          <div class="col-8 m-0" style="text-align: center; margin-left: 23px;">
            <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading"
              [disabled]="isFacebookLoading || isGoogleLoading">
              Sign In
            </app-button>
          </div>
        </div>
      </form>
      <div class="row justify-content-center" >
          <div class="col-8 mt-1">
            <a style="min-width: 120px; text-decoration: underline; text-overflow: ellipsis;white-space: none;" [routerLink]="['/forgot-password']"
          class="mb-1 col-orange">Forgot Password ?</a>
          </div>
      </div>
      <!-- <p class="mb-1">
        
      </p> -->
    </div>

  </div>
</div>