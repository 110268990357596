import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
import { Managecustomer } from '@services/managecustomerguest.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, Location } from '@angular/common';

@Component({
  selector: 'app-edit-guestbooking',
  templateUrl: './edit-guestbooking.component.html',
  styleUrls: ['./edit-guestbooking.component.scss'],
  providers: [DatePipe]
})
export class EditGuestbookingComponent implements OnInit {
  public type = 'add';
  msg: any;
  webbooking: FormGroup;
  bookingId: any;
  webbookinglist: any;
  customer_Name = [];
  rental_city_name = [];
  billing_Location: any = [];
  rental_city: any = [];
  booking_Type: any = [];
  pickup_Date: any = [];
  end_Date: any = [];
  pickup_Time: any = [];
  Vehicle_Model: any = [];
  rental_Type: any = [];
  paymode_Default: any = [];
  booking_source: any = [];
  

  coordinate_name: any = '';
  coordinate_mobile: any = '';
  project_id: any = '';
  request_id: any = '';
  comments: any = '';
  mail_id: any = '';

  pickup_ADdress: any = [];
  bookinglist: any = [];
  rentallist: any = [];
  pickup_address: any;
  drop_address: any;
  spl_instruction: any;
  flight_details: any;
  Prefix: any;
  guest_name: any;
  guest_Employeeid: any;
  guest_mobile: any;
  prefix: any = [];
  user_id : any;

  public isDisableBtn: boolean = false;
  childForm: any = [
    {
      prefix: '',
      guest_name: '',
      guest_Employeeid: '',
      guest_mobile: '',
      guest_Email: ''
    }
  ];

  constructor(private managecustomer: Managecustomer,
    public location: Location,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public helperService: HelperService,
    public activatedRoute: ActivatedRoute,
    public datepipe: DatePipe) { }

  ngOnInit(): void {

    
    this.user_id = localStorage.getItem('Id');

    if (this?.activatedRoute?.snapshot?.url[1]?.path) {
      if (this.activatedRoute.snapshot.url[1].path == 'add') {
        this.type == 'add'
        this.helperService.pageAccess('r_create')
      } else {
        this.bookingId = atob(this.activatedRoute.snapshot.url[1].path);
        this.type = this.activatedRoute.snapshot.url[2].path;
        this.helperService.pageAccess('r_update')
        this.getguestbooking()
      }
    }

    this.bookingId = atob(this.activatedRoute.snapshot.url[1].path);
    this.getguestbooking();

    this.webbooking = this.fb.group({
      customer: [null, Validators.required],
      billing_location: [null, Validators.required],
      rental_city: [null, Validators.required],
      booking_type: [null, Validators.required],
      pickup_date: [null, Validators.required],
      end_date: [null, Validators.required],
      pickup_time: [null, Validators.required],
      Vehicle_model: [null, Validators.required],
      rental_type: [null, Validators.required],
      paymode_default: [null, Validators.required],
      pickup_address: [null, Validators.required],
      booking_source: [null, Validators.required],
      coordinate_name: [null],
      coordinate_mobile: [null],
      project_id: [null],
      request_id: [null],
      comments: [null],
      mail_id: [null],
      quantities: [null],
      drop_address: [null],
      spl_instruction: [null],
      flight_details: [null],
    })
  }

  addQuantity() {
    this.childForm.push({
      prefix: '',
      guest_name: '',
      guest_Employeeid: '',
      guest_mobile: '',
      guest_Email: ''
    });
  }

  getguestbooking() {
    let data = {
      booking_id: this.bookingId
    }
    this.managecustomer.getbookingdata(data).subscribe((res => {
      let responses: any = res;
      if (responses.success == true) {
        this.webbookinglist = responses.Webbooking_List;
        this.customer_Name = responses?.Webbooking_List?.customer_name;
        this.billing_Location = responses?.Webbooking_List.billing_location;
        this.rental_city = responses?.Webbooking_List.rental_city_name;
        this.booking_Type = responses?.Webbooking_List.booking_type_name;
        this.pickup_Date = this.datepipe.transform(responses?.Webbooking_List.pickup_date, 'dd-MM-yyyy')
        this.end_Date = this.datepipe.transform(responses?.Webbooking_List.end_date != '0000-00-00' ? responses?.Webbooking_List.end_date : "", 'dd-MM-yyyy')
        this.pickup_Time = responses?.Webbooking_List.pickup_time;
        this.Vehicle_Model = responses?.Webbooking_List.vehicle_model_name;
        this.rental_Type = responses?.Webbooking_List.rental_type_name;
        this.paymode_Default = responses?.Webbooking_List.paymode_defult_name;
        this.booking_source = responses?.Webbooking_List.booking_source_name;
        this.coordinate_name = responses?.Webbooking_List.coordinate_name;
        this.coordinate_mobile = responses?.Webbooking_List.coordinate_mobile;
        this.project_id = responses?.Webbooking_List.project_id;
        this.request_id = responses?.Webbooking_List.request_id;
        this.comments = responses?.Webbooking_List.comments;
        this.mail_id = responses?.Webbooking_List.mail_id;
        this.pickup_address = responses?.Webbooking_List.pickup_address;
        this.drop_address = responses?.Webbooking_List.drop_address;
        this.spl_instruction = responses?.Webbooking_List.spl_instruction;
        this.flight_details = responses?.Webbooking_List.flight_details;
        this.Prefix = responses?.Webbooking_List.quantities.prefix;
        this.guest_name = responses?.Webbooking_List.quantities[0].guest_name;
        this.guest_Employeeid = responses?.Webbooking_List.quantities[0].guest_Employeeid;
        this.guest_mobile = responses?.Webbooking_List.quantities[0].guest_mobile;
        let childForm = responses?.Webbooking_List.quantities

        console.log("fierst",childForm);
        

        this.childForm = [];
        for (let i = 0; i < childForm.length; i++) {
          const element = childForm[i];
          console.log("ele",element);
          
          // if(element.prefix != '' && element.guest_name != '' && element.guest_mobile != '' ) {
          if(element.guest_name != '' && element.guest_mobile != '' ) {

            Object.keys(element).forEach(key => {
            if (element[key] === '' || element[key] === null) {
              delete element[key];
            } else {
              if (key == 'prefix') {
                element[key] = element[key]
              }
            }
          });
          this.childForm.push(element)
        console.log("before",this.childForm);

        }
        }
        this.childForm = this.childForm.filter(value => Object.keys(value).length !== 0);
        console.log("after",this.childForm);
        
      }
    }))
  }

  back() {
    this.location.back()
  }

  approve() {
    let data = {
      booking_id: this.bookingId,
      user_id: this.user_id
    }
    this.managecustomer.guestapprovelist(data).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.toastr.success(this.msg.message, 'Success');
        this.router.navigate(['/guestbooking']);
      }
    }))
  }

  reject() {
    let data = {
      booking_id: this.bookingId
    }
    this.managecustomer.guestrejectlist(data).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.toastr.error(this.msg.message, 'Success');
        this.router.navigate(['/guestbooking']);
      }
    }))
  }

  prefixDropdown() {
    this.managecustomer.prefixdropdown({}).subscribe((res => {
      this.msg = res;
      if (this.msg.success == true) {
        this.prefix = this.msg.prefix;
      }
    }))
  }

}