import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Managecustomer } from '@services/managecustomerguest.service';
import { response } from 'express';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
type AOA = any[][];


export interface PeriodicElement {
  position: number,
  Customer_Group: string,
  Customer_Name: string,
  rental_location: string,
  guest_Name: string,
  guest_Mobile: string,
  Guest_Email: string,
  Guest_Id: string,
  customer_admin_name: string,
  Vehicle_Type: string,
  Rental_City: string,
  Rental_Type: string,
  Other_Location: string,
  Approval_Type: string,
  status: string

}


const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss']
})
export class ImportExportComponent implements OnInit {


  
  isShowFile: boolean = false;
  public files: any[] = [];
  public isLoading = false;
 

  data: any = [["Customer_Group", "Customer_Name", "employee_location", "employee_name", "employee_mobile_number", "employee_email_id", "employee_id", "customer_admin_name","credit_limit", "select_vehicle", "rental_type", "rental_location", "direct_booking", "status"]];
  data2: any = [["Customer_Group", "Customer_Name", "employee_location", "employee_name", "employee_mobile_number", "employee_email_id", "employee_id", "customer_admin_name", "select_vehicle", "rental_type", "rental_location", "direct_booking", "status"]];


   
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'customer-guest.xlsx';
  public data1 = [];
  toppingList = [];
  tabledata: any[];
  table: boolean = false;
  button: boolean = false;
  isLoader: boolean = false;
  msg: any;
  erroexcel: any; 
  forzaadmin: any;
  log_id: any;

  displayedColumns: string[];

  displayedColumnsf: string[];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  dataSource1 = new MatTableDataSource(ELEMENT_DATA);
  excelJSON: any = []
  failexcel: any = []
  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(public toast: ToastrService, private managecustomer: Managecustomer, private router: Router,  public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.log_id =localStorage.getItem('Id');
    //this.toppingList = this.displayedColumns;
    if (this.forzaadmin == 'Forza Admin'){
    this.displayedColumns =[
      'Customer_Group', 'Customer_Name', 'employee_location', 'employee_name', 'employee_mobile_number', 'employee_email_id', 'employee_id', "customer_admin_name",'credit_limit', 'select_vehicle', 'rental_type', 'rental_location', 'direct_booking', 'status'];


      this.displayedColumnsf =[
        'message', 'Customer_Group', 'Customer_Name', 'employee_location', 'employee_name', 'employee_mobile_number', 'employee_email_id', 'employee_id', "customer_admin_name", 'credit_limit', 'select_vehicle', 'rental_type', 'rental_location', 'direct_booking', 'status'];
      }else{
        this.displayedColumns =[
          'Customer_Group', 'Customer_Name', 'employee_location', 'employee_name', 'employee_mobile_number', 'employee_email_id', 'employee_id', "customer_admin_name", 'select_vehicle', 'rental_type', 'rental_location', 'direct_booking', 'status'];
    
    
          this.displayedColumnsf =[
            'message', 'Customer_Group', 'Customer_Name', 'employee_location', 'employee_name', 'employee_mobile_number', 'employee_email_id', 'employee_id', "customer_admin_name",  'select_vehicle', 'rental_type', 'rental_location', 'direct_booking', 'status'];
      }
   }

  onFileChange(pFileList: File[]) {
    this.files = Object.keys(pFileList).map(key => pFileList[key]);
    var extn = this.files[0].name.split(".").pop();
    this.isShowFile = true;
    if (extn != 'xlsx') {
      this.isShowFile = false;
      this.files = [];
      this.failexcel = [];
      this.excelJSON = [];
      this.toast.error('Please Upload a XSLX File');
    }

    if (this.files.length > 0) {
      // this.validation();
      let workBook: any = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = this.files[0];

      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, { raw: false });
          return initial;
        }, {});

        let arrayData = jsonData.Sheet1;
        if (arrayData.length > 0) {
          this.validation(arrayData);
          this.button = true;
        } else {
          this.isShowFile = false;
          this.failexcel = [];
          this.excelJSON = [];
          this.files = [];
          this.toast.error('Please Upload a Valid File');
        }
      }
      reader.readAsBinaryString(file);
    } else {
      this.isShowFile = false;
      this.failexcel = [];
      this.excelJSON = [];
      this.files = [];
      this.toast.error('Please Upload a Valid File');
    }
  }

  toExportFileName(fileName: string): string {
    return `${fileName}_export_${new Date().getTime()}.xlsx`;
  }

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this.isShowFile = false;
    this.failexcel = [];
    this.excelJSON = [];
    this.button = false;
    this.table = false;
  }

  export(){
   
   
    if (this.forzaadmin == 'Forza Admin') {
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName);
    }else{      
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data2);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName);
    }

   
  }

  submitimport() {
    this.isLoading = true;
    this.managecustomer.createImportExportUpload(this.excelJSON).subscribe(response => {
      this.msg = response;
      if (this.msg.success == true) {
        this.toast.success(this.msg.message, 'Success');
        this.files = [];
        this.isLoading = false;
        this.table = false;
        setTimeout(() => {
          this.router.navigate(['/managecustomerguest']);
        }, 500);
      } else {
        this.toast.error(this.msg.message, 'Error');
        this.isLoading = false;
      }
    },
    error => {
      this.isLoading = false;
    });

  }

  validation(e) {
    this.isLoader = true
    this.isLoader = true    
    let obj = {
    user_id : Number(this.log_id),
    upload_array : e
    }
    this.managecustomer.validationexcel(obj).subscribe(response => {
      this.msg = response;
     // this.car=this.msg.
      console.log(this.msg);
      
      if (this.msg.success == true) {

        if (this.msg.success_list.length > 0) {
          if (this.msg.success_list.length > 1) {
            this.toast.success(this.msg.success_list.length + ' records ready to upload.', 'Success');
          } else {
            this.toast.success(this.msg.success_list.length + ' record ready to upload', 'Success');
            console.log(this.msg.success_list);
            
          }
          this.excelJSON = this.msg.success_list;
          console.log(this.excelJSON);
          
          this.dataSource = new MatTableDataSource(this.excelJSON);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoader = false
        }

        if (this.msg.failed_list.length > 0) {
          if (this.msg.failed_list.length > 1) {
            this.toast.error(this.msg.failed_list.length + ' records are mismatch.', 'Error');
          } else {
            this.toast.error(this.msg.failed_list.length + ' record mismatch', 'Error');
          }


          this.failexcel = this.msg.failed_list;
          // this.erroexcel = this.msg.error_list;
          console.log(this.failexcel);
          
          this.dataSource1 = new MatTableDataSource(this.failexcel);
          this.dataSource1.paginator = this.paginator;
          this.dataSource1.sort = this.sort;
          this.isLoader = false
        }

        if (this.msg.failed_list.length == 0 && this.msg.success_list.length == 0) {
          this.isShowFile = false;
          this.failexcel = [];
          this.excelJSON = [];
          this.files = [];
          this.toast.error('Please Upload a Valid File');
          this.isLoader = false
        }
        this.table = true;
      } else {
        this.toast.error(this.msg.message, 'Error');
        this.isLoader = false
      }
    });
  }

}
