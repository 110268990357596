import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { CustomerName, HistoryConfirmationRejectionList, Location, RentalCity } from "@/models/history-confirmation";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { HistoryConfirmationService } from '@services/history-confirmation-services';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-history-confirmation',
  templateUrl: './history-confirmations.component.html',
  styleUrls: ['./history-confirmations.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})
export class HistoryConfirmation implements OnInit {


  filterForm !: FormGroup;
  rental_city_list: RentalCity[];
  customer_list: CustomerName[];
  billing_location_list: Location[];
  booking_id_list = [];
  booking_id_list_filter = [];
  customer_list_filter = [];
  maxdate: any;
  pickup_date: any;
  forzaadmin: string;
  userid: string;
  id: string;
  displayedColumns: string[] = ['booking_id', 'pickup_date', 'guest_name', 'rental_city', 'vehicle_type', 'billing_location', 'Action'];
  isLoading: boolean = true;
  dataSource = new MatTableDataSource<HistoryConfirmationRejectionList>();
  @ViewChild(MatSort) sort = new MatSort();
  // MatPaginator Inputs
  length = 0;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];
  pageIndex = 0;
  toppingList = [];
  billing_location_list_filter: Location[] = [];
  // MatPaginator Output
  pageEvent: PageEvent;
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
  dataSourceLength: any;
  rental_city_list_filter = [];
  selectedLocation: string;

  constructor(
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private router: Router,
    private historyService: HistoryConfirmationService
  ) { }


  ngOnInit(): void {

    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.userid = localStorage.getItem('userId');
    this.id = localStorage.getItem('Id');
    this.toppingList = this.displayedColumns;
    this.filterForm = this.formBuilder.group({
      pickup_date: ['', null],
      booking_id: ['', null],
      billing_location: ['', null],
      customer_name: ['', null],
      rental_city: ['', null]
    })

    this.getLocationList();
    this.getHistoryConfirmationList();
   

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getLocationList = () => {
    const data = {
      type: this.forzaadmin,
      user_id: this.id
    }
    this.historyService.getLocationList(data).subscribe(
      loc => {
        const response: any = loc;
        if (response.success == true) {
          this.billing_location_list = response.Location_List;
         
          
          this.billing_location_list_filter = this.billing_location_list.slice();
        }
      }
    )
  }

  isFiltered_location(location) {
    return this.billing_location_list_filter.find(item => item.location_id === location.location_id);
  }

  isFiltered_booking(booking) {
    return this.booking_id_list_filter.find(item => item === booking);
  }

  isFiltered_customer(customer) {
  
   
    return this.customer_list_filter.find(item => item.customer_name === customer.customer_name);
    
  }

  isFiltered_rental(rental) {
    return this.rental_city_list_filter.find(item => item.branch_name === rental.branch_name);
  }

  locationChanged = (value) => {
    this.selectedLocation = this.billing_location_list.filter(x => x.location_name == value)[0]["location_id"];
    const data = {
      type:this.forzaadmin,
      user_id: this.id,
       location: this.selectedLocation
    }


    this.historyService.getCustomers(data).subscribe(
      loc => {
        const response: any = loc;
        if (response.success == true) {
          this.customer_list = response.Customer_Name;
          this.customer_list_filter = this.customer_list.slice();
  }
      }
    )
  }

  


  

  customerChanged = (value: string) => {
    const data = {
      loc_id: this.selectedLocation,
      customers_id: this.customer_list.filter(x => x.customer_name == value)[0]["customers_id"]
    }
console.log(data);

    this.historyService.getRentalCity(data).subscribe(
      loc => {
        const response: any = loc;
        if (response.success == true) {
          this.rental_city_list = response.Rental_City;
          this.rental_city_list_filter = this.rental_city_list.slice();
        }
      }
    )
  }

  

  getHistoryConfirmationList = () => {
    const data = {
      "user_id": this.id,
      "booking_id": this.filterForm.get("booking_id").value,
      "pickup_date": this.setPickupDate(),
      "billing_location": this.setLocation(this.filterForm.get("billing_location").value),
      "customer_name": this.filterForm.get("customer_name").value,
      "rental_city": this.filterForm.get("rental_city").value
    }
    
    this.historyService.gethistoryConfirmation(data).subscribe(
      res => {
        const response: any = res;
        if (response.success == true) {
          const historyList = response.result;
          if (this.booking_id_list.length === 0) {
            this.booking_id_list = historyList.map(data => data.booking_id.toString());
            this.booking_id_list_filter = this.booking_id_list.slice();
          }
          this.dataSource = new MatTableDataSource(historyList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSourceLength = historyList.length;
          this.isLoading = false;
        }
      }
    )
  }

  setLocation = (location) => {
    if (location != "" && location != null && location != 'All Location') {
      return location;
    }

    return ""
  }

  setPickupDate = (): string => {

    if (this.filterForm.get("pickup_date").value != "") {

      return this.datepipe.transform(this.filterForm.get("pickup_date").value, 'dd-MM-yyyy');
    }
    return this.filterForm.get("pickup_date").value;
  }

  view = (id) => {
    this.router.navigate(['/reports/history-confirmation/report', id]);
  }
  resetCalendar = () => { }

  search = () => {
    this.getHistoryConfirmationList();

  }
  clear = () => {
    this.filterForm.reset();
    this.getHistoryConfirmationList();
  }

  removeUnderScore = (val) => {
    return val.toString().replace(/_/gi, " ");
  }
}
