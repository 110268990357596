import { Component, OnInit, ViewChild } from '@angular/core';
import { IconOptions, MatIcon } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponentComponent } from '@pages/dialog-component/dialog-component.component';
import { CustomerDirectguestService } from '@services/customer-directguest.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@services/helper.service';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';
export interface Admin {
  id: string,
  location: string,
  guest_name: string,
}

export interface PeriodicElement {
  position: number,
  location: string,
  guest_name: string,
  mobile: number,
  guest_MailID: string,
  guest_ID: string,
  creditlimit: number,
}
@Component({
  selector: 'app-managedirectguest',
  templateUrl: './managedirectguest.component.html',
  styleUrls: ['./managedirectguest.component.scss']
})

export class ManagedirectguestComponent implements OnInit {
  pgIndex = 2;
  firstLastButtons = true;
  pnDisabled = true;
  hdPageSize = true;
  directguestresp: any;
  dataSourceLength: any;
  displayedColumns: string[] = [
    'position', 'location', 'guest_name', 'mobile', 'guest_MailID', 'creditlimit', 'Status', 'Actions'
  ];
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
  directguestlist: Admin[] = [];
  isLoading: boolean = false;
  @ViewChild(MatSort) sort = new MatSort();
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource<Admin>();

  constructor(private router: Router, private matDialog: MatDialog,
    private toastr: ToastrService,
    private customerDirectguestService: CustomerDirectguestService,
    public helperService: HelperService) { }

  ngOnInit(): void {
    this.helperService.pageAccess('all')
    this.getCustomerAdminList()
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  addmanagedirectguest() {
    this.router.navigate(['/managedirectguest', 'add']);
  }

  view(id) {
    if (Number(this.helperService.checkMenuAccess('r_read')) > 0) {
      this.router.navigate(['/view-managedirectguest', btoa(id), 'view']);
    }
  }

  edit(id) {
    if (Number(this.helperService.checkMenuAccess('r_update')) > 0) {
      this.router.navigate(['/managedirectguest', btoa(id), 'edit']);
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.dataSourceLength = this.dataSource.filteredData.length
  }
  
  openDialog(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      body: 'Are you sure want to Delete Record?',
    };
    this.matDialog.open(DialogComponentComponent, dialogConfig);
  }

  getCustomerAdminList = () => {
    this.customerDirectguestService.getCustomerAdminList().subscribe(
      (response) => {
        this.directguestresp = response;
        if (this.directguestresp.success == true) {
          this.directguestlist = this.directguestresp.result
          this.dataSource = new MatTableDataSource(this.directguestlist);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSourceLength = this.directguestresp.result.length
          // console.log(this.dataSourceLength);
          
        }

      },
      (error) => {
        this.toastr.error(error, 'Error');
      }
    );
  };


  statusManager(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      body: 'Are you sure want to change status?'
    };
    let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.customerDirectguestService.updatedirectguestStatus(element).subscribe(
          (response) => {
            let responses: any = response;
            if (responses.success === true) {
              this.toastr.success(responses.message, 'Success');
              element.status = responses.result
            }
          },
          (error) => {
            this.toastr.error(error, 'Error');
          }
        );
      }
    });
  }

  delete(deleteid) {
    var idarray = {};
    idarray['id'] = deleteid;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      body: 'Are you sure want to Delete?'
    };
    let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.customerDirectguestService.delete(JSON.stringify(idarray)).subscribe(
          (response) => {
            let responses: any = response;
            if (responses.success === true) {
              this.getCustomerAdminList();
              this.toastr.success(responses.message, 'Success');
            }
          },
          (error) => {
            this.toastr.error(error, 'Error');
          }
        );
      }
    });
  }
}
