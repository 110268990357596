import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
import { Managecustomer } from '@services/managecustomerguest.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

    RequestCount: any = 0;
    ConfirmedCount: any = 0;
    PendingCount: any = 0;
    PendingCBSCount: any = 0;
    RejectedCount: any = 0;
    log_id : any;
    msg:any;
    
    constructor(
        public helperService: HelperService,
        private router: Router,
        private managecustomer: Managecustomer
    ) { }

    ngOnInit(): void {
      this.log_id =localStorage.getItem('Id');
       this.getManageBookingCount();
     }

     getManageBookingCount(){  
        let obj = {
          "user_id" : Number(this.log_id)
        }
        this.managecustomer.getManageListCount(obj).subscribe((response) =>{    
          this.msg =response;
           if(this.msg.success == true){
              this.RequestCount = this.msg.Request;
              this.ConfirmedCount = this.msg.Confirmed;
              this.PendingCount = this.msg.Pending;
              this.PendingCBSCount = this.msg.CBSPending;
              this.RejectedCount = this.msg.Rejected;  
          }   
        })
      }
}
