<div class="mx-4">

  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span>Dashboard</span> / <span class="breadcrumb-item activebreadcrumb-item active">Manage Booking</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/mainicon-webbooking.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">Manage Booking</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12">

          </div>
          <section class="content">

            <div class="col-md-12">

            </div>
            <section class="content" style="margin-bottom: -15px;margin-left: -15px;">
              <div class="container-fluid">

                <div class="row card_status">
                  <!-- <div class="col-md-2">

                  <div class="small-box info">
                    <div class="inner">
                      <p>Request<br><b style="font-size: 19px;color: #434343;">{{RequestCount}}</b></p>


                    </div>
                    <div class="icon">
                      <i class="fa-brands fa-telegram" style="font-size: 37px; color: #7874747d;"></i>
                    </div>

                  </div>
                 </div> -->

                  <div class="col-md-2">
                    <div class="d-flex flex-column p-2 rounded">
                      <h6 style="color: #1A844A;">
                        <b>Confirmed</b>
                      </h6>
                      <mat-divider style="background-color: #1A844A;"></mat-divider>
                      <div class="d-flex align-items-center justify-content-between mt-2">
                        <div class="icon">
                          <img src="assets/images/confirmed.png" alt="Forza Logo" style="object-fit:fill;width:25px;">
                        </div>
                        <p class="mb-0"><b style="font-size: 19px;color: #434343;">{{ConfirmedCount}}</b></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="d-flex flex-column p-2 rounded pointer" (click)="pending()">
                      <h6 style="color: #BC7D33;">
                        <b>Pending</b>
                      </h6>
                      <mat-divider style="background-color: #BC7D33;"></mat-divider>
                      <div class="d-flex align-items-center justify-content-between mt-2">
                        <div class="icon">
                          <img src="assets/images/pending.png" alt="Forza Logo" style="object-fit:fill;width:25px;">
                        </div>
                        <p class="mb-0"><b style="font-size: 19px;color: #434343;">{{PendingCount}}</b></p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="d-flex flex-column p-2 rounded pointer" (click)="pendingcbs()">
                      <h6 style="color: #FFB82E;">
                        <b>APP Request</b>
                      </h6>
                      <mat-divider style="background-color: #FFB82E;"></mat-divider>
                      <div class="d-flex align-items-center justify-content-between mt-2">
                        <div class="icon">
                          <img src="assets/images/apprequest.png" alt="Forza Logo" style="object-fit:fill;width:25px;">
                        </div>
                        <p class="mb-0"><b style="font-size: 19px;color: #434343;">{{PendingCBSCount}}</b></p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="d-flex flex-column p-2 rounded">
                      <h6 style="color: #D32816;">
                        <b>Rejected</b>
                      </h6>
                      <mat-divider style="background-color: #D32816;"></mat-divider>
                      <div class="d-flex align-items-center justify-content-between mt-2">
                        <div class="icon">
                          <img src="assets/images/rejected.png" alt="Forza Logo" style="object-fit:fill;width:25px;">
                        </div>
                        <p class="mb-0"><b style="font-size: 19px;color: #434343;">{{RejectedCount}}</b></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="d-flex flex-column p-2 rounded">
                      <h6 style="color: #3D3D3D;">
                        <b>Cancelled</b>
                      </h6>
                      <mat-divider style="background-color: #3D3D3D;"></mat-divider>
                      <div class="d-flex align-items-center justify-content-between mt-2">
                        <div class="icon">
                          <img src="assets/images/cancelled.png" alt="Forza Logo" style="object-fit:fill;width:25px;">
                        </div>
                        <p class="mb-0"><b style="font-size: 19px;color: #434343;">{{CancelCount}}</b></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">



                    <div class="d-flex flex-column p-2 rounded">
                      <h6 style="color: #6F51F8;">
                        <b>Total Count</b>
                      </h6>
                      <mat-divider style="background-color: #6F51F8;"></mat-divider>
                      <div class="d-flex align-items-center justify-content-between mt-2">
                        <div class="icon">
                          <img src="assets/images/total_count.png" alt="Forza Logo" style="object-fit:fill;width:30px;">
                        </div>
                        <p class="mb-0"><b style="font-size: 19px;color: #434343;">{{RequestCount}}</b></p>
                      </div>
                    </div>




                  </div>

                </div>

              </div>

            </section><br><br>
            <form [formGroup]="searchform">

              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>From</label>
                    <mat-form-field class="form-control" floatLabel=never>
                      <input autocomplete="off" matInput [(ngModel)]="from_Date" placeholder="dd-mm-yyyy"
                        (click)="picker.open();reset()" matInput [matDatepicker]="picker" formControlName="from_date"
                        readonly>
                      <mat-datepicker-toggle (click)="reset()" matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>To</label>
                    <mat-form-field class="form-control" floatLabel=never>
                      <input autocomplete="off" matInput [(ngModel)]="end_date" placeholder="dd-mm-yyyy"
                        (click)="picker1.open()" matInput [matDatepicker]="picker1" [min]="from_Date"
                        formControlName="end_date" [(ngModel)]="end_date" readonly>
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <!-- <input type="date" [min]="maxdate" [max]="CurrentDate" formControlName="end_date" class="form-control"> -->
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label style="white-space:nowrap; text-overflow: ellipsis;">Billing location</label>
                    <div class="input-group-append">

                      <mat-select *ngIf="baselocationlist.length > 1" formControlName="base_Location"
                        [(ngModel)]="base_Location" class="form-control" placeholder="Please Billing location"
                        #baselocation>
                        <mat-select-filter *ngIf="baselocation.focused" [placeholder]="'Search'"
                          [displayMember]="'branch_name'" [array]="getCountryMasterData"
                          (filteredReturn)="baselocationlist = $event"></mat-select-filter>
                        <div>
                          <mat-option *ngFor="let branch of baselocationlist" [value]="branch.location_name">
                            {{branch.location_name}}
                          </mat-option>
                        </div>
                      </mat-select>
                      <div *ngIf="baselocationlist.length ==1">
                        <div *ngFor="let branch of baselocationlist">
                          <input type="text" formControlName="base_Location" class="form-control"
                            [(ngModel)]="locationFilter" disabled>
                        </div>
                      </div>
                      <a class="decoration-none input-group-text closeicon" *ngIf="base_Location">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="base_Location=''; $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">

                  <div class="form-group">
                    <label>Filter</label>
                    <div class="input-group-append">
                      <mat-select placeholder="Please filter" formControlName="filter_option"
                        [(ngModel)]="filter_option" class="form-control" #filteroption>
                        <mat-select-filter *ngIf="filteroption.focused" [placeholder]="'Search'"
                          [displayMember]="'Filter'" [array]="filterdata" (filteredReturn)="filterdata = $event">
                        </mat-select-filter>
                        <mat-option *ngFor="let fil of filterdata" [value]="fil.Filter">
                          {{fil.Filter}}
                        </mat-option>

                      </mat-select>
                      <a class="decoration-none input-group-text closeicon" *ngIf="filter_option">
                        <mat-icon class="pointer material-icons-outlined pointerv form-control-clear"
                          (click)="filter_option=''; key_word=''; $event.stopPropagation()">close</mat-icon>
                      </a>
                    </div>
                  </div>

                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Keywords</label>
                    <input type="text" formControlName="key_word" placeholder="Search keywords" [(ngModel)]="key_word"
                      class="form-control" [readonly]="filter_option ? false : true">
                  </div>


                </div>
                <div class="col-md-6">
                  <div class="aligh-button">
                    <button mat-button class="mat-button-submit" style="margin-left: 0px !important;" title="Search"
                      (click)="submit()">Search</button>
                    <button class="btn btn-default" style="margin-left: 10px;" title="Reset"
                      (click)="clear()">Reset</button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <!-- <div class="col-md-12"> -->
          <div class="row justify-content-between">
            <div class="col-md-6">
              <h4 style="font-weight:600; color: #233771;">Booking Details</h4>
            </div>
            <div class="col-md-6">
              <div class="row justify-content-end">
                <div class="col-md-7">
                  <div class="search-box pull-center">
                    <div class="search-input">
                      <mat-icon role="img" matprefix="true"
                        class="mat-icon notranslate material-icons mat-icon-no-color icon-grey" aria-hidden="true"
                        data-mat-icon-type="font">search</mat-icon><input placeholder="Search" class="form-control"
                        (keyup)="applyFilter($event.target.value)">
                    </div>
                  </div>
                </div>
                <div class="col-md-5 d-flex justify-content-end">
                  <mat-icon (click)="printPage()" title="Print" type="button" class="mat-button-submit c-btn icon-img"
                    style="background-image:url('assets/images/print.png');"></mat-icon>

                  <mat-icon *ngIf="helperService.checkMenuAccess('r_download')"
                    (click)="exportIt('csv','Manage_booking', 'Manage_booking_data','Forza')" title="CSV" type="button"
                    class="mat-button-submit c-btn icon-img"
                    style="background-image:url('assets/images/CSV.png');"></mat-icon>



                  <mat-icon *ngIf="helperService.checkMenuAccess('r_download')" title="Excel" type="button"
                    (click)="exportIt('xlsx','Manage_booking', 'Manage_booking_data','Forza')"
                    class="mat-button-submit c-btn icon-img"
                    style="background-image:url('assets/images/XLS.png');"></mat-icon>



                  <mat-icon *ngIf="helperService.checkMenuAccess('r_create')" title="Add" type="button"
                    (click)="addguestbooking()" class="text-right plus mat-button-submit c-btn icon-img"
                    style="background-image:url('assets/images/Add.png')"></mat-icon>
                </div>
              </div>
            </div>
          </div>

          <!-- </form> -->

          <div class="col-md-12 mt-2 table-responsive" [hidden]="isLoading">
            <table id="tablerecords" [hidden]="isLoading" [border]="1" style="text-align: center;" matTableExporter
              #exporter="matTableExporter" mat-table [dataSource]="dataSource" class="pointer table-striped"
              [hiddenColumns]="[18]">

              <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>S.No.</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{paginator.pageIndex == 0 ? i + 1 : 1 + i + paginator.pageIndex * paginator.pageSize}}
                  <!-- {{paginator1.pageIndex == 0 ? i + 1 : 1 + i + paginator1.pageIndex * paginator1.pageSize}} -->
              </ng-container>

              <ng-container matColumnDef="booking_id">
                <th mat-header-cell *matHeaderCellDef> BOOKING ID</th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id);">
                  <span class="mat-tooltip-trigger" [matTooltip]="element.booking_id"
                    [matTooltipPosition]="'above'">{{element.booking_id}} </span>
                </td>
              </ng-container>


              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td style="text-align: center !important;" mat-cell *matCellDef="let element">
                  <!-- <span class="green-action pointer"
                      [ngClass]="{'green-danger':element.status !== 'ACTIVE', 'green-actions':element.status === 'ACTIVE'}"
                     >{{element.status}}</span> -->
                  <!-- <span [matTooltip]="element.status" [matTooltipPosition]="'above'">{{element.status}}</span> -->
                  <span class="green-action pointer" [matTooltip]="element.status" [matTooltipPosition]="'above'"
                    [ngClass]="{'greens':element.status === 'Pending confirmation from CBS','green':element.status === 'Pending Confirmation',
                    'green-danger':element.status === 'Rejected', 'green-action':element.status === 'Approved','green-reject':element.status ==='Cancel'}"
                    (click)="statusManager(element)">{{(element.status == "Approved") ? "Confirmed" : (element.status == "Pending confirmation from CBS") ? "APP Request" : element.status}}</span>

                </td>
              </ng-container>


              <!-- Name Column -->
              <ng-container matColumnDef="pickup_date">
                <th mat-header-cell *matHeaderCellDef> PICKUP DATE </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.pickup_date | date: 'dd-MM-yyyy' "
                    [matTooltipPosition]="'above'">{{element.pickup_date | date: 'dd-MM-yyyy'}} </span>
                </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="pickup_time">
                <th mat-header-cell *matHeaderCellDef> Pickup Time </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <!-- <span [matTooltip]="element.pickup_time" [matTooltipPosition]="'above'">{{element.pickup_time |
                    convertFrom24To12Format}}
                  </span> -->
                  <span [matTooltip]="element.pickup_time" [matTooltipPosition]="'above'">{{element.pickup_time}}
                  </span>
                  
                </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="guest_name">
                <th mat-header-cell *matHeaderCellDef> Guest Name </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.guest_name" [matTooltipPosition]="'above'">{{element.guest_name}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="guest_mobile">
                <th mat-header-cell *matHeaderCellDef> Guest Mobile </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.guest_mobile" [matTooltipPosition]="'above'">{{element.guest_mobile}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="trip_status">
                <th mat-header-cell *matHeaderCellDef> TRIP STATUS </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.trip_status" [matTooltipPosition]="'above'">{{element.trip_status}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="vehicle_no">
                <th mat-header-cell *matHeaderCellDef> Vehicle No </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.vehicle_no" [matTooltipPosition]="'above'">{{element.vehicle_no}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="ce_name">
                <th mat-header-cell *matHeaderCellDef> CE NAME </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.ce_name" [matTooltipPosition]="'above'">{{element.ce_name}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="ce_mobile">
                <th mat-header-cell *matHeaderCellDef> CE MOBILE </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.ce_mobile" [matTooltipPosition]="'above'">{{element.ce_mobile}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="vehicle_type">
                <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.vehicle_type" [matTooltipPosition]="'above'">{{element.vehicle_type}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="rental_type">
                <th mat-header-cell *matHeaderCellDef> Rental Type </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.rental_type" [matTooltipPosition]="'above'">{{element.rental_type}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="pickup_address">
                <th mat-header-cell *matHeaderCellDef> Pickup Address </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.pickup_address" [matTooltipPosition]="'above'">{{element.pickup_address}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="booking_source">
                <th mat-header-cell *matHeaderCellDef> Booking Source </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.booking_source" [matTooltipPosition]="'above'">{{element.booking_source}}
                  </span>
                </td>
              </ng-container>


              <ng-container matColumnDef="rental_city">
                <th mat-header-cell *matHeaderCellDef> Rental City </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.rental_city" [matTooltipPosition]="'above'">{{element.rental_city}}
                  </span>
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="guest_id">
                <th mat-header-cell *matHeaderCellDef> GUEST Id </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.guest_id" [matTooltipPosition]="'above'">{{element.guest_id}} </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="billing_location">
                <th mat-header-cell *matHeaderCellDef> Billing location </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.billing_location"
                    [matTooltipPosition]="'above'">{{element.billing_location}}
                  </span>
                </td>
              </ng-container> -->

              <ng-container matColumnDef="trip_tracking">
                <th mat-header-cell *matHeaderCellDef> TRIP TRACKING </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.trip_tracking" [matTooltipPosition]="'above'">{{element.trip_tracking}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="trip_no">
                <th mat-header-cell *matHeaderCellDef> TRIP NUMBER </th>
                <td mat-cell *matCellDef="let element" (click)="edit1(element.booking_id)">
                  <span [matTooltip]="element.trip_no" [matTooltipPosition]="'above'">{{element.trip_no}}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef class="print-none"> ACTION </th>
                <td class="edit" mat-cell *matCellDef="let element" class="print-none">

                  <div
                    *ngIf="helperService.checkMenuAccess('r_read') || helperService.checkMenuAccess('r_update') || helperService.checkMenuAccess('r_delete')">
                    <button mat-icon-button [matMenuTriggerFor]="menu" class="action"
                      aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button *ngIf="helperService.checkMenuAccess('r_read')" (click)="view(element.booking_id)"
                        mat-menu-item>
                        <mat-icon role="img"
                          class="mat-icon notranslate material-icons eye align-middle pointer hover-color"
                          aria-hidden="true" data-mat-icon-type="font">visibility</mat-icon>View
                      </button>
                      <button
                        *ngIf="element.vehicle_no == null &&  helperService.checkMenuAccess('r_update') && element.status != 'Rejected'"
                        (click)="edit(element.booking_id)" mat-menu-item>
                        <mat-icon class="edit align-middle pointer hover-color">edit</mat-icon>Edit
                      </button>
                      <button *ngIf="element.trip_status == 'Trip Closed' && is48Hrs(element.end_date)"
                        (click)="print(element.booking_id)" mat-menu-item><mat-icon
                          class="delete align-middle pointer hover-color">print</mat-icon>Print</button>

                      <!-- <button mat-menu-item >
                        <mat-icon class="delete align-middle pointer hover-color">delete</mat-icon>Print
                      </button> -->

                      <!-- <button mat-menu-item (click)="delete(element.booking_id)">
                      <mat-icon class="delete align-middle pointer hover-color">delete</mat-icon>Delete
                    </button> -->
                    </mat-menu>
                  </div>
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="this.dataSource.filteredData.length === 0" style="text-align: center">
              No records found
            </div>
            <!-- </div> -->

            <div class="col-md-12 mt-2 table" [hidden]="!isLoading">

              <table class="norecordfoundtable">
                <tr>
                  <th *ngFor="let valCol of toppingList"> {{ removeUnderScore(valCol) | uppercase }}</th>
                </tr>
                <tr>
                  <td style="text-align: center;" [attr.colspan]="toppingList.length">
                    {{'Loading.......'}}
                  </td>
                </tr>
              </table>
            </div>
            <!-- </section> -->
          </div>





          <custom-paginator [datalength]="dataSourceLength" [hidden]="isLoading" [pageSizeOptions]="[10,25,50]"
            justify-content="space-between">
          </custom-paginator>

          <!-- <ng-template #fourthDialog>
            <mat-icon class="close-button" [mat-dialog-close]="false" style="float: right;">close</mat-icon>
            <app-printpage></app-printpage>
          </ng-template> -->

          <ng-template #fourthDialog>
            <div class="dialog-header">
              <span *ngIf="!isMaximized" class="dialog-title"><mat-icon class="pointer" (click)="maximizeDialog()">fullscreen</mat-icon></span>              
              <mat-icon class="close-button" [mat-dialog-close]="false" style="float: right !important;">close</mat-icon>
            </div>
            <div class="dialog-content">
              <app-printpage></app-printpage>
            </div>
          </ng-template>