import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;
    forzaadmin: any = [];
    public isBackShow: boolean = false
    public mana: boolean = false
    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.forzaadmin = localStorage.getItem('customer_Role_name');
        this.ui = this.store.select('ui');
        setTimeout(() => {
            this.isBackShow = (localStorage.getItem('loginType') == 'manual') ? false : true;
        }, 600);
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
    }
}

let i = 0
export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/dashboard'],
        resource: 'CBS_Dashboard'
    },
    {
        name: 'Manage Customer Admin',
        iconClasses: 'fas fa-user-gear',
        path: ['/managecustomeradmin'],
        subpath: ['/managecustomeradmin', '/view-managecustomeradmin'],
        resource: 'CBS_Manage_Customer_Admin'
    },
    {
        name: 'Manage Direct Guest',
        iconClasses: 'fas fa-user-clock',
        path: ['/managedirectguest'],
        subpath: ['/managedirectguest', '/view-managedirectgues'],
        resource: 'CBS_Manage_Direct_Guest'
    },

    {
        name: 'Manage Customer Guest',
        iconClasses: 'fas fa-solid fa-user',
        path: ['/managecustomerguest'],
        subpath: ['/managecustomerguest', '/view-managecustomerguest', '/import-customerguest'],
        resource: 'CBS_Manage_Customer_Guest'
    },

    {
        name: 'Manage bookings',
        iconClasses: 'fas fa-list-check',
        path: ['/managebooking'],
        resource: 'Manage_booking',
        children: [
            {
                name: 'Web Booking',
                iconClasses: 'fas fa-user-plus',
                path: ['/webbooking'],
                subpath: ['/webbooking', '/add-webbooking', 'edit-webbooking', '/view-webbooking', '/import-web-booking'],
                resource: 'Customer_Booking'
            },

            {
                name: 'Guest Booking',
                iconClasses: 'fas fa-user-tag',
                path: ['/guestbooking'],
                subpath: ['/guestbooking', '/edit-guestbooking', '/view-guestbooking'],
                resource: 'Guest_Booking'
            }
        ]
    },
    {
        name: ' Invoice page',
        iconClasses: 'fas fa-file-lines',
        path:['/invoice'],
        resource: 'CBS_Generated_invoice_page'
    },
    // {
    //     name: 'Contract Invoice',
    //     iconClasses: 'fas fa-file-contract',
    //     //path: ['/contractinvoice'],
    //     //subpath: ['/contractinvoice'],
    //     resource: 'CBS_Contract_Invoice'
    // },
    {
        name: 'Outstanding payment',
        iconClasses: 'fas fa-file-invoice-dollar',
         path: ['/Outstanding'],
         subpath: ['/Outstanding'],
        resource: 'CBS_Outstanding_payment'
    },

    {
        name: 'Trip Sheet',
        iconClasses: 'fas fa-file-invoice-dollar',
        path: ['/trip-sheet'],
        resource: 'CBS_Trip_Sheet'
    },
    {
        name: 'Report',
        iconClasses: 'fas fa-flag',
        path: ['/reports/history-confirmation'],
        resource: 'CBS_Report',
        children: [
            {
                name: 'History of Confirmation',
                iconClasses: 'fas fa-history',
                path: ['/reports/history-confirmation'],
                resource: 'CBS_History_Confirmation'
            },

            {
                name: 'Panic Alerts',
                iconClasses: 'fas fa-exclamation-triangle',
                path: ['/reports/panic-alert'],
                resource: 'CBS_Panic_Alerts'
            }
        ]
    }



    

];

