import { Component, OnInit,ViewChild } from "@angular/core";
import { FormGroup,FormBuilder } from "@angular/forms";
import { Managecustomer } from "@services/managecustomerguest.service";
import { response } from "express";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { ViewpanicComponent } from "@pages/viewpanic/viewpanic.component";
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
const moment = _rollupMoment || _moment;
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ExportType, MatTableExporterDirective } from "mat-table-exporter";
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};



export interface PeriodicElement {
 
  alert_date:number,
  billing_location:string,
  rental_city:string,
  trip_no:number,
  guest_name:string,
  ce_name:string,
  vehicle:string,
  action_taken:string

}

@Component({
  selector: 'app-panic-alert',
  templateUrl: './panic-alert.component.html',
  styleUrls: ['./panic-alert.component.scss'],
  providers: [
  
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],

})
export class PanicAlert implements OnInit {
  searchform !: FormGroup;
  maxdate:any;
  from_Date: any;
  end_date: any;
  CurrentDate : any;
  isLoading:boolean = false;
  myplaceHolder: string ='dd-mm-yyyy'
  myplaceHolder1: string ='dd-mm-yyyy'
  action_taken:any;
  msg:any;
  to_date:any
  actionlist:any;
  actionlist1:any
  dataSourceLength: any;

  start_date:any
 constructor(
    private managecustomer: Managecustomer,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private dialog: MatDialog
    
  ) { }
  displayedColumns: string[] = ['alert_date','billing_location','rental_city','trip_no','guest_name','ce_name','customer_name', 'vehicle','action_taken'];
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
  dataSource = new MatTableDataSource<PeriodicElement>();
  @ViewChild(MatSort) sort = new MatSort();
//  @ViewChild(MatPaginator) paginator!: MatPaginator;


 ngOnInit(): void {
  this.from_Date = new Date();
    this.end_date = new Date();
 this.CurrentDate =  new Date().toISOString().split('T')[0];
 this.start_date = new Date();
//this.panicalertlist()

this.searchform = this.formBuilder.group({
  from_date: [this.from_Date, null],
  end_date: [this.end_date, null],
  action_taken:[this.action_taken,null]
  
})
this.submit();
 }

 

 submit(){
  
    var x =this.searchform.value.from_date;
    var y =this.searchform.value.end_date;
    let obj={
      from_date: this.datepipe.transform(x,'dd-MM-yyyy'),
      end_date:this.datepipe.transform(y,'dd-MM-yyyy'),
      action_taken:(this.searchform.value.action_taken != '') ? this.searchform.value.action_taken : null,
  
    }
    this.managecustomer.actiontakendata(obj).subscribe((response) =>{
      this.msg =response;
      if(this.msg.success == true){
        this.actionlist1 =this.msg.result;
        this.dataSource = new MatTableDataSource(
          this.actionlist1
      );
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSourceLength = this.msg.result.length;
     
      
      }
     })
      }


  public panicalertlist(){
    this.managecustomer.actiontakendata({}).subscribe((response) =>{
      this.msg =response;
      if(this.msg.success == true){
        this.actionlist =this.msg.result;
        this.dataSource = new MatTableDataSource(this.actionlist);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort; 
        this.dataSourceLength = this.msg.result.length; 
       
        
      
      }
    })
      }


     public openpage(action_taken,id){
     
      //if(action_taken== null){
        const dialogRef = this.dialog.open(ViewpanicComponent,{
          
          height: '600px',
          autoFocus:'false',
          width: 'auto',
          data:{id:id}
          
        }); 
      
        
        dialogRef.afterClosed().subscribe(result => {  
          this.panicalertlist()
        });
     // }

      }
      



clear(){

  this.searchform.reset();
  this.dataSource.filter = "0";
  this.panicalertlist();

}
reset(){
  this.end_date = null;
  this.from_Date = null;
}
checkPlaceHolder() {
  if (this.myplaceHolder) {
    this.myplaceHolder = null
    return;
  } else {
    this.myplaceHolder = 'dd-mm-yyyy'
    return
  }
}

checkPlaceHolder1() {
  if (this.myplaceHolder1) {
    this.myplaceHolder1 = null
    return;
  } else {
    this.myplaceHolder1 = 'dd-mm-yyyy'
    return
  }
}
ngAfterViewInit() {
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
}

exportIt(excelType, name, sheetname, authorName) {

  this.isLoading = true

  var exportType = ExportType.CSV;

  if (excelType == 'xlsx') {
    exportType = ExportType.XLSX;
  }

  if (excelType == 'csv') {
    exportType = ExportType.CSV;
  }


  this.exporter.exportTable(exportType, {
    fileName: name,
    sheet: sheetname,
    Props: {
      Author: authorName
    }
  })

  setTimeout(() => {
    this.isLoading = false
  }, Number(this.panicalertlist.length) * 2);
}

}
