import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Gatekeeper } from 'gatekeeper-client-sdk';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BackendserviceService } from './backendservice.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    constructor(
        private router: Router,
        private backendService: BackendserviceService,
        private cookieService: CookieService
    ) { }

    public getBranches(): Observable<{}> {
        return this.backendService
            .get('api/common/getbranches')
            .pipe(retry(1), catchError(this.handleError));
    }


    public getCustomer(data): Observable<{}> {
        return this.backendService
            .post('api/customerguest/getcustomer', data)
            .pipe(retry(1), catchError(this.handleError));
    }


    public getVechileData(data): Observable<{}> {
        return this.backendService
            .post('api/customerguest/getvehicle', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public rentalData(data): Observable<{}> {
        return this.backendService
            .post('api/customerguest/getrentaltype', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public createCustomerGuest(data): Observable<{}> {
        return this.backendService
            .post('api/customerguest/getrentaltype', data)
            .pipe(retry(1), catchError(this.handleError));
    }






    public getcustomerrole(): Observable<{}> {
        return this.backendService
            .get('api/common/getcustomerrole')
            .pipe(retry(1), catchError(this.handleError));
    }

    public getauthorisedrole(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/authorisedRole', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public getcustomersgroup(data): Observable<{}> {
        return this.backendService
            .post('api/common/getcustomersgroup', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public createCustomerAdmin(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/create', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public getCustomerAdminList(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/getlist', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public getCustomerAdminbyId(id): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/getbyid', id)
            .pipe(retry(1), catchError(this.handleError));
    }
    public updateCustomerAdminStatus(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/updatestatus', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public checkcustomeradminemail(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/checkemail', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public checkdirectguestemail(data): Observable<{}> {
        return this.backendService
            .post('api/directguest/checkemail', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public checkcustomerguestemail(data): Observable<{}> {
        return this.backendService
            .post('api/customerguest/checkemail', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public checkcustomeradminmobile(data): Observable<{}> {
        return this.backendService
          .post('api/customerguest/checkemobile', data)
          .pipe(retry(1), catchError(this.handleError));
      }
      
    public updateCustomerAdmin(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/update', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public updateCustomerAdmins(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/update', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public delete(data): Observable<{}> {
        return this.backendService
            .post('api/customeradmin/delete', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public uploadInvoice(data): Observable<{}> {
        return this.backendService
            .uploadPost('api/contractinvoice/upload', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public createInvoice(data): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/create', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public updateInvoiceFiles(data): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/update/fileNames', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public updateInvoice(data): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/update', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getInvoiceList(data): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/getInvoiceList', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public deleteInvoice(data): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/delete', data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public getInvoicebyId(id): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/getbyid', id)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getInvoiceimageId(fileName): Observable<{}> {
        return this.backendService
            .getFile('api/download/' + fileName)
            .pipe(retry(1), catchError(this.handleError));
    }

    public searchInvoice(data): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/searchInvoice', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public deleteInvoiceImage(data): Observable<{}> {
        return this.backendService
            .post('api/delete/invoiceImage', data)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getmonthList(data): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/getInvoicemonth',data)
            .pipe(retry(1), catchError(this.handleError));
    }
    public getmorefile(data): Observable<{}> {
        return this.backendService
            .post('api/contractinvoice/updatefile',data)
            .pipe(retry(1), catchError(this.handleError));
    }
    
    private handleError(error: HttpErrorResponse) {
        let errorMessage: string = '';
        if (error.error instanceof ErrorEvent) {
            //ClientSide Error--------
            errorMessage = `Error:${error.error.message}`;
        } else {
            //Server side Error---------
            errorMessage = `Status:${error.status}\n
                      Message:${error.message}`;
        }
        return throwError(errorMessage);
    }
}
