<div class="mx-4">
  <section class="content-header">
    <div class="container-fluid">
      <div>
        <span routerLink="/dashboard">Dashboard</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/managebooking">Manage Bookings</span> / <span class="activebreadcrumb-item active pointer"
          routerLink="/webbooking">Web Booking</span> / <span
          class="breadcrumb-item activebreadcrumb-item active">View</span>
      </div>
      <div class="row mt-md-2">
        <div class="col-auto">
          <img src="assets/images/mainicon-webbooking.png" alt="Forza Logo" style="object-fit:fill;width:50px;">
        </div>
        <div class="col">
          <div class="row flex-column justify-content-center mt-md-3">
            <div class="col">
              <h4 style="font-weight:600; color: #233771;">View Web Booking</h4>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <h6 class="pointer pt-3" routerLink="/webbooking"><span class="fas fa-angle-left p-2"></span>Back
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="container-fluid">
            <section>              
              <div class="form-heading p-0 pl-2 d-flex justify-content-between">
                <h2 class="form-heading2">Booking Information </h2>
                <span class="bookid" *ngIf="type == 'edit'">Booking ID :{{webbookinglist?.booking_id}}</span>
              </div><br>
              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Customer</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.customer_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Billing Location</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.billing_location}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Rental City</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.rental_city_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Booking Type</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.booking_type_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Pickup Date</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.pickup_date | date:'dd-MM-yyyy '}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">End Date</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.end_date | date:'dd-MM-yyyy'}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Pickup Time</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <!-- <p class="m-0 py-2 view-data">{{webbookinglist?.pickup_time | convertFrom24To12Format}}</p> -->
                    <p class="m-0 py-2 view-data">{{webbookinglist?.pickup_time}}</p>

                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Vehicle Type</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.vehicle_model_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Rental Type</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.rental_type_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Payment mode</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.paymode_defult_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Booking source</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.booking_source_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>              


              <h2 class="form-heading">Guest Details</h2>


              <div class="form-group row mb-0" style="overflow-x: auto !important;">
                <table style="width:100%;">
                  <tr class="view-label">

                    <td>Sl.no</td>
                    <td>Prefix</td>
                    <td>Guest Name</td>
                    <td>Guest Emp ID</td>
                    <td>Guest Mobile</td>
                    <td>Guest Mail ID</td>
                  </tr>
              <!-- <mat-divider *ngIf="quantitiesList.length > 0"></mat-divider> -->

                  <tr *ngFor="let childdata of quantitiesList;let i = index;">

                    <td><b>{{i+1}}</b></td>
                    <td><b>{{childdata.prefix}}</b></td>
                    <td><b>{{childdata.guest_name}}</b></td>
                    <td><b>{{childdata.guest_Employeeid}}</b></td>
                    <td><b>{{childdata.guest_mobile}}</b></td>
                    <td><b>{{childdata.guest_Email}}</b></td>
                  </tr>
                </table>
              </div>



              <br>
              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Pickup Address</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.pickup_address}} </p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Drop Address</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.drop_address}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Special Instructions</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.spl_instruction}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>


              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Flight Details</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.flight_details}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>


              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Booking status</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.booking_status}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <br>

              <h2 class="form-heading">Coordinatore Details</h2>
              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Coordinatore Name </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.coordinate_name}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Coordinatore Mobile </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.coordinate_mobile}} </p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Project ID </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.project_id}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Request ID </label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.request_id}} </p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">Comments</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.comments}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>

              <div class="form-group row mb-0">
                <label for="" class="col-md-3 col-form-label view-label">CC Mail IDs</label>
                <div class="col-sm-8">
                  <div class="input-group mb-2 mr-sm-2">
                    <p class="m-0 py-2 view-data">{{webbookinglist?.mail_id}}</p>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <br>
              <div class="aligh-button-right">
                <button routerLink="/webbooking" class="mat-button-cancel">Cancel</button>
                <button (click)="edit(webbookinglist.booking_id)" mat-button class="mat-button-submit">Edit</button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>