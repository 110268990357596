import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {Observable, throwError} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {BackendserviceService} from './backendservice.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerDirectguestService {

  constructor(   private router: Router,
    private backendService: BackendserviceService,
    private cookieService: CookieService) { }

    public createdirectguest(data): Observable<{}> {
      return this.backendService
          .post('api/directguest/create',data)
          .pipe(retry(1), catchError(this.handleError));
  }
  public getCustomerAdminList(): Observable<{}> {
    return this.backendService
        .get('api/directguest/list')
        .pipe(retry(1), catchError(this.handleError));
  }
  public getdirectguestbyId(id): Observable<{}> {
    return this.backendService
        .post('api/directguest/getbyid', id)
        .pipe(retry(1), catchError(this.handleError));
  }
  public updatedirectguestStatus(data): Observable<{}> {
    return this.backendService
        .post('api/directguest/updatestatus', data)
        .pipe(retry(1), catchError(this.handleError));
  }
  public checkdirectguestmail(data): Observable<{}> {
    return this.backendService
        .post('api/directguest/checkemail', data)
        .pipe(retry(1), catchError(this.handleError));
  }
  public updateDirectguest(data): Observable<{}> {
    return this.backendService
        .post('api/directguest/update', data)
        .pipe(retry(1), catchError(this.handleError));
  }
  public delete(id): Observable<{}> {
      return this.backendService
          .post('api/directguest/delete',id)
          .pipe(retry(1), catchError(this.handleError));
    }
 

  private handleError(error: HttpErrorResponse) {
      let errorMessage: string = '';
      if (error.error instanceof ErrorEvent) {
          //ClientSide Error--------
          errorMessage = `Error:${error.error.message}`;
      } else {
          //Server side Error---------
          errorMessage = `Status:${error.status}\n
                    Message:${error.message}`;
      }
      return throwError(errorMessage);
  }
}
