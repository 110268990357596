import {Component, OnInit, ViewChild} from '@angular/core';
import {IconOptions, MatIcon} from '@angular/material/icon';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogComponentComponent} from '@pages/dialog-component/dialog-component.component';
import {AppService} from '@services/customeradmin.service';
import {ToastrService} from 'ngx-toastr';
import {Managecustomer} from '@services/managecustomerguest.service';
import { HelperService } from '@services/helper.service';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';
import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';

export interface Customerguest {
    position: number;
    Customer_name: string;
    Employee_Location_name: string;
    Employee_Name: string;
    Employee_Mobile_Number: string;
    Employee_Email_ID: string;
     Vehicle_name: string;
    Rental_Type_name: string;
    Location_name: string;
   status: string;
    Actions: string;
}

@Component({
    selector: 'app-managecustomerguest',
    templateUrl: './managecustomerguest.component.html',
    styleUrls: ['./managecustomerguest.component.scss']
})
export class ManagecustomerguestComponent implements OnInit {
    
    msg: any;
    forzaadmin:any;
    userid:any;
    pgIndex = 2;
    id: any;
    firstLastButtons = true;
    pnDisabled = true;
    hdPageSize = true;
    dataSourceLength: any;

    customerguestlist: Customerguest[] = [];
    displayedColumns: string[] = [
        'position',
       'Customer_name',
        'Employee_Location_name',
        'Employee_Name',
        'Employee_Mobile_Number',
        'Employee_Email_ID',
        'Vehicle_name',
        'Rental_Type_name',
        'Location_name',
        'status',
        'Actions'
    ];
    dataSource = new MatTableDataSource<Customerguest>();
    @ViewChild(CustomPaginatorComponent,{static:true}) paginator:CustomPaginatorComponent
    @ViewChild(MatSort) sort = new MatSort();
    // @ViewChild(MatPaginator) paginator!: MatPaginator;

    toppingList = []
    isLoading: boolean = false;
    @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

    constructor(
        private router: Router,
        private matDialog: MatDialog,
        private managecustomer: Managecustomer,
        private toastr: ToastrService,
        public helperService: HelperService
    ) {}

    ngOnInit(): void {
        this.toppingList = this.displayedColumns;        
        this.forzaadmin =localStorage.getItem('customer_Role_name');
        this.userid =localStorage.getItem('userId');
        this.id = localStorage.getItem('Id');       
        this.customerGuestList();
        this.helperService.pageAccess('all')
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }
    
    public customerGuestList() {

        let data ={
            type:  this.forzaadmin,
            user_id: this.id 
        }

        this.managecustomer.getcustomerList(data).subscribe(
            (response) => {
                this.msg = response;
                if (this.msg.success == true) {
                    this.customerguestlist = this.msg.result;
                    this.dataSource = new MatTableDataSource(
                        this.customerguestlist
                    );
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.dataSourceLength = this.msg.result.length;
                }
            },
            (error) => {
                this.toastr.error(error, 'Error');
            }
        );
    }

  view(id) {
    if(Number(this.helperService.checkMenuAccess('r_read')) > 0) {
      this.router.navigate(['/view-managecustomerguest', btoa(id), 'view']);
    }
  }

  addcustomerguest(){
    this.router.navigate(['/managecustomerguest', 'add']); 
  }

  edit(id) {
      if(Number(this.helperService.checkMenuAccess('r_update')) > 0) {
        this.router.navigate(['/managecustomerguest', btoa(id), 'edit']);
      }
  }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        this.dataSourceLength = this.dataSource.filteredData.length
    }

    click(user) {
        user.active = !user.active;
        // your code here....
    }
    statusManager(element) {


        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            body: 'Are you sure want to change status?'
        };
        let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                
                this.managecustomer.updateCustomerGuestStatus(element).subscribe(
                    (response) => {
                        let responses: any = response;
                        if (responses.success === true) {
                            this.toastr.success(responses.message, 'Success');
                            element.status = responses.result
                        }
                    },
                    (error) => {
                        this.toastr.error(error, 'Error');
                    }
                );
          
            }
        });


    
  }

  delete(deleteid) {
    var idarray = {};
    idarray['id'] = deleteid;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
        body: 'Are you sure want to Delete?'
    };
    let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            this.managecustomer.delete(JSON.stringify(idarray)).subscribe(
                (response) => {
                    let responses: any = response;
                    if (responses.success === true) {
                        this.customerGuestList();
                        this.toastr.success(responses.message, 'Success');
                    }
                },
                (error) => {
                    this.toastr.error(error, 'Error');
                }
            );
        }
    });
}

exportIt(excelType,name, sheetname,authorName) {
    

    this.isLoading = true

    var exportType = ExportType.XLSX;

    if(excelType == 'xlsx'){
      exportType = ExportType.XLSX;
    }

    if(excelType == 'csv'){
      exportType = ExportType.CSV;
    }

   
    this.exporter.exportTable(exportType, {
      fileName: name,
      sheet: sheetname,
      Props: {
        Author: authorName
      }
    })
    
    setTimeout(() => {
      this.isLoading = false
    }, Number(this.customerguestlist.length) * 2);
  }


  removeUnderScore(val) {
    if (val == 'position') {
      val = 'ID';
    } else if (val == 'parent_name') {
      val = 'Parent Name';
    } else if (val == 'child_name') {
      val = 'Child Name';
    } else if (val == 'child_age') {
      val = 'Child Age';
    } else if (val == 'phone') {
      val = 'Phone';
    } else if (val == 'lead_date') {
      val = 'Created On';
    } else if (val == 'lead_status') {
      val = ' Lead Status';
    } else if (val == 'center_name') {
      val = 'CENTER';
    } else if (val == 'createdBy') {
      val = 'Created By';
    }

    return val.toString().replace(/_/gi, " ");
  }


}
