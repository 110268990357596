import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BackendserviceService {
    private apiUrl: string;

    Username = 'forza';
    Password = '&#$vfr$15';
    headerdata = new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Access-Control-Allow-Headers', 'Content-Type')
        .append('Access-Control-Allow-Methods', 'POST')
        .append('Access-Control-Allow-Origin', '*')
        .append(
            'Authorization',
            'Basic ' + btoa(this.Username + ':' + this.Password)
        );

    options = {headers: this.headerdata};

    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiEndPoint;
    }

    public post(url: string, body: any) {
        return this.http.post(`${this.apiUrl}${url}`, body, this.options);
    }

    public uploadPost(url: string, body: any) {
        let fileheaderdata = new HttpHeaders()
            .append('Access-Control-Allow-Headers', 'Content-Type')
            .append('Access-Control-Allow-Methods', 'POST')
            .append('Access-Control-Allow-Origin', '*')
            .append(
                'Authorization',
                'Basic ' + btoa(this.Username + ':' + this.Password)
            );
        let fileoptions = {headers: fileheaderdata};
        return this.http.post(`${this.apiUrl}${url}`, body, fileoptions);
    }

    public get(url: string): Observable<any> {
        console.log(this.options);
        return this.http.get(`${this.apiUrl}${url}`, this.options);
    }

    public getFile(url: string): Observable<any> {
        let fileheaderdata = new HttpHeaders()
        .append('Access-Control-Allow-Headers', 'Content-Type')
        .append('Access-Control-Allow-Methods', 'POST')
        .append('Access-Control-Allow-Origin', '*')
        
        .append(
            'Authorization',
            'Basic ' + btoa(this.Username + ':' + this.Password)
        );
    let fileoptions = {headers: fileheaderdata, responseType: 'blob' as 'json'};
        return this.http.get(`${this.apiUrl}${url}`, fileoptions);
    }
    public delete(url: string,id): Observable<any> {
        return this.http.delete(`${this.apiUrl}${url}`, this.options);
    }
}
