import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AppService} from '@services/customeradmin.service';
import {ToastrService} from 'ngx-toastr';
import { HelperService } from '@services/helper.service';
@Component({
    selector: 'app-view-managecustomeradmin',
    templateUrl: './view-managecustomeradmin.component.html',
    styleUrls: ['./view-managecustomeradmin.component.scss']
})
export class ViewManagecustomeradminComponent implements OnInit {

    public formType: any;
    customeradminresp: any;
    idarray: any[] = [];
    public id: string;
    locationArr:[]
    userId:any;
    customer:[]
    constructor(
        private router: Router,
        private location: Location,
        public activatedRoute: ActivatedRoute,
        private appService: AppService,
        private toastr: ToastrService,
        public helperService: HelperService,
    ) {}

    ngOnInit(): void {
        this.userId = Number(localStorage.getItem('Id'));
  
        this.helperService.pageAccess('r_read')        
        this.id = atob(this.activatedRoute.snapshot.url[1].path);
        this.getCustomerAdminbyId();
    }

    getCustomerAdminbyId = () => {
        var idarray = {

            id:this.id,
            user_id:this.userId
        };
       // idarray['id'] = this.id;
        this.appService.getCustomerAdminbyId(idarray).subscribe(
            (response) => {
                let responses: any = response;
               
                
                if (responses.success == true) {
                    this.customeradminresp = responses.result;
                    this.locationArr = responses.result.locationAuthorised_name.split(',').join(',  ');
                    //this.customer =responses.result.customer_Name_array.split(',').join(', ');
                  
                    
                }
            },
            (error) => {
                this.toastr.error(error, 'Error');
            }
        );
    };
    back() {
        this.location.back();
    }
  
    edit(id) {
        this.router.navigate(['/managecustomeradmin', btoa(id), 'edit']);
    }
}
