<div class="text-center">
    <img src="assets/images/inner_logo.png" alt="Forza Logo" style="width: 100%;">
    <p class="col-orange f-12 m-t-10 font-bold"></p>
  </div>

<div class="card card-outline card-primary">
    
    <div class="card-body">
        <p class="login-box-msg">
            You forgot your password? Here you can easily retrieve a new
            password.
        </p>
        <form [formGroup]="forgotPasswordForm" >
            <div class="input-group mb-3">
                <input
                    formControlName="username"
                    type="username"
                    class="form-control"
                    placeholder="username"
                    [ngClass]="{ 'is-invalid': submitted && f['username'].errors }">
                <div class="input-group-append is-invalid">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
                <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                    <div *ngIf="f['username'].errors">username is required</div>
                  </div>
            </div>

            <div class="row">
                <div class="col-12">
                       
                        <button mat-button  class="mat-button-submit"  (click)="forgotPassword()"> Request new password</button>

                   
                </div>
            </div>
        </form>
        <p class="mt-3 mb-1">
            <a [routerLink]="['/login']">Login</a>
        </p>
    </div>
</div>
