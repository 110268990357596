import { DatePipe } from '@angular/common';
import { Component,TemplateRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponentComponent } from '@pages/dialog-component/dialog-component.component';
import { AppService } from '@services/app.service';
import { Managecustomer } from '@services/managecustomerguest.service';
import { ToastrService } from 'ngx-toastr';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomPaginatorComponent } from '@pages/custom-paginator/custom-paginator.component';



import { ExportType, MatTableExporterDirective } from 'mat-table-exporter';
import { HelperService } from '@services/helper.service';
import {MatPaginatorModule} from '@angular/material/paginator';

// import printJS from 'print-js'


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface PeriodicElement {
  pickup_date: number;
  position: number;
  pickup_time: number;
  guest_name: string;
  guest_mobile: number;
  project_id: number;
  booking_source: string;
  rental_city: string;
  pickup_address: string;
  rental_type: string;
  vehicle_type: string;
  special_instruction: string;
  status: string;
  Actions: string;

}


@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BlankComponent implements OnInit {

  @ViewChild('fourthDialog', { static: true }) fourthDialog: TemplateRef<any>;

  isMaximized = false;

  searchform !: FormGroup;
  location: any;
  sd: any;
  from_Date: any;
  end_date: any;
  author: any;
  filter_option: any;
  key_word: any;
  pagenumber:any;
  forzaadmin: any = [];
  public id: string;
  userid: any = [];
  locationFilter: any;
  getdropdown:any;
  dataSourceLength: any;
  dataSourceLength1: any;
  webbookinglist:any;
  locationFilter1: any
  maxDate = new Date();
  minDate = new Date();
  myplaceHolder: string = 'dd-mm-yyyy'
  myplaceHolder1: string = 'dd-mm-yyyy'
  page:any;
  //For print Config
  public config = {
    printMode: 'template-popup',
    popupProperties: 'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Hello World',
    templateString: '<header>Forza CBS</header>{{printBody}}<footer>Copyright © 2022 forza.com. All rights reserved</footer>',
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],
    styles: ['td { border: 1px solid black; }', 'th {background-color: #edf2f9;}', 'table { border: 1px solid black; padding: 10px; }', 'header, table, footer { margin: auto; text-align: center; }']
  }
  //Print Config



  displayedColumns: string[] = ['position', 'booking_id', 'status', 'pickup_date', 'pickup_time', 'guest_name', 'guest_mobile', 'trip_status','vehicle_no', 'ce_name', 'ce_mobile', 'vehicle_type', 'rental_type', 'pickup_address', 'booking_source', 'rental_city', 'trip_tracking', 'trip_no',  'Actions'];

  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;
  toppingList = []
  isLoading: boolean = false;
  isLoading1: boolean = false;
  dataSource = new MatTableDataSource<PeriodicElement>();
  
  @ViewChild(CustomPaginatorComponent, { static: true }) paginator: CustomPaginatorComponent
  // @ViewChild(CustomPaginator1Component, { static: true }) paginator1: CustomPaginator1Component

    // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort = new MatSort();

  msg: any;
  filname: any;
  webbooklist: any = [];
  numberlist:any =[];
  base_Location: any;
  submitted = false;
  respo: any;
  branches: any;
  filterdata: any;
  getCountryMasterData = [];
  public baselocationlist = [];
  public filterdatas = [];
  maxdate: any;
  CurrentDate: any
  RequestCount: any = 0;
  ConfirmedCount: any = 0;
  PendingCount: any = 0;
  PendingCBSCount: any = 0;
  RejectedCount: any = 0;
  CancelCount:any = 0;
  log_id: any;

  datasourceDialog:any
  


  // matDialog: any;
  constructor(
    private router: Router,
    public datepipe: DatePipe,
    private managecustomer: Managecustomer,
    private appService: AppService,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    public helperService: HelperService,
    public dialog: MatDialog,

  ) { }



  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }



  ngOnInit(): void { 
    this.from_Date = new Date();
    this.end_date = new Date();
    this.toppingList = this.displayedColumns;
    this.id = localStorage.getItem('Id');
    this.log_id = localStorage.getItem('Id');
    this.forzaadmin = localStorage.getItem('customer_Role_name');
    this.userid = localStorage.getItem('userId');
    this.CurrentDate = new Date().toISOString().split('T')[0];
    
    //this.getmanagebookinglist();
    this.getBranches();
    
    this.getFilter()
    this.searchform = this.formBuilder.group({
      from_date: [this.from_Date, null],
      end_date: [this.end_date, null],
      base_Location: ['', null],
      filter_option: ['', null],
      key_word: ['', null],
    })

    const value = JSON.parse(localStorage.getItem('filterData_Managebooking'));

    console.log("datatatat", value);


    if (value !== null) {

      this.from_Date = new Date(value.from_date);
      this.end_date = new Date(value.end_date);

      this.base_Location = value.location;
      this.filter_option = value.filter;
      this.key_word = value.keyword;

      this.searchform = this.formBuilder.group({
        from_date: [value.from_date, null],
        end_date: [value.end_date, null],
        base_Location: [value.location, null],
        filter_option: [value.filter, null],
        key_word: [value.keyword, null],
      })
      this.submit();
    }
    
    this.submit();
    this.getManageBookingCount();

    this.isLoading = true
  }

  is48Hrs(e){    
    let oneDay = Date.parse(new Date().toDateString());
    let secDay = Date.parse(e);
    let ffHrs = 60 * 60 * 48 * 1000;
    let finalVal = secDay+ffHrs
      if(finalVal <= oneDay) {
        return true
      } else {
        return false
      }       
  }

  addguestbooking() {
    this.router.navigate(['/add-webbooking']);
  }
  pending(){
        
    this.router.navigate(['/webbooking'],{ queryParams: { from: (this.from_Date != "" && this.from_Date != null) ? this.from_Date : "reset" ,to: (this.end_date != "" && this.end_date != null) ? this.end_date : "reset" } });
    
  }
  pendingcbs(){
    this.router.navigate(['/guestbooking'],{ queryParams: { from: (this.from_Date != "" && this.from_Date != null) ? this.from_Date : "reset" ,to: (this.end_date != "" && this.end_date != null) ? this.end_date : "reset" } });
  }

  view(id) {
    if(Number(this.helperService.checkMenuAccess('r_read')) > 0) {
    this.router.navigate(['/view-booking', btoa(id), 'view']);
    }
  }

  edit(id) {
    if(Number(this.helperService.checkMenuAccess('r_update')) > 0) {
      let abcd = this.webbooklist.filter(item => item.booking_id == id)[0];
      if (abcd != undefined) {
        if(abcd.vehicle_no) {
          this.router.navigate(['/view-booking', btoa(id), 'view']);
        } else {
          this.router.navigate(['/edit-booking', btoa(id), 'edit']);
        }
      }
      
    }
  }

  edit1(id) {
    console.log("ok");
    
    // this.editmode= true;

    if(Number(this.helperService.checkMenuAccess('r_update')) > 0) {
      let abcd = this.webbooklist.filter(item => item.booking_id == id)[0];
      if (abcd != undefined) {
        if(abcd.vehicle_no) {
          this.router.navigate(['/view-booking', btoa(id), 'view']);
        } else {
          this.router.navigate(['/edit-booking', btoa(id), 'edit1']);
        }
      }
      
    }
  }

  // rowclick(){
  //   console.log("gvh");
    
  //   this.editmode= true;
  // }
  delete(deleteid) {
    var idarray = {};
    idarray['booking_id'] = deleteid;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      body: 'Are you sure want to Delete?'
    };
    let dialogRef = this.matDialog.open(DialogComponentComponent, dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.managecustomer.deletedata(JSON.stringify(idarray)).subscribe(
          (response) => {
            let responses: any = response;
            if (responses.success === true) {
              this.getmanagebookinglist();
              this.toastr.success(responses.message, 'Success');
            }
          },
          (error) => {
            this.toastr.error(error, 'Error');
          }
        );
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    // console.log(this.dataSource.filteredData.length);
    this.dataSourceLength = this.dataSource.filteredData.length

    
  }

  clear() {
    localStorage.removeItem('filterData_Managebooking');

    this.searchform.reset();
    this.dataSource.filter = "0";
    this.getmanagebookinglist();
    this.getManageBookingCount();
    if (this.baselocationlist.length == 1) {
      this.locationFilter = this.baselocationlist[0].location_name;
      this.searchform.get("base_Location").setValue(this.locationFilter);
    }
  }

  reset() {
    this.from_Date = null;
    this.end_date = null;
  }
 
  getmanagebookinglist() {
    let obj = {
      "user_id": Number(this.log_id),
    
     
    }
    this.managecustomer.getmanagelist(obj).subscribe((response) => {
      this.msg = response;
      if (this.msg.success == true) {
        this.webbooklist = this.msg.Manage_booking_List;
        this.dataSource = new MatTableDataSource(this.webbooklist);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSourceLength = this.msg.Manage_booking_List.length
        this.isLoading = false
        console.log(this.webbooklist);

        console.log(this.webbooklist.end_date);
        
      }
    })
   // this.getwebbooking()
  }

  // getwebbooking() {
  //   let data = {
  //     booking_id: this.id
  //   }
  //   this.managecustomer.getprint(data).subscribe((res => {
  //   console.log(res);
  //   this.msg=res;
    
  //     if ( this.msg.success == true) {
  //       this.webbookinglist = this.msg.Trip_Sheet_List[0];
  //       console.log(this.msg.is_button);
        
       
  //     }
  //   }))
  // }

  getManageBookingCount() {
    var x = this.searchform?.value.from_date;
    var y = this.searchform?.value.end_date;
    let obj = {
      "user_id": Number(this.log_id),
      from_date: (x) ? (this.datepipe.transform(x, 'dd-MM-yyyy')) : "",
      end_date: (y) ? (this.datepipe.transform(y, 'dd-MM-yyyy')) : "",
      location: (this.searchform?.value.base_Location != '') ? this.searchform?.value.base_Location : null,
      filter: (this.searchform?.value.filter_option != '') ? this.searchform?.value.filter_option : null ,
      keyword: (this.searchform?.value.key_word != '') ? this.searchform?.value.key_word : null 
    }
    this.managecustomer.getManageListCount(obj).subscribe((response) => {
      this.msg = response;
      if (this.msg.success == true) {
        this.RequestCount = this.msg.Request;
        this.ConfirmedCount = this.msg.Confirmed;
        this.PendingCount = this.msg.Pending;
        this.PendingCBSCount = this.msg.CBSPending;
        this.RejectedCount = this.msg.Rejected;
        this.CancelCount = this.msg.Cancelled;
      }
    })
  }

 
  printPage() {
    // printJS(this.src);
    var divToPrint = document.getElementById("tablerecords");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
    newWin.print();
    newWin.close();
  }

  getBranches = () => {
    let data = {
      type: this.forzaadmin,
      user_id: this.id
    }
    this.appService.getlocation(data).subscribe(
      response => {
        this.respo = response
        if (this.respo.success == true) {
          this.getCountryMasterData = this.respo.Location_List;
          this.getCountryMasterData = this.getCountryMasterData.sort((a, b) => {
            if (a.location_id < b.location_id) { return -1; }
            if (a.location_id > b.location_id) { return 1; }
          })
          this.baselocationlist = this.getCountryMasterData.slice();
          this.locationFilter = this.baselocationlist[0].location_name;
        }
      });
  }


  getFilter = () => {
    this.appService.getfilterdata({}).subscribe(
      response => {
        this.respo = response
        if (this.respo.success == true) {
          this.filterdata = this.respo.Filter;
          //this.filterdata = this.branches;      
          this.filterdata = this.filterdata.sort((a, b) => {
            if (a.Filter < b.Filter) { return -1; }
            if (a.Filter > b.Filter) { return 1; }
          })
          this.filterdatas = this.filterdata.slice();
        }
      });

  }

  submit() {
    var x = this.searchform.value.from_date;
    var y = this.searchform.value.end_date;
    let obj = {
      user_id: Number(this.log_id),
      from_date: this.datepipe.transform(x, 'dd-MM-yyyy'),
      end_date: this.datepipe.transform(y, 'dd-MM-yyyy'),
      location: (this.searchform.value.base_Location != '') ? this.searchform.value.base_Location : null,
      filter: (this.searchform.value.filter_option != '') ? this.searchform.value.filter_option : null ,
      keyword: (this.searchform.value.key_word != '') ? this.searchform.value.key_word : null 
    }

    this.managecustomer.getmanagelist(obj).subscribe((response) => {
      this.msg = response;
      if (this.msg.success == true) {
        var x = this.searchform.value.from_date;
        var y = this.searchform.value.end_date;
        obj.from_date = x;
        obj.end_date = y;

        console.log(obj);
        localStorage.setItem('filterData_Managebooking', JSON.stringify(obj));
        this.getManageBookingCount();
          this.isLoading = false
        this.webbooklist = this.msg.Manage_booking_List;
        this.dataSource = new MatTableDataSource(
          this.webbooklist
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSourceLength = this.msg.Manage_booking_List.length
      }      
    })
  }

  statusManager(element) {

  }

  checkPlaceHolder() {
    if (this.myplaceHolder) {
      this.myplaceHolder = null
      return;
    } else {
      this.myplaceHolder = 'dd-mm-yyyy'
      return
    }
  }

  checkPlaceHolder1() {
    if (this.myplaceHolder) {
      this.myplaceHolder1 = null
      return;
    } else {
      this.myplaceHolder1 = 'dd-mm-yyyy'
      return
    }
  }


  
  exportIt(excelType, name, sheetname, authorName) {

    this.isLoading = true

    var exportType = ExportType.CSV;

    if (excelType == 'xlsx') {
      exportType = ExportType.XLSX;
    }

    if (excelType == 'csv') {
      exportType = ExportType.CSV;
    }


    this.exporter.exportTable(exportType, {
      fileName: name,
      sheet: sheetname,
      Props: {
        Author: authorName
      }
    })

    setTimeout(() => {
      this.isLoading = false
    }, Number(this.webbooklist.length) * 2);
  }


  

  removeUnderScore(val) {
    if (val == 'position') {
      val = 'ID';
    } else if (val == 'parent_name') {
      val = 'Parent Name';
    } else if (val == 'child_name') {
      val = 'Child Name';
    } else if (val == 'child_age') {
      val = 'Child Age';
    } else if (val == 'phone') {
      val = 'Phone';
    } else if (val == 'lead_date') {
      val = 'Created On';
    } else if (val == 'lead_status') {
      val = ' Lead Status';
    } else if (val == 'center_name') {
      val = 'CENTER';
    } else if (val == 'createdBy') {
      val = 'Created By';
    }

    return val.toString().replace(/_/gi, " ");
  }

  print(id){
    const dialogRef = this.dialog.open(this.fourthDialog, {
      width: '70%',
      height:'80%',
     data:{'id':id},
     autoFocus: false,
      restoreFocus: false

    });

    this.datasourceDialog = id
  }
  

  maximizeDialog(): void {
    this.isMaximized =  true;
    const dialogRef = this.dialog.open(this.fourthDialog, {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      data:{'id':this.datasourceDialog},
      panelClass: 'maximized-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
    this.isMaximized =  false;

      // Dialog closed logic here
    });
  }

  closeDialog(): void {
    // this.dialog.close(this.maximizeDialog);
  }
}
